import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HeartIcon from "../../../../utilities/organs/HeartIcon";
import KidneyIcon from "../../../../utilities/organs/KidneyIcon";
import LiverIcon from "../../../../utilities/organs/LiverIcon";
import LungIcon from "../../../../utilities/organs/LungIcon";
import PancreasIcon from "../../../../utilities/organs/PancreasIcon";
import IntestineIcon from "../../../../utilities/organs/IntestineIcon";
import { formatTwoDecimals } from "../../../../utilities/formatter";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

function TrendsInOrgansTransplantedPerDonorChart(props) {
  let exportTitle = props.title;

  let subTitle = props.subTitle;

  let _data = [];
  for (let i = 0; i < props.chartData.length; i++) {
    _data.push(props.chartData[i].value);
  }

  let series = [
    {
      name: props.organ.toUpperCase(),
      data: _data,
      color: "#43a7cb",
      fillOpacity: 0.8,
    },
  ];

  let options = {
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "printChart",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "downloadCSV",
          ],
        },
      },
      title: {
        text: `<div style="font-family:Open Sans;font-size:14px;font-weight: 700;text-align:center;">${exportTitle}</div><br /><div style="font-family:Open Sans;font-size:12px;font-weight: 400;text-align:center;">${subTitle}</div>`,
      },
      chart: {
        events: {
          load: function () {
            let width = 0;
            let height = this.chartHeight - 50;
            this.renderer.image("/srtr_logo.jpg", width, height, 50, 50).add();
          },
        },
      },
    },
    chart: {
      type: "area",
      borderColor: "#d2d2d2",
      borderRadius: 0,
      borderWidth: 1,
      marginRight: 25,
    },
    title: "",
    xAxis: {
      min: 0.5,
      max: 3.5,
      crosshair: {
        width: 3,
        color: "green",
      },
      labels: {
        style: {
          fontFamily: "Open Sans",
          fontSize: "12px",
        },
      },
      categories: props.categories,
    },
    yAxis: {
      title: {
        style: {
          fontFamily: "Open Sans",
          fontSize: "14px",
        },
        text: "Transplants per donor",
      },
      labels: {
        style: {
          fontFamily: "Open Sans",
          fontSize: "14px",
        },
      },
    },
    tooltip: {
      crosshairs: true,
      borderWidth: 0,
      backgroundColor: "white",
      borderRadius: 10,
      shadow: false,
      useHTML: true,
      formatter: function () {
        return (
          '<div style="text-align:center"><span style="font-family:Open Sans;font-size:16px;font-weight: 700;text-align:left">' +
          this.x +
          ' </span><br/><span style="font-family:Open Sans;font-size:14px;font-weight: 400;text-align:left">' +
          formatTwoDecimals(this.y) +
          "</span></div>"
        );
      },
    },
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.5,
      },
      area: {
        lineColor: "#333333",
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: "#333333",
        },
      },
    },
    series: series,
  };

  let title = "Chart title";
  if (props.organ === "heart") {
    title = (
      <div className="organ-title">
        <div>
          <HeartIcon showHover={true} />
        </div>
        Heart Transplants
      </div>
    );
  } else if (props.organ === "kidney") {
    title = (
      <div className="organ-title">
        <div>
          <KidneyIcon showHover={true} />
        </div>
        Kidney Transplants
      </div>
    );
  } else if (props.organ === "liver") {
    title = (
      <div className="organ-title">
        <div>
          <LiverIcon showHover={true} />
        </div>
        Liver Transplants
      </div>
    );
  } else if (props.organ === "lung") {
    title = (
      <div className="organ-title">
        <div>
          <LungIcon showHover={true} />
        </div>
        Lung Transplants
      </div>
    );
  } else if (props.organ === "pancreas") {
    title = (
      <div className="organ-title">
        <div>
          <PancreasIcon showHover={true} />
        </div>
        Pancreas Transplants
      </div>
    );
  } else if (props.organ === "intestine") {
    title = (
      <div className="organ-title">
        <div>
          <IntestineIcon showHover={true} />
        </div>
        Intestine Transplants
      </div>
    );
  }
  return (
    <div className="donors-organs-transplanted">
      <Row>
        <Col>
          {options.xAxis.categories && (
            <>
              <div className="title">{title}</div>
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
                oneToOne={true}
                containerProps={{ className: "highcharts-light" }}
              />
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default TrendsInOrgansTransplantedPerDonorChart;
