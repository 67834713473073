import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);

function TransplantRatioScatterPlotChart(props) {

    Highcharts.setOptions({
        lang: {
            thousandsSep: ','
        }
    });

    const [chartOptions, setOptions] = useState({
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ['printChart', 'downloadPNG', 'downloadSVG', 'downloadPDF', 'downloadCSV']
                },
            },
            title: {
                text: `<div style="font-family:Open Sans;font-size:14px;font-weight: 700;text-align:center;">${props.title}</div><br /><div style="font-family:Open Sans;font-size:12px;font-weight: 400;text-align:center;"></div>`
            },
            chart: {
                events: {
                    load: function () {
                        let width = 0;
                        let height = this.chartHeight - 50;
                        this.renderer.image('/srtr_logo.jpg', width, height, 50, 50)
                            .add();
                    }
                }
            }
        },
        lang: {
            noData: `${props.centerCode} did not perform any transplants meeting <br />these criteria during the timeframe studied`,
        },
        chart: {
            type: "scatter",
            zoomType: "xy"
        },
        credits: {
            enabled: false
        },
        accessibility: {
            description: ""
        },
        xAxis: {
            min: 0,
            title: {
                text: props.xAxisLabel,
                style: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"
                }
            },
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true,
            labels: {
                style: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"
                }
            }
        },
        yAxis: {
            type: 'logarithmic',
            title: {
                text: props.yAxisLabel,
                style: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"
                }
            },
            plotLines: [{
                dashStyle: "solid",
                value: 1,
                width: 4
            }],
            labels: {
                style: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"
                },
                format: '{value:.2f}'
            }
        },
        legend: {
            itemStyle: {
                fontFamily: "Open Sans",
                fontSize: "14px"
            }
        },
        tooltip: {
            useHTML: true,
            headerFormat: "",
            pointFormat: `
                <div style="padding-bottom: 5px;">
                    <span style="font-family:Open Sans;font-size:16px;font-weight: 700;text-align:left;padding-bottom:10px;">{point.centerName}</span>
                </div>
                <div style="padding-bottom: 5px;">
                    <span style="font-family:Open Sans;font-size:16px;font-weight: 700;text-align:left">${props.ratioLabel}: </span>
                    <span style="font-family:Open Sans;font-size:16px;font-weight: 400;text-align:left">{point.y:.2f}</span> 
                </div>
                <div style="padding-bottom: 5px">
                    <div style="display:inline-block;font-family:Open Sans;font-size:16px;font-weight: 700;text-align:left;">${props.xAxisLabel}: </span>
                    <span style="font-family:Open Sans;font-size:16px;font-weight: 400;text-align:left">{point.x:,.0f}</span>
                </div>`
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: 10
                }
            },
            series: {
                states: {
                    inactive: {
                        opacity: 1
                    }
                }
            }
        }
    });

    Highcharts.Axis.prototype.log2lin = function (num) {
        return Math.log(num) / Math.LN2;
    };

    Highcharts.Axis.prototype.lin2log = function (num) {
        return Math.pow(2, num);
    };

    useEffect(() => {

        let series = [];
        let otherCentersData = [];
        if (props.otherCentersData) {
            otherCentersData = props.otherCentersData.filter(x => x[props.rate] !== null).map(function (obj) {
                return { x: obj[props.programVolume], y: obj[props.rate], centerName: obj.centerName }
            });
        }
        series.push({
            name: "Other Centers",
            marker: {
                symbol: "circle",
                lineWidth: 1
            },
            data: otherCentersData,
            color: "#e45d2c"
        });

        series.push(
            {
                name: props.centerCode,
                data: props.centerRate ? [{ x: props.centerProgramVolume, y: props.centerRate, centerName: props.centerCode }] : [],
                color: "#182474",
                marker: {
                    symbol: "circle",
                    lineWidth: 1
                }
            }
        );

        setOptions({
            ...chartOptions,
            title: {
                text: `<div style="font-family:Open Sans;font-size:20px;font-weight: 400;text-align:center;">${props.title}</div>`
            },
            series
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    return (
        <div className="transplant-grafts-chart-container mt-4">
            <HighchartsReact highcharts={Highcharts} options={chartOptions} oneToOne={true} containerProps={{ className: "highcharts-light" }}/>
        </div>
    )
}

export default TransplantRatioScatterPlotChart;