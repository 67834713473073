import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Table from "react-bootstrap/Table";
import { formatOneDecimal, formatThousands } from "../../../utilities/formatter";
import MedicalUrgencyStatus from "./MedicalUrgencyStatus";
import PeakPRA from "./PeakPRA";
import PrimaryDisease from "./PrimaryDisease";
import YearsSinceDiabetes from "./YearsSinceDiabetes";
import Demographics from "./Demographics";

function TimeToTransplantTable(props) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <div className="collapse-container show-all-row">
                View all time to transplant data &nbsp;&nbsp;
                <Button
                    onClick={() => setOpen(!open)}
                    aria-controls="collapse-text"
                    aria-expanded={open}
                    className="btn-dark-blue min-98"
                >
                    {!open && "Show All"}
                    {open && "Hide All"}
                </Button>
            </div>
            <Collapse in={open}>
                <div id="collapse-text" className="collapse-inner">
                    <Table responsive striped className="overflow-auto time-to-transplant-table" borderless>
                        <thead>
                            <tr>
                                <td></td>
                                <th className="text-center p-0" colSpan="10">
                                    Percent transplanted at time periods since listing
                                </th>
                            </tr>
                            <tr>
                                <td></td>
                                <th colSpan="5" className="text-center p-0">
                                    This Center
                                </th>
                                <th colSpan="5" className="text-center p-0">
                                    United States
                                </th>
                            </tr>
                            <tr>
                                <th className="border-bottom p-0">Characteristic</th>
                                <th className="text-center border-bottom p-0">
                                    N
                                </th>
                                <th className="text-center border-bottom p-0">
                                    30 day
                                </th>
                                <th className="text-center border-bottom p-0">
                                    1 year
                                </th>
                                <th className="text-center border-bottom p-0">
                                    2 years
                                </th>
                                <th className="text-center border-bottom p-0">
                                    3 years
                                </th>
                                <th className="text-center border-bottom p-0">
                                    N
                                </th>
                                <th className="text-center border-bottom p-0">
                                    30 day
                                </th>
                                <th className="text-center border-bottom p-0">
                                    1 year
                                </th>
                                <th className="text-center border-bottom p-0">
                                    2 years
                                </th>
                                <th className="text-center border-bottom p-0">
                                    3 years
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-left font-weight-bold">All</td>
                                <td className="text-center">{formatThousands(parseFloat(props.data.tpc_all_nc))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_all_pct1))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_all_pct2))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_all_pct3))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_all_pct4))}</td>
                                <td className="text-center">{formatThousands(parseFloat(props.data.tpc_all_nu))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_all_put1))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_all_put2))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_all_put3))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_all_put4))}</td>
                            </tr>
                            <Demographics organ={props.organ} data={props.data} />
                            <tr>
                                <td colSpan="11" className="text-left font-weight-bold">Blood Type</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">O</td>
                                <td className="text-center">{formatThousands(parseFloat(props.data.tpc_bo_nc))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bo_pct1))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bo_pct2))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bo_pct3))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bo_pct4))}</td>
                                <td className="text-center">{formatThousands(parseFloat(props.data.tpc_bo_nu))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bo_put1))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bo_put2))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bo_put3))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bo_put4))}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">A</td>
                                <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ba_nc))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ba_pct1))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ba_pct2))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ba_pct3))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ba_pct4))}</td>
                                <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ba_nu))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ba_put1))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ba_put2))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ba_put3))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ba_put4))}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">B</td>
                                <td className="text-center">{formatThousands(parseFloat(props.data.tpc_bb_nc))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bb_pct1))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bb_pct2))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bb_pct3))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bb_pct4))}</td>
                                <td className="text-center">{formatThousands(parseFloat(props.data.tpc_bb_nu))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bb_put1))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bb_put2))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bb_put3))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bb_put4))}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">AB</td>
                                <td className="text-center">{formatThousands(parseFloat(props.data.tpc_bab_nc))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bab_pct1))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bab_pct2))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bab_pct3))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bab_pct4))}</td>
                                <td className="text-center">{formatThousands(parseFloat(props.data.tpc_bab_nu))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bab_put1))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bab_put2))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bab_put3))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_bab_put4))}</td>
                            </tr>
                            <tr>
                                <td colSpan="11" className="text-left font-weight-bold">Previous Transplant</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">Yes</td>
                                <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ptxy_nc))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ptxy_pct1))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ptxy_pct2))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ptxy_pct3))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ptxy_pct4))}</td>
                                <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ptxy_nu))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ptxy_put1))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ptxy_put2))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ptxy_put3))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ptxy_put4))}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">No</td>
                                <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ptxn_nc))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ptxn_pct1))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ptxn_pct2))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ptxn_pct3))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ptxn_pct4))}</td>
                                <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ptxn_nu))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ptxn_put1))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ptxn_put2))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ptxn_put3))}</td>
                                <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ptxn_put4))}</td>
                            </tr>
                            <PeakPRA organ={props.organ} data={props.data} />
                            <PrimaryDisease organ={props.organ} data={props.data} />
                            <YearsSinceDiabetes organ={props.organ} data={props.data} />
                            <MedicalUrgencyStatus organ={props.organ} data={props.data} />
                        </tbody>
                    </Table>
                </div>
            </Collapse>
        </>
    );
}

export default TimeToTransplantTable;