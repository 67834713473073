import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HeartIcon from "../../../utilities/organs/HeartIcon";
import KidneyIcon from "../../../utilities/organs/KidneyIcon";
import LiverIcon from "../../../utilities/organs/LiverIcon";
import LungIcon from "../../../utilities/organs/LungIcon";
import PancreasIcon from "../../../utilities/organs/PancreasIcon";
import IntestineIcon from "../../../utilities/organs/IntestineIcon";
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

function TotalDonorsAndOrgansTransplantedChart(props) {
    let exportTitle = props.title;
    let series = [];
    let dbdData = [];
    let dcdData = [];
    let totalData = [];

    for (let i = 0; i < props.chartData.length; i++) {
        if (props.chartData[i].donorType.toLowerCase() === "total") {
            totalData.push(props.chartData[i].value);
        }
        else if (props.chartData[i].donorType.toLowerCase() === "dbd") {
            dbdData.push(props.chartData[i].value);
        }
        else {
            dcdData.push(props.chartData[i].value);
        }
    }

    series.push({
        name: "Total",
        data: totalData,
        color: "#2d62a1",
        fillOpacity: .8
    });

    series.push({
        name: "DBD",
        data: dbdData,
        color: "#43a7cb",
        fillOpacity: .8
    });

    series.push({
        name: "DCD",
        data: dcdData,
        color: "#9fd6ce",
        fillOpacity: .8
    });



    let options = {
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ['printChart', 'downloadPNG', 'downloadSVG', 'downloadPDF', 'downloadCSV']
                },
            },
            title: {
                text: `<div style="font-family:Open Sans;font-size:14px;font-weight: 700;text-align:center;">${exportTitle}</div><br /><div style="font-family:Open Sans;font-size:12px;font-weight: 400;text-align:center;"></div>`
            },
            chart: {
                events: {
                    load: function () {
                        let width = 0;
                        let height = this.chartHeight - 50;
                        this.renderer.image('/srtr_logo.jpg', width, height, 50, 50)
                            .add();
                    }
                }
            }
        },
        chart: {
            type: 'area',
            marginRight: 25
        },

        credits: {
            enabled: false
        },
        title: "",
        xAxis: {
            min: 0.5,
            max: 3.8,
            crosshair: {
                width: 3,
                color: 'green'
            },
            labels: {
                style: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"
                }
            },
            categories: props.categories
        },
        yAxis: {
            title: {
                style: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"
                },
                text: ""
            },
            labels: {
                style: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"
                }
            }
        },
        tooltip: {
            crosshairs: true,
            borderWidth: 0,
            backgroundColor: "white",
            borderRadius: 10,
            shadow: false,
            useHTML: true,
            formatter: function () {
                // The first returned item is the header, subsequent items are the
                // points
                return [''].concat(
                    this.points ?
                        this.points.map(function (point) {
                            return '<span style="font-family:Open Sans;font-size:16px;font-weight: 700;text-align:left">' + point.y + ' </span><span style="font-family:Open Sans;font-size:14px;font-weight: 400;text-align:left">' + point.series.name + '</span>';
                        }) : []
                );
            },
            split: true
        },
        legend: {
            labelFormatter: function () {
                return '<span class="area-label">' + this.name + '</span>';
            },
            itemStyle: {
                fontFamily: "Open Sans",
                fontSize: "14px"

            }
        },
        plotOptions: {
            areaspline: {
                fillOpacity: 0.5
            },
            area: {
                lineColor: '#333333',
                lineWidth: 1,
                marker: {
                    lineWidth: 1,
                    lineColor: '#333333'
                }
            }
        },
        series: series
    };

    let title = "";
    if (props.organ === "heart") {
        title = <div className="organ-title"><HeartIcon showHover={true} />Heart Transplants</div>
    }
    else if (props.organ === "kidney") {
        title = <div className="organ-title"><KidneyIcon showHover={true} />Kidney Transplants</div>;
    }
    else if (props.organ === "liver") {
        title = <div className="organ-title"><LiverIcon showHover={true} />Liver Transplants</div>;
    }
    else if (props.organ === "lung") {
        title = <div className="organ-title"><LungIcon showHover={true} />Lung Transplants</div>;
    }
    else if (props.organ === "pancreas") {
        title = <div className="organ-title"><PancreasIcon showHover={true} />Pancreas Transplants</div>;
    }
    else if (props.organ === "intestine") {
        title = <div className="organ-title"><IntestineIcon showHover={true} />Intestine Transplants</div>;
    }
    return (
        <div className="donors-organs-transplanted">
            <Row>
                <Col>
                    {options.xAxis.categories &&
                        <>
                            <div className="title">
                                {title}
                            </div>
                            <HighchartsReact highcharts={Highcharts} options={options} oneToOne={true} containerProps={{ className: "highcharts-light" }}/>
                        </>
                    }

                </Col>
            </Row>
        </div>
    )
}

export default TotalDonorsAndOrgansTransplantedChart;