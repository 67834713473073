import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TransplantCharacteristicsChart from "./TransplantCharacteristicsChart";
import { getLivingCharacteristicOptions, getDeceasedCharacteristicOptions } from "./Options";
import moment from "moment";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import { formatThousands } from "../../../utilities/formatter";

function TransplantCharacteristics(props) {

    const [livingCharacteristicOptions, setLivingCharacteristicOptions] = useState(null);
    const [deceasedCharacteristicOptions, setDeceasedCharacteristicOptions] = useState(null);
    const [selectedDeceasedCharacteristicOption, setSelectedDeceasedCharacteristicOption] = useState(null);
    const [selectedLivingCharacteristicOption, setSelectedLivingCharacteristicOption] = useState(null);

    let isLivingDonor = props.organ === "ki" || props.organ === "li";

    let title = "Transplant Characteristics";
    let subTitle = "Transplants performed";
    if (props.deceasedData) {
        subTitle = `Transplants performed between ${moment(new Date(props.deceasedData.begDate)).format("MM/DD/YYYY")} and ${moment(new Date(props.deceasedData.endDate)).format("MM/DD/YYYY")}`;
    }
    else if (props.livingData) {
        subTitle = `Transplants performed between ${moment(new Date(props.livingData.begDate)).format("MM/DD/YYYY")} and ${moment(new Date(props.livingData.endDate)).format("MM/DD/YYYY")}`;
    }

    const _onDeceasedCharacteristicChange = (selectedDeceasedCharacteristicOption) => {
        setSelectedDeceasedCharacteristicOption(selectedDeceasedCharacteristicOption);
    }

    const _onLivingCharacteristicChange = (selectedLivingCharacteristicOption) => {
        setSelectedLivingCharacteristicOption(selectedLivingCharacteristicOption);
    }

    useEffect(() => {
        let _livingCharacteristicOptions = getLivingCharacteristicOptions(props.organ);
        let _deceasedCharacteristicOptions = getDeceasedCharacteristicOptions(props.organ);

        setLivingCharacteristicOptions(_livingCharacteristicOptions);
        setDeceasedCharacteristicOptions(_deceasedCharacteristicOptions);
        setSelectedLivingCharacteristicOption(_livingCharacteristicOptions[0]);
        setSelectedDeceasedCharacteristicOption(_deceasedCharacteristicOptions[0]);

    }, [props]);

    if (!props.isLoading) {
        return (
            <div className="transplant-recipients-wrapper">
                <Row>
                    <Col xs={12} className="mb-4">
                        <h1 className="section-title">
                            {title}
                        </h1>
                        <div className="sub-title">
                            {subTitle}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={isLivingDonor ? 6 : 12}>
                        <div className="select-demographic-wrapper">
                            <div className="sub-title" id="deceasedCharacteristicsLabel">
                                Deceased Donor Characteristic
                        </div>
                            <div className="center-region-us-numbers">
                                <div className="header">
                                    Center<br />
                                    (N={props.deceasedData ? formatThousands(props.deceasedData["toc_all_c"]) : "NA"})
                                </div>
                                <div className="header">
                                    Region<br />
                                    (N={props.deceasedData ? formatThousands(props.deceasedData["toc_all_r"]) : "NA"})
                                </div>
                                <div className="header">
                                    U.S.<br />
                                    (N={props.deceasedData ? formatThousands(props.deceasedData["toc_all_u"]) : "NA"})
                                </div>
                            </div>
                            {deceasedCharacteristicOptions &&
                                <Select
                                    id="select-demographic"
                                    className="select-demographic mt-2"
                                    classNamePrefix="select"
                                    isClearable={false}
                                    isSearchable={true}
                                    name="select-demographic"
                                    options={deceasedCharacteristicOptions}
                                    onChange={_onDeceasedCharacteristicChange}
                                    value={selectedDeceasedCharacteristicOption}
                                    aria-labelledby={`deceasedCharacteristicsLabel`}
                                />
                            }
                        </div>
                        <TransplantCharacteristicsChart
                            data={props.deceasedData}
                            selectedOption={selectedDeceasedCharacteristicOption}
                            centerCode={props.centerCode}
                            title="Deceased Donor Transplant Characteristics"
                            subTitle={subTitle}

                        />
                    </Col>
                    {isLivingDonor &&
                        <Col lg={6}>
                            <div className="select-demographic-wrapper">
                                <div className="sub-title" id="livingCharacteristicsLabel">
                                    Living Donor Characteristic
                        </div>
                                <div className="center-region-us-numbers">
                                    <div className="header">
                                        Center<br />
                                    (N={props.livingData ? formatThousands(props.livingData["tol_all_c"]) : "NA"})
                                </div>
                                    <div className="header">
                                        Region<br />
                                    (N={props.livingData ? formatThousands(props.livingData["tol_all_r"]) : "NA"})
                                </div>
                                    <div className="header">
                                        U.S.<br />
                                    (N={props.livingData ? formatThousands(props.livingData["tol_all_u"]) : "NA"})
                                </div>
                                </div>
                                {livingCharacteristicOptions &&
                                    <Select
                                        id="select-demographic"
                                        className="select-demographic mt-2"
                                        classNamePrefix="select"
                                        isClearable={false}
                                        isSearchable={true}
                                        name="select-demographic"
                                        options={livingCharacteristicOptions}
                                        onChange={_onLivingCharacteristicChange}
                                        value={selectedLivingCharacteristicOption}
                                        aria-labelledby={`livingCharacteristicsLabel`}
                                    />
                                }
                            </div>
                            <TransplantCharacteristicsChart
                                data={props.livingData}
                                selectedOption={selectedLivingCharacteristicOption}
                                centerCode={props.centerCode}
                                title="Living Donor Transplant Characteristics"
                                subTitle={subTitle}
                            />
                        </Col>
                    }
                </Row>
            </div>
        );
    }
    else {
        return (
            <div className="transplant-recipients-wrapper">
                <Row>
                    <Col xs={12} className="mb-4">
                        <h1 className="section-title">
                            <Skeleton width={600} />
                        </h1>
                        <div className="sub-title">
                            <Skeleton width={500} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="background-teal psr-select-options">
                            <div className="select-living-deceased-wrapper">
                                <div className="sub-title">
                                    <Skeleton width={200} />
                                </div>
                                <Skeleton width={400} height={20} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <div className="select-demographic-wrapper">
                            <div className="sub-title">
                                <Skeleton width={400} />
                            </div>
                            <Skeleton width={400} height={20} />
                        </div>
                        <Skeleton height={500} />
                    </Col>
                    <Col lg={6}>
                        <div className="select-demographic-wrapper">
                            <div className="sub-title">
                                <Skeleton width={400} />
                            </div>
                            <Skeleton width={400} height={20} />
                        </div>
                        <Skeleton height={500} />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default TransplantCharacteristics;