import React, { useState, useEffect } from "react"
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import Skeleton from "react-loading-skeleton";
import SelectOPO from "../../utilities/SelectOPO";
import OPOInformation from "../compare/OPOInformation";
import CompareOPO from "../compare/CompareOPO.js";
import SelectOrgan from "../../utilities/SelectOrgan.js";
import OPOInformationMap from "./opo-information/OPOInformationMap";
import DensityMaps from "./density-maps/DensityMaps";
import ReceivingProgramsMap from "./receiving-programs/ReceivingProgramsMap";
import ReceivingProgramsTable from "./receiving-programs/ReceivingProgramsTable";
import TransplantsFacilitated from "./transplants-facilitated/TransplantsFacilitated";
import TransplantProgramsTable from "./opo-information/TransplantProgramsTable";
import DonorData from "./donor-data/DonorData";
import OrganYield from "./organ-utilization/OrganYield";
import Select from "react-select";
import ErrorFallback from "../../utilities/error-fallback/ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import CovidAlert from "../../utilities/CovidAlert";

function OPODetail(props) {
    const Host = process.env.REACT_APP_API_HOST;
    const [opoData, setOPOData] = useState(null);
    const [donorData, setDonorData] = useState(null);
    const [utilizationData, setUtilizationData] = useState(null);
    const [thisOPOCentersWithTransplants, setThisOPOCentersWithTransplants] = useState(null);
    const [organFilter, setOrganFilter] = useState(null);
    const opoCode = props.match.params.opoCode.toUpperCase();
    const [activeKey, setActiveKey] = useState("opoInformation");
    const [tabOptions, setTabOptions] = useState(null);

    useEffect(() => {
        let mounted = true;
        if (props.match && opoCode) {
            fetch(`${Host}/opo/${opoCode}`)
                .then(response => response.json())
                .then(response => {
                    if (mounted === true) {
                        setOPOData(response.opoDetail);
                        setDonorData(response.donorData);
                        setUtilizationData(response.utilizationData);

                        if (response.opoDetail && response.opoDetail.centersWithTransplants) {
                            let thisOPOCentersWithTransplants = response.opoDetail.centersWithTransplants.features.filter(obj => {
                                return obj.properties.opoCode === opoCode;
                            });

                            setThisOPOCentersWithTransplants(thisOPOCentersWithTransplants);
                        }

                        setTabOptions([
                            { label: `${response.opoDetail.code.toUpperCase()} Overview`, value: "opoInformation" },
                            { label: "Population Density & Death Rates", value: "densityMaps" },
                            { label: "Donor Data", value: "donorData" },
                            { label: "Organ Yield", value: "organYield" },
                            { label: `Programs Receiving Organs From ${response.opoDetail.code.toUpperCase()}`, value: "receivingPrograms" }
                        ]);
                    }
                })
                .catch((err) => { console.log(err) });
        }

        return () => mounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);



    const onTabOptionsChange = (selectedTab) => {
        setActiveKey(selectedTab.value);
    }

    const onTabClick = (key) => {
        setActiveKey(key);
    }

    const onSelectOPO = (selectedOPOCode) => {
        redirectToOPO(selectedOPOCode);
    };

    const onSelectOrgan = (selectedOrgan) => {

        let organFilter = "";
        if (selectedOrgan === "heart") {
            organFilter = "hasHeart";
        }
        else if (selectedOrgan === "intestine") {
            organFilter = "hasIntestine";
        }
        else if (selectedOrgan === "kidney") {
            organFilter = "hasKidney";
        }
        else if (selectedOrgan === "liver") {
            organFilter = "hasLiver";
        }
        else if (selectedOrgan === "lung") {
            organFilter = "hasLung";
        }
        else if (selectedOrgan === "pancreas") {
            organFilter = "hasPancreas";
        }

        setOrganFilter(organFilter);
    };

    const redirectToOPO = (selectedOPOCode) => {

        if ('parentIFrame' in window) {
            var json = {
                type: "opo",
                opoCode: selectedOPOCode
            };

            //window.parentIFrame.sendMessage(json, `${process.env.REACT_APP_TARGET_ORIGIN}`);
            window.parentIFrame.sendMessage(json, "*");
        }

        if (selectedOPOCode) {
            window.location.href = `/opo/${selectedOPOCode.toLowerCase()}`;
        }
        else {
            window.location.href = "/opo"
        }

    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${props.match.params.opoCode.toUpperCase()} Overview`} | SRTR Data Visualization</title>
                <meta name="description" content=" Here you will find data about various aspects of this organ procurement organization (OPO) and its donation service area (DSA)." />
            </Helmet>
            <Row className="opo-detail">
                <Col>
                    <Row className="opo-detail-about">
                        <Col sm={12} lg={6}>
                            {opoData &&
                                <>
                                    <Link to="/opo" className="back-link">{"<"} Back to OPO Overview</Link>
                                </>
                            }
                            {!opoData &&
                                <Skeleton width={400} />
                            }
                            <h1>{opoData == null ? <Skeleton count={3} /> : `${opoData.name} (${opoData.code})`}</h1>
                        </Col>
                        <Col sm={12} lg={6} className="opos-description">
                            {opoData &&
                                <>
                                    Here you will find data about various aspects of this organ procurement organization (OPO) and its donation service area (DSA).
                                    Click through the tabs to review data about the OPO/DSA (Overview, Population Density &amp; Death Rates), donors procured by the
                                    OPO (Donor Data), utilization of donor organs (Organ Yield), and the programs
                                    receiving organs from donors procured by this OPO (Programs Receiving Organs).
                                   In addition, you can <a href={`${Host}/documents/opo/${opoCode.toLowerCase()}`}>download a PDF of these data</a>.
                                </>
                            }
                            {!opoData &&
                                <>
                                    <Skeleton width={600} count={8} />
                                </>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CovidAlert />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="opo-detail-mobile-select-wrapper">
                                {tabOptions &&
                                    <>
                                        <div id="label-selecttab" htmlFor="selecttab" className="select-opo-text">
                                            Select a section to view
                                         </div>
                                        <Select
                                            id="selecttab"
                                            className="select-opo-detail"
                                            classNamePrefix="select"
                                            isClearable={false}
                                            isSearchable={false}
                                            name="opoDetailTabs"
                                            options={tabOptions ? tabOptions : null}
                                            onChange={onTabOptionsChange}
                                            type="text"
                                            aria-labelledby={`label-tab-options`}
                                            defaultValue={tabOptions ? tabOptions[0] : null}

                                        />
                                    </>
                                }
                                {!tabOptions &&
                                    <Skeleton width={600} />
                                }
                            </div>
                            <Tabs
                                activeKey={activeKey}
                                onSelect={onTabClick}
                                unmountOnExit={true}
                                className="opo-detail-tabs"
                                tabIndex="0"
                            >
                                <Tab
                                    eventKey="opoInformation"
                                    title={opoData && tabOptions ? tabOptions[0].label : <Skeleton width={120} />}
                                    className="margin-right"
                                >
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        <Row>
                                            <Col className="">
                                                <div className="select-container opo-select">
                                                    <Row>
                                                        <Col xs={12} lg={8}>
                                                            <SelectOPO onSelectOPO={onSelectOPO} selectedOPOCode={opoData !== null ? opoData.code : null} />
                                                        </Col>
                                                        <Col xs={12} lg={4}>
                                                            <SelectOrgan onSelectOrgan={onSelectOrgan} selectedOrgan={organFilter} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                {opoData &&
                                                    <OPOInformationMap
                                                        opoData={opoData}
                                                        centersWithTransplants={opoData.centersWithTransplants}
                                                        counties={opoData.counties}
                                                        organFilter={organFilter}
                                                    />
                                                }
                                                {!opoData &&
                                                    <OPOInformationMap
                                                        opoData={null}
                                                    />
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                {opoData && thisOPOCentersWithTransplants &&
                                                    <TransplantProgramsTable
                                                        opoName={opoData.name}
                                                        opoCode={opoData.code}
                                                        data={thisOPOCentersWithTransplants}
                                                    />
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="outer-wrapper">
                                                    <div className="inner-wrapper">
                                                        <Row>
                                                            <Col sm={12} lg={6}>
                                                                <OPOInformation opoData={opoData !== null ? opoData : null} showDonorData={true} />
                                                            </Col>
                                                            <Col sm={12} lg={6}>
                                                                <CompareOPO />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </ErrorBoundary>
                                </Tab>
                                <Tab
                                    eventKey="densityMaps"
                                    title={opoData && tabOptions ? tabOptions[1].label : <Skeleton width={120} count={3} />}
                                    tabIndex="0">
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        <Row className="densityMaps">
                                            <Col className="">
                                                <div className="select-container opo-select">
                                                    <Row>
                                                        <Col xs={12} lg={12}>
                                                            <SelectOPO onSelectOPO={onSelectOPO} selectedOPOCode={opoData !== null ? opoData.code : null} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <DensityMaps
                                                    opoData={opoData}
                                                    activeKey={activeKey === "densityMaps"}
                                                />
                                            </Col>
                                        </Row>
                                    </ErrorBoundary>
                                </Tab>
                                <Tab eventKey="donorData" title={donorData && tabOptions ? tabOptions[2].label : <Skeleton width={120} />}>
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        <Row>
                                            <Col className="">
                                                <div className="select-container opo-select">
                                                    <Row>
                                                        <Col xs={12} lg={12}>
                                                            <SelectOPO onSelectOPO={onSelectOPO} selectedOPOCode={opoData !== null ? opoData.code : null} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                {donorData &&
                                                    <DonorData
                                                        donorData={donorData}
                                                        opoCode={opoData.code}
                                                        activeKey={activeKey === "donorData"}
                                                    />
                                                }
                                            </Col>
                                        </Row>
                                    </ErrorBoundary>
                                </Tab>
                                <Tab eventKey="organYield" title={utilizationData && tabOptions ? tabOptions[3].label : <Skeleton width={120} />}>
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        <Row>
                                            <Col className="">
                                                <div className="select-container opo-select">
                                                    <Row>
                                                        <Col xs={12} lg={12}>
                                                            <SelectOPO onSelectOPO={onSelectOPO} selectedOPOCode={opoData !== null ? opoData.code : null} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                {utilizationData &&
                                                    <OrganYield
                                                        utilizationData={utilizationData}
                                                        activeKey={activeKey === "organYield"}
                                                    />
                                                }
                                            </Col>
                                        </Row>
                                    </ErrorBoundary>
                                </Tab>
                                <Tab eventKey="receivingPrograms" title={opoData && tabOptions ? tabOptions[4].label : <Skeleton width={120} count={3} />}>
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        <Row>
                                            <Col className="">
                                                <div className="select-container opo-select">
                                                    <Row>
                                                        <Col xs={12} lg={8}>
                                                            <SelectOPO onSelectOPO={onSelectOPO} selectedOPOCode={opoData !== null ? opoData.code : null} />
                                                        </Col>
                                                        <Col xs={12} lg={4}>
                                                            <SelectOrgan onSelectOrgan={onSelectOrgan} selectedOrgan={organFilter} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                {opoData &&
                                                    <ReceivingProgramsMap
                                                        opoData={opoData}
                                                        counties={opoData.counties}
                                                        data={opoData.receivingPrograms}
                                                        organFilter={organFilter}
                                                        activeKey={activeKey === "receivingPrograms"}
                                                    />
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} >
                                                {opoData &&
                                                    <ReceivingProgramsTable
                                                        opoName={opoData.name}
                                                        opoCode={opoData.code}
                                                        data={opoData.receivingPrograms.features}
                                                    />
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="outer-wrapper">
                                                    <div className="inner-wrapper">
                                                        <Row>
                                                            <Col xs={12}>
                                                                {opoData &&
                                                                    <TransplantsFacilitated
                                                                        opoCode={opoData.code}
                                                                        data={opoData.transplantsFacilitated}
                                                                    />
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </ErrorBoundary>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default OPODetail;