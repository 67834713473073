import React, { useState, useEffect, useRef } from "react"
import Helmet from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Skeleton from "react-loading-skeleton";
import mapboxgl from "mapbox-gl";
import TopNav from "./TopNav";
import TransplantSummary from "./transplant-summary/TransplantSummary";
import Waitlist from "../waitlist/Waitlist";
import TransplantProcedures from "../transplant-procedures/TransplantProcedures";
import TransplantGrafts from "../posttransplant-outcomes/TransplantGrafts";
import OfferAcceptances from "../offer-acceptances/OfferAcceptances";
import Select from "react-select";
import ErrorFallback from "../../utilities/error-fallback/ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import InvalidCenter from "./InvalidCenter";
import CovidAlert from "../../utilities/CovidAlert";
import LDCIcon from "../../../assets/images/ldc.svg";

function CenterDetail(props) {
    const Host = process.env.REACT_APP_API_HOST;
    const [centerData, setCenterData] = useState(null);
    const [selectedOrgan, setSelectedOrgan] = useState();
    const [organData, setOrganData] = useState();
    const [graftsData, setGraftsData] = useState(null);
    const [offerAcceptancesData, setOfferAcceptancesData] = useState(null);
    const [waitlistData, setWaitlistData] = useState(null);
    const [transplantRatesData, setTransplantRatesData] = useState(null);
    const [timeToTransplantData, setTimeToTransplantData] = useState(null);
    const [demographicsData, setDemographicsData] = useState(null);
    const [centerTransplantSummary, setCenterTransplantSummary] = useState(null);
    const mapContainer = useRef(null);
    const [centerCode, setCenterCode] = useState(null);
    const [centerType, setCenterType] = useState(null);
    const [activeKey, setActiveKey] = useState("waitlistOverview");
    const [tabOptions, setTabOptions] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isValidCenter, setIsValidCenter] = useState(true);

    const ldcCenters = [
        { center: "txtx", organ: "ki" },
        { center: "txtx", organ: "li" },
        { center: "gaem", organ: "ki" },
        { center: "mnum", organ: "ki" },
        { center: "mnum", organ: "li" },
        { center: "mnhc", organ: "ki" },
        { center: "nyms", organ: "ki" },
        { center: "nyms", organ: "li" },
        { center: "mdjh", organ: "ki" },
        { center: "mdjh", organ: "li" },
        { center: "mnmc", organ: "ki" },
        { center: "mnmc", organ: "li" },
        { center: "mosl", organ: "ki" },
        { center: "cauc", organ: "ki" },
        { center: "papt", organ: "ki" },
        { center: "papt", organ: "li" }
    ]
    useEffect(() => {
        if (props.match && props.match.params.centerCode) {
            try {
                let _centerCode = props.match.params.centerCode.toUpperCase();

                //Get the center's detail data
                fetch(`${Host}/psr/${_centerCode}`)
                    .then(response => response.json())
                    .then(response => {
                        if (response.errorCode) {
                            setIsValidCenter(false);
                        }
                        else {
                            let map = new mapboxgl.Map({
                                container: mapContainer.current,
                                style: "mapbox://styles/srtr/ckrzb18mj2hkn17p2k0yqt5w8",
                                center: [response.centerDetail.feature.geometry.coordinates[0], response.centerDetail.feature.geometry.coordinates[1]],
                                zoom: 15
                            });

                            map.on("load", () => {
                                map.loadImage("/hospital.png", (error, image) => {
                                    if (error) {
                                        return;
                                    }
                                    map.addImage("centerIcon", image);
                                });

                                map.addSource("centerSource", {
                                    "type": "geojson",
                                    "data": response.centerDetail.feature
                                });

                                map.addLayer({
                                    id: "centerPoint",
                                    type: "symbol",
                                    source: "centerSource",
                                    filter: ["!", ["has", "point_count"]],
                                    layout: {
                                        "icon-image": "centerIcon",
                                        "icon-size": .5
                                    }
                                });

                                map.addControl(new mapboxgl.NavigationControl());
                            });

                            setCenterData(response.centerDetail);
                            setCenterTransplantSummary(response.centerTransplantSummary);
                            setWaitlistData(response.waitlistOverview);
                            setTransplantRatesData(response.transplantRates);
                            setTimeToTransplantData(response.timeToTransplant);
                            setDemographicsData(response.demographics);
                            setOfferAcceptancesData(response.offerAcceptances);

                            setTabOptions([
                                { label: "Waiting List Overview", value: "waitlistOverview" },
                                { label: "Offer Acceptance Practices", value: "offerAcceptances" },
                                { label: "Transplant Procedures", value: "transplantProcedures" },
                                { label: "Posttransplant Outcomes", value: "postTransplantOutcomes" }
                            ]);

                            let urlOrgan = props.match.params.organ ? props.match.params.organ.toLowerCase() : false;

                            if (response.centerDetail) {
                                if (!urlOrgan || !response.centerDetail.organs.includes(urlOrgan)) {
                                    let organ = response.centerDetail.organs[0];
                                    _onOrganSelectClick(organ);
                                }
                                else {
                                    _onOrganSelectClick(urlOrgan);
                                }
                            }

                            fetch(`${Host}/psr/${_centerCode}/grafts`)
                                .then(response => response.json())
                                .then(response => {
                                    setGraftsData(response);
                                }).catch((error) => {
                                    console.error('Error:', error);
                                });
                        }
                    })
                    .catch((er) => { console.log(er) });

                setCenterCode(_centerCode.substring(0, 4));
                setCenterType(_centerCode.substring(4, 7));
                setIsLoading(false);
            }
            catch (err) {
                console.log(err);
            }


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _onOrganSelectClick = (organ) => {

        if (organ) {
            if (centerCode) {
                if ('parentIFrame' in window) {
                    var json = {
                        type: "psr",
                        centerCode: centerCode,
                        centerType: centerType,
                        organ: organ
                    };

                    //window.parentIFrame.sendMessage(json, `${process.env.REACT_APP_TARGET_ORIGIN}`);
                    window.parentIFrame.sendMessage(json, "*");
                }
            }
            setSelectedOrgan(organ);
            setOrganData(organ + "Data");
        }
    }

    const onTabOptionsChange = (selectedTab) => {
        setActiveKey(selectedTab.value);
    }

    const onTabClick = (key) => {
        setActiveKey(key);
    }


    if (!isLoading && isValidCenter) {
        let isLDCCenter = false;
        if (centerCode) {
            const _isLDCCenter = ldcCenters.filter(item => {
                return item.organ === selectedOrgan && item.center === centerCode.toLowerCase()
            });

            isLDCCenter = _isLDCCenter.length > 0;
        }
        return (
            <div className="psr">
                <Helmet>
                    <title>{centerCode ? `${centerCode.toUpperCase()} Overview` : `Center Overview`} | SRTR Data Visualization</title>
                </Helmet>
                {centerData &&
                    <TopNav
                        centerName={centerData.name}
                        selectedOrgan={selectedOrgan}
                        organs={centerData.organs}
                        onSelectClick={_onOrganSelectClick}
                    />
                }
                {!centerData &&
                    <TopNav
                        centerName={null}
                    />
                }
                <Row className="center-detail">
                    <Col xs={12}>

                    </Col>
                    <Col xs={12} lg={6} className="center-detail-about">
                        <Row>
                            <Col sm={12} lg={12} className="center-name">
                                <h1>{centerData == null ? <Skeleton count={3} /> : `${centerData.name} (${centerData.code})`}</h1>
                                {isLDCCenter &&
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip id="tooltip-right">
                                                Living Donor Collective Participant
                                            </Tooltip>
                                        }
                                    >
                                        <a
                                            href="https://livingdonorcollective.org/"
                                            target="_blank"
                                            rel="noopener nofollow noreferrer">
                                            <img src={LDCIcon} alt="Living Donor Collective Center" />
                                        </a>
                                    </OverlayTrigger>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} lg={12} className="center-description">
                                {centerData && selectedOrgan &&
                                    <>
                                        Here you will find data about various aspects of this transplant program. Click through the tabs to review data about the program’s waiting
                                        list (Waiting List Overview), organ offer acceptance rates (Offer Acceptance Practices), transplants performed (Transplant Procedures), and
                                        patient outcomes following transplant (Posttransplant Outcomes). If this hospital performs other types of transplants, you may navigate to
                                        the other organ programs within this hospital by choosing the organ type at the top of this page. 

                                        <a target="_blank" rel="noopener noreferrer" href={`${Host}/documents/psr/${centerCode.toLowerCase()}${centerType.toLowerCase()}_${selectedOrgan.toLowerCase()}`}>
                                            <div className="button">
                                                <Button className="btn-dark-blue min-98 mt-4">
                                                    Download PDF
                                                </Button>
                                            </div>
                                        </a>
                                    </>
                                }
                                {!centerData && !selectedOrgan &&
                                    <>
                                        <Skeleton count={5} />
                                    </>
                                }
                            </Col>
                        </Row>
                    </Col >
                    <Col xs={12} lg={6}>

                        <div className="center-map">
                            <div id="centerDetailMap" className="center-map">
                                <div style={{ height: "100%" }} ref={mapContainer}>
                                    {!centerData && <Skeleton height={400} />}
                                </div>
                            </div>
                        </div>

                        <div className="center-address-wrapper">
                            {centerData &&
                                <>
                                    {centerData.address &&
                                        <div className="center-address">
                                            {`${centerData.address}, ${centerData.city}, ${centerData.state} ${centerData.zipCode}`}
                                        </div>
                                    }
                                    {centerData.phoneNumber &&
                                        <div className="center-phone">
                                            {centerData.phoneNumber}
                                        </div>
                                    }
                                    {centerData.url &&
                                        <div className="center-url">
                                            <a href={`${centerData.url}`} target="_blank" rel="noopener noreferrer">View Website</a>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </Col>
                </Row >
                <Row>
                    <Col>
                        <CovidAlert />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {centerTransplantSummary && selectedOrgan && organData && centerTransplantSummary[organData] &&
                            <TransplantSummary
                                organ={selectedOrgan}
                                transplantType="Adult"
                                data={centerTransplantSummary[organData]}
                            />
                        }
                    </Col>
                </Row>
                <hr />
                <div className="opo-detail-mobile-select-wrapper">
                    {tabOptions &&
                        <>
                            <div id="label-selecttab" htmlFor="selecttab" className="select-opo-text">
                                Select a section to view
                            </div>
                            <Select
                                id="selecttab"
                                className="select-opo-detail"
                                classNamePrefix="select"
                                isClearable={false}
                                isSearchable={false}
                                name="opoDetailTabs"
                                options={tabOptions ? tabOptions : null}
                                onChange={onTabOptionsChange}
                                type="text"
                                aria-labelledby={`label-tab-options`}
                                defaultValue={tabOptions ? tabOptions[0] : null}

                            />
                        </>
                    }
                    {!tabOptions &&
                        <Skeleton width={600} />
                    }
                </div>
                <Tabs
                    activeKey={activeKey}
                    onSelect={onTabClick}
                    unmountOnExit={true}
                    className="opo-detail-tabs"
                >
                    <Tab eventKey="waitlistOverview" title={waitlistData && tabOptions ? tabOptions[0].label : <Skeleton width={120} />} className="margin-right">
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <Row>
                                <Col xs={12}>
                                    {waitlistData && timeToTransplantData && demographicsData && transplantRatesData &&
                                        <Waitlist
                                            organ={selectedOrgan}
                                            centerCode={centerCode}
                                            waitlistData={waitlistData[organData]}
                                            timeToTransplantData={timeToTransplantData[organData]}
                                            demographicsData={demographicsData[organData]}
                                            transplantRatesData={transplantRatesData[organData]}
                                        />
                                    }
                                </Col>
                            </Row>
                        </ErrorBoundary>
                    </Tab>
                    <Tab eventKey="offerAcceptances" title={offerAcceptancesData && tabOptions ? tabOptions[1].label : <Skeleton width={120} />} className="margin-right">
                        {offerAcceptancesData &&
                            <ErrorBoundary FallbackComponent={ErrorFallback}>
                                <Row>
                                    <Col xs={12} className="section">
                                        <OfferAcceptances
                                            data={offerAcceptancesData[organData]}
                                            nationalData={offerAcceptancesData.nationalOfferAcceptances[organData]}
                                            organ={selectedOrgan}
                                            centerCode={centerCode}
                                            centerType={centerType}
                                        />
                                    </Col>
                                </Row>
                            </ErrorBoundary>
                        }
                    </Tab>
                    <Tab eventKey="transplantProcedures" title={centerData && tabOptions ? tabOptions[2].label : <Skeleton width={120} />} className="margin-right">
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <TransplantProcedures
                                organ={selectedOrgan}
                                centerCode={centerCode}
                                centerType={centerType}
                            />
                        </ErrorBoundary>
                    </Tab>
                    <Tab eventKey="postTransplantOutcomes" title={graftsData && tabOptions ? tabOptions[3].label : <Skeleton width={120} />} className="margin-right">
                        {graftsData &&
                            <ErrorBoundary FallbackComponent={ErrorFallback}>
                                <TransplantGrafts
                                    gsrData={graftsData.gsrData[organData]}
                                    psrData={graftsData.psrData[organData]}
                                    otherCentersGSRData={graftsData.gsrOther[organData]}
                                    otherCentersPSRData={graftsData.psrOther[organData]}
                                    centerCode={centerCode}
                                    organ={selectedOrgan}
                                />
                            </ErrorBoundary>
                        }
                    </Tab>
                </Tabs>
                <div>
                    <div className="mb-3 ml-2 float-left">
                        Learn more about where this data comes from:&nbsp;
                        <a href={`${process.env.REACT_APP_SRTR_HOST}/about-the-data/the-srtr-database/`} target="_blank" rel="noopener noreferrer">
                            Data Collection & Methods
                        </a>
                    </div>
                    <div className="mb-3 mr-2 float-right">
                        Questions about your report?&nbsp;
                        <a href={`${process.env.REACT_APP_SRTR_HOST}/contact-us/contact-form/`} target="_blank" rel="noopener noreferrer">
                            Contact Us!
                        </a>
                    </div>
                </div>
            </div >
        );
    }
    else if (isLoading && !isValidCenter) {
        return (
            <div className="psr">
                <Helmet>
                    <title>Center Overview | SRTR Data Visualization</title>
                </Helmet>
                <TopNav
                    centerName={null}
                />
                <Row className="center-detail">
                    <Col xs={12}>

                    </Col>
                    <Col xs={12} lg={6} className="center-detail-about">
                        <Row>
                            <Col sm={12} lg={12}>
                                <h1><Skeleton count={3} /></h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} lg={12} className="center-description">
                                <Skeleton count={5} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6}>

                        <div className="center-map">
                            <div id="centerDetailMap" className="center-map">
                                <div style={{ height: isLoading ? "100%" : "0" }} ref={mapContainer}>
                                    <Skeleton height={400} />
                                </div>

                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>

                    </Col>
                </Row>
                <hr />
                <div className="opo-detail-mobile-select-wrapper">
                    <Skeleton width={600} />
                </div>
                <Tabs
                    activeKey={activeKey}
                    className="opo-detail-tabs"
                >
                    <Tab eventKey="waitlistOverview" title={<Skeleton width={120} />} className="margin-right">
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <Row>
                                <Col xs={12}>

                                </Col>
                            </Row>
                        </ErrorBoundary>
                    </Tab>
                    <Tab eventKey="offerAcceptances" title={<Skeleton width={120} />} className="margin-right">

                    </Tab>
                    <Tab eventKey="transplantprocedures" title={<Skeleton width={120} />} className="margin-right">

                    </Tab>
                    <Tab eventKey="postTransplantOutcomes" title={<Skeleton width={120} />} className="margin-right">

                    </Tab>
                </Tabs>
            </div>
        );
    }
    else {
        return <InvalidCenter />
    }
}

export default CenterDetail;
