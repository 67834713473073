import React, { useState } from "react";
import Heart from "../../../assets/images/organs/72x72/Heart_Up_72x72.png";
import HeartHover from "../../../assets/images/organs/72x72/Heart_Over_72x72.png";

function HeartIcon(props) {
    const [isHover, setIsHover] = useState(false);

    if (props.showHover) {
        return (
            <div
                className={props.count ? "icon-container organ-count-wrapper" : "icon-container"}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
            >
                <img src={isHover ? HeartHover : Heart} alt="Heart Icon" className="organ-icon" />
                {props.count &&
                    <div className="organ-count heart">
                        {props.count}
                    </div>
                }
            </div>
        );
    }
    else {
        return (<img src={Heart} alt="Heart Icon" className="organ-icon" />);
    }
}

export default HeartIcon;