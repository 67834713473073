import React, { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { brandColorArray, formatTwoDecimals } from "../../utilities/formatter";
import HighchartMore from "highcharts/highcharts-more";

HighchartMore(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);
function RatioBoxPlotChart(props) {
  let subTitle = props.subTitle;

  const chartRef = useRef(null);
  const [chartOptions, setChartOptions] = useState({
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "printChart",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "downloadCSV",
          ],
        },
      },
      title: {
        text: `<div style="font-family:Open Sans;font-size:14px;font-weight: 700;text-align:center;">${props.title}</div><br /><div style="font-family:Open Sans;font-size:12px;font-weight: 400;text-align:center;">${subTitle}</div>`,
      },
      chart: {
        events: {
          load: function () {
            let width = 0;
            let height = this.chartHeight - 50;
            this.renderer.image("/srtr_logo.jpg", width, height, 50, 50).add();
          },
        },
      },
    },
    chart: {
      type: "boxplot",
      inverted: true,
      styledMode: false,
    },
    lang: {
      noData: getNoDataMessage(
        props.centerCode,
        props.organ,
        props.survivalResult
      ),
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [props.centerCode],
      labels: {
        style: {
          fontFamily: "Open Sans",
          fontSize: "14px",
        },
      },
    },
    yAxis: {
      title: {
        text: props.yAxisLabel,
        style: {
          fontFamily: "Open Sans",
          fontSize: "14px",
        },
      },
      labels: {
        style: {
          fontFamily: "Open Sans",
          fontSize: "14px",
        },
      },
    },
    tooltip: {
      shared: false,
      valueDecimals: 2,
      borderRadius: 10,
      shadow: true,
      useHTML: true,
    },
    legend: {
      enabled: false,
      itemStyle: {
        fontFamily: "Open Sans",
        fontSize: "14px",
      },
    },
    labels: {
      useHTML: true,
      allowOverlap: true,
      style: {
        wordBreak: "break-all",
      },
    },
    plotOptions: {
      columnrange: {
        grouping: false,
        color: brandColorArray[1],
      },
      scatter: {
        color: brandColorArray[1],
        marker: {
          radius: 10,
        },
      },
    },
  });

  useEffect(() => {
    let series = [];
    series = [
      {
        name: "columnRange",
        id: "ratioRange",
        type: "columnrange",
        pointWidth: 4,
        data:
          props.ratio && props.ratioLow && props.ratioHigh
            ? [[props.ratioLow, props.ratioHigh]]
            : [],
        className: "custom-column-range-line",
      },
      {
        name: "columnRange",
        linkedTo: "ratioRange",
        type: "columnrange",
        pointWidth: 25,
        minPointLength: 4,
        data:
          props.ratio && props.ratioLow && props.ratioHigh
            ? [[props.ratioLow, props.ratioLow]]
            : [],
        className: "custom-column-range-bottom",
      },
      {
        name: "columnRange",
        linkedTo: "ratioRange",
        type: "columnrange",
        pointWidth: 25,
        minPointLength: 4,
        data:
          props.ratio && props.ratioLow && props.ratioHigh
            ? [[props.ratioHigh, props.ratioHigh]]
            : [],
        className: "custom-column-range-top",
      },
      {
        name: "scatter",
        type: "scatter",
        linkedTo: ":previous",
        pointWidth: 10,
        data:
          props.ratio && props.ratioLow && props.ratioHigh
            ? [[props.ratio]]
            : [],
        className: "custom-column-range-scatter",
        marker: {
          symbol: "circle",
          lineWidth: 1,
        },
      },
    ];

    setChartOptions({
      ...chartOptions,
      title: {
        text: `<div style="font-family:Open Sans;font-size:20px;font-weight: 400;text-align:center;">${props.title}</div>`,
      },
      tooltip: {
        formatter: function () {
          let body = `<div style="padding: 0px 5px 5px 5px"><span style="font-family:Open Sans;font-size:16px;font-weight: 600;text-align:left">${
            props.ratioLabel
          }</span>: <span style="font-family:Open Sans;font-size:16px;font-weight: 400;text-align:left">${formatTwoDecimals(
            props.ratio
          )}</span></div>`;
          body += `<div style="padding: 0px 5px 5px 5px"><span style="font-family:Open Sans;font-size:16px;font-weight: 600;text-align:left">95% Credible Interval</span>: <span style="font-family:Open Sans;font-size:16px;font-weight: 400;text-align:left">[${formatTwoDecimals(
            props.ratioLow
          )}, ${formatTwoDecimals(props.ratioHigh)}]</span></div>`;
          return `<div>${body}</div>`;
        },
      },
      series,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <div className="transplant-grafts-hazard-ratio-chart">
      <Row>
        <Col xs={12} lg={12}>
          <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            options={chartOptions}
            containerProps={{ className: "highcharts-light" }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default RatioBoxPlotChart;

const getNoDataMessage = (centerCode, organ, survivalResult) => {
  if (organ === "pa" && survivalResult === "gsr") {
    return "Graft failure has not been defined for pancreas grafts";
  } else {
    return `${centerCode} did not perform any transplants meeting <br />these criteria during the timeframe studied`;
  }
};
