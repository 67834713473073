import React from "react";
import moment from "moment";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MedicalChart from "./MedicalChart";

function Medical(props) {
    let beginDate = moment(new Date(props.data.begDate));
    let endDate = moment(new Date(props.data.endDate));
    let title = "Medical Characteristics";
    let subTitle = `Candidates registered on the waiting list between ${beginDate.format("MM/DD/YYYY")} and ${endDate.format("MM/DD/YYYY")}`
    return (
        <div className="waitlist-demographics-wrapper section">
            <Row>
                <Col xs={12}>
                    <Row>
                        <Col xs={12}>
                            <h1 className="section-title">
                               {title}
                            </h1>
                            <div className="sub-title">
                                {subTitle}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <MedicalChart
                        organ={props.organ}
                        data={props.data}
                        centerCode={props.centerCode}
                        title={title}
                        subTitle={subTitle}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default Medical;