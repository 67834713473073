import React, { Component } from "react"
import mapboxgl from 'mapbox-gl';
import bbox from "@turf/bbox";
import HoverTransplantCenterPopup from "./HoverTransplantCenterPopup";
import ControlPanel from "./ControlPanel";
import { ScaleLoader } from "react-spinners";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
var geoViewport = require('@mapbox/geo-viewport');
class OPOInformationMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lng: -96,
            lat: 38,
            zoom: 3.4,
            hoveredCounty: null,
            hoveredTransplantCenter: null,
            map: null
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.organFilter !== this.props.organFilter) {
            if (this.props.organFilter && this.props.centersWithTransplants.features) {
                var filteredResult = this.props.centersWithTransplants.features.filter(obj => {
                    if (this.props.organFilter === "hasHeart") {
                        return obj.properties.hasHeart === true;
                    }
                    if (this.props.organFilter === "hasKidney") {
                        return obj.properties.hasKidney === true;
                    }
                    if (this.props.organFilter === "hasLiver") {
                        return obj.properties.hasLiver === true;
                    }
                    if (this.props.organFilter === "hasLung") {
                        return obj.properties.hasLung === true;
                    }
                    if (this.props.organFilter === "hasPancreas") {
                        return obj.properties.hasPancreas === true;
                    }
                    if (this.props.organFilter === "hasIntestine") {
                        return obj.properties.hasIntestine === true;
                    }

                    return null;
                });

                this.state.map.getSource('centersWithTransplants').setData({ type: "FeatureCollection", features: filteredResult });
            }
            else {
                this.state.map.getSource('centersWithTransplants').setData(this.props.centersWithTransplants);
            }
        }
    }
    componentDidMount() {
        if (this.props.opoData) {
            let map = new mapboxgl.Map({
                container: this.mapContainer,
                style: this.props.opoData.mapboxStyleUrl,
                center: [this.state.lng, this.state.lat],
                zoom: this.state.zoom
            });

            map.on('move', () => {
                this.setState({
                    lng: map.getCenter().lng.toFixed(4),
                    lat: map.getCenter().lat.toFixed(4),
                    zoom: map.getZoom().toFixed(2)
                });
            });

            map.on('load', () => {
                map.loadImage('/hospital.png', (error, image) => {
                    if (error) {
                        return;
                    }
                    map.addImage('centerIcon', image);
                });

                if (this.props.centersWithTransplants) {
                    map.addSource('centersWithTransplants', {
                        type: 'geojson',
                        data: this.props.centersWithTransplants,
                        cluster: true,
                        clusterMaxZoom: 14, // Max zoom to cluster points on
                        clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
                    });

                
                    map.addLayer({
                        id: 'clusters',
                        type: 'circle',
                        source: 'centersWithTransplants',
                        filter: ['has', 'point_count'],
                        paint: {
                            'circle-color': [
                                'step',
                                ['get', 'point_count'],
                                '#2d62a1',
                                100,
                                '#2d62a1',
                                750,
                                '#2d62a1'
                            ],
                            'circle-radius': [
                                'step',
                                ['get', 'point_count'],
                                20,
                                100,
                                30,
                                750,
                                40
                            ]
                        }
                    });

                    map.addLayer({
                        id: 'cluster-count',
                        type: 'symbol',
                        source: 'centersWithTransplants',
                        filter: ['has', 'point_count'],
                        layout: {
                            'text-field': '{point_count_abbreviated}',
                            'text-size': 12,
                        }
                    });

                    map.addLayer({
                        id: 'unclustered-point',
                        type: 'symbol',
                        source: 'centersWithTransplants',
                        filter: ['!', ['has', 'point_count']],
                        layout: {
                            'icon-image': 'centerIcon',
                            'icon-size': .5
                        }
                    });

                    map.on('click', 'clusters', function (e) {
                        var features = map.queryRenderedFeatures(e.point, {
                            layers: ['clusters']
                        });
                        var clusterId = features[0].properties.cluster_id;
                        map.getSource('centersWithTransplants').getClusterExpansionZoom(
                            clusterId,
                            function (err, zoom) {
                                if (err) return;

                                map.easeTo({
                                    center: features[0].geometry.coordinates,
                                    zoom: zoom
                                });
                            }
                        );
                    });
                    
                }

                map.on('mouseenter', 'clusters', function () {
                    map.getCanvas().style.cursor = 'pointer';
                });

                map.on('mouseleave', 'clusters', function () {
                    map.getCanvas().style.cursor = '';
                });

                map.on('mousemove', 'unclustered-point', (e) => {
                    map.getCanvas().style.cursor = "pointer";

                    const _hoveredTransplantCenter = e.features[0];

                    this.setState({
                        hoveredTransplantCenter: _hoveredTransplantCenter,
                        offsetX: e.point.x,
                        offsetY: e.point.y
                    });
                });

                map.on('mouseleave', 'unclustered-point', () => {
                    map.getCanvas().style.cursor = '';
                    this.setState({
                        hoveredTransplantCenter: null
                    });
                });

                map.on('mousemove', 'opo-counties', (e) => {

                    map.getCanvas().style.cursor = "pointer";

                    const _hoveredCounty = e.features[0];

                    this.setState({
                        hoveredCounty: _hoveredCounty
                    });
                });

                map.on('mouseleave', 'opo-counties', () => {
                    map.getCanvas().style.cursor = '';
                    this.setState({
                        hoveredCounty: null
                    });
                });

                if (this.props.opoData) {
                    if (this.props.opoData.code.toLowerCase() === "walc") {
                        try {
                            const { width, height } = map.getCanvas().getBoundingClientRect();
                            const viewport = geoViewport.viewport(
                                [-180, 50, -106, 72],
                                [width, height]
                            );
                            map.setCenter(viewport.center);
                            map.setZoom(2.6);
                        }
                        catch (err) {
                            console.log(err);
                        }
                    }
                    else {
                        try {
                            let bounds = bbox(this.props.opoData.feature);
                            map.fitBounds(bounds, { padding: 20 });
                        }
                        catch (err) {
                            console.log(err);
                        }
                    }
                }
            });

            map.addControl(new mapboxgl.NavigationControl());

            this.setState({
                map
            });

        }
    }

    render() {
        if (!this.props.opoData) {
            return (
                <div id="opoInformationMap" className="opo-map">
                    <div style={{ height: "100%" }} ref={el => this.mapContainer = el}>
                        <div className="loading">
                            <ScaleLoader
                                height={50}
                                width={20}
                                radius={2}
                                margin={6}
                                color={"#d1ece8"}
                                loading={true}
                            />
                            Getting OPO Information...
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <Row>
                    <Col xs={12} lg={4}>
                        <ControlPanel
                            opoName={this.props.opoData.name}
                            hoveredCounty={this.state.hoveredCounty}
                            donorHospitals={this.props.opoData.donorHospitals}
                            counties={this.props.counties}
                            beginDate={this.props.centersWithTransplants.features[0] ? this.props.centersWithTransplants.features[0].properties.begDate : ""}
                            endDate={this.props.centersWithTransplants.features[0] ? this.props.centersWithTransplants.features[0].properties.endDate: ""}
                        />
                    </Col>

                    <Col xs={12} lg={8}>
                        <div id="opoInformationMap" className="opo-map">
                            <div style={{ height: "100%" }} ref={el => this.mapContainer = el}>
                            </div>

                            {this.state.hoveredTransplantCenter &&
                                <HoverTransplantCenterPopup hoveredTransplantCenter={this.state.hoveredTransplantCenter} x={this.state.offsetX} y={this.state.offsetY} />
                            }
                        </div>
                    </Col>
                    <Col xs={12}>
                        <hr />

                    </Col>
                </Row>

            );
        }
    }
}

export default OPOInformationMap;
