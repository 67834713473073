export function getLivingCharacteristicOptions(organ) {
    let options = [];
    let organName = "";
    if (organ === "ki") {
        organName = "Kidney";
    }
    else if (organ === "li") {
        organName = "Liver";
    }

    options.push(
        {
            label: "Relation with Donor",
            values: [
                { label: "Related", value: { center: "tol_rey_c", region: "tol_rey_r", us: "tol_rey_u", category: "Relation with Donor" } },
                { label: "Unrelated", value: { center: "tol_ren_c", region: "tol_ren_r", us: "tol_ren_u", category: "Relation with Donor" } },
                { label: "Not Reported", value: { center: "tol_renr_c", region: "tol_renr_r", us: "tol_renr_u", category: "Relation with Donor" } }
            ]
        });

    if (organ === "ki") {
        options.push(
            {

                label: "Level Of Mismatch: A Locus Mismatches",
                values: [
                    { label: "0", value: { center: "tol_amm0_c", region: "tol_amm0_r", us: "tol_amm0_u", category: "A Locus Mismatches" } },
                    { label: "1", value: { center: "tol_amm1_c", region: "tol_amm1_r", us: "tol_amm1_u", category: "A Locus Mismatches" } },
                    { label: "2", value: { center: "tol_amm2_c", region: "tol_amm2_r", us: "tol_amm2_u", category: "A Locus Mismatches" } },
                    { label: "Not Reported", value: { center: "tol_ammur_c", region: "tol_ammur_r", us: "tol_ammur_u", category: "A Locus Mismatches" } }
                ]

            },
            {
                label: "Level Of Mismatch: B Locus Mismatches",
                values: [
                    { label: "0", value: { center: "tol_bmm0_c", region: "tol_bmm0_r", us: "tol_bmm0_u", category: "B Locus Mismatches" } },
                    { label: "1", value: { center: "tol_bmm1_c", region: "tol_bmm1_r", us: "tol_bmm1_u", category: "B Locus Mismatches" } },
                    { label: "2", value: { center: "tol_bmm2_c", region: "tol_bmm2_r", us: "tol_bmm2_u", category: "B Locus Mismatches" } },
                    { label: "Not Reported", value: { center: "tol_bmmur_c", region: "tol_bmmur_r", us: "tol_bmmur_u", category: "B Locus Mismatches" } }
                ]
            },
            {
                label: "Level Of Mismatch: DR Locus Mismatches",
                values: [
                    { label: "0", value: { center: "tol_drmm0_c", region: "tol_drmm0_r", us: "tol_drmm0_u", category: "DR Locus Mismatches" } },
                    { label: "1", value: { center: "tol_drmm1_c", region: "tol_drmm1_r", us: "tol_drmm1_u", category: "DR Locus Mismatches" } },
                    { label: "2", value: { center: "tol_drmm2_c", region: "tol_drmm2_r", us: "tol_drmm2_u", category: "DR Locus Mismatches" } },
                    { label: "Not Reported", value: { center: "tol_drmmur_c", region: "tol_drmmur_r", us: "tol_drmmur_u", category: "DR Locus Mismatches" } }
                ]
            },
            {
                label: "Level Of Mismatch: Total Mismatches",
                values: [
                    { label: "0", value: { center: "tol_mm0_c", region: "tol_mm0_r", us: "tol_mm0_u", category: "Total Mismatches" } },
                    { label: "1", value: { center: "tol_mm1_c", region: "tol_mm1_r", us: "tol_mm1_u", category: "Total Mismatches" } },
                    { label: "2", value: { center: "tol_mm2_c", region: "tol_mm2_r", us: "tol_mm2_u", category: "Total Mismatches" } },
                    { label: "3", value: { center: "tol_mm3_c", region: "tol_mm3_r", us: "tol_mm3_u", category: "Total Mismatches" } },
                    { label: "4", value: { center: "tol_mm4_c", region: "tol_mm4_r", us: "tol_mm4_u", category: "Total Mismatches" } },
                    { label: "5", value: { center: "tol_mm5_c", region: "tol_mm5_r", us: "tol_mm5_u", category: "Total Mismatches" } },
                    { label: "6", value: { center: "tol_mm6_c", region: "tol_mm6_r", us: "tol_mm6_u", category: "Total Mismatches" } },
                    { label: "Not Reported", value: { center: "tol_mmnr_c", region: "tol_mmnr_r", us: "tol_mmnr_u", category: "Total Mismatches" } }
                ]
            }
        );

        options.push(
            {
                label: "Dialysis in First Week After Transplant",
                values: [
                    { label: "Yes", value: { center: "tol_wkdialy_c", region: "tol_wkdialy_r", us: "tol_wkdialy_u", category: "Dialysis in First Week After Transplant" } },
                    { label: "No", value: { center: "tol_wkdialn_c", region: "tol_wkdialn_r", us: "tol_wkdialn_u", category: "Dialysis in First Week After Transplant" } },
                    { label: "Not Reported", value: { center: "tol_wkdialnr_c", region: "tol_wkdialnr_r", us: "tol_wkdialnr_u", category: "Dialysis in First Week After Transplant" } }
                ]
            });
    }

    options.push(
        {
            label: "Procedure Type",
            values: [
                { label: organName + " alone", value: { center: "tol_1org_c", region: "tol_1org_r", us: "tol_1org_u", category: "Procedure Type" } },
                { label: organName + " and another organ", value: { center: "tol_multi_c", region: "tol_multi_r", us: "tol_multi_u", category: "Procedure Type" } }
            ]
        });

    return options;
}

export function getDeceasedCharacteristicOptions(organ) {
    let options = [];
    let organName = "";
    if (organ === "ki") {
        organName = "Kidney";
    }
    else if (organ === "lu") {
        organName = "Lung";
    }
    else if (organ === "hr") {
        organName = "Heart";
    }
    else if (organ === "in") {
        organName = "Intestine";
    }
    else if (organ === "li") {
        organName = "Liver";
    }
    else if (organ === "pa") {
        organName = "Pancreas";
    }

    if (organ === "hr" || organ === "lu" || organ === "hl") {
        options.push(
            {
                label: "Total Ischemic Time (Minutes): Local",
                values: [
                    { label: "Deceased: 0-90 min", value: { center: "toc_lcthor1_c", region: "toc_lcthor1_r", us: "toc_lcthor1_u", category: "Total Ischemic Time (Minutes): Local" } },
                    { label: "Deceased: 91-180 min", value: { center: "toc_lcthor2_c", region: "toc_lcthor2_r", us: "toc_lcthor2_u", category: "Total Ischemic Time (Minutes): Local" } },
                    { label: "Deceased: 181-270 min", value: { center: "toc_lcthor3_c", region: "toc_lcthor3_r", us: "toc_lcthor3_u", category: "Total Ischemic Time (Minutes): Local" } },
                    { label: "Deceased: 271-360 min", value: { center: "toc_lcthor4_c", region: "toc_lcthor4_r", us: "toc_lcthor4_u", category: "Total Ischemic Time (Minutes): Local" } },
                    { label: "Deceased: 361+ min", value: { center: "toc_lcthor5_c", region: "toc_lcthor5_r", us: "toc_lcthor5_u", category: "Total Ischemic Time (Minutes): Local" } },
                    { label: "Not Reported", value: { center: "toc_lcthor6_c", region: "tocbmi41P_r", us: "toc_lcthor6_u", category: "Total Ischemic Time (Minutes): Local" } }
                ]
            });

        options.push(
            {
                label: "Total Ischemic Time (Minutes): Shared",
                values: [
                    { label: "Deceased: 0-90 min", value: { center: "toc_scthor1_c", region: "toc_scthor1_r", us: "toc_scthor1_u", category: "Total Ischemic Time (Minutes): Shared" } },
                    { label: "Deceased: 91-180 min", value: { center: "toc_scthor2_c", region: "toc_scthor2_r", us: "toc_scthor2_u", category: "Total Ischemic Time (Minutes): Shared" } },
                    { label: "Deceased: 181-270 min", value: { center: "toc_scthor3_c", region: "toc_scthor3_r", us: "toc_scthor3_u", category: "Total Ischemic Time (Minutes): Shared" } },
                    { label: "Deceased: 271-360 min", value: { center: "toc_scthor4_c", region: "toc_scthor4_r", us: "toc_scthor4_u", category: "Total Ischemic Time (Minutes): Shared" } },
                    { label: "Deceased: 361+ min", value: { center: "toc_scthor5_c", region: "toc_scthor5_r", us: "toc_scthor5_u", category: "Total Ischemic Time (Minutes): Shared" } },
                    { label: "Not Reported", value: { center: "toc_scthor6_c", region: "tocbmi41P_r", us: "toc_scthor6_u", category: "Total Ischemic Time (Minutes): Shared" } }
                ]
            });
    }

    if (organ === "ki" || organ === "pa") {
        options.push(
            {
                label: "Cold Ischemic Time (Hours): Local",
                values: [
                    { label: "Deceased: 0-11 hr", value: { center: "toc_lckikp1_c", region: "toc_lckikp1_r", us: "toc_lckikp1_u", category: "Cold Ischemic Time (Hours): Local" } },
                    { label: "Deceased: 12-21 hr", value: { center: "toc_lckikp2_c", region: "toc_lckikp2_r", us: "toc_lckikp2_u", category: "Cold Ischemic Time (Hours): Local" } },
                    { label: "Deceased: 22-31 hr", value: { center: "toc_lckikp3_c", region: "toc_lckikp3_r", us: "toc_lckikp3_u", category: "Cold Ischemic Time (Hours): Local" } },
                    { label: "Deceased: 32-41 hr", value: { center: "toc_lckikp4_c", region: "toc_lckikp4_r", us: "toc_lckikp4_u", category: "Cold Ischemic Time (Hours): Local" } },
                    { label: "Deceased: 42+ hr", value: { center: "toc_lckikp5_c", region: "toc_lckikp5_r", us: "toc_lckikp5_u", category: "Cold Ischemic Time (Hours): Local" } },
                    { label: "Not Reported", value: { center: "toc_lckikp6_c", region: "tocbmi41P_r", us: "toc_lckikp6_u", category: "Cold Ischemic Time (Hours): Local" } }
                ]
            });

        options.push(
            {
                label: "Cold Ischemic Time (Hours): Shared",
                values: [
                    { label: "Deceased: 0-11 hr", value: { center: "toc_sckikp1_c", region: "toc_sckikp1_r", us: "toc_sckikp1_u", category: "Cold Ischemic Time (Hours): Local" } },
                    { label: "Deceased: 12-21 hr", value: { center: "toc_sckikp2_c", region: "toc_sckikp2_r", us: "toc_sckikp2_u", category: "Cold Ischemic Time (Hours): Local" } },
                    { label: "Deceased: 22-31 hr", value: { center: "toc_sckikp3_c", region: "toc_sckikp3_r", us: "toc_sckikp3_u", category: "Cold Ischemic Time (Hours): Local" } },
                    { label: "Deceased: 32-41 hr", value: { center: "toc_sckikp4_c", region: "toc_sckikp4_r", us: "toc_sckikp4_u", category: "Cold Ischemic Time (Hours): Local" } },
                    { label: "Deceased: 42+ hr", value: { center: "toc_sckikp5_c", region: "toc_sckikp5_r", us: "toc_sckikp5_u", category: "Cold Ischemic Time (Hours): Local" } },
                    { label: "Not Reported", value: { center: "toc_sckikp6_c", region: "toc_bmi41P_r", us: "toc_sckikp6_u", category: "Cold Ischemic Time (Hours): Local" } }
                ]
            });
    }

    if (organ === "ki") {
        options.push(
            {
                label: "Level Of Mismatch: A Locus Mismatches",
                values: [
                    { label: "0", value: { center: "toc_amm0_c", region: "toc_amm0_r", us: "toc_amm0_u", category: "A Locus Mismatches" } },
                    { label: "1", value: { center: "toc_amm1_c", region: "toc_amm1_r", us: "toc_amm1_u", category: "A Locus Mismatches" } },
                    { label: "2", value: { center: "toc_amm2_c", region: "toc_amm2_r", us: "toc_amm2_u", category: "A Locus Mismatches" } },
                    { label: "Not Reported", value: { center: "toc_ammur_c", region: "toc_ammur_r", us: "toc_ammur_u", category: "A Locus Mismatches" } }
                ]

            },
            {
                label: "Level Of Mismatch: B Locus Mismatches",
                values: [
                    { label: "0", value: { center: "toc_bmm0_c", region: "toc_bmm0_r", us: "toc_bmm0_u", category: "B Locus Mismatches" } },
                    { label: "1", value: { center: "toc_bmm1_c", region: "toc_bmm1_r", us: "toc_bmm1_u", category: "B Locus Mismatches" } },
                    { label: "2", value: { center: "toc_bmm2_c", region: "toc_bmm2_r", us: "toc_bmm2_u", category: "B Locus Mismatches" } },
                    { label: "Not Reported", value: { center: "toc_bmmur_c", region: "toc_bmmur_r", us: "toc_bmmur_u", category: "B Locus Mismatches" } }
                ]
            },
            {
                label: "Level Of Mismatch: DR Locus Mismatches",
                values: [
                    { label: "0", value: { center: "toc_drmm0_c", region: "toc_drmm0_r", us: "toc_drmm0_u", category: "DR Locus Mismatches" } },
                    { label: "1", value: { center: "toc_drmm1_c", region: "toc_drmm1_r", us: "toc_drmm1_u", category: "DR Locus Mismatches" } },
                    { label: "2", value: { center: "toc_drmm2_c", region: "toc_drmm2_r", us: "toc_drmm2_u", category: "DR Locus Mismatches" } },
                    { label: "Not Reported", value: { center: "toc_drmmur_c", region: "toc_drmmur_r", us: "toc_drmmur_u", category: "DR Locus Mismatches" } }
                ]
            },
            {
                label: "Level Of Mismatch: Total Mismatches",
                values: [
                    { label: "0", value: { center: "toc_mm0_c", region: "toc_mm0_r", us: "toc_mm0_u", category: "Total Mismatches" } },
                    { label: "1", value: { center: "toc_mm1_c", region: "toc_mm1_r", us: "toc_mm1_u", category: "Total Mismatches" } },
                    { label: "2", value: { center: "toc_mm2_c", region: "toc_mm2_r", us: "toc_mm2_u", category: "Total Mismatches" } },
                    { label: "3", value: { center: "toc_mm3_c", region: "toc_mm3_r", us: "toc_mm3_u", category: "Total Mismatches" } },
                    { label: "4", value: { center: "toc_mm4_c", region: "toc_mm4_r", us: "toc_mm4_u", category: "Total Mismatches" } },
                    { label: "5", value: { center: "toc_mm5_c", region: "toc_mm5_r", us: "toc_mm5_u", category: "Total Mismatches" } },
                    { label: "6", value: { center: "toc_mm6_c", region: "toc_mm6_r", us: "toc_mm6_u", category: "Total Mismatches" } },
                    { label: "Not Reported", value: { center: "toc_mmnr_c", region: "toc_mmnr_r", us: "toc_mmnr_u", category: "Total Mismatches" } }
                ]
            }
        );

        options.push(
            {
                label: "Dialysis in First Week After Transplant",
                values: [
                    { label: "Yes", value: { center: "toc_wkdialy_c", region: "toc_wkdialy_r", us: "toc_wkdialy_u", category: "Dialysis in First Week After Transplant" } },
                    { label: "No", value: { center: "toc_wkdialn_c", region: "toc_wkdialn_r", us: "toc_wkdialn_u", category: "Dialysis in First Week After Transplant" } },
                    { label: "Not Reported", value: { center: "toc_wkdialnr_c", region: "toc_wkdialnr_r", us: "toc_wkdialnr_u", category: "Dialysis in First Week After Transplant" } }
                ]
            });
    }

    if (organ === "in" || organ === "li") {
        options.push(
            {
                label: "Total Ischemic Time (Hours): Local",
                values: [
                    { label: "Deceased: 0-5 hr", value: { center: "toc_lcliin1_c", region: "toc_lcliin1_r", us: "toc_lcliin1_u", category: "Total Ischemic Time (Hours): Local" } },
                    { label: "Deceased: 6-10 hr", value: { center: "toc_lcliin2_c", region: "toc_lcliin2_r", us: "toc_lcliin2_u", category: "Total Ischemic Time (Hours): Local" } },
                    { label: "Deceased: 11-15 hr", value: { center: "toc_lcliin3_c", region: "toc_lcliin3_r", us: "toc_lcliin3_u", category: "Total Ischemic Time (Hours): Local" } },
                    { label: "Deceased: 16-20 hr", value: { center: "toc_lcliin4_c", region: "toc_lcliin4_r", us: "toc_lcliin4_u", category: "Total Ischemic Time (Hours): Local" } },
                    { label: "Deceased: 21+ hr", value: { center: "toc_lcliin5_c", region: "toc_lcliin5_r", us: "toc_lcliin5_u", category: "Total Ischemic Time (Hours): Local" } },
                    { label: "Not Reported", value: { center: "toc_lcliin6_c", region: "tocbmi41P_r", us: "toc_lcliin6_u", category: "Total Ischemic Time (Hours): Local" } }
                ]
            });

        options.push(
            {
                label: "Total Ischemic Time (Hours): Shared",
                values: [
                    { label: "Deceased: 0-5 hr", value: { center: "toc_scliin1_c", region: "toc_scliin1_r", us: "toc_scliin1_u", category: "Total Ischemic Time (Hours): Shared" } },
                    { label: "Deceased: 6-10 hr", value: { center: "toc_scliin2_c", region: "toc_scliin2_r", us: "toc_scliin2_u", category: "Total Ischemic Time (Hours): Shared" } },
                    { label: "Deceased: 11-15 hr", value: { center: "toc_scliin3_c", region: "toc_scliin3_r", us: "toc_scliin3_u", category: "Total Ischemic Time (Hours): Shared" } },
                    { label: "Deceased: 16-20 hr", value: { center: "toc_scliin4_c", region: "toc_scliin4_r", us: "toc_scliin4_u", category: "Total Ischemic Time (Hours): Shared" } },
                    { label: "Deceased: 21+ hr", value: { center: "toc_scliin5_c", region: "toc_scliin5_r", us: "toc_scliin5_u", category: "Total Ischemic Time (Hours): Shared" } },
                    { label: "Not Reported", value: { center: "toc_scliin6_c", region: "tocbmi41P_r", us: "toc_scliin6_u", category: "Total Ischemic Time (Hours): Shared" } }
                ]
            });
    }

    if (organ !== "hl" && organ !== "kp") {
        options.push(
            {
                label: "Procedure Type",
                values: [
                    { label: organName + " alone", value: { center: "toc_1org_c", region: "toc_1org_r", us: "toc_1org_u", category: "Procedure Type" } },
                    { label: organName + " and another organ", value: { center: "toc_multi_c", region: "toc_multi_r", us: "toc_multi_u", category: "Procedure Type" } }
                ]
            });
    }

    options.push(
        {
            label: "Sharing",
            values: [
                { label: "Local", value: { center: "toc_loc_c", region: "toc_loc_r", us: "toc_loc_u", category: "Sharing" } },
                { label: "Shared", value: { center: "toc_shr_c", region: "toc_shr_r", us: "toc_shr_u", category: "Sharing" } }
            ]
        });

    return options;
}

