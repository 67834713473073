import React from "react";
import {
  formatTwoDecimals,
  formatThousands,
} from "../../../utilities/formatter";

function ControlPanel(props) {
  if (props.type === "populationDensity") {
    const county = props.hoveredCounty;
    const opoPopulation = props.opoPopulation;
    const opoPopulationRank = props.opoPopulationRank;
    const opoLandArea = props.opoLandArea;
    const opoLandAreaRank = props.opoLandAreaRank;
    return (
      <div className="control-panel">
        <div className="title">{props.opoName}</div>
        <div className="sub-title">Population Density</div>
        <div>
          <label className="label">County:</label>{" "}
          {county ? county.properties.county : "--"}
        </div>
        <div>
          <label className="label">State:</label>{" "}
          {county ? county.properties.state : "--"}
        </div>
        <div>
          <label className="label">Density:</label>{" "}
          {county ? (
            <span>
              {formatThousands(county.properties.density)} persons/mi
              <sup>2</sup>
            </span>
          ) : (
            "--"
          )}
        </div>
        <hr></hr>
        <div>
          <label className="label">Population:</label>{" "}
          {formatThousands(opoPopulation)}
          <p>
            This ranks <label className="label">{opoPopulationRank}</label>{" "}
            among the 56 DSAs <br />
            (1st is the highest).
          </p>
        </div>
        <div>
          <label className="label">Land area:</label>{" "}
          {formatThousands(opoLandArea)} square miles{" "}
          <p>
            This ranks <label className="label">{opoLandAreaRank}</label> among
            the 56 DSAs <br />
            (1st is the highest).
          </p>
        </div>
        <hr />
        <div className="source">
          Source: US Census Bureau, 2020 projected population/2010 land area
        </div>
        <hr />
      </div>
    );
  } else {
    const county = props.hoveredCounty;
    const death = props.death;
    const deathRank = props.deathRank;
    const deathRate = props.deathRate;
    const deathRateRank = props.deathRateRank;
    return (
      <div className="control-panel">
        <div className="title">{props.opoName}</div>
        <div className="sub-title">Death Rates Per 1,000 People</div>
        <div>
          <label className="label">County:</label>{" "}
          {county ? county.properties.county : "--"}
        </div>
        <div>
          <label className="label">State:</label>{" "}
          {county ? county.properties.state : "--"}
        </div>
        <div>
          <label className="label">Deaths per 1,000:</label>{" "}
          {county ? (
            <span>{formatThousands(county.properties.deathrate)}</span>
          ) : (
            "--"
          )}
        </div>
        <hr></hr>
        <div>
          <label className="label">Deaths:</label> {formatThousands(death)}
          <p>
            This ranks <label className="label">{deathRank}</label> among the 56
            DSAs
            <br />
            (1st is the highest).
          </p>
        </div>
        <div>
          <label className="label">Death rate:</label>{" "}
          {formatTwoDecimals(deathRate)}{" "}
          <p>
            This ranks <label className="label">{deathRateRank}</label> among
            the 56 DSAs
            <br />
            (1st is the highest).
          </p>
        </div>
        <hr />
        <div className="source">
          Source: US Census Bureau, 2020 projected death (includes all deaths,
          not limited to eligible deaths) and population
        </div>
        <hr />
      </div>
    );
  }
}

export default ControlPanel;
