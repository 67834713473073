import React from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TrendsInOrgansTransplantedPerDonorChart from "./TrendsInOrgansTransplantedPerDonorChart";

function TrendsInOrgansTransplantedPerDonor(props) {
    const data = props.data;
    const categories = [
        data.yearOne,
        data.yearTwo,
        data.yearThree,
        data.yearFour,
        data.yearFive,
    ];
    let title = "Trends In Organs Transplanted Per Donor";
    let subTitle = data ? `${data.begDate} to ${data.endDate}` : "";
    return (
        <Row>
            <Col xs={12}>
                <Row>
                    <Col xs={12} >
                        <h1 className="section-title">
                            {title}
                    </h1>
                        <div className="sub-title">
                            
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col s={12} md={6}>
                        {data.heartData &&
                            <TrendsInOrgansTransplantedPerDonorChart
                                chartData={data.heartData}
                                categories={categories}
                                organ="heart" 
                                title={`${title} - Heart`}
                                subTitle={subTitle}
                            />
                        }
                    </Col>
                    <Col s={12} md={6}>
                        {data.kidneyData &&
                            <TrendsInOrgansTransplantedPerDonorChart
                                chartData={data.kidneyData}
                                categories={categories}
                                organ="kidney"
                                title={`${title} - Kidney`}
                                subTitle={subTitle} />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col s={12} md={6}>
                        {data.liverData &&
                            <TrendsInOrgansTransplantedPerDonorChart
                                chartData={data.liverData}
                                categories={categories}
                                organ="liver"  
                                title={`${title} - Liver`}
                                subTitle={subTitle}/>
                        }
                    </Col>
                    <Col s={12} md={6}>
                        {data.lungData &&
                            <TrendsInOrgansTransplantedPerDonorChart
                                chartData={data.lungData}
                                categories={categories}
                                organ="lung" 
                                title={`${title} - Lung`}
                                subTitle={subTitle}/>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col s={12} md={6}>
                        {data.pancreasData &&
                            <TrendsInOrgansTransplantedPerDonorChart
                                chartData={data.pancreasData}
                                categories={categories}
                                organ="pancreas" 
                                title={`${title} - Pancreas`}
                                subTitle={subTitle}/>
                        }
                    </Col>
                    <Col s={12} md={6}>
                        {data.intestineData &&
                            <TrendsInOrgansTransplantedPerDonorChart
                                chartData={data.intestineData}
                                categories={categories}
                                organ="intestine" 
                                title={`${title} - Intestine`}
                                subTitle={subTitle}/>
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default TrendsInOrgansTransplantedPerDonor;