import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

function ObservedExpectedYieldChart(props) {
    let title = props.title;
    let subTitle = props.subTitle;

    const [chartOptions, setChartOptions] = useState(null);

    useEffect(() => {

        let series = [];
        series.push({
            name: "Other OPOs",
            marker: {
                symbol: "circle",
                lineWidth: 1
            },
            data: props.otherOPOs,
            color: "#e45d2c"
        });

        if (props.opoData.x && props.opoData.y) {
            series.push({
                name: props.opoData.opoCode,
                data: [{ x: props.opoData.x, y: props.opoData.y, opoCode: props.opoData.opoCode }],
                color: "#182474",
                lineWidth: 1
            });
        }

        let chartOptions =
        {
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: ['printChart', 'downloadPNG', 'downloadSVG', 'downloadPDF', 'downloadCSV']
                    },
                },
                title: {
                    text: `<div style="font-family:Open Sans;font-size:14px;font-weight: 700;text-align:center;">${title}</div><br /><div style="font-family:Open Sans;font-size:12px;font-weight: 400;text-align:center;">${subTitle}</div>`
                },
                chart: {
                    events: {
                        load: function () {
                            let width = 0;
                            let height = this.chartHeight - 50;
                            this.renderer.image('/srtr_logo.jpg', width, height, 50, 50)
                                .add();
                        }
                    }
                }
            },
            chart: {
                type: "scatter",
                zoomType: "xy" - +
                    9
            },
            credits: {
                enabled: false
            },
            accessibility: {
                description: ""
            },
            title: {
                text: ""
            },
            xAxis: {
                title: {
                    text: "Number of Donors",
                    style: {
                        fontFamily: "Open Sans",
                        fontSize: "14px"
                    }
                },
                startOnTick: true,
                endOnTick: true,
                showLastLabel: true,
                labels: {
                    style: {
                        fontFamily: "Open Sans",
                        fontSize: "14px"
                    }
                }
            },
            yAxis: {
                title: {
                    text: "Obs/Exp Ratio",
                    style: {
                        fontFamily: "Open Sans",
                        fontSize: "14px"
                    }
                },
                plotLines: [{
                    dashStyle: "solid",
                    value: 1,
                    width: 4
                }],
                labels: {
                    style: {
                        fontFamily: "Open Sans",
                        fontSize: "14px"
                    }
                }
            },
            legend: {
                itemStyle: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"
                }
            },
            tooltip: {
                shared: true,
                headerFormat: "",
                pointFormat: "OPO Code: <b>{point.opoCode}</b><br/>Obs/Exp Ratio: <b>{point.y}</b><br/>Number of Donors: <b>{point.x}</b>"
            },
            plotOptions: {
                scatter: {
                    marker: {
                        radius: 10
                    },
                    tooltip: {
                        headerFormat: "",
                        pointFormat: '<div style="margin-bottom: 15px;"><span style="font-family:Open Sans;font-size:16px;font-weight: 700;text-align:left">OPO Code: </span> <span style="font-family:Open Sans;font-size:16px;font-weight: 400;text-align:left">{point.opoCode}</span></div><br /><div style="padding-bottom: 5px;"><span style="font-family:Open Sans;font-size:16px;font-weight: 700;text-align:left">Obs/Exp Ratio: </span><span style="font-family:Open Sans;font-size:16px;font-weight: 400;text-align:left">{point.y:.2f}</span></div><br /><div  style="padding-bottom: 5px;"><span style="font-family:Open Sans;font-size:16px;font-weight: 700;text-align:left">Number of Donors: </span><span style="font-family:Open Sans;font-size:16px;font-weight: 400;text-align:left">{point.x}</span></div>'
                    }
                },
                series: {
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    }
                }
            },
            series: series
        };


        setChartOptions(chartOptions)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    return (
        <div className="observed-expected-yield-container">
            <HighchartsReact highcharts={Highcharts} options={chartOptions} oneToOne={true} containerProps={{ className: "highcharts-light" }}/>
        </div>
    )
}

export default ObservedExpectedYieldChart;