import React, { useState } from 'react'
import SelectOPO from "../../utilities/SelectOPO";
import OPOInformation from "./OPOInformation";
import ErrorFallback from "../../utilities/error-fallback/ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";

const Host = process.env.REACT_APP_API_HOST;
function CompareOPO() {
    const [opoData, setOPOData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const _onSelectOPO = (opoCode) => {
        try {
            setIsLoading(true);
            fetch(`${Host}/opo/${opoCode}`)
            .then(response => response.json())
            .then(response => {
                    setOPOData(response.opoDetail);
                    setIsLoading(false);
               
            })
            .catch((err) => { console.log(err) });;
        }
        catch (err) {
            console.log(err);
        }
    };

    const _onCompareClick = () => {
        setIsLoading(false);
        setOPOData(null);
    };

    if (opoData === null && !isLoading) {
        return (
            <div className="compare-opo background-teal">
                <div className="header">
                    <h2>Compare OPO</h2>
                </div>
                <div className="body">
                    <SelectOPO onSelectOPO={_onSelectOPO} />
                </div>
            </div>
        );
    }
    else {
        return (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <OPOInformation opoData={opoData} showCompare={true} onCompareClick={_onCompareClick} />
            </ErrorBoundary>
        );
    }
}

export default CompareOPO;