import React, { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { brandColorArray, formatTwoDecimals } from "../../utilities/formatter";
import HighchartMore from "highcharts/highcharts-more";
HighchartMore(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

Highcharts.Axis.prototype.log2lin = function (num) {
    return Math.log(num) / Math.LN2;
};
Highcharts.Axis.prototype.lin2log = function (num) {
    return Math.pow(2, num);
};

function OfferAcceptancesChart(props) {
    let title = props.title;
    let subTitle = props.subTitle;
    const chartRef = useRef(null);
    const [chartOptions, setChartOptions] = useState(null)

    useEffect(() => {
        let chartOptions = {
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: ['printChart', 'downloadPNG', 'downloadSVG', 'downloadPDF', 'downloadCSV']
                    },
                },
                title: {
                    text: `<div style="font-family:Open Sans;font-size:14px;font-weight: 700;text-align:center;">${title}</div><br /><div style="font-family:Open Sans;font-size:12px;font-weight: 400;text-align:center;">${subTitle}</div>`
                },
                chart: {
                    events: {
                        load: function () {
                            let width = 0;
                            let height = this.chartHeight - 50;
                            this.renderer.image('/srtr_logo.jpg', width, height, 50, 50)
                                .add();
                        }
                    }
                }
            },
            chart: {
                type: "boxplot",
                styledMode: true,
                height: 300

            },
            credits: {
                enabled: false
            },
            title: {
                text: `<div style="font-family:Open Sans;font-size:20px;font-weight: 400;text-align:center;">${title}</div>`
            },
            xAxis: {
                categories: ["National", props.centerCode],
                labels: {
                    style: {
                        fontFamily: "Open Sans",
                        fontSize: "14px"
                    }
                }
            },
            yAxis: {
                type: "logarithmic",
                title: {
                    text: "Offer Acceptance Ratio",
                    style: {
                        fontFamily: "Open Sans",
                        fontSize: "14px"
                    }
                },
                labels: {
                    style: {
                        fontFamily: "Open Sans",
                        fontSize: "14px"
                    }
                },
                plotLines: [{
                    value: 1,
                    color: "red",
                    width: 1,
                    label: {
                        align: "center",
                        style: {
                            color: "gray"
                        }
                    }
                }]
            },
            tooltip: {
                shared: false,
                valueDecimals: 2,
                borderWidth: 1,
                backgroundColor: "white",
                borderRadius: 10,
                borderColor: "#d2d2d2",
                shadow: true,
                useHTML: true,

                formatter: function () {

                    let header = `<div style="padding-bottom:5px;font-family:Open Sans;font-size:16px;font-weight: 600;text-align:center">${this.x} - ${props.displayName}</div>`;
                    let body = "";
                    if (this.series.name === "scatter" || this.series.name === "columnRange") {
                        body = `<div style="padding: 0px 5px 5px 5px"><span style="font-family:Open Sans;font-size:16px;font-weight: 600;text-align:left">Offer Acceptance Ratio</span>: <span style="font-family:Open Sans;font-size:16px;font-weight: 400;text-align:left">${formatTwoDecimals(props.ratio)}</span></div>`
                        body += `<div style="padding: 0px 5px 5px 5px"><span style="font-family:Open Sans;font-size:16px;font-weight: 600;text-align:left">95% Credible Interval</span>: <span style="font-family:Open Sans;font-size:16px;font-weight: 400;text-align:left">[${formatTwoDecimals(props.low)}, ${formatTwoDecimals(props.high)}]</span></div>`
                    }
                    else {
                        body = `<div style="padding: 0px 5px 5px 5px"><span style="font-family:Open Sans;font-size:16px;font-weight: 600;text-align:left">Maximum</span>: <span style="font-family:Open Sans;font-size:16px;font-weight: 400;text-align:left">${formatTwoDecimals(props.nationalData.max)}</span></div>`
                        body += `<div style="padding: 0px 5px 5px 5px"><span style="font-family:Open Sans;font-size:16px;font-weight: 600;text-align:left">Quartile Three</span>: <span style="font-family:Open Sans;font-size:16px;font-weight: 400;text-align:left">${formatTwoDecimals(props.nationalData.quartileThree)}</span></div>`
                        body += `<div style="padding: 0px 5px 5px 5px"><span style="font-family:Open Sans;font-size:16px;font-weight: 600;text-align:left">Median</span>: <span style="font-family:Open Sans;font-size:16px;font-weight: 400;text-align:left">${formatTwoDecimals(props.nationalData.median)}</span></div>`
                        body += `<div style="padding: 0px 5px 5px 5px"><span style="font-family:Open Sans;font-size:16px;font-weight: 600;text-align:left">Quartile One</span>: <span style="font-family:Open Sans;font-size:16px;font-weight: 400;text-align:left">${formatTwoDecimals(props.nationalData.quartileOne)}</span></div>`
                        body += `<div style="padding: 0px 5px 5px 5px"><span style="font-family:Open Sans;font-size:16px;font-weight: 600;text-align:left">Low</span>: <span style="font-family:Open Sans;font-size:16px;font-weight: 400;text-align:left">${formatTwoDecimals(props.nationalData.low)}</span></div>`

                    }

                    return `<div class="tool-tip-container">${header}${body}</div>`;
                }
            },
            legend: {
                enabled: false,
                itemStyle: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"
                }
            },
            labels: {
                useHTML: true,
                allowOverlap: true,
                style: {
                    wordBreak: "break-all",
                    textOverflow: "allow"
                }
            },
            plotOptions: {
                boxplot: {
                    whiskerLength: 30,
                    whiskerWidth: 2,
                    pointWidth: 10
                },
                columnrange: {
                    grouping: false,
                    color: brandColorArray[0]
                },
                scatter: {
                    color: brandColorArray[0]
                }
            },
            series: [{
                data: [
                    [
                        props.nationalData.low,
                        props.nationalData.quartileOne,
                        props.nationalData.median,
                        props.nationalData.quartileThree,
                        props.nationalData.max
                    ],

                ],
                className: "custom-boxplot"
            },
            {
                name: "columnRange",
                id: "ratioRange",
                type: "columnrange",
                pointWidth: 2,
                data: [
                    [null, null],
                    [props.low, props.high]
                ],
                className: "custom-column-range-line"
            },
            {
                name: "columnRange",
                linkedTo: "ratioRange",
                type: "columnrange",
                pointWidth: 25,
                minPointLength: 4,
                data: [
                    [null, null],
                    [props.low, props.low]
                ],
                className: "custom-column-range-bottom"
            },
            {
                name: "columnRange",
                linkedTo: "ratioRange",
                type: "columnrange",
                pointWidth: 25,
                minPointLength: 4,
                data: [
                    [null, null],
                    [props.high, props.high]
                ],
                className: "custom-column-range-top"
            },
            {
                name: "scatter",
                type: "scatter",
                linkedTo: ":previous",
                data: [
                    [null],
                    [props.ratio]
                ],
                className: "custom-column-range-scatter"
            }]
        }

        setChartOptions(chartOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    return (
        <div className="offer-acceptance-practies-chart">
            <Row>
                <Col xs={12} lg={8}>
                    <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOptions} containerProps={{ className: "highcharts-light" }}/>
                </Col>
                <Col xs={12} lg={4}>
                    <div className="green-control-box">
                        <div className="description">
                            <div className="title">
                                {props.displayName}
                            </div>
                            <div className="figure">
                                {formatTwoDecimals(props.ratio)}
                            </div>
                            <div className="range">
                                [{formatTwoDecimals(props.low)}-{formatTwoDecimals(props.high)}]
                            </div>
                            <div className="range-description">
                                95% Credible Interval
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );

}

export default OfferAcceptancesChart;