import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Table from "react-bootstrap/Table";
import { formatOneDecimal, formatThousands } from "../../../utilities/formatter";
import moment from "moment";

function DescriptionOfDonorsRecoveredTable(props) {
    const [open, setOpen] = useState(false);

    return (
        <>
        <hr />
            <div className="collapse-container show-all-row">
                Characteristics of all deceased donors recovered {moment(new Date(props.data.begDate)).format("MM/DD/YYYY")} to {moment(new Date(props.data.endDate)).format("MM/DD/YYYY")}  &nbsp;&nbsp;
                <Button
                    onClick={() => setOpen(!open)}
                    aria-controls="collapse-text"
                    aria-expanded={open}
                    className="btn-dark-blue min-98"
                >
                    {!open && "Show All"}
                    {open && "Hide All"}
                </Button>
            </div>
            <Collapse in={open}>
                <div id="collapse-text" className="collapse-inner">
                    <Table striped className="overflow-auto description-of-donors-recovered-table">
                        <thead>
                            <tr>
                                <td></td>
                                <th className="text-center" colSpan="2">
                                    {props.opoCode}
                                </th>
                                <th className="text-center d-none d-md-table-cell" colSpan="2">
                                    United States
                                </th>
                            </tr>
                            <tr>
                                <td className="border-bottom"></td>
                                <th className="text-center border-bottom">
                                    {props.data.yearOne}
                                </th>
                                <th className="text-center border-bottom">
                                    {props.data.yearTwo}
                                </th>
                                <th className="text-center border-bottom d-none d-md-table-cell">
                                    {props.data.yearOne}
                                </th>
                                <th className="text-center border-bottom d-none d-md-table-cell">
                                    {props.data.yearTwo}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="font-weight-bold w-25">Number of Donors</td>
                                <td className="text-center">{formatThousands(formatOneDecimal(props.data.dcA_n_o1))}</td>
                                <td className="text-center">{formatThousands(formatOneDecimal(props.data.dcA_n_o2))}</td>
                                <td className="text-center d-none d-md-table-cell">{formatThousands(formatOneDecimal(props.data.dcA_n_u1))}</td>
                                <td className="text-center d-none d-md-table-cell">{formatThousands(formatOneDecimal(props.data.dcA_n_u2))}</td>
                            </tr>
                            <tr>
                                <td colSpan="5" className="font-weight-bold">Race (%)</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">White</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Rw_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Rw_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Rw_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Rw_u2)}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">African-American</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Rb_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Rb_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Rb_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Rb_u2)}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">Hispanic/Latino</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Rh_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Rh_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Rh_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Rh_u2)}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">Asian</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Ra_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Ra_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Ra_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Ra_u2)}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">Other</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Ro_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Ro_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Ro_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Ro_u2)}</td>
                            </tr>
                            <tr>
                                <td colSpan="5" className="font-weight-bold">Age (%)</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">0-1 Years</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_A2_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_A2_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_A2_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_A2_u2)}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">2-11 Years</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_A10_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_A10_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_A10_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_A10_u2)}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">12-17 Years</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_A17_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_A17_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_A17_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_A17_u2)}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">18-34 Years</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_A34_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_A34_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_A34_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_A34_u2)}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">35-49 Years</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_A49_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_A49_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_A49_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_A49_u2)}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">50-64 Years</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_A64_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_A64_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_A64_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_A64_u2)}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">65+ Years</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_A65p_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_A65p_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_A65p_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_A65p_u2)}</td>
                            </tr>
                            <tr>
                                <td colSpan="5" className="font-weight-bold">Gender (%)</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">Male</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Gm_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Gm_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Gm_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Gm_u2)}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">Female</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Gf_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Gf_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Gf_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Gf_u2)}</td>
                            </tr>
                            <tr>
                                <td colSpan="5" className="font-weight-bold">Blood Type (%)</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">O</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Bo_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Bo_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Bo_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Bo_u2)}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">A</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Ba_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Ba_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Ba_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Ba_u2)}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">B</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Bb_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Bb_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Bb_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Bb_u2)}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">AB</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Bab_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Bab_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Bab_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Bab_u2)}</td>
                            </tr>
                            <tr>
                                <td colSpan="5" className="font-weight-bold">Cause of Death (%)</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">Anoxia</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Anox_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Anox_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Anox_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Anox_u2)}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">Stroke</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Str_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Str_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Str_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Str_u2)}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">CNS Tumor</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_CNS_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_CNS_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_CNS_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_CNS_u2)}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">Head Trauma</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Traum_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_Traum_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Traum_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_Traum_u2)}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">Other</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_CDOth_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_CDOth_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_CDOth_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_CDOth_u2)}</td>
                            </tr>
                            <tr>
                                <td className="padding-left-30">Unknown</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_CDUnk_o1)}</td>
                                <td className="text-center">{formatOneDecimal(props.data.dcA_CDUnk_o2)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_CDUnk_u1)}</td>
                                <td className="text-center d-none d-md-table-cell">{formatOneDecimal(props.data.dcA_CDUnk_u2)}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </Collapse>
        </>
    );
}

export default DescriptionOfDonorsRecoveredTable;