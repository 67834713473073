export const demographicOptions = [
    {
        label: "Race",
        deceasedValues: [
            { label: "White", value: { center: "rcc_rw_c", region: "rcc_rw_r", us: "rcc_rw_n", category: "Race" } },
            { label: "African-American", value: { center: "rcc_rb_c", region: "rcc_rb_r", us: "rcc_rb_n", category: "Race" } },
            { label: "Hispanic/Latino", value: { center: "rcc_rh_c", region: "rcc_rh_r", us: "rcc_rh_n", category: "Race" } },
            { label: "Asian", value: { center: "rcc_ra_c", region: "rcc_ra_r", us: "rcc_ra_n", category: "Race" } },
            { label: "Other", value: { center: "rcc_ro_c", region: "rcc_ro_r", us: "rcc_ro_n", category: "Race" } },
            { label: "Unknown", value: { center: "rcc_ru_c", region: "rcc_ru_r", us: "rcc_ru_n", category: "Race" } }
        ],
        livingValues: [
            { label: "White", value: { center: "rcl_rw_c", region: "rcl_rw_r", us: "rcl_rw_n", category: "Race" } },
            { label: "African-American", value: { center: "rcl_rb_c", region: "rcl_rb_r", us: "rcl_rb_n", category: "Race" } },
            { label: "Hispanic/Latino", value: { center: "rcl_rh_c", region: "rcl_rh_r", us: "rcl_rh_n", category: "Race" } },
            { label: "Asian", value: { center: "rcl_ra_c", region: "rcl_ra_r", us: "rcl_ra_n", category: "Race" } },
            { label: "Other", value: { center: "rcl_ro_c", region: "rcl_ro_r", us: "rcl_ro_n", category: "Race" } },
            { label: "Unknown", value: { center: "rcl_ru_c", region: "rcl_ru_r", us: "rcl_ru_n", category: "Race" } }
        ]
    },
    {
        label: "Age",
        deceasedValues: [
            { label: "<2 years", value: { center: "rcc_a2_c", region: "rcc_a2_r", us: "rcc_a2_n", category: "Age" } },
            { label: "2-11 years", value: { center: "rcc_a10_c", region: "rcc_a10_r", us: "rcc_a10_n", category: "Age" } },
            { label: "12-17 years", value: { center: "rcc_a17_c", region: "rcc_a17_r", us: "rcc_a17_n", category: "Age" } },
            { label: "18-34 years", value: { center: "rcc_a34_c", region: "rcc_a34_r", us: "rcc_a34_n", category: "Age" } },
            { label: "35-49 years", value: { center: "rcc_a49_c", region: "rcc_a49_r", us: "rcc_a49_n", category: "Age" } },
            { label: "50-64 years", value: { center: "rcc_a64_c", region: "rcc_a64_r", us: "rcc_a64_n", category: "Age" } },
            { label: "65-69 years", value: { center: "rcc_a69_c", region: "rcc_a69_r", us: "rcc_a69_n", category: "Age" } },
            { label: "70+ years", value: { center: "rcc_a70p_c", region: "rcc_a70p_r", us: "rcc_a70p_n", category: "Age" } },
        ],
        livingValues: [
            { label: "<2 years", value: { center: "rcl_a2_c", region: "rcl_a2_r", us: "rcl_a2_n", category: "Age" } },
            { label: "2-11 years", value: { center: "rcl_a10_c", region: "rcl_a10_r", us: "rcl_a10_n", category: "Age" } },
            { label: "12-17 years", value: { center: "rcl_a17_c", region: "rcl_a17_r", us: "rcl_a17_n", category: "Age" } },
            { label: "18-34 years", value: { center: "rcl_a34_c", region: "rcl_a34_r", us: "rcl_a34_n", category: "Age" } },
            { label: "35-49 years", value: { center: "rcl_a49_c", region: "rcl_a49_r", us: "rcl_a49_n", category: "Age" } },
            { label: "50-64 years", value: { center: "rcl_a64_c", region: "rcl_a64_r", us: "rcl_a64_n", category: "Age" } },
            { label: "65-69 years", value: { center: "rcl_a69_c", region: "rcl_a69_r", us: "rcl_a69_n", category: "Age" } },
            { label: "70+ years", value: { center: "rcl_a70p_c", region: "rcl_a70p_r", us: "rcl_a70p_n", category: "Age" } },
        ]
    },
    {
        label: "Gender",
        deceasedValues: [
            { label: "Male", value: { center: "rcc_gm_c", region: "rcc_gm_r", us: "rcc_gm_n", category: "Gender" } },
            { label: "Female", value: { center: "rcc_gf_c", region: "rcc_gf_r", us: "rcc_gf_n", category: "Gender" } }
        ],
        livingValues: [
            { label: "Male", value: { center: "rcl_gm_c", region: "rcl_gm_r", us: "rcl_gm_n", category: "Gender" } },
            { label: "Female", value: { center: "rcl_gf_c", region: "rcl_gf_r", us: "rcl_gf_n", category: "Gender" } }
        ]
    }
];

export function getMedicalOptions(organ) {
    let options = [];

    options.push(
        {
            label: "Blood Type",
            deceasedValues: [
                { label: "O", value: { center: "rcc_bo_c", region: "rcc_bo_r", us: "rcc_bo_n", regionAll: "rcc_bo_AllR2", usNew: "rcc_bo_NewU2", usAll: "rcc_bo_AllU2", category: "Blood Type" } },
                { label: "A", value: { center: "rcc_ba_c", region: "rcc_ba_r", us: "rcc_ba_n", regionAll: "rcc_ba_AllR2", usNew: "rcc_ba_NewU2", usAll: "rcc_ba_AllU2", category: "Blood Type" } },
                { label: "B", value: { center: "rcc_bb_c", region: "rcc_bb_r", us: "rcc_bb_n", regionAll: "rcc_bb_AllR2", usNew: "rcc_bb_NewU2", usAll: "rcc_bb_AllU2", category: "Blood Type" } },
                { label: "AB", value: { center: "rcc_bab_c", region: "rcc_bab_r", us: "rcc_bab_n", regionAll: "rcc_bab_AllR2", usNew: "rcc_bab_NewU2", usAll: "rcc_bab_AllU2", category: "Blood Type" } }
               
            ],
            livingValues: [
                { label: "O", value: { center: "rcl_bo_c", region: "rcl_bo_r", us: "rcl_bo_n", regionAll: "rcl_bo_AllR2", usNew: "rcl_bo_NewU2", usAll: "rcl_bo_AllU2", category: "Blood Type" } },
                { label: "A", value: { center: "rcl_ba_c", region: "rcl_ba_r", us: "rcl_ba_n", regionAll: "rcl_ba_AllR2", usNew: "rcl_ba_NewU2", usAll: "rcl_ba_AllU2", category: "Blood Type" } },
                { label: "B", value: { center: "rcl_bb_c", region: "rcl_bb_r", us: "rcl_bb_n", regionAll: "rcl_bb_AllR2", usNew: "rcl_bb_NewU2", usAll: "rcl_bb_AllU2", category: "Blood Type" } },
                { label: "AB", value: { center: "rcl_bab_c", region: "rcl_bab_r", us: "rcl_bab_n", regionAll: "rcl_bab_AllR2", usNew: "rcl_bab_NewU2", usAll: "rcl_bab_AllU2", category: "Blood Type" } }
            ]
        });

    options.push(
        {
            label: "Previous Transplant",
            deceasedValues: [
                { label: "Yes", value: { center: "rcc_ptxy_c", region: "rcc_ptxy_r", us: "rcc_ptxy_n", regionAll: "rcc_ptxy_AllR2", usNew: "rcc_ptxy_NewU2", usAll: "rcc_ptxy_AllU2", category: "Blood Type" } },
                { label: "No", value: { center: "rcc_ptxn_c", region: "rcc_ptxn_r", us: "rcc_ptxn_n", regionAll: "rcc_ptxn_AllR2", usNew: "rcc_ptxn_NewU2", usAll: "rcc_ptxn_AllU2", category: "Blood Type" } }
            ],
            livingValues: [
                { label: "Yes", value: { center: "rcl_ptxy_c", region: "rcl_ptxy_r", us: "rcl_ptxy_n", regionAll: "rcl_ptxy_AllR2", usNew: "rcl_ptxy_NewU2", usAll: "rcl_ptxy_AllU2", category: "Blood Type" } },
                { label: "No", value: { center: "rcl_ptxn_c", region: "rcl_ptxn_r", us: "rcl_ptxn_n", regionAll: "rcl_ptxn_AllR2", usNew: "rcl_ptxn_NewU2", usAll: "rcl_ptxn_AllU2", category: "Blood Type" } }
            ]
        });

    options.push(
        {
            label: "Body Mass Index",
            deceasedValues: [
                { label: "0-20", value: { center: "rcc_bmi20_c", region: "rcc_bmi20_r", us: "rcc_bmi20_n", category: "Body Mass Index" } },
                { label: "21-25", value: { center: "rcc_bmi25_c", region: "rcc_bmi25_r", us: "rcc_bmi25_n", category: "Body Mass Index" } },
                { label: "26-30", value: { center: "rcc_bmi30_c", region: "rcc_bmi30_r", us: "rcc_bmi30_n", category: "Body Mass Index" } },
                { label: "31-35", value: { center: "rcc_bmi35_c", region: "rcc_bmi35_r", us: "rcc_bmi35_n", category: "Body Mass Index" } },
                { label: "36-40", value: { center: "rcc_bmi40_c", region: "rcc_bmi40_r", us: "rcc_bmi40_n", category: "Body Mass Index" } },
                { label: "41+", value: { center: "rcc_bmi41p_c", region: "rcc_bmi41p_r", us: "rcc_bmi41p_n", category: "Body Mass Index" } },
                { label: "Unknown", value: { center: "rcc_bminr_c", region: "rcc_bminr_r", us: "rcc_bminr_n", category: "Body Mass Index" } }
            ],
            livingValues: [
                { label: "0-20", value: { center: "rcl_bmi20_c", region: "rcl_bmi20_r", us: "rcl_bmi20_n", category: "Body Mass Index" } },
                { label: "21-25", value: { center: "rcl_bmi25_c", region: "rcl_bmi25_r", us: "rcl_bmi25_n", category: "Body Mass Index" } },
                { label: "26-30", value: { center: "rcl_bmi30_c", region: "rcl_bmi30_r", us: "rcl_bmi30_n", category: "Body Mass Index" } },
                { label: "31-35", value: { center: "rcl_bmi35_c", region: "rcl_bmi35_r", us: "rcl_bmi35_n", category: "Body Mass Index" } },
                { label: "36-40", value: { center: "rcl_bmi40_c", region: "rcl_bmi40_r", us: "rcl_bmi40_n", category: "Body Mass Index" } },
                { label: "41+", value: { center: "rcl_bmi41p_c", region: "rcl_bmi41p_r", us: "rcl_bmi41p_n", category: "Body Mass Index" } },
                { label: "Unknown", value: { center: "rcl_bminr_c", region: "rcl_bminr_r", us: "rcl_bminr_n", category: "Body Mass Index" } }
            ]
        });

    if (organ === "hr") {
        options.push(
            {
                label: "Primary Disease",
                deceasedValues: [
                    { label: "Cardiomyopathy", value: { center: "rcc_car_c", region: "rcc_car_r", us: "rcc_car_n", category: "Primary Disease" } },
                    { label: "Congenital Heart Disease", value: { center: "rcc_chd_c", region: "rcc_chd_r", us: "rcc_chd_n", category: "Primary Disease" } },
                    { label: "Coronary Artery Disease", value: { center: "rcc_cad_c", region: "rcc_cad_r", us: "rcc_cad_n", category: "Primary Disease" } },
                    { label: "Retransplant/Graft Failure", value: { center: "rcc_ret_c", region: "rcc_ret_r", us: "rcc_ret_n", category: "Primary Disease" } },
                    { label: "Valvular Heart Disease", value: { center: "rcc_vhd_c", region: "rcc_vhd_r", us: "rcc_vhd_n", category: "Primary Disease" } },
                    { label: "Other", value: { center: "rcc_otk_c", region: "rcc_otk_r", us: "rcc_otk_n", category: "Primary Disease" } },
                    { label: "Missing", value: { center: "rcc_mis_c", region: "rcc_mis_r", us: "rcc_mis_n", category: "Primary Disease" } }
                ]
            });

        options.push(
            {
                label: "Medical Urgency Status at Transplant",
                deceasedValues: [
                    { label: "Status 1A", value: { center: "rcc_tst1a_c", region: "rcc_tst1a_r", us: "rcc_tst1a_n", category: "Medical Urgency Status at Transplant" } },
                    { label: "Status 1B", value: { center: "rcc_tst1b_c", region: "rcc_tst1b_r", us: "rcc_tst1b_n", category: "Medical Urgency Status at Transplant" } },
                    { label: "Status 2", value: { center: "rcc_tst2_c", region: "rcc_tst2_r", us: "rcc_tst2_n", category: "Medical Urgency Status at Transplant" } },
                    { label: "Adult Status 1", value: { center: "rcc_tast1_c", region: "rcc_tast1_r", us: "rcc_tast1_n", category: "Medical Urgency Status at Transplant" } },
                    { label: "Adult Status 2", value: { center: "rcc_tast2_c", region: "rcc_tast2_r", us: "rcc_tast2_n", category: "Medical Urgency Status at Transplant" } },
                    { label: "Adult Status 3", value: { center: "rcc_tast3_c", region: "rcc_tast3_r", us: "rcc_tast3_n", category: "Medical Urgency Status at Transplant" } },
                    { label: "Adult Status 4", value: { center: "rcc_tast4_c", region: "rcc_tast4_r", us: "rcc_tast4_n", category: "Medical Urgency Status at Transplant" } },
                    { label: "Adult Status 5", value: { center: "rcc_tast5_c", region: "rcc_tast5_r", us: "rcc_tast5_n", category: "Medical Urgency Status at Transplant" } },
                    { label: "Adult Status 6", value: { center: "rcc_tast6_c", region: "rcc_tast6_r", us: "rcc_tast6_n", category: "Medical Urgency Status at Transplant" } }
                ]
            });

    }
    else if (organ === "in") {
        options.push(
            {
                label: "Primary Disease",
                deceasedValues: [
                    { label: "Functional Bowel Problem", value: { center: "rcc_fbp_c", region: "rcc_fbp_r", us: "rcc_fbp_n", category: "Primary Disease" } },
                    { label: "Short Gut Syndrome", value: { center: "rcc_sgs_c", region: "rcc_sgs_r", us: "rcc_sgs_n", category: "Primary Disease" } },
                    { label: "Other", value: { center: "rcc_otk_c", region: "rcc_otk_r", us: "rcc_otk_n", category: "Primary Disease" } },
                    { label: "Missing", value: { center: "rcc_mis_c", region: "rcc_mis_r", us: "rcc_mis_n", category: "Primary Disease" } }
                ]
            });
    }
    else if (organ === "ki") {
        options.push(
            {
                label: "Primary Disease",
                deceasedValues: [
                    { label: "Glomerular Diseases", value: { center: "rcc_glo_c", region: "rcc_glo_r", us: "rcc_glo_n", category: "Primary Disease" } },
                    { label: "Tubular and Interstitial Diseases", value: { center: "rcc_tub_c", region: "rcc_tub_r", us: "rcc_tub_n", category: "Primary Disease" } },
                    { label: "Polycystic Kidneys", value: { center: "rcc_pol_c", region: "rcc_pol_r", us: "rcc_pol_n", category: "Primary Disease" } },
                    { label: "Congenital, Familial, Metabolic", value: { center: "rcc_con_c", region: "rcc_con_r", us: "rcc_con_n", category: "Primary Disease" } },
                    { label: "Diabetes", value: { center: "rcc_dia_c", region: "rcc_dia_r", us: "rcc_dia_n", category: "Primary Disease" } },
                    { label: "Renovascular & Vascular Diseases", value: { center: "rcc_ren_c", region: "rcc_ren_r", us: "rcc_ren_n", category: "Primary Disease" } },
                    { label: "Neoplasms", value: { center: "rcc_neo_c", region: "rcc_neo_r", us: "rcc_neo_n", category: "Primary Disease" } },
                    { label: "Hypertensive Nephrosclerosis", value: { center: "rcc_hyp_c", region: "rcc_hyp_r", us: "rcc_hyp_n", category: "Primary Disease" } },
                    { label: "Other", value: { center: "rcc_otk_c", region: "rcc_otk_r", us: "rcc_otk_n", category: "Primary Disease" } },
                    { label: "Missing", value: { center: "rcc_mis_c", region: "rcc_mis_r", us: "rcc_mis_n", category: "Primary Disease" } }
                ],
                livingValues: [
                    { label: "Glomerular Diseases", value: { center: "rcl_glo_c", region: "rcl_glo_r", us: "rcl_glo_n", category: "Primary Disease" } },
                    { label: "Tubular and Interstitial Diseases", value: { center: "rcl_tub_c", region: "rcl_tub_r", us: "rcl_tub_n", category: "Primary Disease" } },
                    { label: "Polycystic Kidneys", value: { center: "rcl_pol_c", region: "rcl_pol_r", us: "rcl_pol_n", category: "Primary Disease" } },
                    { label: "Congenital, Familial, Metabolic", value: { center: "rcl_con_c", region: "rcl_con_r", us: "rcl_con_n", category: "Primary Disease" } },
                    { label: "Diabetes", value: { center: "rcl_dia_c", region: "rcl_dia_r", us: "rcl_dia_n", category: "Primary Disease" } },
                    { label: "Renovascular & Vascular Diseases", value: { center: "rcl_ren_c", region: "rcl_ren_r", us: "rcl_ren_n", category: "Primary Disease" } },
                    { label: "Neoplasms", value: { center: "rcl_neo_c", region: "rcl_neo_r", us: "rcl_neo_n", category: "Primary Disease" } },
                    { label: "Hypertensive Nephrosclerosis", value: { center: "rcl_hyp_c", region: "rcl_hyp_r", us: "rcl_hyp_n", category: "Primary Disease" } },
                    { label: "Other", value: { center: "rcl_otk_c", region: "rcl_otk_r", us: "rcl_otk_n", category: "Primary Disease" } },
                    { label: "Missing", value: { center: "rcl_mis_c", region: "rcl_mis_r", us: "rcl_mis_n", category: "Primary Disease" } }
                ]
            });

        options.push(
            {
                label: "Peak PRA/CPRA Prior to Transplant",
                deceasedValues: [
                    { label: "0-9%", value: { center: "rcc_pra9_c", region: "rcc_pra9_r", us: "rcc_pra9_n", category: "Peak PRA/CPRA Prior to Transplant" } },
                    { label: "10-79%", value: { center: "rcc_pra79_c", region: "rcc_pra79_r", us: "rcc_pra79_n", category: "Peak PRA/CPRA Prior to Transplant" } },
                    { label: "80+ %", value: { center: "rcc_pra80_c", region: "rcc_pra80_r", us: "rcc_pra80_n", category: "Peak PRA/CPRA Prior to Transplant" } }
                ],
                livingValues: [
                    { label: "0-9%", value: { center: "rcl_pra9_c", region: "rcl_pra9_r", us: "rcl_pra9_n", category: "Peak PRA/CPRA Prior to Transplant" } },
                    { label: "10-79%", value: { center: "rcl_pra79_c", region: "rcl_pra79_r", us: "rcl_pra79_n", category: "Peak PRA/CPRA Prior to Transplant" } },
                    { label: "80+ %", value: { center: "rcl_pra80_c", region: "rcl_pra80_r", us: "rcl_pra80_n", category: "Peak PRA/CPRA Prior to Transplant" } }
                ]
            });
    }
    else if (organ === "li") {
        options.push(
            {
                label: "Primary Disease",
                deceasedValues: [
                    { label: "Acute Hepatic Necrosis", value: { center: "rcc_nec_c", region: "rcc_nec_r", us: "rcc_nec_n", category: "Primary Disease" } },
                    { label: "Non-Cholestatic Cirrhosis", value: { center: "rcc_ncc_c", region: "rcc_ncc_r", us: "rcc_ncc_n", category: "Primary Disease" } },
                    { label: "Cholestatic Liver Disease/Cirrhosis", value: { center: "rcc_cld_c", region: "rcc_cld_r", us: "rcc_cld_n", category: "Primary Disease" } },
                    { label: "Biliary Atresia", value: { center: "rcc_bat_c", region: "rcc_bat_r", us: "rcc_bat_n", category: "Primary Disease" } },
                    { label: "Metabolic Diseases", value: { center: "rcc_met_c", region: "rcc_met_r", us: "rcc_met_n", category: "Primary Disease" } },
                    { label: "Malignant Neoplasms", value: { center: "rcc_mneo_c", region: "rcc_mneo_r", us: "rcc_mneo_n", category: "Primary Disease" } },
                    { label: "Other", value: { center: "rcc_otk_c", region: "rcc_otk_r", us: "rcc_otk_n", category: "Primary Disease" } },
                    { label: "Missing", value: { center: "rcc_mis_c", region: "rcc_mis_r", us: "rcc_mis_n", category: "Primary Disease" } }
                ],
                livingValues: [
                    { label: "Acute Hepatic Necrosis", value: { center: "rcl_nec_c", region: "rcl_nec_r", us: "rcl_nec_n", category: "Primary Disease" } },
                    { label: "Non-Cholestatic Cirrhosis", value: { center: "rcl_ncc_c", region: "rcl_ncc_r", us: "rcl_ncc_n", category: "Primary Disease" } },
                    { label: "Cholestatic Liver Disease/Cirrhosis", value: { center: "rcl_cld_c", region: "rcl_cld_r", us: "rcl_cld_n", category: "Primary Disease" } },
                    { label: "Biliary Atresia", value: { center: "rcl_bat_c", region: "rcl_bat_r", us: "rcl_bat_n", category: "Primary Disease" } },
                    { label: "Metabolic Diseases", value: { center: "rcl_met_c", region: "rcl_met_r", us: "rcl_met_n", category: "Primary Disease" } },
                    { label: "Malignant Neoplasms", value: { center: "rcl_mneo_c", region: "rcl_mneo_r", us: "rcl_mneo_n", category: "Primary Disease" } },
                    { label: "Other", value: { center: "rcl_otk_c", region: "rcl_otk_r", us: "rcl_otk_n", category: "Primary Disease" } },
                    { label: "Missing", value: { center: "rcl_mis_c", region: "rcl_mis_r", us: "rcl_mis_n", category: "Primary Disease" } }
                ]
            });

        options.push(
            {
                label: "Medical Urgency Status/MELD/PELD at Transplant",
                deceasedValues: [
                    { label: "Status 1A", value: { center: "rcc_tst1a_c", region: "rcc_tst1a_r", us: "rcc_tst1a_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "Status 1B", value: { center: "rcc_tst1b_c", region: "rcc_tst1b_r", us: "rcc_tst1b_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "MELD 6-10", value: { center: "rcc_me6_c", region: "rcc_me6_r", us: "rcc_me6_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "MELD 11-14", value: { center: "rcc_me11_c", region: "rcc_me11_r", us: "rcc_me11_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "MELD 15-20", value: { center: "rcc_me15_c", region: "rcc_me15_r", us: "rcc_me15_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "MELD 21-30", value: { center: "rcc_me21_c", region: "rcc_me21_r", us: "rcc_me21_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "MELD 31-40", value: { center: "rcc_me31_c", region: "rcc_me31_r", us: "rcc_me31_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "PELD less than or equal to 10", value: { center: "rcc_pe6_c", region: "rcc_pe6_r", us: "rcc_pe6_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "PELD 11-14", value: { center: "rcc_pe11_c", region: "rcc_pe11_r", us: "rcc_pe11_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "PELD 15-20", value: { center: "rcc_pe15_c", region: "rcc_pe15_r", us: "rcc_pe15_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "PELD 21-30", value: { center: "rcc_pe21_c", region: "rcc_pe21_r", us: "rcc_pe21_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "PELD 31 or greater", value: { center: "rcc_pe31_c", region: "rcc_pe31_r", us: "rcc_pe31_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "Temporarily Inactive", value: { center: "rcc_tina_c", region: "rcc_tina_r", us: "rcc_tina_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } }
                ],
                livingValues: [
                    { label: "Status 1A", value: { center: "rcl_tst1a_c", region: "rcl_tst1a_r", us: "rcl_tst1a_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "Status 1B", value: { center: "rcl_tst1b_c", region: "rcl_tst1b_r", us: "rcl_tst1b_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "MELD 6-10", value: { center: "rcl_me6_c", region: "rcl_me6_r", us: "rcl_me6_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "MELD 11-14", value: { center: "rcl_me11_c", region: "rcl_me11_r", us: "rcl_me11_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "MELD 15-20", value: { center: "rcl_me15_c", region: "rcl_me15_r", us: "rcl_me15_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "MELD 21-30", value: { center: "rcl_me21_c", region: "rcl_me21_r", us: "rcl_me21_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "MELD 31-40", value: { center: "rcl_me31_c", region: "rcl_me31_r", us: "rcl_me31_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "PELD less than or equal to 10", value: { center: "rcl_pe6_c", region: "rcl_pe6_r", us: "rcl_pe6_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "PELD 11-14", value: { center: "rcl_pe11_c", region: "rcl_pe11_r", us: "rcl_pe11_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "PELD 15-20", value: { center: "rcl_pe15_c", region: "rcl_pe15_r", us: "rcl_pe15_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "PELD 21-30", value: { center: "rcl_pe21_c", region: "rcl_pe21_r", us: "rcl_pe21_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "PELD 31 or greater", value: { center: "rcl_pe31_c", region: "rcl_pe31_r", us: "rcl_pe31_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } },
                    { label: "Temporarily Inactive", value: { center: "rcl_tina_c", region: "rcl_tina_r", us: "rcl_tina_n", category: "Medical Urgency Status/MELD/PELD at Transplant" } }
                ]
            });

        options.push(
            {
                label: "Recipient Medical Condition at Transplant",
                deceasedValues: [
                    { label: "Not Hospitalized", value: { center: "rcc_nothosp_c", region: "rcc_nothosp_r", us: "rcc_nothosp_n", category: "Recipient Medical Condition at Transplant" } },
                    { label: "Hospitalized", value: { center: "rcc_hosp_c", region: "rcc_hosp_r", us: "rcc_hosp_n", category: "Recipient Medical Condition at Transplant" } },
                    { label: "ICU", value: { center: "rcc_icu_c", region: "rcc_icu_r", us: "rcc_icu_n", category: "Recipient Medical Condition at Transplant" } }
                ],
                livingValues: [
                    { label: "Not Hospitalized", value: { center: "rcl_nothosp_c", region: "rcl_nothosp_r", us: "rcl_nothosp_n", category: "Recipient Medical Condition at Transplant" } },
                    { label: "Hospitalized", value: { center: "rcl_hosp_c", region: "rcl_hosp_r", us: "rcl_hosp_n", category: "Recipient Medical Condition at Transplant" } },
                    { label: "ICU", value: { center: "rcl_icu_c", region: "rcl_icu_r", us: "rcl_icu_n", category: "Recipient Medical Condition at Transplant" } }
                ]
            });
    }
    else if (organ === "lu") {
        options.push(
            {
                label: "Primary Disease",
                deceasedValues: [
                    { label: "Idiopathic Pulmonary Arterial Hypertension", value: { center: "rcc_pph_c", region: "rcc_pph_r", us: "rcc_pph_n", category: "Primary Disease" } },
                    { label: "Cystic Fibrosis", value: { center: "rcc_cyf_c", region: "rcc_cyf_r", us: "rcc_cyf_n", category: "Primary Disease" } },
                    { label: "Idiopathic Pulmonary Fibrosis", value: { center: "rcc_idp_c", region: "rcc_idp_r", us: "rcc_idp_n", category: "Primary Disease" } },
                    { label: "Emphysema/COPD", value: { center: "rcc_eco_c", region: "rcc_eco_r", us: "rcc_eco_n", category: "Primary Disease" } },
                    { label: "Other", value: { center: "rcc_otk_c", region: "rcc_otk_r", us: "rcc_otk_n", category: "Primary Disease" } },
                    { label: "Missing", value: { center: "rcc_mis_c", region: "rcc_mis_r", us: "rcc_mis_n", category: "Primary Disease" } }
                ]
            });

        options.push(
            {
                label: "Recipient Medical Condition at Transplant",
                deceasedValues: [
                    { label: "Not Hospitalized", value: { center: "rcc_nothosp_c", region: "rcc_nothosp_r", us: "rcc_nothosp_n", category: "Recipient Medical Condition at Transplant" } },
                    { label: "Hospitalized", value: { center: "rcc_hosp_c", region: "rcc_hosp_r", us: "rcc_hosp_n", category: "Recipient Medical Condition at Transplant" } },
                    { label: "ICU", value: { center: "rcc_icu_c", region: "rcc_icu_r", us: "rcc_icu_n", category: "Recipient Medical Condition at Transplant" } }
                ]
            });
    }
    else if (organ === "hl") {
        options.push(
            {
                label: "Primary Disease",
                deceasedValues: [
                    { label: "Congenital Disease", value: { center: "rcc_cod_c", region: "rcc_cod_r", us: "rcc_cod_n", category: "Primary Disease" } },
                    { label: "Retransplant/Graft Failure", value: { center: "rcc_ret_c", region: "rcc_ret_r", us: "rcc_ret_n", category: "Primary Disease" } },
                    { label: "Idiopathic Pulmonary Arterial Hypertension", value: { center: "rcc_pph_c", region: "rcc_pph_r", us: "rcc_pph_n", category: "Primary Disease" } },
                    { label: "Cystic Fibrosis", value: { center: "rcc_cyf_c", region: "rcc_cyf_r", us: "rcc_cyf_n", category: "Primary Disease" } },
                    { label: "Idiopathic Pulmonary Fibrosis", value: { center: "rcc_idp_c", region: "rcc_idp_r", us: "rcc_idp_n", category: "Primary Disease" } },
                    { label: "Emphysema/COPD", value: { center: "rcc_eco_c", region: "rcc_eco_r", us: "rcc_eco_n", category: "Primary Disease" } },
                    { label: "Other", value: { center: "rcc_otk_c", region: "rcc_otk_r", us: "rcc_otk_n", category: "Primary Disease" } },
                    { label: "Missing", value: { center: "rcc_mis_c", region: "rcc_mis_r", us: "rcc_mis_n", category: "Primary Disease" } }
                ]
            });

        options.push(
            {
                label: "Recipient Medical Condition at Transplant",
                deceasedValues: [
                    { label: "Not Hospitalized", value: { center: "rcc_nothosp_c", region: "rcc_nothosp_r", us: "rcc_nothosp_n", category: "Recipient Medical Condition at Transplant" } },
                    { label: "Hospitalized", value: { center: "rcc_hosp_c", region: "rcc_hosp_r", us: "rcc_hosp_n", category: "Recipient Medical Condition at Transplant" } },
                    { label: "ICU", value: { center: "rcc_icu_c", region: "rcc_icu_r", us: "rcc_icu_n", category: "Recipient Medical Condition at Transplant" } }
                ]
            });
    }
    else if (organ === "kp") {
        options.push(
            {
                label: "Peak PRA/CPRA Prior to Transplant",
                deceasedValues: [
                    { label: "0-9%", value: { center: "rcc_pra9_c", region: "rcc_pra9_r", us: "rcc_pra9_n", category: "Peak PRA/CPRA Prior to Transplant" } },
                    { label: "10-79%", value: { center: "rcc_pra79_c", region: "rcc_pra79_r", us: "rcc_pra79_n", category: "Peak PRA/CPRA Prior to Transplant" } },
                    { label: "80+ %", value: { center: "rcc_pra80_c", region: "rcc_pra80_r", us: "rcc_pra80_n", category: "Peak PRA/CPRA Prior to Transplant" } }
                ]
            });
    }


    return options;
}

