import React from "react";

function HoverOPOPopup(props) {
    let hoveredFeature = props.hoveredFeature;

    let x = props.x;
    let y = props.y;

    if (hoveredFeature) {
        let properties = hoveredFeature.properties;

        return (
            <div className="opo-overview-hover-popup" style={{ left: x, top: y }}>
                <div className="opo-name">{properties.entire_name} ({properties.cms_opo.toUpperCase()})</div>
            </div>
        )

    }
    else {
        return null;
    }
}

export default HoverOPOPopup;