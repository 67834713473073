import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Table from "react-bootstrap/Table";
import { formatThousands, formatOneDecimal } from "../../../../utilities/formatter";
import moment from "moment";
function DemographicsTable(props) {
    const [open, setOpen] = useState(false);
    let beginDate = moment(new Date(props.data.begDate));
    let endDate = moment(new Date(props.data.endDate));
    return (
        <>
            <div className="collapse-container show-all-row mt-4">
                View all demographic characteristics of waiting list candidates &nbsp;&nbsp;
                <Button
                    onClick={() => setOpen(!open)}
                    aria-controls="collapse-text"
                    aria-expanded={open}
                    className="btn-dark-blue min-98"
                >
                    {!open && "Show All"}
                    {open && "Hide All"}
                </Button>
            </div>
            <Collapse in={open}>
                <div id="collapse-text" className="collapse-inner">
                    <div className="table-responsive">
                        <div className="sub-title">
                            Swipe to view more data {">>>"}
                        </div>
                        <Table striped className="overflow-auto description-of-donors-recovered-table">
                            <thead>
                                <tr>
                                    <th>Demographic Characteristic</th>
                                    <th className="text-center" colSpan="3">
                                        New Waiting List Registrations <br />
                                        {beginDate.format("MM/DD/YYYY")} to {endDate.format("MM/DD/YYYY")} (%)
                                </th>
                                    <th className="text-center" colSpan="3">
                                        All Waiting List Registrations <br />
                                    on {endDate.format("MM/DD/YYYY")} (%)
                                </th>
                                </tr>
                                <tr>
                                    <td className="border-bottom"></td>
                                    <th className="text-center border-bottom">
                                        {props.centerCode}<br />
                                    (N={props.data ? formatThousands(props.data["wlc_n_newc2"]) : "NA"})
                                    </th>
                                    <th className="text-center border-bottom">
                                        OPTN Region<br />
                                    (N={props.data ? formatThousands(props.data["wlc_n_newr2"]) : "NA"})
                                </th>
                                    <th className="text-center border-bottom">
                                        U.S.<br />
                                    (N={props.data ? formatThousands(props.data["wlc_n_newu2"]) : "NA"})
                                </th>
                                    <th className="text-center border-bottom">
                                        {props.centerCode}<br />
                                    (N={props.data ? formatThousands(props.data["wlc_n_allc2"]) : "NA"})
                                    </th>
                                    <th className="text-center border-bottom">
                                        OPTN Region<br />
                                    (N={props.data ? formatThousands(props.data["wlc_n_allr2"]) : "NA"})
                                </th>
                                    <th className="text-center border-bottom">
                                        U.S.<br />
                                    (N={props.data ? formatThousands(props.data["wlc_n_allu2"]) : "NA"})
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.options.map((item, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <tr key={i}>
                                                    <td colSpan="7" className="font-weight-bold">{item.label} (%)</td>
                                                </tr>

                                                {item.value.map((subItem, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className="padding-left-30">{subItem.label}</td>
                                                            <td className="text-center">{formatOneDecimal(props.data[subItem.value.centerNew])}</td>
                                                            <td className="text-center">{formatOneDecimal(props.data[subItem.value.regionNew])}</td>
                                                            <td className="text-center">{formatOneDecimal(props.data[subItem.value.usNew])}</td>
                                                            <td className="text-center">{formatOneDecimal(props.data[subItem.value.centerAll])}</td>
                                                            <td className="text-center">{formatOneDecimal(props.data[subItem.value.regionAll])}</td>
                                                            <td className="text-center">{formatOneDecimal(props.data[subItem.value.usAll])}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </React.Fragment>
                                        )
                                    })}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Collapse>
        </>
    );
}

export default DemographicsTable;