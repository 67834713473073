import React from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PopulationDensityMap from "./PopulationDensityMap";
import DeathRateMap from "./DeathRateMap";

function DensityMaps(props) {
    return (
        <Row>
            <Col>
                <Row>
                    <Col xs={12}>
                        <PopulationDensityMap
                            opoData={props.opoData}
                            activeKey={props.activeKey}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <DeathRateMap
                            opoData={props.opoData}
                            activeKey={props.activeKey}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>

    );
}

export default DensityMaps;