export const demographicOptions = [
    {
        label: "All",
        value: [
            { label: "", value: { centerNew: "wlc_all_newc2", centerAll: "wlc_all_allc2", regionNew: "wlc_all_newr2", regionAll: "wlc_all_allr2", usNew: "wlc_all_newu2", usAll: "wlc_all_allu2", category: "all" } }
        ]
    },
    {
        label: "Race",
        value: [
            { label: "White", value: { centerNew: "wlc_rw_newc2", centerAll: "wlc_rw_allc2", regionNew: "wlc_rw_newr2", regionAll: "wlc_rw_allr2", usNew: "wlc_rw_newu2", usAll: "wlc_rw_allu2", category: "race" } },
            { label: "African-American", value: { centerNew: "wlc_rb_newc2", centerAll: "wlc_rb_allc2", regionNew: "wlc_rb_newr2", regionAll: "wlc_rb_allr2", usNew: "wlc_rb_newu2", usAll: "wlc_rb_allu2", category: "race" } },
            { label: "Hispanic/Latino", value: { centerNew: "wlc_rh_newc2", centerAll: "wlc_rh_allc2", regionNew: "wlc_rh_newr2", regionAll: "wlc_rh_allr2", usNew: "wlc_rh_newu2", usAll: "wlc_rh_allu2", category: "race" } },
            { label: "Asian", value: { centerNew: "wlc_ra_newc2", centerAll: "wlc_ra_allc2", regionNew: "wlc_ra_newr2", regionAll: "wlc_ra_allr2", usNew: "wlc_ra_newu2", usAll: "wlc_ra_allu2", category: "race" } },
            { label: "Other", value: { centerNew: "wlc_ro_newc2", centerAll: "wlc_ro_allc2", regionNew: "wlc_ro_newr2", regionAll: "wlc_ro_allr2", usNew: "wlc_ro_newu2", usAll: "wlc_ro_allu2", category: "race" } },
            { label: "Unknown", value: { centerNew: "wlc_ru_newc2", centerAll: "wlc_ru_allc2", regionNew: "wlc_ru_newr2", regionAll: "wlc_ru_allr2", usNew: "wlc_ru_newu2", usAll: "wlc_ru_allu2", category: "race" } }
        ]
    },
    {
        label: "Age",
        value: [
            { label: "<2 years", value: { centerNew: "wlc_a2_newc2", centerAll: "wlc_a2_allc2", regionNew: "wlc_a2_newr2", regionAll: "wlc_a2_allr2", usNew: "wlc_a2_newu2", usAll: "wlc_a2_allu2", category: "age" } },
            { label: "2-11 years", value: { centerNew: "wlc_a10_newc2", centerAll: "wlc_a10_allc2", regionNew: "wlc_a10_newr2", regionAll: "wlc_a10_allr2", usNew: "wlc_a10_newu2", usAll: "wlc_a10_allu2", category: "age" } },
            { label: "12-17 years", value: { centerNew: "wlc_a17_newc2", centerAll: "wlc_a17_allc2", regionNew: "wlc_a17_newr2", regionAll: "wlc_a17_allr2", usNew: "wlc_a17_newu2", usAll: "wlc_a17_allu2", category: "age" } },
            { label: "18-34 years", value: { centerNew: "wlc_a34_newc2", centerAll: "wlc_a34_allc2", regionNew: "wlc_a34_newr2", regionAll: "wlc_a34_allr2", usNew: "wlc_a34_newu2", usAll: "wlc_a34_allu2", category: "age" } },
            { label: "35-49 years", value: { centerNew: "wlc_a49_newc2", centerAll: "wlc_a49_allc2", regionNew: "wlc_a49_newr2", regionAll: "wlc_a49_allr2", usNew: "wlc_a49_newu2", usAll: "wlc_a49_allu2", category: "age" } },
            { label: "50-64 years", value: { centerNew: "wlc_a64_newc2", centerAll: "wlc_a64_allc2", regionNew: "wlc_a64_newr2", regionAll: "wlc_a64_allr2", usNew: "wlc_a64_newu2", usAll: "wlc_a64_allu2", category: "age" } },
            { label: "65-69 years", value: { centerNew: "wlc_a69_newc2", centerAll: "wlc_a69_allc2", regionNew: "wlc_a69_newr2", regionAll: "wlc_a69_allr2", usNew: "wlc_a69_newu2", usAll: "wlc_a69_allu2", category: "age" } },
            { label: "70+ years", value: { centerNew: "wlc_a70p_newc2", centerAll: "wlc_a70p_allc2", regionNew: "wlc_a70p_newr2", regionAll: "wlc_a70p_allr2", usNew: "wlc_a70p_newu2", usAll: "wlc_a70p_allu2", category: "age" } },
        ]
    },
    {
        label: "Gender",
        value: [
            { label: "Male", value: { centerNew: "wlc_gm_newc2", centerAll: "wlc_gm_allc2", regionNew: "wlc_gm_newr2", regionAll: "wlc_gm_allr2", usNew: "wlc_gm_newu2", usAll: "wlc_gm_allu2", category: "Gender" } },
            { label: "Female", value: { centerNew: "wlc_gf_newc2", centerAll: "wlc_gf_allc2", regionNew: "wlc_gf_newr2", regionAll: "wlc_gf_allr2", usNew: "wlc_gf_newu2", usAll: "wlc_gf_allu2", category: "Gender" } }
        ]
    }
];

export function getMedicalOptions(organ) {
    let options = [{
        label: "All",
        value: [
            { label: "", value: { centerNew: "wlc_all_newc2", centerAll: "wlc_all_allc2", regionNew: "wlc_all_newr2", regionAll: "wlc_all_allr2", usNew: "wlc_all_newu2", usAll: "wlc_all_allu2", category: "all" } }
        ]
    }];
    options.push(
        {
            label: "Blood Type",
            value: [
                { label: "O", value: { centerNew: "wlc_bo_newc2", centerAll: "wlc_bo_allc2", regionNew: "wlc_bo_newr2", regionAll: "wlc_bo_allr2", usNew: "wlc_bo_newu2", usAll: "wlc_bo_allu2", category: "blood Type" } },
                { label: "A", value: { centerNew: "wlc_ba_newc2", centerAll: "wlc_ba_allc2", regionNew: "wlc_ba_newr2", regionAll: "wlc_ba_allr2", usNew: "wlc_ba_newu2", usAll: "wlc_ba_allu2", category: "blood Type" } },
                { label: "B", value: { centerNew: "wlc_bb_newc2", centerAll: "wlc_bb_allc2", regionNew: "wlc_bb_newr2", regionAll: "wlc_bb_allr2", usNew: "wlc_bb_newu2", usAll: "wlc_bb_allu2", category: "blood Type" } },
                { label: "AB", value: { centerNew: "wlc_bab_newc2", centerAll: "wlc_bab_allc2", regionNew: "wlc_bab_newr2", regionAll: "wlc_bab_allr2", usNew: "wlc_bab_newu2", usAll: "wlc_bab_allu2", category: "blood Type" } },
                { label: "Unknown", value: { centerNew: "wlc_bu_newc2", centerAll: "wlc_bu_allc2", regionNew: "wlc_bu_newr2", regionAll: "wlc_bu_allr2", usNew: "wlc_bu_newu2", usAll: "wlc_bu_allu2", category: "blood Type" } }
            ]
        });

    options.push(
        {
            label: "Previous Transplant",
            value: [
                { label: "Yes", value: { centerNew: "wlc_ptxy_newc2", centerAll: "wlc_ptxy_allc2", regionNew: "wlc_ptxy_newr2", regionAll: "wlc_ptxy_allr2", usNew: "wlc_ptxy_newu2", usAll: "wlc_ptxy_allu2", category: "blood Type" } },
                { label: "No", value: { centerNew: "wlc_ptxn_newc2", centerAll: "wlc_ptxn_allc2", regionNew: "wlc_ptxn_newr2", regionAll: "wlc_ptxn_allr2", usNew: "wlc_ptxn_newu2", usAll: "wlc_ptxn_allu2", category: "blood Type" } },
                { label: "Unknown", value: { centerNew: "wlc_ptxu_newc2", centerAll: "wlc_ptxu_allc2", regionNew: "wlc_ptxu_newr2", regionAll: "wlc_ptxu_allr2", usNew: "wlc_ptxu_newu2", usAll: "wlc_ptxu_allu2", category: "blood Type" } }
            ]
        });

    if (organ === "hr") {
        options.push(
            {
                label: "Primary Disease",
                value: [
                    { label: "Cardiomyopathy", value: { centerNew: "wlc_hrcar_newc2", centerAll: "wlc_hrcar_allc2", regionNew: "wlc_hrcar_newr2", regionAll: "wlc_hrcar_allr2", usNew: "wlc_hrcar_newu2", usAll: "wlc_hrcar_allu2", category: "Primary Disease" } },
                    { label: "Congenital Heart Disease", value: { centerNew: "wlc_hrcon_newc2", centerAll: "wlc_hrcon_allc2", regionNew: "wlc_hrcon_newr2", regionAll: "wlc_hrcon_allr2", usNew: "wlc_hrcon_newu2", usAll: "wlc_hrcon_allu2", category: "Primary Disease" } },
                    { label: "Coronary Artery Disease", value: { centerNew: "wlc_hrcor_newc2", centerAll: "wlc_hrcor_allc2", regionNew: "wlc_hrcor_newr2", regionAll: "wlc_hrcor_allr2", usNew: "wlc_hrcor_newu2", usAll: "wlc_hrcor_allu2", category: "Primary Disease" } },
                    { label: "Retransplant/Graft Failure", value: { centerNew: "wlc_hrrtr_newc2", centerAll: "wlc_hrrtr_allc2", regionNew: "wlc_hrrtr_newr2", regionAll: "wlc_hrrtr_allr2", usNew: "wlc_hrrtr_newu2", usAll: "wlc_hrrtr_allu2", category: "Primary Disease" } },
                    { label: "Valvular Heart Disease", value: { centerNew: "wlc_hrvlv_newc2", centerAll: "wlc_hrvlv_allc2", regionNew: "wlc_hrvlv_newr2", regionAll: "wlc_hrvlv_allr2", usNew: "wlc_hrvlv_newu2", usAll: "wlc_hrvlv_allu2", category: "Primary Disease" } },
                    { label: "Other", value: { centerNew: "wlc_hroth_newc2", centerAll: "wlc_hroth_allc2", regionNew: "wlc_hroth_newr2", regionAll: "wlc_hroth_allr2", usNew: "wlc_hroth_newu2", usAll: "wlc_hroth_allu2", category: "Primary Disease" } },
                    { label: "Missing", value: { centerNew: "wlc_hrmis_newc2", centerAll: "wlc_hrmis_allc2", regionNew: "wlc_hrmis_newr2", regionAll: "wlc_hrmis_allr2", usNew: "wlc_hrmis_newu2", usAll: "wlc_hrmis_allu2", category: "Primary Disease" } }
                ]
            });

        options.push(
            {
                label: "Medical Urgency Status at Listing",
                value: [
                    { label: "Status 1A", value: { centerNew: "wlc_st1a_newc2", centerAll: "wlc_st1a_allc2", regionNew: "wlc_st1a_newr2", regionAll: "wlc_st1a_allr2", usNew: "wlc_st1a_newu2", usAll: "wlc_st1a_allu2", category: "Medical Urgency Status at Listing" } },
                    { label: "Status 1B", value: { centerNew: "wlc_st1b_newc2", centerAll: "wlc_st1b_allc2", regionNew: "wlc_st1b_newr2", regionAll: "wlc_st1b_allr2", usNew: "wlc_st1b_newu2", usAll: "wlc_st1b_allu2", category: "Medical Urgency Status at Listing" } },
                    { label: "Status 2", value: { centerNew: "wlc_st2_newc2", centerAll: "wlc_st2_allc2", regionNew: "wlc_st2_newr2", regionAll: "wlc_st2_allr2", usNew: "wlc_st2_newu2", usAll: "wlc_st2_allu2", category: "Medical Urgency Status at Listing" } },
                    { label: "Adult Status 1", value: { centerNew: "wlc_sta1_newc2", centerAll: "wlc_sta1_allc2", regionNew: "wlc_sta1_newr2", regionAll: "wlc_sta1_allr2", usNew: "wlc_sta1_newu2", usAll: "wlc_sta1_allu2", category: "Medical Urgency Status at Listing" } },
                    { label: "Adult Status 2", value: { centerNew: "wlc_sta2_newc2", centerAll: "wlc_sta2_allc2", regionNew: "wlc_sta2_newr2", regionAll: "wlc_sta2_allr2", usNew: "wlc_sta2_newu2", usAll: "wlc_sta2_allu2", category: "Medical Urgency Status at Listing" } },
                    { label: "Adult Status 3", value: { centerNew: "wlc_sta3_newc2", centerAll: "wlc_sta3_allc2", regionNew: "wlc_sta3_newr2", regionAll: "wlc_sta3_allr2", usNew: "wlc_sta3_newu2", usAll: "wlc_sta3_allu2", category: "Medical Urgency Status at Listing" } },
                    { label: "Adult Status 4", value: { centerNew: "wlc_sta4_newc2", centerAll: "wlc_sta4_allc2", regionNew: "wlc_sta4_newr2", regionAll: "wlc_sta4_allr2", usNew: "wlc_sta4_newu2", usAll: "wlc_sta4_allu2", category: "Medical Urgency Status at Listing" } },
                    { label: "Adult Status 5", value: { centerNew: "wlc_sta5_newc2", centerAll: "wlc_sta5_allc2", regionNew: "wlc_sta5_newr2", regionAll: "wlc_sta5_allr2", usNew: "wlc_sta5_newu2", usAll: "wlc_sta5_allu2", category: "Medical Urgency Status at Listing" } },
                    { label: "Adult Status 6", value: { centerNew: "wlc_sta6_newc2", centerAll: "wlc_sta6_allc2", regionNew: "wlc_sta6_newr2", regionAll: "wlc_sta6_allr2", usNew: "wlc_sta6_newu2", usAll: "wlc_sta6_allu2", category: "Medical Urgency Status at Listing" } },
                    { label: "Temporarily Inactive", value: { centerNew: "wlc_sto_newc2", centerAll: "wlc_sto_allc2", regionNew: "wlc_sto_newr2", regionAll: "wlc_sto_allr2", usNew: "wlc_sto_newu2", usAll: "wlc_sto_allu2", category: "Medical Urgency Status at Listing" } },

                ]
            });

    }
    else if (organ === "in") {
        options.push(
            {
                label: "Primary Disease",
                value: [
                    { label: "Functional Bowel Problem", value: { centerNew: "wlc_inFbP_newc2", centerAll: "wlc_inFbP_allc2", regionNew: "wlc_inFbP_newr2", regionAll: "wlc_inFbP_allr2", usNew: "wlc_inFbP_newu2", usAll: "wlc_inFbP_allu2", category: "Primary Disease" } },
                    { label: "Short Gut Syndrome", value: { centerNew: "wlc_inSGS_newc2", centerAll: "wlc_inSGS_allc2", regionNew: "wlc_inSGS_newr2", regionAll: "wlc_inSGS_allr2", usNew: "wlc_inSGS_newu2", usAll: "wlc_inSGS_allu2", category: "Primary Disease" } },
                    { label: "Other", value: { centerNew: "wlc_inoth_newc2", centerAll: "wlc_inoth_allc2", regionNew: "wlc_inoth_newr2", regionAll: "wlc_inoth_allr2", usNew: "wlc_inoth_newu2", usAll: "wlc_inoth_allu2", category: "Primary Disease" } },
                    { label: "Missing", value: { centerNew: "wlc_inmis_newc2", centerAll: "wlc_inmis_allc2", regionNew: "wlc_inmis_newr2", regionAll: "wlc_inmis_allr2", usNew: "wlc_inmis_newu2", usAll: "wlc_inmis_allu2", category: "Primary Disease" } }
                ]
            });
    }
    else if (organ === "ki") {
        options.push(
            {
                label: "Initial CPRA",
                value: [
                    { label: "0-9%", value: { centerNew: "wlc_pra9_newc2", centerAll: "wlc_pra9_allc2", regionNew: "wlc_pra9_newr2", regionAll: "wlc_pra9_allr2", usNew: "wlc_pra9_newu2", usAll: "wlc_pra9_allu2", category: "Primary Disease" } },
                    { label: "10-79%", value: { centerNew: "wlc_pra79_newc2", centerAll: "wlc_pra79_allc2", regionNew: "wlc_pra79_newr2", regionAll: "wlc_pra79_allr2", usNew: "wlc_pra79_newu2", usAll: "wlc_pra79_allu2", category: "Primary Disease" } },
                    { label: "80+%", value: { centerNew: "wlc_pra80_newc2", centerAll: "wlc_pra80_allc2", regionNew: "wlc_pra80_newr2", regionAll: "wlc_pra80_allr2", usNew: "wlc_pra80_newu2", usAll: "wlc_pra80_allu2", category: "Primary Disease" } },
                    { label: "Unknown", value: { centerNew: "wlc_prau_newc2", centerAll: "wlc_prau_allc2", regionNew: "wlc_prau_newr2", regionAll: "wlc_prau_allr2", usNew: "wlc_prau_newu2", usAll: "wlc_prau_allu2", category: "Primary Disease" } }
                ]
            });
        options.push(
            {
                label: "Primary Disease",
                value: [
                    { label: "Glomerular Diseases", value: { centerNew: "wlc_kiglo_newc2", centerAll: "wlc_kiglo_allc2", regionNew: "wlc_kiglo_newr2", regionAll: "wlc_kiglo_allr2", usNew: "wlc_kiglo_newu2", usAll: "wlc_kiglo_allu2", category: "Primary Disease" } },
                    { label: "Tubular and Interstitial Diseases", value: { centerNew: "wlc_kiTub_newc2", centerAll: "wlc_kiTub_newc2", regionNew: "wlc_kiTub_newr2", regionAll: "wlc_kiTub_allr2", usNew: "wlc_kiTub_newu2", usAll: "wlc_kiTub_newc2", category: "Primary Disease" } },
                    { label: "Polycystic Kidneys", value: { centerNew: "wlc_kipol_newc2", centerAll: "wlc_kipol_allc2", regionNew: "wlc_kipol_newr2", regionAll: "wlc_kipol_allr2", usNew: "wlc_kipol_newu2", usAll: "wlc_kipol_allu2", category: "Primary Disease" } },
                    { label: "Congenital, Familial, Metabolic", value: { centerNew: "wlc_kicon_newc2", centerAll: "wlc_kicon_allc2", regionNew: "wlc_kicon_newr2", regionAll: "wlc_kicon_allr2", usNew: "wlc_kicon_newu2", usAll: "wlc_kicon_allu2", category: "Primary Disease" } },
                    { label: "Diabetes", value: { centerNew: "wlc_kidia_newc2", centerAll: "wlc_kidia_allc2", regionNew: "wlc_kidia_newr2", regionAll: "wlc_kidia_allr2", usNew: "wlc_kidia_newu2", usAll: "wlc_kidia_allu2", category: "Primary Disease" } },
                    { label: "Renovascular & Vascular Diseases", value: { centerNew: "wlc_kiren_newc2", centerAll: "wlc_kiren_allc2", regionNew: "wlc_kiren_newr2", regionAll: "wlc_kiren_allr2", usNew: "wlc_kiren_newu2", usAll: "wlc_kiren_allu2", category: "Primary Disease" } },
                    { label: "Neoplasms", value: { centerNew: "wlc_kineo_newc2", centerAll: "wlc_kineo_allc2", regionNew: "wlc_kineo_newr2", regionAll: "wlc_kineo_allr2", usNew: "wlc_kineo_newu2", usAll: "wlc_kineo_allu2", category: "Primary Disease" } },
                    { label: "Hypertensive Nephrosclerosis", value: { centerNew: "wlc_kihyp_newc2", centerAll: "wlc_kihyp_allc2", regionNew: "wlc_kihyp_newr2", regionAll: "wlc_kihyp_allr2", usNew: "wlc_kihyp_newu2", usAll: "wlc_kihyp_allu2", category: "Primary Disease" } },
                    { label: "Other", value: { centerNew: "wlc_kioth_newc2", centerAll: "wlc_kioth_allc2", regionNew: "wlc_kioth_newr2", regionAll: "wlc_kioth_allr2", usNew: "wlc_kioth_newu2", usAll: "wlc_kioth_allu2", category: "Primary Disease" } },
                    { label: "Missing *", value: { centerNew: "wlc_kimis_newc2", centerAll: "wlc_kimis_allc2", regionNew: "wlc_kimis_newr2", regionAll: "wlc_kimis_allr2", usNew: "wlc_kimis_newu2", usAll: "wlc_kimis_allu2", category: "Primary Disease" } }
                ]
            });
    }
    else if (organ === "li") {
        options.push(
            {
                label: "Primary Disease",
                value: [
                    { label: "Acute Hepatic Necrosis", value: { centerNew: "wlc_liacu_newc2", centerAll: "wlc_liacu_allc2", regionNew: "wlc_liacu_newr2", regionAll: "wlc_liacu_allr2", usNew: "wlc_liacu_newu2", usAll: "wlc_liacu_allu2", category: "Primary Disease" } },
                    { label: "Biliary Atresia", value: { centerNew: "wlc_libil_newc2", centerAll: "wlc_libil_allc2", regionNew: "wlc_libil_newr2", regionAll: "wlc_libil_allr2", usNew: "wlc_libil_newu2", usAll: "wlc_libil_allu2", category: "Primary Disease" } },
                    { label: "Cholestatic Liver Disease/Cirrhosis", value: { centerNew: "wlc_licho_newc2", centerAll: "wlc_licho_allc2", regionNew: "wlc_licho_newr2", regionAll: "wlc_licho_allr2", usNew: "wlc_licho_newu2", usAll: "wlc_licho_allu2", category: "Primary Disease" } },
                    { label: "Malignant Neoplasms", value: { centerNew: "wlc_limal_newc2", centerAll: "wlc_limal_allc2", regionNew: "wlc_limal_newr2", regionAll: "wlc_limal_allr2", usNew: "wlc_limal_newu2", usAll: "wlc_limal_allu2", category: "Primary Disease" } },
                    { label: "Metabolic Diseases", value: { centerNew: "wlc_limet_newc2", centerAll: "wlc_limet_allc2", regionNew: "wlc_limet_newr2", regionAll: "wlc_limet_allr2", usNew: "wlc_limet_newu2", usAll: "wlc_limet_allu2", category: "Primary Disease" } },
                    { label: "Non-cholestatic Cirrhosis", value: { centerNew: "wlc_linch_newc2", centerAll: "wlc_linch_allc2", regionNew: "wlc_linch_newr2", regionAll: "wlc_linch_allr2", usNew: "wlc_linch_newu2", usAll: "wlc_linch_allu2", category: "Primary Disease" } },
                    { label: "Other", value: { centerNew: "wlc_lioth_newc2", centerAll: "wlc_lioth_allc2", regionNew: "wlc_lioth_newr2", regionAll: "wlc_lioth_allr2", usNew: "wlc_lioth_newu2", usAll: "wlc_lioth_allu2", category: "Primary Disease" } },
                    { label: "Missing", value: { centerNew: "wlc_limis_newc2", centerAll: "wlc_limis_allc2", regionNew: "wlc_limis_newr2", regionAll: "wlc_limis_allr2", usNew: "wlc_limis_newu2", usAll: "wlc_limis_allu2", category: "Primary Disease" } }
                ]
            });

        options.push(
            {
                label: "Medical Urgency Status/MELD/PELD at Listing",
                value: [
                    { label: "Status 1A", value: { centerNew: "wlc_st1a_newc2", centerAll: "wlc_st1a_allc2", regionNew: "wlc_st1a_newr2", regionAll: "wlc_st1a_allr2", usNew: "wlc_st1a_newu2", usAll: "wlc_st1a_allu2", category: "Medical Urgency Status/MELD/PELD at Listing" } },
                    { label: "Status 1B", value: { centerNew: "wlc_st1b_newc2", centerAll: "wlc_st1b_allc2", regionNew: "wlc_st1b_newr2", regionAll: "wlc_st1b_allr2", usNew: "wlc_st1b_newu2", usAll: "wlc_st1b_allu2", category: "Medical Urgency Status/MELD/PELD at Listing" } },
                    { label: "Status 2A", value: { centerNew: "wlc_st2a_newc2", centerAll: "wlc_st2a_allc2", regionNew: "wlc_st2a_newr2", regionAll: "wlc_st2a_allr2", usNew: "wlc_st2a_newu2", usAll: "wlc_st2a_allu2", category: "Medical Urgency Status/MELD/PELD at Listing" } },
                    { label: "Status 2B", value: { centerNew: "wlc_st2b_newc2", centerAll: "wlc_st2b_allc2", regionNew: "wlc_st2b_newr2", regionAll: "wlc_st2b_allr2", usNew: "wlc_st2b_newu2", usAll: "wlc_st2b_allu2", category: "Medical Urgency Status/MELD/PELD at Listing" } },
                    { label: "Status 3", value: { centerNew: "wlc_st3_newc2", centerAll: "wlc_st3_allc2", regionNew: "wlc_st3_newr2", regionAll: "wlc_st3_allr2", usNew: "wlc_st3_newu2", usAll: "wlc_st3_allu2", category: "Medical Urgency Status/MELD/PELD at Listing" } },
                    { label: "MELD 6-10", value: { centerNew: "wlc_me6_newc2", centerAll: "wlc_me6_allc2", regionNew: "wlc_me6_newr2", regionAll: "wlc_me6_allr2", usNew: "wlc_me6_newu2", usAll: "wlc_me6_allu2", category: "Medical Urgency Status/MELD/PELD at Listing" } },
                    { label: "MELD 11-14", value: { centerNew: "wlc_me11_newc2", centerAll: "wlc_me11_allc2", regionNew: "wlc_me11_newr2", regionAll: "wlc_me11_allr2", usNew: "wlc_me11_newu2", usAll: "wlc_me11_allu2", category: "Medical Urgency Status/MELD/PELD at Listing" } },
                    { label: "MELD 15-20", value: { centerNew: "wlc_me15_newc2", centerAll: "wlc_me15_allc2", regionNew: "wlc_me15_newr2", regionAll: "wlc_me15_allr2", usNew: "wlc_me15_newu2", usAll: "wlc_me15_allu2", category: "Medical Urgency Status/MELD/PELD at Listing" } },
                    { label: "MELD 21-30", value: { centerNew: "wlc_me21_newc2", centerAll: "wlc_me21_allc2", regionNew: "wlc_me21_newr2", regionAll: "wlc_me21_allr2", usNew: "wlc_me21_newu2", usAll: "wlc_me21_allu2", category: "Medical Urgency Status/MELD/PELD at Listing" } },
                    { label: "MELD 31-40", value: { centerNew: "wlc_me31_newc2", centerAll: "wlc_me31_allc2", regionNew: "wlc_me31_newr2", regionAll: "wlc_me31_allr2", usNew: "wlc_me31_newu2", usAll: "wlc_me31_allu2", category: "Medical Urgency Status/MELD/PELD at Listing" } },
                    { label: "PELD less than or equal to 10", value: { centerNew: "wlc_me6_newc2", centerAll: "wlc_me6_allc2", regionNew: "wlc_me6_newr2", regionAll: "wlc_me6_allr2", usNew: "wlc_me6_newu2", usAll: "wlc_me6_allu2", category: "Medical Urgency Status/MELD/PELD at Listing" } },
                    { label: "PELD 11-14", value: { centerNew: "wlc_pe11_newc2", centerAll: "wlc_pe11_allc2", regionNew: "wlc_pe11_newr2", regionAll: "wlc_pe11_allr2", usNew: "wlc_pe11_newu2", usAll: "wlc_pe11_allu2", category: "Medical Urgency Status/MELD/PELD at Listing" } },
                    { label: "PELD 15-20", value: { centerNew: "wlc_pe15_newc2", centerAll: "wlc_pe15_allc2", regionNew: "wlc_pe15_newr2", regionAll: "wlc_pe15_allr2", usNew: "wlc_pe15_newu2", usAll: "wlc_pe15_allu2", category: "Medical Urgency Status/MELD/PELD at Listing" } },
                    { label: "PELD 21-30", value: { centerNew: "wlc_pe21_newc2", centerAll: "wlc_pe21_allc2", regionNew: "wlc_pe21_newr2", regionAll: "wlc_pe21_allr2", usNew: "wlc_pe21_newu2", usAll: "wlc_pe21_allu2", category: "Medical Urgency Status/MELD/PELD at Listing" } },
                    { label: "PELD 31 or greater", value: { centerNew: "wlc_pe31_newc2", centerAll: "wlc_pe31_allc2", regionNew: "wlc_pe31_newr2", regionAll: "wlc_pe31_allr2", usNew: "wlc_pe31_newu2", usAll: "wlc_pe31_allu2", category: "Medical Urgency Status/MELD/PELD at Listing" } },
                    { label: "Temporarily Inactive", value: { centerNew: "wlc_sto_newc2", centerAll: "wlc_sto_allc2", regionNew: "wlc_sto_newr2", regionAll: "wlc_sto_allr2", usNew: "wlc_sto_newu2", usAll: "wlc_sto_allu2", category: "Medical Urgency Status/MELD/PELD at Listing" } },

                ]
            });
    }
    else if (organ === "lu") {
        options.push(
            {
                label: "Primary Disease",
                value: [
                    { label: "Congenital Disease", value: { centerNew: "wlc_lucon_newc2", centerAll: "wlc_lucon_allc2", regionNew: "wlc_lucon_newr2", regionAll: "wlc_lucon_allr2", usNew: "wlc_lucon_newu2", usAll: "wlc_lucon_allu2", category: "Primary Disease" } },
                    { label: "Idiopathic Pulmonary Arterial Hypertension", value: { centerNew: "wlc_lupri_newc2", centerAll: "wlc_lupri_allc2", regionNew: "wlc_lupri_newr2", regionAll: "wlc_lupri_allr2", usNew: "wlc_lupri_newu2", usAll: "wlc_lupri_allu2", category: "Primary Disease" } },
                    { label: "Cystic Fibrosis", value: { centerNew: "wlc_lucys_newc2", centerAll: "wlc_lucys_allc2", regionNew: "wlc_lucys_newr2", regionAll: "wlc_lucys_allu2", usNew: "wlc_lucys_newu2", usAll: "wlc_lucys_allu2", category: "Primary Disease" } },
                    { label: "Idiopathic Pulmonary Fibrosis", value: { centerNew: "wlc_luidi_newc2", centerAll: "wlc_luidi_allr2", regionNew: "wlc_luidi_newr2", regionAll: "wlc_luidi_allu2", usNew: "wlc_luidi_newu2", usAll: "wlc_luidi_allu2", category: "Primary Disease" } },
                    { label: "Emphysema/COPD", value: { centerNew: "wlc_luemp_newc2", centerAll: "wlc_luemp_allc2", regionNew: "wlc_luemp_newr2", regionAll: "wlc_luemp_allr2", usNew: "wlc_luemp_newu2", usAll: "wlc_luemp_allu2", category: "Primary Disease" } },
                    { label: "Other", value: { centerNew: "wlc_luoth_newc2", centerAll: "wlc_luoth_allc2", regionNew: "wlc_luoth_newr2", regionAll: "wlc_luoth_allr2", usNew: "wlc_luoth_newu2", usAll: "wlc_luoth_allu2", category: "Primary Disease" } },
                    { label: "Missing", value: { centerNew: "wlc_lumis_newc2", centerAll: "wlc_lumis_allc2", regionNew: "wlc_lumis_newr2", regionAll: "wlc_lumis_allr2", usNew: "wlc_lumis_newu2", usAll: "wlc_lumis_allu2", category: "Primary Disease" } }
                ]
            });
    }
    else if (organ === "hl") {
        options.push(
            {
                label: "Primary Disease",
                value: [
                    { label: "Congenital Disease", value: { centerNew: "wlc_lucon_newc2", centerAll: "wlc_lucon_allc2", regionNew: "wlc_lucon_newr2", regionAll: "wlc_lucon_allr2", usNew: "wlc_lucon_newu2", usAll: "wlc_lucon_allu2", category: "Primary Disease" } },
                    { label: "Retransplant/Graft Failure", value: { centerNew: "wlc_luret_newc2", centerAll: "wlc_luret_allc2", regionNew: "wlc_luret_newr2", regionAll: "wlc_luret_allr2", usNew: "wlc_luret_newu2", usAll: "wlc_luret_allu2", category: "Primary Disease" } },
                    { label: "Idiopathic Pulmonary Arterial Hypertension", value: { centerNew: "wlc_lupri_newc2", centerAll: "wlc_lupri_allc2", regionNew: "wlc_lupri_newr2", regionAll: "wlc_lupri_allr2", usNew: "wlc_lupri_newu2", usAll: "wlc_lupri_allu2", category: "Primary Disease" } },
                    { label: "Cystic Fibrosis", value: { centerNew: "wlc_lucys_newc2", centerAll: "wlc_lucys_allc2", regionNew: "wlc_lucys_newr2", regionAll: "wlc_lucys_allu2", usNew: "wlc_lucys_newu2", usAll: "wlc_lucys_allu2", category: "Primary Disease" } },
                    { label: "Idiopathic Pulmonary Fibrosis", value: { centerNew: "wlc_luidi_newc2", centerAll: "wlc_luidi_allr2", regionNew: "wlc_luidi_newr2", regionAll: "wlc_luidi_allu2", usNew: "wlc_luidi_newu2", usAll: "wlc_luidi_allu2", category: "Primary Disease" } },
                    { label: "Emphysema/COPD", value: { centerNew: "wlc_luemp_newc2", centerAll: "wlc_luemp_allc2", regionNew: "wlc_luemp_newr2", regionAll: "wlc_luemp_allr2", usNew: "wlc_luemp_newu2", usAll: "wlc_luemp_allu2", category: "Primary Disease" } },
                    { label: "Other", value: { centerNew: "wlc_luoth_newc2", centerAll: "wlc_luoth_allc2", regionNew: "wlc_luoth_newr2", regionAll: "wlc_luoth_allr2", usNew: "wlc_luoth_newu2", usAll: "wlc_luoth_allu2", category: "Primary Disease" } },
                    { label: "Missing", value: { centerNew: "wlc_lumis_newc2", centerAll: "wlc_lumis_allc2", regionNew: "wlc_lumis_newr2", regionAll: "wlc_lumis_allr2", usNew: "wlc_lumis_newu2", usAll: "wlc_lumis_allu2", category: "Primary Disease" } }
                ]
            });
    }
    else if (organ === "kp") {
        options.push(
            {
                label: "Initial CPRA",
                value: [
                    { label: "0-9%", value: { centerNew: "wlc_pra9_newc2", centerAll: "wlc_pra9_allc2", regionNew: "wlc_pra9_newr2", regionAll: "wlc_pra9_allr2", usNew: "wlc_pra9_newu2", usAll: "wlc_pra9_allu2", category: "Primary Disease" } },
                    { label: "10-79%", value: { centerNew: "wlc_pra79_newc2", centerAll: "wlc_pra79_allc2", regionNew: "wlc_pra79_newr2", regionAll: "wlc_pra79_allr2", usNew: "wlc_pra79_newu2", usAll: "wlc_pra79_allu2", category: "Primary Disease" } },
                    { label: "80+%", value: { centerNew: "wlc_pra80_newc2", centerAll: "wlc_pra80_allc2", regionNew: "wlc_pra80_newr2", regionAll: "wlc_pra80_allr2", usNew: "wlc_pra80_newu2", usAll: "wlc_pra80_allu2", category: "Primary Disease" } },
                    { label: "Unknown", value: { centerNew: "wlc_prau_newc2", centerAll: "wlc_prau_allc2", regionNew: "wlc_prau_newr2", regionAll: "wlc_prau_allr2", usNew: "wlc_prau_newu2", usAll: "wlc_prau_allu2", category: "Primary Disease" } }
                ]
            });
    }


    return options;
}
