import React, { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Select from "react-select";
import DemographicsTable from "./DemographicsTable";

import { demographicOptions } from "../../Options";
import { brandColorArray, formatOneDecimal } from "../../../../utilities/formatter";

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

function DemographicsChart(props) {
    let title = props.title;
    let subTitle = props.subTitle;
    const uniqueId = `demographics-chart-${Math.random()}`;
    const chartRef = useRef(null);
    const selectOptions = demographicOptions.filter(function (value, index, arr) { return value.label !== "All"; });
    const [selectedOption, setSelectedOption] = useState(null);
    const [chartOptions, setChartOptions] = useState({
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ['printChart', 'downloadPNG', 'downloadSVG', 'downloadPDF', 'downloadCSV']
                },
            },
            title: {
                text: `<div style="font-family:Open Sans;font-size:14px;font-weight: 700;text-align:center;">${title}</div><br /><div style="font-family:Open Sans;font-size:12px;font-weight: 400;text-align:center;">${subTitle}</div>`
            },
            chart: {
                events: {
                    load: function () {
                        let width = 0;
                        let height = this.chartHeight - 50;
                        this.renderer.image('/srtr_logo.jpg', width, height, 50, 50)
                            .add();
                    }
                }
            }
        },
        chart: {
            type: "column"
        },
        credits: {
            enabled: false
        },
        title: {
            text: ""
        },
        xAxis: {
            categories: [
                `${props.centerCode} (New)`,
                "OPTN Region (New)",
                "U.S. (New)",
                `${props.centerCode} (All)`,
                "OPTN Region (All)",
                "U.S. (All)"
            ],
            labels: {
                style: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"
                }
            }
        },
        yAxis: {
            title: {
                style: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"
                }
            },
            labels: {
                style: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"
                }
            },
            reversedStacks: false,
            max: 100
        },
        tooltip: {
            borderWidth: 0,
            backgroundColor: '#FCFFC5',
            borderRadius: 10,
            shadow: true,
            useHTML: true,
            headerFormat: '<div style="font-family:Open Sans;font-size:14px;font-weight: 700;text-align:center;margin-bottom:10px">{point.key}</div>',
            pointFormat: '<div style="padding:0px 5px 5px 5px;font-family:Open Sans;font-size:14px;font-weight: 700;text-align:left"><span>{series.name}</span>: <span style="font-family:Open Sans;font-size:14px;font-weight: 600;text-align:left">{point.y:.1f}</span></div>',
            shared: false
        },

        legend: {
            itemStyle: {
                fontFamily: "Open Sans",
                fontSize: "14px"

            }
        },
        labels: {
            useHTML: true,
            allowOverlap: true,
            style: {
                wordBreak: 'break-all',
                textOverflow: 'allow',
                fontFamily: "Open Sans",
                fontSize: "14px"
            }
        },
        plotOptions: {
            column: {
                stacking: 'column'
            }
        }
    });


    const _onChange = (selectedOption) => {

        if (chartRef && chartRef.current && chartRef.current.chart && selectedOption) {
            let chart = chartRef.current.chart;
            let selectedValue = selectedOption.value;
            let series = selectedValue.map((x, index) => {
                let newSeries = {
                    name: x.label,
                    color: brandColorArray[index],
                    data: [
                        formatOneDecimal(props.data[x.value.centerNew]),
                        formatOneDecimal(props.data[x.value.regionNew]),
                        formatOneDecimal(props.data[x.value.usNew]),
                        formatOneDecimal(props.data[x.value.centerAll]),
                        formatOneDecimal(props.data[x.value.regionAll]),
                        formatOneDecimal(props.data[x.value.usAll])
                    ]
                };

                return newSeries;
            });

            let _newOptions = {
                ...chartOptions,
                series: series
            }

            chart.yAxis[0].update({
                title: { text: `Percentage (${selectedOption.label})` }
            });

            setChartOptions(_newOptions);
            setSelectedOption(selectedOption);
        }
    };

    useEffect(() => {
        if (selectedOption) {
            _onChange(selectedOption);
        }
        else {
            _onChange(selectOptions[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);


    if (props.data) {
        return (
            <div className="transplants-facilitated-chart">
                <Row >
                    <Col xs={12} lg={8}>
                        <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOptions} containerProps={{ className: "highcharts-light" }}/>
                    </Col>
                    <Col xs={12} lg={4}>
                        <div className="green-control-box">
                            <div className="description" id={`label-${uniqueId}`} htmlFor={uniqueId}>
                                Select characteristic
                            </div>
                            <Select
                                id={uniqueId}
                                className="select-characteristics mt-2"
                                classNamePrefix="select"
                                isClearable={false}
                                isSearchable={true}
                                name="characteristics"
                                options={selectOptions}
                                onChange={_onChange}
                                defaultValue={selectOptions[0]}
                                aria-labelledby={`label-${uniqueId}`}
                            />

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} >
                        {props.data &&
                            <DemographicsTable
                                data={props.data}
                                organ={props.organ}
                                options={demographicOptions}
                                centerCode={props.centerCode}
                            />
                        }
                    </Col>
                </Row>
            </div>
        );
    }
    else {
        return (
            <div className="transplants-facilitated-chart">
                <Row>
                    <Col xs={12} className="text-center">
                        <div className="sub-title mt-4">
                            Characteristics of deceased donor <strong>{props.organ}</strong> recipients
                        </div>
                    </Col>
                </Row>

            </div>
        );
    }
}

export default DemographicsChart;