import React from "react";

function ErrorFallback({error, componentStack, resetErrorBoundary}) {
    return (
      <div role="alert" className="error-message">
        <p>The application has encountered an unknown error.</p>
        <p>Our technical staff have been automatically notified and will be looking into this right away.</p>
      </div>
    )
  }

export default ErrorFallback;