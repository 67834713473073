import React, { useState, useEffect } from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TotalDonorsAndOrgansTransplantedChart from "./TotalDonorsAndOrgansTransplantedChart";
import TotalDonorsAndOrgansTransplantedLineChart from "./TotalDonorsAndOrgansTransplantedLineChart";
import Skeleton from "react-loading-skeleton";

function TotalDonorsAndOrgansTransplanted(props) {
    const Host = process.env.REACT_APP_API_HOST;
    const opoCode = props.opoCode;

    const [heartData, setHeartData] = useState(null);
    const [kidneyData, setKidneyData] = useState(null);
    const [liverData, setLiverData] = useState(null);
    const [lungData, setLungData] = useState(null);
    const [pancreasData, setPancreasData] = useState(null);
    const [intestineData, setIntestineData] = useState(null);
    const [donorsData, setDonorsData] = useState(null);
    const [categories, setCategories] = useState(null);

    useEffect(() => {
        fetch(`${Host}/opo/${opoCode}/organstransplanted`)
        .then(response => response.json())
        .then(response => {
                let _categories = [
                    response.yearOne,
                    response.yearTwo,
                    response.yearThree,
                    response.yearFour,
                    response.yearFive
                ];

                //Set the categories to be used through the entire lifetime of the page
                setCategories(_categories);

                setDonorsData(response.donorsData);
                setHeartData(response.heartData);
                setKidneyData(response.kidneyData);
                setLiverData(response.liverData);
                setLungData(response.lungData);
                setPancreasData(response.pancreasData);
                setIntestineData(response.intestineData);
            
        })
        .catch((err) => { console.log(err) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    let title = "Total Donors & Organs Transplanted";
    return (
        <div>
            <Row>
                <Col xs={12}>
                    <h1 className="section-title">
                        {donorsData ? title : <Skeleton width={500} />}
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {donorsData &&
                        <TotalDonorsAndOrgansTransplantedLineChart
                            categories={categories}
                            chartData={donorsData}
                            organ="All" 
                        />
                    }
                </Col>
            </Row>
            <Row>
                <Col s={12} md={6}>
                    {heartData &&
                        <TotalDonorsAndOrgansTransplantedChart
                            categories={categories}
                            chartData={heartData}
                            organ="heart" 
                            title="Total Heart Transplants"
                        />
                    }
                </Col>
                <Col s={12} md={6} >
                    {kidneyData &&
                        <TotalDonorsAndOrgansTransplantedChart
                            categories={categories}
                            chartData={kidneyData}
                            organ="kidney" 
                            title="Total Kidney Transplants"
                        />
                    }
                </Col>
            </Row>
            <Row>
                <Col s={12} md={6} >
                    {liverData &&
                        <TotalDonorsAndOrgansTransplantedChart
                            categories={categories}
                            chartData={liverData}
                            organ="liver" 
                            title="Total Liver Transplants"
                        />
                    }
                </Col>
                <Col s={12} md={6} >
                    {lungData &&
                        <TotalDonorsAndOrgansTransplantedChart
                            categories={categories}
                            chartData={lungData}
                            organ="lung" 
                            title="Total Lung Transplants"
                        />
                    }
                </Col>
            </Row>
            <Row>
                <Col s={12} md={6} >
                    {pancreasData &&
                        <TotalDonorsAndOrgansTransplantedChart
                            categories={categories}
                            chartData={pancreasData}
                            organ="pancreas" 
                            title="Total Pancreas Transplants"
                        />
                    }
                </Col>
                <Col s={12} md={6} >
                    {intestineData &&
                        <TotalDonorsAndOrgansTransplantedChart
                            categories={categories}
                            chartData={intestineData}
                            organ="intestine" 
                            title="Total Intestine Transplants"
                        />
                    }
                </Col>
            </Row>

        </div>
    )
}

export default TotalDonorsAndOrgansTransplanted;