import React from "react";
import {formatOneDecimal, formatThousands} from "../../../utilities/formatter";
function MedicalUrgencyStatus(props){
    if (props.organ === "li" || props.organ === "hr") {

        if (props.organ === "li") {
            return (
                <>
                    <tr>
                        <td colSpan="11" className="text-left font-weight-bold">Medical Urgency Status/MELD/PELD at Listing</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">Status 1</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstl1_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstl1_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">Status 1A</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstl1a_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1a_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1a_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1a_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1a_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstl1a_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1a_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1a_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1a_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1a_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">Status 1B</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstl1b_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1b_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1b_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1b_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1b_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstl1bp_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1b_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1b_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1b_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl1b_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">Status 2A</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstl2a_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl2a_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl2a_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl2a_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl2a_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstl2a_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl2a_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl2a_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl2a_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl2a_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">Status 2B</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstl2b_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl2b_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl2b_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl2b_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl2b_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstl2b_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl2b_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl2b_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl2b_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl2b_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">Status 3</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstl3_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl3_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl3_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl3_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl3_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstl3_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl3_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl3_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl3_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstl3_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">MELD 6-10</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstme6_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme6_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme6_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme6_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme6_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstme6_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme6_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme6_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme6_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme6_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">MELD 11-14</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstme11_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme11_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme11_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme11_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme11_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstme11_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme11_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme11_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme11_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme11_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">MELD 15-20</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstme15_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme15_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme15_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme15_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme15_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstme15_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme15_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme15_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme15_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme15_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">MELD 21-30</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstme21_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme21_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme21_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme21_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme21_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstme21_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme21_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme21_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme21_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme21_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">MELD 31-40</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstme31_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme31_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme31_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme31_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme31_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstme31_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme31_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme31_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme31_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstme31_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">PELD less than or equal to 10</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstpe6_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe6_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe6_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe6_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe6_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstpe6_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe6_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe6_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe6_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe6_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">PELD 11-14</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstpe11_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe11_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe11_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe11_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe11_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstpe11_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe11_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe11_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe11_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe11_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">PELD 15-20</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstpe15_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe15_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe15_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe15_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe15_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstpe15_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe15_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe15_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe15_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe15_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">PELD 21-30</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstpe21_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe21_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe21_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe21_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe21_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstpe21_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe21_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe21_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe21_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe21_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">PELD 31 or greater</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstpe31_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe31_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe31_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe31_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe31_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstpe31_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe31_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe31_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe31_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstpe31_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">Temporarily Inactive</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstlu_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstlu_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstlu_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstlu_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstlu_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lstlu_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstlu_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstlu_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstlu_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lstlu_put4))}</td>
                    </tr>
                </>
            );
        }
        else if (props.organ === "hr") {
            return (
                <>
                    <tr>
                        <td colSpan="11" className="text-left font-weight-bold">Medical Urgency Status at Listing</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">Old Status 1</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lsth1_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lsth1_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">Status 1A</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lsth1a_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1a_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1a_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1a_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1a_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lsth1a_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1a_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1a_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1a_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1a_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">Status 1B</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lsth1b_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1b_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1b_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1b_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1b_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lsth1b_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1b_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1b_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1b_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth1b_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">Status 2</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lsth2_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth2_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth2_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth2_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth2_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lsth2_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth2_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth2_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth2_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsth2_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">Unknown</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lsthu_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsthu_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsthu_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsthu_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsthu_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lsthu_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsthu_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsthu_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsthu_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lsthu_put4))}</td>
                    </tr>
                </>
            );
        }
    }
    
    return null;
}

export default MedicalUrgencyStatus;