import React, {useState} from "react";
import Pancreas from "../../../assets/images/organs/72x72/Pancreas_Up_72x72.png";
import PancreasHover from "../../../assets/images/organs/72x72/Pancreas_Over_72x72.png";
function PancreasIcon(props) {
    const [isHover, setIsHover] = useState(false);

    if (props.showHover) {
        return (
            <div 
            className={props.count ? "icon-container organ-count-wrapper" : "icon-container"}
                onMouseEnter={()=> setIsHover(true)}
                onMouseLeave={()=> setIsHover(false)}
            >
                <img src={isHover ? PancreasHover : Pancreas} alt="Pancreas Icon" className="organ-icon" />
                {props.count &&
                    <div className="organ-count pancreas">
                        {props.count}
                    </div>
                }
            </div>
        );
    }
    else {
        return (<img src={Pancreas} alt="Pancreas Icon" className="organ-icon" />);
    }
}

export default PancreasIcon;