import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Table from "react-bootstrap/Table";
import { formatThousands, formatOneDecimal } from "../../../../utilities/formatter";

function SurvivalRatesTable(props) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <div className="collapse-container show-all-row mt-4">
            View all patient mortality after listing data&nbsp;&nbsp; &nbsp;&nbsp;
                <Button
                    onClick={() => setOpen(!open)}
                    aria-controls="collapse-text"
                    aria-expanded={open}
                    className="btn-dark-blue min-98"
                >
                    {!open && "Show All"}
                    {open && "Hide All"}
                </Button>
            </div>
            <Collapse in={open}>
                <div id="collapse-text" className="collapse-inner">
                    <div className="table-responsive">
                        <div className="sub-title">
                            Swipe to view more data {">>>"}
                        </div>
                        <Table striped className="overflow-auto description-of-donors-recovered-table">
                            <thead>
                                <tr>
                                    <th className="border-bottom">Waiting List Registrations</th>
                                    <th className="text-center border-bottom">
                                        {props.centerCode}
                                    </th>
                                    <th className="text-center border-bottom">
                                        OPO/DSA
                                </th>
                                    <th className="text-center border-bottom">
                                        Region
                                </th>
                                    <th className="text-center border-bottom">
                                        U.S.
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="5" className="font-weight-bold">All Patients</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30">Count at risk during the evaluation period</td>
                                    <td className="text-center">{props.data ? formatThousands(props.data["sfl_all_n_c"]) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(props.data["sfl_all_n_o"]) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(props.data["sfl_all_n_r"]) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(props.data["sfl_all_n_u"]) : "-"}</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30">Person Years</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_all_py_c"])) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_all_py_o"])) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_all_py_r"])) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_all_py_u"])) : "-"}</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30">Number of Deaths</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_all_obs_c"])) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_all_obs_o"])) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_all_obs_r"])) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_all_obs_u"])) : "-"}</td>
                                </tr>
                                <tr>
                                    <td colSpan="5" className="font-weight-bold">Adult (18+) Patients</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30">Count at risk during the evaluation period</td>
                                    <td className="text-center">{props.data ? formatThousands(props.data["sfl_ad_n_c"]) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(props.data["sfl_ad_n_o"]) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(props.data["sfl_ad_n_r"]) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(props.data["sfl_ad_n_u"]) : "-"}</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30">Person Years</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_ad_py_c"])) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_ad_py_o"])) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_ad_py_r"])) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_ad_py_u"])) : "-"}</td>
                         </tr>
                                <tr>
                                    <td className="padding-left-30">Number of Deaths</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_ad_obs_c"])) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_ad_obs_o"])) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_ad_obs_r"])) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_ad_obs_u"])) : "-"}</td>
                                </tr>
                                <tr>
                                    <td colSpan="5" className="font-weight-bold">Pediatric ({"<18"}) Patients</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30">Count at risk during the evaluation period</td>
                                    <td className="text-center">{props.data ? formatThousands(props.data["sfl_p0_n_c"]) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(props.data["sfl_p0_n_o"]) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(props.data["sfl_p0_n_r"]) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(props.data["sfl_p0_n_u"]) : "-"}</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30">Person Years</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_p0_py_c"])) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_p0_py_o"])) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_p0_py_r"])) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_p0_py_u"])) : "-"}</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30">Number of Deaths</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_p0_obs_c"])) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_p0_obs_o"])) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_p0_obs_r"])) : "-"}</td>
                                    <td className="text-center">{props.data ? formatThousands(formatOneDecimal(props.data["sfl_p0_obs_u"])) : "-"}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Collapse>
        </>
    );
}

export default SurvivalRatesTable;