import React from "react"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WaitlistOverview from "./WaitlistOverview";
import TransplantRates from "./transplant-rates/TransplantRates";
import TimeToTransplant from "./time-to-transplant/TimeToTransplant";
import Characteristics from "./characteristics/Characteristics";


function Waitlist(props) {
   
    return (
        <div className="waitlist-wrapper">
            <Row>
                <Col xs={12} className="section">
                    {props.waitlistData &&
                        <WaitlistOverview
                            data={props.waitlistData}
                            organ={props.organ}
                            centerCode={props.centerCode}
                        />
                    }
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="section">
                    {props.timeToTransplantData &&
                        <TimeToTransplant
                            data={props.timeToTransplantData}
                            centerCode={props.centerCode}
                            organ={props.organ}
                        />
                    }
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="section">
                    <div className="outer-wrapper">
                        <div className="inner-wrapper">
                            <Characteristics
                                data={props.demographicsData}
                                organ={props.organ}
                                centerCode={props.centerCode}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="section">
                    {props.transplantRatesData &&
                        <TransplantRates
                            data={props.transplantRatesData}
                            organ={props.organ}
                            centerCode={props.centerCode}
                        />
                    }
                </Col>
            </Row>

        </div>
    );
}

export default Waitlist;