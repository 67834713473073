import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TotalDonorsAndOrgansTransplanted from "./TotalDonorsAndOrgansTransplanted";
import DescriptionOfDonorsRecovered from "./DescriptionOfDonorsRecovered";
import DeceasedDonorData from "./DeceasedDonorData";

function DonorData(props) {
    return (
        <Row>
            <Col xs={12}>
                <Row>
                    <Col xs={12} className="section">
                        <div className="outer-wrapper mt-0">
                            <div className="inner-wrapper">
                                <DeceasedDonorData
                                    data={props.donorData ? props.donorData.deathData : null}
                                    opoCode={props.opoCode ? props.opoCode : ""}
                                />
                            </div>
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="section">
                        <DescriptionOfDonorsRecovered
                            data={props.donorData ? props.donorData.descriptionOfDonorsRecovered : null}
                            opoCode={props.opoCode ? props.opoCode : ""}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="section">
                        <div className="outer-wrapper mt-0">
                            <div className="inner-wrapper">
                                <TotalDonorsAndOrgansTransplanted
                                     opoCode={props.opoCode ? props.opoCode : ""} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default DonorData;