import React, { Component } from 'react'
import ControlPanel from "./ControlPanel";
import mapboxgl from 'mapbox-gl';
import bbox from "@turf/bbox";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
var geoViewport = require('@mapbox/geo-viewport');
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
class DeathRateMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lng: -96,
            lat: 38,
            zoom: 3.8,
            hoveredCounty: null,
            map: null,
            hasResized: false
        };
    }

    componentDidMount() {
        let map = new mapboxgl.Map({
            container: this.mapContainer,
            style: 'mapbox://styles/srtr/ckrzc64jm3m7017qk9djum2yu',
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom
        });

        map.on('move', () => {
            this.setState({
                lng: map.getCenter().lng.toFixed(4),
                lat: map.getCenter().lat.toFixed(4),
                zoom: map.getZoom().toFixed(2)
            });
        });

        map.on('style.load', () => {
            const waiting = () => {
                if (!map.isStyleLoaded()) {
                    setTimeout(waiting, 100);
                } else {
                    if (this.props.opoData) {

                        map.addSource('opo', {
                            type: 'geojson',
                            data: this.props.opoData.feature,
                        });

                        map.addLayer({
                            id: 'opo-outline',
                            source: "opo",
                            type: 'line',
                            paint: {
                                "line-color": "#B22222",
                                "line-width": 2
                            }
                        });

                        map.on('mousemove', 'us-county-deathrate', (e) => {
                            map.getCanvas().style.cursor = "pointer";

                            const _hoveredCounty = e.features[0];
                            this.setState({
                                hoveredCounty: _hoveredCounty,
                                offsetX: e.point.x,
                                offsetY: e.point.y
                            });
                        });

                        map.on('mouseleave', 'us-county-deathrate', () => {
                            map.getCanvas().style.cursor = '';
                            this.setState({
                                hoveredCounty: null
                            });
                        });

                        this.updateMap();
                    }
                }
            };
            waiting();
        });


        map.addControl(new mapboxgl.NavigationControl());

        this.setState({
            map
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activeKey !== this.props.activeKey && !this.state.hasResized) {
            this.updateMap();
        }
    }

    updateMap() {
        this.state.map.resize();
        if (this.props.opoData && this.props.opoData.feature) {
            if (this.props.opoData.code.toLowerCase() === "walc") {
                try {
                    const { width, height } = this.state.map.getCanvas().getBoundingClientRect();
                    const viewport = geoViewport.viewport(
                        [-180, 50, -106, 72],
                        [width, height]
                    );
                    this.state.map.setCenter(viewport.center);
                    this.state.map.setZoom(2.6);
                }
                catch (err) {
                    console.log(err);
                }
            }
            else {
                try {
                    let bounds = bbox(this.props.opoData.feature);
                    this.state.map.fitBounds(bounds, { padding: 20 });
                }
                catch (err) {
                    console.log(err);
                }
            }
        }
        this.setState({
            hasResized: true
        });
    }

    render() {
        return (
            <Row>
                <Col xs={12} lg={4}>
                    {this.props.opoData &&
                        <ControlPanel
                            opoName={this.props.opoData.name}
                            type="deathRate"
                            hoveredCounty={this.state.hoveredCounty}
                            opoCode={this.props.opoData.code}
                            death={this.props.opoData.death}
                            deathRank={this.props.opoData.deathRank}
                            deathRate={this.props.opoData.deathRate}
                            deathRateRank={this.props.opoData.deathRateRank}
                        />
                    }
                </Col>
                <Col xs={12} lg={8}>
                    <div id="deathRateMap" className="opo-map">
                        <div style={{ height: "100%" }} ref={el => this.mapContainer = el} key="deathRateMap">
                        </div>

                    </div>
                </Col>
            </Row>
        );
    }
}

export default DeathRateMap;