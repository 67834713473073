export const demographicOptions = [
    {
        label: "Cause of Death",
        deceasedValues: [
            { label: "Deceased: Stroke", value: { center: "dcc_cstr_c", region: "dcc_cstr_r", us: "dcc_cstr_u", category: "Cause of Death" } },
            { label: "Deceased: MVA", value: { center: "dcc_cmva_c", region: "dcc_cmva_r", us: "dcc_cmva_u", category: "Cause of Death" } },
            { label: "Deceased: Other", value: { center: "dcc_coth_c", region: "dcc_coth_r", us: "dcc_coth_u", category: "Cause of Death" } }
        ]
    },
    {
        label: "Race",
        deceasedValues: [
            { label: "White", value: { center: "dcc_rw_c", region: "dcc_rw_r", us: "dcc_rw_u", category: "Race" } },
            { label: "African-American", value: { center: "dcc_rb_c", region: "dcc_rb_r", us: "dcc_rb_u", category: "Race" } },
            { label: "Hispanic/Latino", value: { center: "dcc_rh_c", region: "dcc_rh_r", us: "dcc_rh_u", category: "Race" } },
            { label: "Asian", value: { center: "dcc_ra_c", region: "dcc_ra_r", us: "dcc_ra_u", category: "Race" } },
            { label: "Other", value: { center: "dcc_ro_c", region: "dcc_ro_r", us: "dcc_ro_u", category: "Race" } },
            { label: "Unknown", value: { center: "dcc_ru_c", region: "dcc_ru_r", us: "dcc_ru_u", category: "Race" } }
        ],
        livingValues: [
            { label: "White", value: { center: "dcl_rw_c", region: "dcl_rw_r", us: "dcl_rw_u", category: "Race" } },
            { label: "African-American", value: { center: "dcl_rb_c", region: "dcl_rb_r", us: "dcl_rb_u", category: "Race" } },
            { label: "Hispanic/Latino", value: { center: "dcl_rh_c", region: "dcl_rh_r", us: "dcl_rh_u", category: "Race" } },
            { label: "Asian", value: { center: "dcl_ra_c", region: "dcl_ra_r", us: "dcl_ra_u", category: "Race" } },
            { label: "Other", value: { center: "dcl_ro_c", region: "dcl_ro_r", us: "dcl_ro_u", category: "Race" } },
            { label: "Unknown", value: { center: "dcl_ru_c", region: "dcl_ru_r", us: "dcl_ru_u", category: "Race" } }
        ]
    },
    {
        label: "Age",
        deceasedValues: [
            { label: "<2 years", value: { center: "dcc_a2_c", region: "dcc_a2_r", us: "dcc_a2_u", category: "Age" } },
            { label: "2-11 years", value: { center: "dcc_a10_c", region: "dcc_a10_r", us: "dcc_a10_u", category: "Age" } },
            { label: "12-17 years", value: { center: "dcc_a17_c", region: "dcc_a17_r", us: "dcc_a17_u", category: "Age" } },
            { label: "18-34 years", value: { center: "dcc_a34_c", region: "dcc_a34_r", us: "dcc_a34_u", category: "Age" } },
            { label: "35-49 years", value: { center: "dcc_a49_c", region: "dcc_a49_r", us: "dcc_a49_u", category: "Age" } },
            { label: "50-64 years", value: { center: "dcc_a64_c", region: "dcc_a64_r", us: "dcc_a64_u", category: "Age" } },
            { label: "65-69 years", value: { center: "dcc_a69_c", region: "dcc_a69_r", us: "dcc_a69_u", category: "Age" } },
            { label: "70+ years", value: { center: "dcc_a70p_c", region: "dcc_a70p_r", us: "dcc_a70p_u", category: "Age" } },
        ],
        livingValues: [
            { label: "<2 years", value: { center: "dcl_a2_c", region: "dcl_a2_r", us: "dcl_a2_u", category: "Age" } },
            { label: "2-11 years", value: { center: "dcl_a10_c", region: "dcl_a10_r", us: "dcl_a10_u", category: "Age" } },
            { label: "12-17 years", value: { center: "dcl_a17_c", region: "dcl_a17_r", us: "dcl_a17_u", category: "Age" } },
            { label: "18-34 years", value: { center: "dcl_a34_c", region: "dcl_a34_r", us: "dcl_a34_u", category: "Age" } },
            { label: "35-49 years", value: { center: "dcl_a49_c", region: "dcl_a49_r", us: "dcl_a49_u", category: "Age" } },
            { label: "50-64 years", value: { center: "dcl_a64_c", region: "dcl_a64_r", us: "dcl_a64_u", category: "Age" } },
            { label: "65-69 years", value: { center: "dcl_a69_c", region: "dcl_a69_r", us: "dcl_a69_u", category: "Age" } },
            { label: "70+ years", value: { center: "dcl_a70p_c", region: "dcl_a70p_r", us: "dcl_a70p_u", category: "Age" } },
        ]
    },
    {
        label: "Gender",
        deceasedValues: [
            { label: "Male", value: { center: "dcc_gm_c", region: "dcc_gm_r", us: "dcc_gm_u", category: "Gender" } },
            { label: "Female", value: { center: "dcc_gf_c", region: "dcc_gf_r", us: "dcc_gf_u", category: "Gender" } }
        ],
        livingValues: [
            { label: "Male", value: { center: "dcl_gm_c", region: "dcl_gm_r", us: "dcl_gm_u", category: "Gender" } },
            { label: "Female", value: { center: "dcl_gf_c", region: "dcl_gf_r", us: "dcl_gf_u", category: "Gender" } }
        ]
    },
    {
        label: "Blood Type",
        deceasedValues: [
            { label: "O", value: { center: "dcc_bo_c", region: "dcc_bo_r", us: "dcc_bo_u", category: "Blood Type" } },
            { label: "A", value: { center: "dcc_ba_c", region: "dcc_ba_r", us: "dcc_ba_u", category: "Blood Type" } },
            { label: "B", value: { center: "dcc_bb_c", region: "dcc_bb_r", us: "dcc_bb_u", category: "Blood Type" } },
            { label: "AB", value: { center: "dcc_bab_c", region: "dcc_bab_r", us: "dcc_bab_u", category: "Blood Type" } }
        ],
        livingValues: [
            { label: "O", value: { center: "dcl_bo_c", region: "dcl_bo_r", us: "dcl_bo_u", category: "Blood Type" } },
            { label: "A", value: { center: "dcl_ba_c", region: "dcl_ba_r", us: "dcl_ba_u", category: "Blood Type" } },
            { label: "B", value: { center: "dcl_bb_c", region: "dcl_bb_r", us: "dcl_bb_u", category: "Blood Type" } },
            { label: "AB", value: { center: "dcl_bab_c", region: "dcl_bab_r", us: "dcl_bab_u", category: "Blood Type" } }
        ]
    }
];

