import React, { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { brandColorArray, formatOneDecimal } from "../../../utilities/formatter";

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require("highcharts/highcharts-3d")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

function TransplantRecipientCharacteristicsChart(props) {
    let title = props.title;
    let subTitle = props.subTitle;
    if (props.selectedOption) {
        title = title + " - " + props.selectedOption.label;
    }

    const chartRef = useRef(null);
    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: "bar"
        },
        lang: {
            noData: `${props.centerCode} did not perform any transplants meeting <br />these criteria during the timeframe studied`,
        },

        credits: {
            enabled: false
        },
        title: {
            text: ""
        },
        xAxis: {
            labels: {
                style: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"
                }
            }
        },
        yAxis: {
            min: 0,
            startOnTick: false,
            max: 100,
            title: {
                text: "Percentage",
                style: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"
                }
            },
            labels: {
                style: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"
                }
            }
        },
        tooltip: {
            shared: true,
            valueDecimals: 2,
            borderWidth: 1,
            backgroundColor: "white",
            borderRadius: 10,
            borderColor: '#d2d2d2',
            shadow: true,
            useHTML: true,

            formatter: function () {
                let header = `<div style="padding-bottom:5px;font-family:Open Sans;font-size:16px;font-weight: 600;text-align:center">${this.x}</div>`;
                let body = "";
                this.points.forEach((point) =>
                    body += `<div style="padding: 0px 5px 5px 5px"><span style="font-family:Open Sans;font-size:14px;font-weight: 600;text-align:left">${point.series.name}</span>: <span style="font-family:Open Sans;font-size:14px;font-weight: 400;text-align:left">${formatOneDecimal(point.y)}%</span></div>`
                );

                return `<div class="tool-tip-container">${header}${body}</div>`;
            }
        },
        legend: {
            itemStyle: {
                fontFamily: "Open Sans",
                fontSize: "14px"

            }
        },
        labels: {
            useHTML: true,
            allowOverlap: true,
            style: {
                wordBreak: 'break-all',
                textOverflow: 'allow'
            }
        }
    });

    useEffect(() => {
        if (chartRef && chartRef.current && chartRef.current.chart && props.selectedOption) {
            let _selectedValues = props.type === "living" ? props.selectedOption.livingValues : props.selectedOption.deceasedValues;
            let centerData = [];
            let regionData = [];
            let usData = [];

            if (props.data) {
                _selectedValues.forEach((x, index) => {
                    centerData.push([x.label, props.data[x.value["center"]]]);
                    regionData.push([x.label, props.data[x.value["region"]]]);
                    usData.push([x.label, props.data[x.value["us"]]]);

                });
            }

            let categories = [];
            if (props.selectedOption) {
                if (props.type === "living") {
                    categories = props.selectedOption.livingValues.map(function (obj) {
                        return obj.label;
                    });
                }
                else {
                    categories = props.selectedOption.deceasedValues.map(function (obj) {
                        return obj.label;
                    });
                }
            }

            let _newOptions = {
                ...chartOptions,
                exporting: {
                    buttons: {
                        contextButton: {
                            menuItems: ['printChart', 'downloadPNG', 'downloadSVG', 'downloadPDF', 'downloadCSV']
                        },
                    },
                    title: {
                        text: `<div style="font-family:Open Sans;font-size:14px;font-weight: 700;text-align:center;">${title}</div><br /><div style="font-family:Open Sans;font-size:12px;font-weight: 400;text-align:center;">${subTitle}</div>`
                    },
                    chart: {
                        events: {
                            load: function () {
                                let width = 0;
                                let height = this.chartHeight - 50;
                                this.renderer.image('/srtr_logo.jpg', width, height, 50, 50)
                                    .add();
                            }
                        }
                    }
                },
                xAxis: {
                    text: props.selectedOption.label,
                    categories: categories
                },
                series: [{
                    name: props.centerCode,
                    data: centerData,
                    color: brandColorArray[1]
                }, {
                    name: "Region",
                    data: regionData,
                    color: brandColorArray[2]
                }, {
                    name: "U.S.",
                    data: usData,
                    color: brandColorArray[3]
                }]
            }

            chartRef.current.chart.redraw();
            setChartOptions(_newOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    return (
        <div className="transplant-recipients-chart">
            <Row >
                <Col xs={12} lg={12}>
                    <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOptions} oneToOne={true} containerProps={{ className: "highcharts-light" }}/>
                </Col>
            </Row>
        </div>
    );

}

export default TransplantRecipientCharacteristicsChart;