import React from 'react';

function ControlPanel(props) {
    const opoCode = props.opoCode;

    return (
        <div className="control-panel">
            <div className="title">
                {props.opoName}
            </div>
            <div>
             The map shows locations of programs that transplanted organs from this OPO ({opoCode}). 
             </div>
            <hr />
            <div>
                Use the organ drop down above to view what organs from this OPO went to transplant centers across the country.
            </div>           
        </div>
    );
}

export default ControlPanel;