import React, { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { brandColorArray, formatOneDecimal } from "../../../utilities/formatter";
import moment from "moment";

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);
function ObservedExpectedTransplantRates(props) {
    const chartRef = useRef(null);
    let subTitle = props.begDate && props.endDate ? `${moment(new Date(props.begDate)).format("MM/DD/YYYY")} - ${moment(new Date(props.endDate)).format("MM/DD/YYYY")}` : "";

    const [chartOptions, setChartOptions] = useState(
        {
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: ['printChart', 'downloadPNG', 'downloadSVG', 'downloadPDF', 'downloadCSV']
                    },
                },
                title: {
                    text: `<div style="font-family:Open Sans;font-size:14px;font-weight: 700;text-align:center;">${props.title}</div><br /><div style="font-family:Open Sans;font-size:12px;font-weight: 400;text-align:center;">${subTitle}</div>`
                },
                chart: {
                    events: {
                        load: function () {
                            let width = 0;
                            let height = this.chartHeight - 50;
                            this.renderer.image('/srtr_logo.jpg', width, height, 50, 50)
                                .add();
                        }
                    }
                }
            },
            lang: {
                noData: `${props.centerCode} did not perform any transplants meeting <br />these criteria during the timeframe studied`,
            },
            chart: {
                type: "column"
            },
            credits: {
                enabled: false
            },
            title: {
                text: `<div style="font-family:Open Sans;font-size:20px;font-weight: 400;text-align:center;">${props.title}</div>`
            },
            xAxis: {
                categories: [
                    "All",
                    "Adult",
                    "Pediatric"
                ],
                labels: {
                    style: {
                        fontFamily: "Open Sans",
                        fontSize: "14px"
                    }
                }
            },
            yAxis: {
                title: {
                    text: props.yAxisLabel,
                    style: {
                        fontFamily: "Open Sans",
                        fontSize: "14px"
                    }
                },
                labels: {
                    style: {
                        fontFamily: "Open Sans",
                        fontSize: "14px"
                    }
                }
            },

            legend: {
                itemStyle: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"

                }
            },
            labels: {
                useHTML: true,
                allowOverlap: true,
                style: {
                    wordBreak: 'break-all',
                    textOverflow: 'allow'
                }
            },
            tooltip: {
                borderWidth: 0,
                backgroundColor: '#FCFFC5',
                borderRadius: 10,
                shadow: true,
                useHTML: true,
                headerFormat: '<div style="font-family:Open Sans;font-size:14px;font-weight: 700;text-align:center;margin-bottom:10px">{point.key}</div>',
                pointFormat: '<div style="padding:0px 5px 5px 5px;font-family:Open Sans;font-size:14px;font-weight: 700;text-align:left"><span>{series.name}</span>: <span style="font-family:Open Sans;font-size:14px;font-weight: 600;text-align:left">{point.y:.1f}</span></div>',
                shared: false
            }
        }
    );


    useEffect(() => {
        let series = [];
        series.push({
            name: "Observed",
            color: brandColorArray[0],
            data: props.observedAll || props.observedAdult || props.observedPediatric ? [
                props.observedAll ? formatOneDecimal(props.observedAll * 100) : null,
                props.observedAdult ? formatOneDecimal(props.observedAdult * 100) : null,
                props.observedPediatric ? formatOneDecimal(props.observedPediatric * 100) : null
            ] : []
        });

        series.push(
            {
                name: "Expected",
                color: brandColorArray[3],
                data: props.expectedAll || props.expectedAdult || props.expectedPediatric ? [
                    props.expectedAll ? formatOneDecimal(props.expectedAll * 100) : null,
                    props.expectedAdult ? formatOneDecimal(props.expectedAdult * 100) : null,
                    props.expectedPediatric ? formatOneDecimal(props.expectedPediatric * 100) : null
                ] : []
            }
        );

        setChartOptions({
            ...chartOptions,
            series
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);
    return (
        <div className="observed-expected-transplant-rate-chart">
            <Row >
                <Col xs={12} lg={12}>
                    <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOptions} containerProps={{ className: "highcharts-light" }}/>
                </Col>
            </Row>
        </div>
    );
}

export default ObservedExpectedTransplantRates;