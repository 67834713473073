import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Table from "react-bootstrap/Table";
import { formatTwoDecimals, formatThousands, formatThousandsOneDecimal } from "../../utilities/formatter";
import { getTableOptions } from "./Options";

function OfferAcceptancesTable(props) {
    const [open, setOpen] = useState(false);
    let tableOptions = getTableOptions(props.option.name);
    return (
        <>
            <div className="collapse-container show-all-row mt-2">
                View {props.option.displayName} characteristics &nbsp;&nbsp;
                <Button
                    onClick={() => setOpen(!open)}
                    aria-controls="collapse-text"
                    aria-expanded={open}
                    className="btn-dark-blue min-98"
                >
                    {!open && "Show Data"}
                    {open && "Hide Data"}
                </Button>
            </div>
            <Collapse in={open}>
                <div id="collapse-text" className="collapse-inner">
                    <div className="table-responsive">
                        <Table striped className="overflow-auto offer-acceptances-table">
                            <thead>
                                <tr>
                                    <td className="border-bottom font-weight-bold">
                                        {props.option.displayName}
                                    </td>
                                    <th className="text-center border-bottom">
                                        {props.centerCode}
                                    </th>
                                    <th className="text-center border-bottom">
                                        OPO/DSA
                                    </th>
                                    <th className="text-center border-bottom">
                                        Region
                                    </th>
                                    <th className="text-center border-bottom">
                                        U.S.
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="padding-left-30">Number of Offers</td>
                                    <td className="text-center">{formatThousands(props.data[tableOptions["offers"] + "center"])}</td>
                                    <td className="text-center">{formatThousands(props.data[tableOptions["offers"] + "opo"])}</td>
                                    <td className="text-center">{formatThousands(props.data[tableOptions["offers"] + "region"])}</td>
                                    <td className="text-center">{formatThousands(props.data[tableOptions["offers"] + "nation"])}</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30">Number of Acceptances</td>
                                    <td className="text-center">{formatThousands(props.data[tableOptions["accepts"] + "center"])}</td>
                                    <td className="text-center">{formatThousands(props.data[tableOptions["accepts"] + "opo"])}</td>
                                    <td className="text-center">{formatThousands(props.data[tableOptions["accepts"] + "region"])}</td>
                                    <td className="text-center">{formatThousands(props.data[tableOptions["accepts"] + "nation"])}</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30">Expected Acceptances</td>
                                    <td className="text-center">{formatThousandsOneDecimal(props.data[tableOptions["expected"] + "center"])}</td>
                                    <td className="text-center">{formatThousandsOneDecimal(props.data[tableOptions["expected"] + "opo"])}</td>
                                    <td className="text-center">{formatThousandsOneDecimal(props.data[tableOptions["expected"] + "region"])}</td>
                                    <td className="text-center">{formatThousandsOneDecimal(props.data[tableOptions["expected"] + "nation"])}</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30">Offer Acceptance Ratio</td>
                                    <td className="text-center">{formatTwoDecimals(props.data[tableOptions["ratio"] + "center"])}</td>
                                    <td className="text-center">{formatTwoDecimals(props.data[tableOptions["ratio"] + "opo"])}</td>
                                    <td className="text-center">{formatTwoDecimals(props.data[tableOptions["ratio"] + "region"])}</td>
                                    <td className="text-center">{formatTwoDecimals(props.data[tableOptions["ratio"] + "nation"])}</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30 pl-2">95% Credible Interval</td>
                                    <td className="text-center">[{formatTwoDecimals(props.data[tableOptions["ratiolow"] + "center"])}, {formatTwoDecimals(props.data[tableOptions["ratiohigh"] + "center"])}]</td>
                                    <td className="text-center">--</td>
                                    <td className="text-center">--</td>
                                    <td className="text-center">--</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Collapse>
        </>
    );
}

export default OfferAcceptancesTable;