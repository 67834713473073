import React from "react";
import ProgressBar from 'react-bootstrap/ProgressBar'
import HeartIcon from "../../../../utilities/organs/HeartIcon";
import KidneyIcon from "../../../../utilities/organs/KidneyIcon";
import LiverIcon from "../../../../utilities/organs/LiverIcon";
import LungIcon from "../../../../utilities/organs/LungIcon";
import PancreasIcon from "../../../../utilities/organs/PancreasIcon";
import IntestineIcon from "../../../../utilities/organs/IntestineIcon";
import { formatOneDecimal, formatTwoDecimals } from "../../../../utilities/formatter";
function ObservedExpectedOrganRateChart(props) {
    let organIcon = "";
    let observedExpected = "--";
    let max = 95;

    if (props.observedData != null && props.expectedData !== null) {
        if (!isNaN(props.expectedData) && props.expectedData > 0) {
            observedExpected = formatTwoDecimals(props.observedData / props.expectedData);
        }

        max = props.observedData > props.expectedData ? props.observedData : props.expectedData;
    }

    if (props.organ) {
        if (props.organ === "heart") {
            organIcon = <HeartIcon showHover={true} />;
        }
        else if (props.organ === "kidney") {
            organIcon = <KidneyIcon showHover={true} />;
        }
        else if (props.organ === "liver") {
            organIcon = <LiverIcon showHover={true} />;
        }
        else if (props.organ === "lung") {
            organIcon = <LungIcon showHover={true} />;
        }
        else if (props.organ === "pancreas") {
            organIcon = <PancreasIcon showHover={true} />;
        }
        else if (props.organ === "intestine") {
            organIcon = <IntestineIcon showHover={true} />;
        }
    }

    return (
        <>
            <div className="organ-rate">
                <div className="title">
                    {props.organ}
                </div>
                <div className="chart">
                    {organIcon}
                </div>
                <div className="value strong">
                    O/E = {observedExpected}
                    {props.low && props.high &&
                        <div className="low-high">
                            ({formatTwoDecimals(props.low)}-{formatTwoDecimals(props.high)})
                    </div>
                    }
                </div>
            </div>
            <div className="observed-expected">
                <div className="type">
                    Observed: <span className="strong">{formatOneDecimal(props.observedData)}</span>
                </div>
                <div className={`observed-bar ` + props.organ}>
                    <ProgressBar now={props.observedData} max={max} label={`Observed: ${props.observedData}`} srOnly />
                </div>
                <div className="type pt-2">
                    Expected: <span className="strong">{formatOneDecimal(props.expectedData)}</span>
                </div>
                <div className={`observed-bar expected ` + props.organ}>
                    <ProgressBar now={props.expectedData} max={max} label={`Expected: ${props.expectedData}`} srOnly />
                </div>
            </div>
        </>
    );
}

export default ObservedExpectedOrganRateChart;