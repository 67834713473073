import React, { useState, useEffect } from "react"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TransplantRecipientCharacteristics from "./transplant-recipient-characteristics/TransplantRecipientCharacteristics";
import TransplantDonorCharacteristics from "./transplant-donor-characteristics/TransplantDonorCharacteristics";
import TransplantCharacteristics from "./transplant-characteristics/TransplantCharacteristics";

function TransplantProcedures(props) {
    const Host = process.env.REACT_APP_API_HOST;
    const organData = props.organ + "Data";
    const [recipientsData, setRecipientsData] = useState(null);
    const [donorsData, setDonorsData] = useState(null);
    const [transplantsData, setTransplantsData] = useState(null);
    
    useEffect(() => {
        if (props.centerCode && props.centerType) {
            //Get the center's detail data
            fetch(`${Host}/psr/${props.centerCode}${props.centerType}/transplants`)
                .then(response => response.json())
                .then(response => {
                    setRecipientsData(response.recipients);
                    setDonorsData(response.donors);
                    setTransplantsData(response.transplants);
                }).catch((error) => {
                    console.error('Error:', error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row className="transplant-procedures">
            <Col xs={12}>
                <Row>
                    <Col xs={12} className="section">
                        {recipientsData &&
                            <TransplantRecipientCharacteristics
                                livingData={recipientsData.livingDonorRecipients[organData]}
                                deceasedData={recipientsData.deceasedDonorRecipients[organData]}
                                centerCode={props.centerCode}
                                organ={props.organ}
                                isLoading={false}
                            />
                        }
                        {!recipientsData &&
                            <TransplantRecipientCharacteristics
                            isLoading={true} />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="section">
                        <div className="outer-wrapper">
                            <div className="inner-wrapper">
                                {donorsData &&
                                    <TransplantDonorCharacteristics
                                        livingData={donorsData.livingDonors[organData]}
                                        deceasedData={donorsData.deceasedDonors[organData]}
                                        centerCode={props.centerCode}
                                        organ={props.organ}
                                        isLoading={false}
                                    />
                                }
                                {!donorsData &&
                                    <TransplantDonorCharacteristics
                                        isLoading={true}
                                    />
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="section">
                        {transplantsData &&
                            <TransplantCharacteristics
                                livingData={transplantsData.livingTransplants[organData]}
                                deceasedData={transplantsData.deceasedTransplants[organData]}
                                centerCode={props.centerCode}
                                organ={props.organ}
                                isLoading={false}
                            />
                        }
                        {!transplantsData &&
                            <TransplantCharacteristics
                              isLoading={true}
                            />
                        }
                    </Col>
                </Row>

            </Col>
        </Row>
    );
}

export default TransplantProcedures;