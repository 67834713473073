import React from "react";

function NoData(props) {
    if (props.centerCode) {
        return (
            <div className="no-data">
                {props.centerCode.toUpperCase()} did not perform any transplants meeting <br />these criteria during the timeframe studied
            </div>
        )
    }

    return (
        <div className="no-data">
            No Data Returned For This Table
        </div>
    );
}

export default NoData;