import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
//import moment from "moment";
import TransplantRatesTable from "./transplant-rate-tables/TransplantRatesTable";
import DeceasedDonorsTransplantRatesTable from "./transplant-rate-tables/DeceasedDonorsTransplantRatesTable";
import MortalityRatesTable from "./transplant-rate-tables/MortalityRatesTable";
import SurvivalRatesTable from "./transplant-rate-tables/SurvivalRatesTable";
import ObservedExpectedTransplantRates from "./ObservedExpectedTransplantRates";
import ObservedAdultPediatricTransplantRates from "./ObservedAdultPediatricTransplantRates";
import RatioBoxPlotChart from "../../common-charts/RatioBoxPlotChart";
import TransplantRatioScatterPlotChart from "./TransplantRatioScatterPlotChart";
import moment from "moment";

function TransplantRates(props) {
  let subTitle = "No data reported for this organ";
  const [activeKey, setActiveKey] = useState(null);

  if (props.data.all) {
    subTitle = `${moment(new Date(props.data.all.begDate)).format(
      "MM/DD/YYYY"
    )} - ${moment(new Date(props.data.all.endDate)).format("MM/DD/YYYY")}`;
  }

  useEffect(() => {
    if (props.organ === "ki" || props.organ === "li") {
      setActiveKey("transplantRates");
    } else {
      setActiveKey("deceasedDonors");
    }
  }, [props]);

  const handleSelect = (key) => {
    setActiveKey(key);
  };

  return (
    <div className="waitlist-transplant-rates-wrapper section">
      <Tabs activeKey={activeKey} unmountOnExit={true} onSelect={handleSelect}>
        <Tab
          eventKey="transplantRates"
          title="Transplant Rates"
          tabClassName={
            props.organ !== "ki" && props.organ !== "li" ? "d-none" : ""
          }
          className="margin-right"
        >
          <Row>
            <Col xs={12} className="section">
              <h1 className="section-title">Transplant Rates</h1>
              <div className="sub-title">{subTitle}</div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={12} lg={6}>
                  <ObservedExpectedTransplantRates
                    centerCode={props.centerCode}
                    title="Observed and expected transplant rates"
                    observedAll={
                      props.data.all ? props.data.all.tmr_txr_c : null
                    }
                    observedAdult={
                      props.data.adult ? props.data.adult.tmr_ad_txr_c : null
                    }
                    observedPediatric={
                      props.data.pediatric
                        ? props.data.pediatric.tmr_p0_txr_c
                        : null
                    }
                    expectedAll={
                      props.data.all ? props.data.all.tmr_txer_c : null
                    }
                    expectedAdult={
                      props.data.adult ? props.data.adult.tmr_ad_txer_c : null
                    }
                    expectedPediatric={
                      props.data.pediatric
                        ? props.data.pediatric.tmr_p0_txer_c
                        : null
                    }
                    begDate={props.data.all ? props.data.all.begDate : null}
                    endDate={props.data.all ? props.data.all.endDate : null}
                    yAxisLabel="Rate per 100 Person Years"
                  />
                </Col>
                <Col xs={12} lg={6}>
                  <ObservedAdultPediatricTransplantRates
                    centerCode={props.centerCode}
                    title="Observed adult (18+) and pediatric (<18) transplant rates"
                    centerAdult={
                      props.data.adult ? props.data.adult.tmr_ad_txr_c : null
                    }
                    centerPediatric={
                      props.data.pediatric
                        ? props.data.pediatric.tmr_p0_txr_c
                        : null
                    }
                    opoAdult={
                      props.data.adult ? props.data.adult.tmr_ad_txr_o : null
                    }
                    opoPediatric={
                      props.data.pediatric
                        ? props.data.pediatric.tmr_p0_txr_o
                        : null
                    }
                    regionAdult={
                      props.data.adult ? props.data.adult.tmr_ad_txr_r : null
                    }
                    regionPediatric={
                      props.data.pediatric
                        ? props.data.pediatric.tmr_p0_txr_r
                        : null
                    }
                    usAdult={
                      props.data.adult ? props.data.adult.tmr_ad_txr_u : null
                    }
                    usPediatric={
                      props.data.pediatric
                        ? props.data.pediatric.tmr_p0_txr_u
                        : null
                    }
                    begDate={props.data.all ? props.data.all.begDate : null}
                    endDate={props.data.all ? props.data.all.endDate : null}
                    yAxisLabel="Rate per 100 Person Years"
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={6} className="pt-4">
              <RatioBoxPlotChart
                centerCode={props.centerCode}
                ratio={
                  props.data.transplantRateRatio
                    ? props.data.transplantRateRatio.tmr_txrr_c
                    : null
                }
                ratioLow={
                  props.data.transplantRateRatio
                    ? props.data.transplantRateRatio.tmr_txcredlo_c
                    : null
                }
                ratioHigh={
                  props.data.transplantRateRatio
                    ? props.data.transplantRateRatio.tmr_txcredhi_c
                    : null
                }
                title="Transplant rate ratio estimate"
                yAxisLabel="Estimated Transplant Rate Ratio"
                ratioLabel="Rate Ratio"
              />
            </Col>
            <Col xs={12} lg={6}>
              <TransplantRatioScatterPlotChart
                otherCentersData={
                  props.data.transplantRateOthers
                    ? props.data.transplantRateOthers
                    : []
                }
                rate="tmr_txrr_c"
                programVolume="tmr_txpy_c"
                ratioLabel="Rate Ratio"
                centerRate={
                  props.data.transplantRateRatio
                    ? props.data.transplantRateRatio.tmr_txrr_c
                    : null
                }
                centerProgramVolume={
                  props.data.all ? props.data.all.tmr_txpy_c : null
                }
                centerCode={props.centerCode}
                title="Transplant rate ratio estimate"
                yAxisLabel="Estimated Transplant Rate Ratio"
                xAxisLabel="Person Years"
              />
            </Col>
            <Col xs={12}>
              <TransplantRatesTable
                data={props.data}
                centerCode={props.centerCode}
              />
            </Col>
          </Row>
        </Tab>
        <Tab
          eventKey="deceasedDonors"
          title="Deceased Donor Transplant Rates"
          className="margin-right"
        >
          <Row>
            <Col xs={12} className="section">
              <h1 className="section-title">Deceased Donor Transplant Rates</h1>
              <div className="sub-title">{subTitle}</div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={12} lg={6}>
                  <ObservedExpectedTransplantRates
                    centerCode={props.centerCode}
                    title="Observed and expected deceased donor transplant rates"
                    observedAll={
                      props.data.all ? props.data.all.tmr_cadtxr_c : null
                    }
                    observedAdult={
                      props.data.adult ? props.data.adult.tmr_ad_cadtxr_c : null
                    }
                    observedPediatric={
                      props.data.pediatric
                        ? props.data.pediatric.tmr_p0_cadtxr_c
                        : null
                    }
                    expectedAll={
                      props.data.all ? props.data.all.tmr_cadtxer_c : null
                    }
                    expectedAdult={
                      props.data.adult
                        ? props.data.adult.tmr_ad_cadtxer_c
                        : null
                    }
                    expectedPediatric={
                      props.data.pediatric
                        ? props.data.pediatric.tmr_p0_cadtxer_c
                        : null
                    }
                    begDate={props.data.all ? props.data.all.begDate : null}
                    endDate={props.data.all ? props.data.all.endDate : null}
                    yAxisLabel="Rate per 100 Person Years"
                  />
                </Col>
                <Col xs={12} lg={6}>
                  <ObservedAdultPediatricTransplantRates
                    centerCode={props.centerCode}
                    title="Observed adult (18+) and pediatric (<18) deceased donor transplant rates"
                    centerAdult={
                      props.data.adult ? props.data.adult.tmr_ad_cadtxr_c : null
                    }
                    centerPediatric={
                      props.data.pediatric
                        ? props.data.pediatric.tmr_p0_cadtxr_c
                        : null
                    }
                    opoAdult={
                      props.data.adult ? props.data.adult.tmr_ad_cadtxr_o : null
                    }
                    opoPediatric={
                      props.data.pediatric
                        ? props.data.pediatric.tmr_p0_cadtxr_o
                        : null
                    }
                    regionAdult={
                      props.data.adult ? props.data.adult.tmr_ad_cadtxr_r : null
                    }
                    regionPediatric={
                      props.data.pediatric
                        ? props.data.pediatric.tmr_p0_cadtxr_r
                        : null
                    }
                    usAdult={
                      props.data.adult ? props.data.adult.tmr_ad_cadtxr_u : null
                    }
                    usPediatric={
                      props.data.pediatric
                        ? props.data.pediatric.tmr_p0_cadtxr_u
                        : null
                    }
                    begDate={props.data.all ? props.data.all.begDate : null}
                    endDate={props.data.all ? props.data.all.endDate : null}
                    yAxisLabel="Rate per 100 Person Years"
                  />
                </Col>
              </Row>
            </Col>
            {props.organ !== "hl" && (
              <>
                <Col xs={12} lg={6} className="pt-4">
                  <RatioBoxPlotChart
                    centerCode={props.centerCode}
                    ratio={
                      props.data.transplantRateRatio
                        ? props.data.transplantRateRatio.tmr_cadtxrr_c
                        : null
                    }
                    ratioLow={
                      props.data.transplantRateRatio
                        ? props.data.transplantRateRatio.tmr_cadtxcredlo_c
                        : null
                    }
                    ratioHigh={
                      props.data.transplantRateRatio
                        ? props.data.transplantRateRatio.tmr_cadtxcredhi_c
                        : null
                    }
                    title="Deceased donor transplant rate ratio estimate"
                    yAxisLabel="Estimated Transplant Rate Ratio"
                    ratioLabel="Rate Ratio"
                  />
                </Col>
                <Col xs={12} lg={6}>
                  <TransplantRatioScatterPlotChart
                    otherCentersData={
                      props.data.transplantRateOthers
                        ? props.data.transplantRateOthers
                        : null
                    }
                    rate="tmr_cadtxrr_c"
                    programVolume="tmr_cadtxpy_c"
                    ratioLabel="Rate Ratio"
                    centerRate={
                      props.data.transplantRateRatio
                        ? props.data.transplantRateRatio.tmr_cadtxrr_c
                        : null
                    }
                    centerProgramVolume={
                      props.data.all ? props.data.all.tmr_cadtxpy_c : null
                    }
                    centerCode={props.centerCode}
                    title="Deceased donor transplant rate ratio estimate"
                    yAxisLabel="Estimated Transplant Rate Ratio"
                    xAxisLabel="Person Years"
                  />
                </Col>

                <Col>
                  <DeceasedDonorsTransplantRatesTable
                    data={props.data}
                    centerCode={props.centerCode}
                  />
                </Col>
              </>
            )}
          </Row>
        </Tab>
        <Tab
          eventKey="mortalityRates"
          title="Pre-transplant Mortality Rates"
          className="margin-right"
        >
          <Row>
            <Col xs={12} className="section">
              <h1 className="section-title">Pre-transplant Mortality Rates</h1>
              <div className="sub-title">{subTitle}</div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={12} lg={6}>
                  <ObservedExpectedTransplantRates
                    centerCode={props.centerCode}
                    title="Observed and expected pre-transplant mortality rates"
                    observedAll={
                      props.data.all ? props.data.all.tmr_dthr_c : null
                    }
                    observedAdult={
                      props.data.adult ? props.data.adult.tmr_ad_dthr_c : null
                    }
                    observedPediatric={
                      props.data.pediatric
                        ? props.data.pediatric.tmr_p0_dthr_c
                        : null
                    }
                    expectedAll={
                      props.data.all ? props.data.all.tmr_dther_c : null
                    }
                    expectedAdult={
                      props.data.adult ? props.data.adult.tmr_ad_dther_c : null
                    }
                    expectedPediatric={
                      props.data.pediatric
                        ? props.data.pediatric.tmr_p0_dther_c
                        : null
                    }
                    begDate={props.data.all ? props.data.all.begDate : null}
                    endDate={props.data.all ? props.data.all.endDate : null}
                    yAxisLabel="Rate per 100 Person Years"
                  />
                </Col>
                <Col xs={12} lg={6}>
                  <ObservedAdultPediatricTransplantRates
                    centerCode={props.centerCode}
                    title="Observed adult (18+) and pediatric (<18) pre-transplant mortality rates"
                    centerAdult={
                      props.data.adult ? props.data.adult.tmr_ad_dthr_c : null
                    }
                    centerPediatric={
                      props.data.pediatric
                        ? props.data.pediatric.tmr_p0_dthr_c
                        : null
                    }
                    opoAdult={
                      props.data.adult ? props.data.adult.tmr_ad_dthr_o : null
                    }
                    opoPediatric={
                      props.data.pediatric
                        ? props.data.pediatric.tmr_p0_dthr_o
                        : null
                    }
                    regionAdult={
                      props.data.adult ? props.data.adult.tmr_ad_dthr_r : null
                    }
                    regionPediatric={
                      props.data.pediatric
                        ? props.data.pediatric.tmr_p0_dthr_r
                        : null
                    }
                    usAdult={
                      props.data.adult ? props.data.adult.tmr_ad_dthr_u : null
                    }
                    usPediatric={
                      props.data.pediatric
                        ? props.data.pediatric.tmr_p0_dthr_u
                        : null
                    }
                    begDate={props.data.all ? props.data.all.begDate : null}
                    endDate={props.data.all ? props.data.all.endDate : null}
                    yAxisLabel="Rate per 100 Person Years"
                  />
                </Col>
              </Row>
            </Col>
            {props.organ !== "hl" && (
              <>
                <Col xs={12} lg={6} className="pt-4">
                  <RatioBoxPlotChart
                    centerCode={props.centerCode}
                    ratio={
                      props.data.transplantRateRatio
                        ? props.data.transplantRateRatio.tmr_dthrr_c
                        : null
                    }
                    ratioLow={
                      props.data.transplantRateRatio
                        ? props.data.transplantRateRatio.tmr_dthcredlo_c
                        : null
                    }
                    ratioHigh={
                      props.data.transplantRateRatio
                        ? props.data.transplantRateRatio.tmr_dthcredhi_c
                        : null
                    }
                    title="Pre-transplant mortality rate ratio estimate"
                    yAxisLabel="Estimated Mortality Rate Ratio"
                    ratioLabel="Rate Ratio"
                  />
                </Col>
                <Col xs={12} lg={6}>
                  <TransplantRatioScatterPlotChart
                    otherCentersData={
                      props.data.transplantRateOthers
                        ? props.data.transplantRateOthers
                        : []
                    }
                    rate="tmr_dthrr_c"
                    programVolume="tmr_dthpy_c"
                    ratioLabel="Rate Ratio"
                    centerRate={
                      props.data.transplantRateRatio
                        ? props.data.transplantRateRatio.tmr_dthrr_c
                        : null
                    }
                    centerProgramVolume={
                      props.data.all ? props.data.all.tmr_dthpy_c : null
                    }
                    centerCode={props.centerCode}
                    title="Pre-transplant mortality rate ratio estimate"
                    yAxisLabel="Estimated Mortality Rate Ratio"
                    xAxisLabel="Person Years"
                  />
                </Col>
                <Col>
                  <MortalityRatesTable
                    data={props.data}
                    centerCode={props.centerCode}
                  />
                </Col>
              </>
            )}
          </Row>
        </Tab>
        {props.organ !== "hl" && (
          <Tab
            eventKey="survivalFromListing"
            title="Overall Survival From Listing"
            className="margin-right"
          >
            <Row>
              <Col xs={12} className="section">
                <h1 className="section-title">Overall Survival From Listing</h1>
                <div className="sub-title">{subTitle}</div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Row>
                  <Col xs={12} lg={6}>
                    <ObservedExpectedTransplantRates
                      centerCode={props.centerCode}
                      title={`${
                        props.organ === "lu" ? "*" : ""
                      } Observed and expected overall mortality rates after listing`}
                      observedAll={
                        props.data.survivalFromListing
                          ? props.data.survivalFromListing.sfl_all_obs_rate_c
                          : null
                      }
                      observedAdult={
                        props.data.survivalFromListing
                          ? props.data.survivalFromListing.sfl_ad_obs_rate_c
                          : null
                      }
                      observedPediatric={
                        props.data.survivalFromListing
                          ? props.data.survivalFromListing.sfl_p0_obs_rate_c
                          : null
                      }
                      expectedAll={
                        props.data.survivalFromListing
                          ? props.data.survivalFromListing.sfl_all_exp_rate_c
                          : null
                      }
                      expectedAdult={
                        props.data.survivalFromListing
                          ? props.data.survivalFromListing.sfl_ad_exp_rate_c
                          : null
                      }
                      expectedPediatric={
                        props.data.survivalFromListing
                          ? props.data.survivalFromListing.sfl_p0_exp_rate_c
                          : null
                      }
                      begDate={
                        props.data.survivalFromListing
                          ? props.data.survivalFromListing.begDate
                          : null
                      }
                      endDate={
                        props.data.survivalFromListing
                          ? props.data.survivalFromListing.endDate
                          : null
                      }
                      yAxisLabel="Rate per 100 Person Years"
                    />
                  </Col>
                  <Col xs={12} lg={6}>
                    <ObservedAdultPediatricTransplantRates
                      centerCode={props.centerCode}
                      title="Observed adult (18+) and pediatric (<18) overall mortality rates after listing"
                      centerAdult={
                        props.data.survivalFromListing
                          ? props.data.survivalFromListing.sfl_ad_obs_rate_c
                          : null
                      }
                      centerPediatric={
                        props.data.survivalFromListing
                          ? props.data.survivalFromListing.sfl_p0_obs_rate_c
                          : null
                      }
                      opoAdult={
                        props.data.survivalFromListing
                          ? props.data.survivalFromListing.sfl_ad_obs_rate_o
                          : null
                      }
                      opoPediatric={
                        props.data.survivalFromListing
                          ? props.data.survivalFromListing.sfl_p0_obs_rate_o
                          : null
                      }
                      regionAdult={
                        props.data.survivalFromListing
                          ? props.data.survivalFromListing.sfl_ad_obs_rate_r
                          : null
                      }
                      regionPediatric={
                        props.data.survivalFromListing
                          ? props.data.survivalFromListing.sfl_p0_obs_rate_r
                          : null
                      }
                      usAdult={
                        props.data.survivalFromListing
                          ? props.data.survivalFromListing.sfl_ad_obs_rate_u
                          : null
                      }
                      usPediatric={
                        props.data.survivalFromListing
                          ? props.data.survivalFromListing.sfl_p0_obs_rate_u
                          : null
                      }
                      begDate={
                        props.data.survivalFromListing
                          ? props.data.survivalFromListing.begDate
                          : null
                      }
                      endDate={
                        props.data.survivalFromListing
                          ? props.data.survivalFromListing.endDate
                          : null
                      }
                      yAxisLabel="Rate per 100 Person Years"
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={6} className="pt-4">
                <RatioBoxPlotChart
                  centerCode={props.centerCode}
                  ratio={
                    props.data.survivalFromListing
                      ? props.data.survivalFromListing.sfl_all_hr_c
                      : null
                  }
                  ratioLow={
                    props.data.survivalFromListing
                      ? props.data.survivalFromListing.sfl_all_hr_lb_c
                      : null
                  }
                  ratioHigh={
                    props.data.survivalFromListing
                      ? props.data.survivalFromListing.sfl_all_hr_ub_c
                      : null
                  }
                  title={`${
                    props.organ === "lu" ? "*" : ""
                  } Overall mortality after listing rate ratio estimate`}
                  yAxisLabel="Estimated Mortality Rate Ratio"
                  ratioLabel="Rate Ratio"
                />
              </Col>
              <Col xs={12} lg={6}>
                <TransplantRatioScatterPlotChart
                  otherCentersData={
                    props.data.transplantRateOthers
                      ? props.data.transplantRateOthers
                      : []
                  }
                  rate="sfl_all_hr_c"
                  programVolume="sfl_all_py_c"
                  ratioLabel="Rate Ratio"
                  centerRate={
                    props.data.survivalFromListing
                      ? props.data.survivalFromListing.sfl_all_hr_c
                      : null
                  }
                  centerProgramVolume={
                    props.data.survivalFromListing
                      ? props.data.survivalFromListing.sfl_all_py_c
                      : null
                  }
                  centerCode={props.centerCode}
                  title={`${
                    props.organ === "lu" ? "*" : ""
                  } Overall mortality after listing rate ratio estimate`}
                  yAxisLabel="Estimated Mortality Rate Ratio"
                  xAxisLabel="Person Years"
                />
              </Col>
              {props.organ === "lu" && (
                <Col xs={12}>
                  <div className="lung-correction">
                    *Lung risk-adjustment models no longer include the pCO2
                    variable captured on the TCR form. Visit our{" "}
                    <a
                      href="https://www.srtr.org/news-media/news/news-items/news/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      News page
                    </a>{" "}
                    for more details
                  </div>
                </Col>
              )}
              <Col>
                <SurvivalRatesTable
                  data={props.data.survivalFromListing}
                  centerCode={props.centerCode}
                />
              </Col>
            </Row>
          </Tab>
        )}
      </Tabs>
    </div>
  );
}

export default TransplantRates;
