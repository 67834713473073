import React from "react";
import {formatOneDecimal, formatThousands} from "../../../utilities/formatter";
function PeakPRA(props){
    if (props.organ === "ki" || props.organ === "kp") {
        return (
            <>
                <tr>
                    <td colSpan="11" className="text-left font-weight-bold">Peak PRA/CPRA</td>
                </tr>
                <tr>
                    <td className="padding-left-30">0-9%</td>
                    <td className="text-center">{formatThousands(parseFloat(props.data.tpc_pra1_nc))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra1_pct1))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra1_pct2))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra1_pct3))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra1_pct4))}</td>
                    <td className="text-center">{formatThousands(parseFloat(props.data.tpc_pra1_nu))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra1_put1))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra1_put2))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra1_put3))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra1_put4))}</td>
                </tr>
                <tr>
                    <td className="padding-left-30">10-79%</td>
                    <td className="text-center">{formatThousands(parseFloat(props.data.tpc_pra2_nc))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra2_pct1))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra2_pct2))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra2_pct3))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra2_pct4))}</td>
                    <td className="text-center">{formatThousands(parseFloat(props.data.tpc_pra2_nu))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra2_put1))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra2_put2))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra2_put3))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra2_put4))}</td>
                </tr>
                <tr>
                    <td className="padding-left-30">80+%</td>
                    <td className="text-center">{formatThousands(parseFloat(props.data.tpc_pra3_nc))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra3_pct1))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra3_pct2))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra3_pct3))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra3_pct4))}</td>
                    <td className="text-center">{formatThousands(parseFloat(props.data.tpc_pra3_nu))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra3_put1))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra3_put2))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra3_put3))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra3_put4))}</td>
                </tr>
                <tr>
                    <td className="padding-left-30">Unknown</td>
                    <td className="text-center">{formatThousands(parseFloat(props.data.tpc_pra4_nc))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra4_pct1))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra4_pct2))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra4_pct3))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra4_pct4))}</td>
                    <td className="text-center">{formatThousands(parseFloat(props.data.tpc_pra4_nu))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra4_put1))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra4_put2))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra4_put3))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_pra4_put4))}</td>
                </tr>
            </>
        );
    }

    return null;
}

export default PeakPRA;