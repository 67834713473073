import React from "react";
import Select from "react-select";
function SelectOPO(props) {
  const uniqueId = `select-opo${Math.random()}`;
  const selectedOPOCode =
    props.selectedOPOCode == null ? "" : props.selectedOPOCode;
  const opoOptions = [
    { label: "ALOB - Legacy of Hope", value: "ALOB|Alabama|AL|Georgia|GA" },
    {
      label: "AROR - Arkansas Regional Organ Recovery Agency",
      value: "AROR|Arkansas|AR",
    },
    { label: "AZOB - Donor Network of Arizona", value: "AZOB|Arizona|AZ" },
    {
      label: "CADN - Donor Network West",
      value: "CADN|California|CA|Nevada|NV",
    },
    { label: "CAGS - Sierra Donor Services", value: "CAGS|California|CA" },
    { label: "CAOP - OneLegacy", value: "CAOP|California|CA" },
    {
      label: "CASD - Lifesharing - A Donate Life Organization",
      value: "CASD|California|CA",
    },
    { label: "CORS - Donor Alliance", value: "CORS|Colorado|CO|Wyoming|WY" },
    { label: "FLFH - OurLegacy", value: "FLFH|Florida|FL" },
    {
      label: "FLMP - Life Alliance Organ Recovery Agency",
      value: "FLMP|Florida|FL",
    },
    {
      label: "FLUF - LifeQuest Organ Recovery Services",
      value: "FLUF|Florida|FL",
    },
    { label: "FLWC - LifeLink of Florida", value: "FLWC|Florida|FL" },
    {
      label: "GALL - LifeLink of Georgia",
      value: "GALL|Georgia|GA|South Carolina|SC",
    },
    { label: "HIOP - Legacy of Life Hawaii", value: "HIOP|Hawaii|HI" },
    { label: "IAOP - Iowa Donor Network", value: "IAOP|Iowa|IA|Nebraska|NE" },
    {
      label: "ILIP - Gift of Hope Organ & Tissue Donor Network",
      value: "ILIP|Illinois|IL|Indiana|IN|Iowa|IA",
    },
    {
      label: "INOP - Indiana Donor Network",
      value: "INOP|Indiana|IN|Kentucky|KY",
    },
    {
      label: "KYDA - Kentucky Organ Donor Affiliates",
      value: "KYDA|Indiana|IN|Kentucky|KY|Ohio|OH|West Virginia|WV",
    },
    {
      label: "LAOP - Louisiana Organ Procurement Agency",
      value: "LAOP|Louisiana|LA",
    },
    {
      label: "MAOB - New England Organ Bank",
      value:
        "MAOB|Connecticut|CT|Maine|ME|Massachusetts|MA|New Hampshire|NH|Rhode Island|RI|Vermont|VT",
    },
    {
      label: "MDPC - Infinite Legacy",
      value:
        "MDPC|Maryland|MD|District of Columbia|DC|Virginia|VA|West Virginia|WV",
    },
    { label: "MIOP - Gift of Life Michigan", value: "MIOP|Michigan|MI" },
    {
      label: "MNOP - LifeSource Upper Midwest Organ Procurement Organization",
      value: "MNOP|Minnesota|MN|North Dakota|ND|South Dakota|SD|Wisconsin|WI",
    },
    {
      label: "MOMA - Mid-America Transplant Services",
      value: "MOMA|Arkansas|AR|Illinois|IL|Missouri|MO",
    },
    {
      label: "MSOP - Mississippi Organ Recovery Agency",
      value: "MSOP|Mississippi|MS",
    },
    {
      label: "MWOB - Midwest Transplant Network",
      value: "MWOB|Kansas|KS|Missouri|MO",
    },
    {
      label: "NCCM - LifeShare Carolinas",
      value: "NCCM|North Carolina|NC|South Carolina|SC",
    },
    {
      label: "NCNC - HonorBridge",
      value: "NCNC|North Carolina|NC|Virginia|VA",
    },
    { label: "NEOR - Live On Nebraska", value: "NEOR|Iowa|IA|Nebraska|NE" },
    {
      label: "NJTO - New Jersey Organ and Tissue Sharing Network OPO",
      value: "NJTO|New Jersey|NJ",
    },
    { label: "NMOP - New Mexico Donor Services", value: "NMOP|New Mexico|NM" },
    {
      label: "NVLV - Nevada Donor Network",
      value: "NVLV|Arizona|AZ|Nevada|NV",
    },
    {
      label: "NYAP - Center for Donation and Transplant",
      value: "NYAP|New York|NY|Vermont|VT",
    },
    {
      label: "NYFL - Finger Lakes Donor Recovery Network",
      value: "NYFL|New York|NY",
    },
    { label: "NYRT - LiveOnNY", value: "NYRT|New York|NY|Pennsylvania|PA" },
    {
      label: "NYWN - Upstate New York Transplant Services Inc",
      value: "NYWN|New York|NY",
    },
    { label: "OHLB - LifeBanc", value: "OHLB|Ohio|OH" },
    { label: "OHLC - Life Connection of Ohio", value: "OHLC|Ohio|OH" },
    {
      label: "OHLP - Lifeline of Ohio",
      value: "OHLP|Ohio|OH|West Virginia|WV",
    },
    {
      label: "OHOV - LifeCenter Organ Donor Network",
      value: "OHOV|Indiana|IN|Kentucky|KY|Ohio|OH",
    },
    {
      label: "OKOP - LifeShare Transplant Donor Services of Oklahoma",
      value: "OKOP|Oklahoma|OK",
    },
    {
      label: "ORUO - Cascade Life Alliance",
      value: "ORUO|Idaho|ID|Oregon|OR|Washington|WA",
    },
    {
      label: "PADV - Gift of Life Donor Program",
      value: "PADV|Delaware|DE|New Jersey|NJ|Pennsylvania|PA",
    },
    {
      label: "PATF - Center for Organ Recovery and Education",
      value: "PATF|New York|NY|Pennsylvania|PA|West Virginia|WV",
    },
    { label: "PRLL - LifeLink of Puerto Rico", value: "PRLL|Puerto Rico|PR" },
    { label: "SCOP - We Are Sharing Hope SC", value: "SCOP|South Carolina|SC" },
    {
      label: "TNDS - Tennessee Donor Services",
      value: "TNDS|Georgia|GA|Tennessee|TN|Virginia|VA",
    },
    {
      label: "TNMS - Mid-South Transplant Foundation",
      value: "TNMS|Arkansas|AR|Mississippi|MS|Tennessee|TN",
    },
    { label: "TXGC - LifeGift Organ Donation Center", value: "TXGC|Texas|TX" },
    { label: "TXSA - Texas Organ Sharing Alliance", value: "TXSA|Texas|TX" },
    {
      label: "TXSB - Southwest Transplant Alliance",
      value: "TXSB|Arkansas|AR|Texas|TX",
    },
    { label: "UTOP - DonorConnect", value: "UTOP|Idaho|ID|Utah|UT|Wyoming|WY" },
    {
      label: "VATB - LifeNet Health",
      value: "VATB|North Carolina|NC|Virginia|VA",
    },
    {
      label: "WALC - LifeCenter Northwest",
      value: "WALC|Alaska|AK|Idaho|ID|Montana|MT|Washington|WA",
    },
    { label: "WIDN - Versiti", value: "WIDN|Versiti|Wisconsin|WI" },
    {
      label: "WIUW - UW Health Organ and Tissue Donation",
      value: "WIUW|Michigan|MI|Minnesota|MN|Wisconsin|WI",
    },
  ];

  if (!selectedOPOCode) {
    opoOptions.unshift({ label: "All Locations", value: "" });
  }

  const _onChange = (event) => {
    let opoCode = event.value;
    if (opoCode.indexOf("|") > -1) {
      opoCode = opoCode.split("|")[0];
    }

    props.onSelectOPO(opoCode);
  };

  return (
    <>
      <div
        id={`label-${uniqueId}`}
        htmlFor={uniqueId}
        className="select-opo-text"
      >
        Select another OPO or search by name or state
      </div>
      <Select
        className="select-opo"
        classNamePrefix="select"
        isClearable={false}
        isSearchable={true}
        name="opos"
        options={opoOptions}
        onChange={_onChange}
        type="text"
        aria-labelledby={`label-${uniqueId}`}
        value={opoOptions.filter((option) => {
          if (selectedOPOCode) {
            if (option.value.indexOf("|") > -1) {
              return (
                option.value.split("|")[0].toLowerCase() ===
                selectedOPOCode.toLowerCase()
              );
            } else {
              return (
                option.value.toLowerCase() === selectedOPOCode.toLowerCase()
              );
            }
          } else {
            return option.value === "";
          }
        })}
      />
    </>
  );
}

export default SelectOPO;
