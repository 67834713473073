import React, { Component } from "react"
import mapboxgl from 'mapbox-gl';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HoverTransplantCenterPopup from "../opo-information/HoverTransplantCenterPopup";
import ControlPanel from "./ControlPanel";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

class ReceivingProgramsMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lng: -96,
            lat: 38,
            zoom: 3.1,
            hoveredTransplantCenter: null,
            map: null,
            hasResized: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.organFilter !== this.props.organFilter) {
            if (this.props.organFilter) {
                var filteredResult = this.props.data.features.filter(obj => {
                    if (this.props.organFilter === "hasHeart") {
                        return obj.properties.hasHeart === true;
                    }
                    if (this.props.organFilter === "hasKidney") {
                        return obj.properties.hasKidney === true;
                    }
                    if (this.props.organFilter === "hasLiver") {
                        return obj.properties.hasLiver === true;
                    }
                    if (this.props.organFilter === "hasLung") {
                        return obj.properties.hasLung === true;
                    }
                    if (this.props.organFilter === "hasPancreas") {
                        return obj.properties.hasPancreas === true;
                    }
                    if (this.props.organFilter === "hasIntestine") {
                        return obj.properties.hasIntestine === true;
                    }

                    return null;
                });

                this.state.map.getSource('transplantCenters').setData({ type: "FeatureCollection", features: filteredResult });
            }
            else {
                this.state.map.getSource('transplantCenters').setData(this.props.data);
            }
        }

        if (prevProps.activeKey !== this.props.activeKey && !this.state.hasResized) {
            this.state.map.resize();
            this.setState({
                hasResized: true
            });
        }
    }

    componentDidMount() {
        let map = new mapboxgl.Map({
            container: this.mapContainer,
            style: this.props.opoData.mapboxStyleUrl,
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom
        });

        map.on('move', () => {
            this.setState({
                lng: map.getCenter().lng.toFixed(4),
                lat: map.getCenter().lat.toFixed(4),
                zoom: map.getZoom().toFixed(2)
            });
        });

        map.on('load', () => {
            map.loadImage("/hospital.png", (error, image) => {
                if (error) {
                    return;
                }
                map.addImage('centerIcon', image);
            });

            if (this.props.data) {
                map.addSource('transplantCenters', {
                    type: 'geojson',
                    data: this.props.data,
                    cluster: true,
                    clusterMaxZoom: 14, // Max zoom to cluster points on
                    clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
                });

                map.addLayer({
                    id: 'clusters',
                    type: 'circle',
                    source: 'transplantCenters',
                    filter: ['has', 'point_count'],
                    paint: {
                        'circle-color': [
                            'step',
                            ['get', 'point_count'],
                            '#2d62a1',
                            100,
                            '#2d62a1',
                            750,
                            '#2d62a1'
                        ],
                        'circle-radius': [
                            'step',
                            ['get', 'point_count'],
                            20,
                            100,
                            30,
                            750,
                            40
                        ]
                    }
                });

                map.addLayer({
                    id: 'cluster-count',
                    type: 'symbol',
                    source: 'transplantCenters',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': '{point_count_abbreviated}',
                        'text-size': 12
                    }
                });

                map.addLayer({
                    id: 'unclustered-point',
                    type: 'symbol',
                    source: 'transplantCenters',
                    filter: ['!', ['has', 'point_count']],
                    layout: {
                        'icon-image': 'centerIcon',
                        'icon-size': .5
                    }
                });

                map.on('click', 'clusters', function (e) {
                    var features = map.queryRenderedFeatures(e.point, {
                        layers: ['clusters']
                    });
                    var clusterId = features[0].properties.cluster_id;
                    map.getSource('transplantCenters').getClusterExpansionZoom(
                        clusterId,
                        function (err, zoom) {
                            if (err) return;

                            map.easeTo({
                                center: features[0].geometry.coordinates,
                                zoom: zoom
                            });
                        }
                    );
                });
            }

            map.on('mouseenter', 'clusters', function () {
                map.getCanvas().style.cursor = 'pointer';
            });

            map.on('mouseleave', 'clusters', function () {
                map.getCanvas().style.cursor = '';
            });

            map.on('mousemove', 'unclustered-point', (e) => {
                map.getCanvas().style.cursor = "pointer";

                const _hoveredTransplantCenter = e.features[0];
                this.setState({
                    hoveredTransplantCenter: _hoveredTransplantCenter,
                    offsetX: e.point.x,
                    offsetY: e.point.y
                });
            });

            map.on('mouseleave', 'unclustered-point', () => {
                map.getCanvas().style.cursor = '';
                this.setState({
                    hoveredTransplantCenter: null
                });
            });
        });

        map.addControl(new mapboxgl.NavigationControl());
        
        this.setState({
            map
        })
    }

    render() {
        return (
            <Row>
                <Col xs={12} lg={4}>
                    <ControlPanel
                        opoCode={this.props.opoData.code}
                        opoName={this.props.opoData.name}
                    />
                </Col>
                <Col xs={12} lg={8}>
                    <div id="receivingProgramsMap" className="opo-map">
                        <div style={{ height: "100%" }} ref={el => this.mapContainer = el} key="receivingProgramsMap">
                        </div>
                        {this.state.hoveredTransplantCenter &&
                            <HoverTransplantCenterPopup hoveredTransplantCenter={this.state.hoveredTransplantCenter} x={this.state.offsetX} y={this.state.offsetY} />
                        }
                    </div>
                </Col>
            </Row>


        )
    }
}

export default ReceivingProgramsMap;