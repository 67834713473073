import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
import TimeToTransplantTable from "./TimeToTransplantTable";
import ProgressBar from 'react-bootstrap/ProgressBar'
import { formatTwoDecimals } from "../../../utilities/formatter";
function TimeToTransplant(props) {
    const [selectedPeriod, setselectedPeriod] = useState(1);
    let beginDate = moment(new Date(props.data.begDate));
    let endDate = moment(new Date(props.data.endDate));
    let title = "Time To Transplant For Waiting List Candidates";
    let subTitle = `Candidates registered on the waiting list between ${beginDate.format("MM/DD/YYYY")} and ${endDate.format("MM/DD/YYYY")}`

    let centerValue = "NA";
    if(props.data["tpc_all_pct" + selectedPeriod]){
        centerValue = formatTwoDecimals(props.data["tpc_all_pct" + selectedPeriod]);
    }

    let nationValue = "NA";
    if(props.data["tpc_all_put" + selectedPeriod]){
        nationValue = formatTwoDecimals(props.data["tpc_all_put" + selectedPeriod]);
    }
    
    useEffect(() => {
        selectedPeriodChange(selectedPeriod);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const selectedPeriodChange = (selectedValue) => {
        setselectedPeriod(selectedValue);
    }

    return (
        <div className="time-to-transplant-rate-chart">
            <Row>
                <Col xs={12}>
                    <Row>
                        <Col xs={12}>
                            <h1 className="section-title">
                                {title}
                            </h1>
                            <div className="sub-title">
                                {subTitle}
                            </div>
                            <div className="description">
                                
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="timeline">
                        <div className="timeline-events-wrapper">
                            <div className="timeline-events">
                                <ol>
                                    <li><span onClick={() => selectedPeriodChange(1)} aria-controls="time-to-transplant-stats" className={selectedPeriod === 1 ? "selected" : ""}>30 days</span></li>
                                    <li><span onClick={() => selectedPeriodChange(2)} aria-controls="time-to-transplant-stats" className={selectedPeriod === 2 ? "selected" : ""}>1 year</span></li>
                                    <li><span onClick={() => selectedPeriodChange(3)} aria-controls="time-to-transplant-stats" className={selectedPeriod === 3 ? "selected" : ""}>2 years</span></li>
                                    <li><span onClick={() => selectedPeriodChange(4)} aria-controls="time-to-transplant-stats" className={selectedPeriod === 4 ? "selected" : ""}>3 years</span></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row >
                <Col xs={12} lg={6}>
                    <div className="block-wrapper">
                        <div className="block">
                            This Center
                       </div>
                        <div className="figure">
                        {centerValue !== "NA" ? centerValue + "%" : centerValue}
                        </div>
                        <div className="description">
                            received a transplant
                       </div>
                        <div className="bar center">
                            <ProgressBar now={centerValue} max={100} srOnly/>
                        </div>
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                <div className="block-wrapper">
                        <div className="block">
                            Nationally
                       </div>
                        <div className="figure">
                           {nationValue !== "NA" ? nationValue + "%" : nationValue}
                        </div>
                        <div className="description">
                            received a transplant
                       </div>
                        <div className="bar nation">
                            <ProgressBar now={nationValue} max={100} srOnly/>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} >
                    <TimeToTransplantTable
                        data={props.data}
                        centerCode={props.centerCode}
                        organ={props.organ}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default TimeToTransplant;