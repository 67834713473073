import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ObservedExpectedYieldChart from "./ObservedExpectedOrganYieldChart";
import HeartIcon from "../../../../utilities/organs/HeartIcon";
import KidneyIcon from "../../../../utilities/organs/KidneyIcon";
import LiverIcon from "../../../../utilities/organs/LiverIcon";
import LungIcon from "../../../../utilities/organs/LungIcon";
import PancreasIcon from "../../../../utilities/organs/PancreasIcon";
import IntestineIcon from "../../../../utilities/organs/IntestineIcon";
import ObservedExpectedYieldTable from "./ObservedExpectedOrganYieldTable";
import Skeleton from "react-loading-skeleton";

function ObservedExpectedOrganYield(props) {
  let title = "Observed/Expected Yield by Organ";

  let subTitle = props.data
    ? `${props.data.begDate} to ${props.data.endDate}`
    : "";

  return (
    <>
      <Row>
        <Col xs={12}>
          <h1 className="section-title">
            {props.data ? title : <Skeleton width={600} />}
          </h1>
          <div className="sub-title">
            {props.data ? subTitle : <Skeleton width={200} />}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="icon-tabs observed-expected-organ-yield">
          <Tabs defaultActiveKey="allData">
            <Tab
              eventKey="allData"
              title={props.data ? "All Organs" : <Skeleton width={100} />}
              className="margin-right"
            >
              {props.data && (
                <ObservedExpectedYieldChart
                  opoData={{
                    x: props.data.numberOfDonors,
                    y: props.data.opoAllData,
                    opoCode: props.data.opoCode,
                  }}
                  otherOPOs={props.data.otherOPOsAllData}
                  title="Observed/Expected Yield - All"
                  subTitle={subTitle}
                />
              )}
              {!props.data && (
                <ObservedExpectedYieldChart opoData={null} otherOPOs={null} />
              )}
            </Tab>
            <Tab
              eventKey="heartData"
              title={
                props.data ? (
                  <HeartIcon showHover={true} />
                ) : (
                  <Skeleton circle={true} height={71} width={71} />
                )
              }
              className="margin-right"
            >
              {props.data && (
                <ObservedExpectedYieldChart
                  opoData={{
                    x: props.data.numberOfDonors,
                    y: props.data.opoHeartData,
                    opoCode: props.data.opoCode,
                  }}
                  otherOPOs={props.data.otherOPOsHeartData}
                  title="Observed/Expected Yield - Heart"
                  subTitle={subTitle}
                />
              )}
              {!props.data && (
                <ObservedExpectedYieldChart opoData={null} otherOPOs={null} />
              )}
            </Tab>
            <Tab
              eventKey="kidneyData"
              title={
                props.data ? (
                  <KidneyIcon showHover={true} />
                ) : (
                  <Skeleton circle={true} height={71} width={71} />
                )
              }
              className="margin-right"
            >
              {props.data && (
                <ObservedExpectedYieldChart
                  opoData={{
                    x: props.data.numberOfDonors,
                    y: props.data.opoKidneyData,
                    opoCode: props.data.opoCode,
                  }}
                  otherOPOs={props.data.otherOPOsKidneyData}
                  title="Observed/Expected Yield - Kidney"
                  subTitle={subTitle}
                />
              )}
              {!props.data && (
                <ObservedExpectedYieldChart opoData={null} otherOPOs={null} />
              )}
            </Tab>
            <Tab
              eventKey="liverData"
              title={
                props.data ? (
                  <LiverIcon showHover={true} />
                ) : (
                  <Skeleton circle={true} height={71} width={71} />
                )
              }
              className="margin-right"
            >
              {props.data && (
                <ObservedExpectedYieldChart
                  opoData={{
                    x: props.data.numberOfDonors,
                    y: props.data.opoLiverData,
                    opoCode: props.data.opoCode,
                  }}
                  otherOPOs={props.data.otherOPOsLiverData}
                  title="Observed/Expected Yield - Liver"
                  subTitle={subTitle}
                />
              )}
              {!props.data && (
                <ObservedExpectedYieldChart opoData={null} otherOPOs={null} />
              )}
            </Tab>
            <Tab
              eventKey="lungData"
              title={
                props.data ? (
                  <LungIcon showHover={true} />
                ) : (
                  <Skeleton circle={true} height={71} width={71} />
                )
              }
              className="margin-right"
            >
              {props.data && (
                <ObservedExpectedYieldChart
                  opoData={{
                    x: props.data.numberOfDonors,
                    y: props.data.opoLungData,
                    opoCode: props.data.opoCode,
                  }}
                  otherOPOs={props.data.otherOPOsLungData}
                  title="Observed/Expected Yield - Lung"
                  subTitle={subTitle}
                />
              )}
              {!props.data && (
                <ObservedExpectedYieldChart opoData={null} otherOPOs={null} />
              )}
            </Tab>
            <Tab
              eventKey="pancreasData"
              title={
                props.data ? (
                  <PancreasIcon showHover={true} />
                ) : (
                  <Skeleton circle={true} height={71} width={71} />
                )
              }
              className="margin-right"
            >
              {props.data && (
                <ObservedExpectedYieldChart
                  opoData={{
                    x: props.data.numberOfDonors,
                    y: props.data.opoPancreasData,
                    opoCode: props.data.opoCode,
                  }}
                  otherOPOs={props.data.otherOPOsPancreasData}
                  title="Observed/Expected Yield - Pancreas"
                  subTitle={subTitle}
                />
              )}
              {!props.data && (
                <ObservedExpectedYieldChart opoData={null} otherOPOs={null} />
              )}
            </Tab>
            <Tab
              eventKey="intestineData"
              title={
                props.data ? (
                  <IntestineIcon showHover={true} />
                ) : (
                  <Skeleton circle={true} height={71} width={71} />
                )
              }
              className="margin-right"
            >
              {props.data && (
                <ObservedExpectedYieldChart
                  opoData={{
                    x: props.data.numberOfDonors,
                    y: props.data.opoIntestineData,
                    opoCode: props.data.opoCode,
                  }}
                  otherOPOs={props.data.otherOPOsIntestineData}
                  title="Observed/Expected Yield - Intestine"
                  subTitle={subTitle}
                />
              )}
              {!props.data && (
                <ObservedExpectedYieldChart opoData={null} otherOPOs={null} />
              )}
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {props.data && (
            <ObservedExpectedYieldTable
              opoName={props.data.name}
              data={props.data.observedExpectedOrganYieldTable}
              begDate={props.data.begDate}
              endDate={props.data.endDate}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default ObservedExpectedOrganYield;
