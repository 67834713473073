import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Skeleton from "react-loading-skeleton";
import start from "../../../../assets/images/start_arrow.png";
import donors from "../../../../assets/images/donors.png";
import { formatThousands } from "../../../utilities/formatter";
import moment from "moment";

function DeceasedDonorData(props) {

    const title = props.data ? "Deceased Donor Data" : <Skeleton width={400} />;
    const subTitle = props.data ? `${moment(new Date(props.data.begDate)).format("MM/DD/YYYY")} to ${moment(new Date(props.data.endDate)).format("MM/DD/YYYY")}` : <Skeleton width={250} />;
    return (
        <div className="deceased-donor-data-wrapper">
            <Row>
                <Col xs={12}>
                    <h1 className="section-title">
                        {title}
                    </h1>
                    <div className="sub-title">
                        {subTitle}
                    </div>
                </Col>
            </Row>
            <Row className="deceased-donor-data-inner">
                <Col className="column1 single">
                    {props.data &&
                        <>
                            <div className="wrapper">
                                <img src={start} alt="Referals" className="referals"/>
                                <div className="innertext">
                                    {formatThousands(props.data.dth_refferals)}
                                </div>
                            </div>
                            <div className="text">
                                In-hospital deaths referred to {props.opoCode}
                            </div>
                        </>
                    }
                    {!props.data &&
                        <Skeleton circle={true} width={300} height={250} />
                    }
                </Col>
                <Col className="column4 single">
                    {props.data &&
                        <>
                            <div className="wrapper">
                                <img src={donors} alt="Number of donors" className="donors"/>
                                <div className="innertext">
                                    {formatThousands(props.data.dth_donors)}
                                    <div className="sub-text">
                                        Donors
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {!props.data &&
                        <Skeleton circle={true} width={250} height={250} />
                    }
                </Col>
                <Col className="column5 stacked">
                    {props.data &&
                        <>
                            <div className="circle-wrapper">
                                <span className="circle">{props.data.dth_DBD}</span>
                                <div className="text">
                                    Donation After Brain Death
                        </div>
                            </div>
                            <div className="circle-wrapper">
                                <span className="circle">{props.data.dth_DCD}</span>
                                <div className="text">
                                    Donation After Circulatory Death
                        </div>
                            </div>
                        </>
                    }
                    {!props.data &&
                        <>
                            <div className="circle-wrapper">
                                <Skeleton circle={true} height={100} width={100} />
                                <div className="text">
                                    <Skeleton width={100} />
                                </div>
                            </div>
                            <div className="circle-wrapper">
                                <Skeleton circle={true} height={100} width={100} />
                                <div className="text">
                                    <Skeleton width={100} />
                                </div>
                            </div>
                        </>
                    }
                </Col>
            </Row>
        </div>
    )
}

export default DeceasedDonorData;