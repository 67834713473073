import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import triangle from "../../assets/images/icons/exclamation-triangle.png";

function CovidAlert() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="alert alert-warning covid" role="alert">
                <img src={triangle} alt="COVID Alert" /> <span>The COVID-19 pandemic affected the nation's transplant system this reporting period. Click <Button variant="link" onClick={handleShow}>here</Button> to learn about changes to reports due to the pandemic.</span>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>COVID-19 Impacts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        The Scientific Registry of Transplant Recipients (SRTR) reviewed the impacts of COVID-19 on the transplantation system with the SRTR Review Committee and with the Health Resources and Services Administration (HRSA).
                    </p>
                    <p>
                        Due to the COVID-19 pandemic, some data after March 13, 2020, have been removed from some performance evaluations.
                    </p>
                    <p>
                        For more info on the changes, read the <a href={`${process.env.REACT_APP_SRTR_HOST}/news-media/news/news-items/news/#COVID19ChangesJanuary2022`} className="alert-link" target="_blank" rel="noopener noreferrer">news release</a> and/or visit the COVID-19-related Changes <a href={`${process.env.REACT_APP_SRTR_HOST}/faqs/covid-19-related-changes/`} className="alert-link" target="_blank" rel="noopener noreferrer">FAQs page</a>.
                    </p>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CovidAlert;