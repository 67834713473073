import React from 'react';
import './App.css';
import Container from "react-bootstrap/Container";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import "mapbox-gl/dist/mapbox-gl.css";
import Home from './screens/Home';
import OPO from "./screens/OPO";
import PSR from "./screens/PSR";
import ScrollToTop from './components/utilities/ScrollToTop';
import ErrorFallback from "./components/utilities/error-fallback/ErrorFallback";
import {ErrorBoundary} from 'react-error-boundary'

function App() {
  return (
    <div className="App">
      <Container className="main-body">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route path="/opo">
                <OPO />
            </Route>
            <Route path="/psr">
              <PSR />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
        </ErrorBoundary>
      </Container>
    </div>
  );
}

if (!String.prototype.repeat) {
  // eslint-disable-next-line no-extend-native
  String.prototype.repeat = function(count) {
    if (this == null)
      throw new TypeError('can\'t convert ' + this + ' to object');

    var str = '' + this;
    // To convert string to integer.
    count = +count;
    // Check NaN

    if (count < 0)
      throw new RangeError('repeat count must be non-negative');

    if (count === Infinity)
      throw new RangeError('repeat count must be less than infinity');

    count = Math.floor(count);
    if (str.length === 0 || count === 0)
      return '';

    // Ensuring count is a 31-bit integer allows us to heavily optimize the
    // main part. But anyway, most current (August 2014) browsers can't handle
    // strings 1 << 28 chars or longer, so:
    if (str.length * count >= 1 << 28)
      throw new RangeError('repeat count must not overflow maximum string size');

    var maxCount = str.length * count;
    count = Math.floor(Math.log(count) / Math.log(2));
    while (count) {
       str += str;
       count--;
    }
    str += str.substring(0, maxCount - str.length);
    return str;
  }
}
export default App;
