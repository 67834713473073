import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TransplantRecipientCharacteristicsChart from "./TransplantRecipientCharacteristicsChart";
import { demographicOptions, getMedicalOptions } from "./Options";
import { formatThousands } from "../../../utilities/formatter";
import moment from "moment";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";

function TransplantRecipientCharacteristics(props) {
    let medicalOptions = getMedicalOptions(props.organ);
    let livingDeceasedOptions = [];
    let isLivingDonor = props.organ === "ki" || props.organ === "li";
    let centerN = "--";
    let regionN = "--";
    let usN = "--";

    if (isLivingDonor) {
        livingDeceasedOptions.push({ label: "Deceased Donor", value: "deceased" });
        livingDeceasedOptions.push({ label: "Living Donor", value: "living" });
    }
    else {
        livingDeceasedOptions.push({ label: "Deceased Donor", value: "deceased" });
    }

    const [selectedDemographicOption, setSelectedDemographicOption] = useState(demographicOptions[0]);
    const [selectedMedicalOption, setSelectedMedicalOption] = useState(medicalOptions[0]);
    const [selectedLivingDeceasedOption, setSelectedLivingDeceasedOption] = useState(livingDeceasedOptions[0]);

    let subTitle = "Patients Transplanted";

    if (props.deceasedData) {
        subTitle = `Patients transplanted between ${moment(new Date(props.deceasedData.begDate)).format("MM/DD/YYYY")} and ${moment(new Date(props.deceasedData.endDate)).format("MM/DD/YYYY")}`;
    }
    else if (props.livingData) {
        subTitle = `Patients transplanted between ${moment(new Date(props.livingData.begDate)).format("MM/DD/YYYY")} and ${moment(new Date(props.livingData.endDate)).format("MM/DD/YYYY")}`;
    }

    if (selectedLivingDeceasedOption && selectedLivingDeceasedOption.value === "living" && props.livingData) {
        centerN = props.livingData["rcl_n_c"];
        regionN = props.livingData["rcl_n_r"];
        usN = props.livingData["rcl_n_n"];
    }

    if (selectedLivingDeceasedOption && selectedLivingDeceasedOption.value === "deceased" && props.deceasedData) {
        centerN = props.deceasedData["rcc_n_c"];
        regionN = props.deceasedData["rcc_n_r"];
        usN = props.deceasedData["rcc_n_n"];
    }

    useEffect(() => {
        setSelectedDemographicOption(demographicOptions[0]);
        setSelectedMedicalOption(medicalOptions[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const _onLivingDeceasedChange = (selectedLivingDeceasedOption) => {
        setSelectedLivingDeceasedOption(selectedLivingDeceasedOption);
    }

    const _onDemographicChange = (selectedDemographicOption) => {
        setSelectedDemographicOption(selectedDemographicOption);
    }

    const _onMedicalChange = (selectedMedicalOption) => {
        setSelectedMedicalOption(selectedMedicalOption);
    }

    if (!props.isLoading) {
        return (
            <div className="transplant-recipients-wrapper">
                <Row>
                    <Col xs={12} className="mb-4">
                        <h1 className="section-title">
                            Transplant Recipient Characteristics
                    </h1>
                        <div className="sub-title">
                            {subTitle}
                        </div>
                    </Col>
                </Row>
                {isLivingDonor &&
                    <Row>
                        <Col xs={12}>
                            <div className="background-teal psr-select-options">

                                <div className="select-living-deceased-wrapper">
                                    <div className="sub-title" id="label-select-living-deceased">Donor Type</div>
                                    <Select
                                        id="select-living-deceased"
                                        className="select-living-deceased mt-2"
                                        classNamePrefix="select"
                                        isClearable={false}
                                        isSearchable={true}
                                        name="select-living-deceased"
                                        options={livingDeceasedOptions}
                                        onChange={_onLivingDeceasedChange}
                                        value={selectedLivingDeceasedOption}
                                        aria-labelledby={`label-select-living-deceased`}
                                    />
                                </div>

                            </div>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col xs={12} md={6}>
                        <div className="select-demographic-wrapper">
                            <div className="sub-title" id="demographicCharacteristicsLabel">
                                Demographic Characteristics
                        </div>
                            <div className="center-region-us-numbers">
                                <div className="header">
                                    Center<br />
                                    (N={formatThousands(centerN)})
                                </div>
                                <div className="header">
                                    Region<br />
                                    (N={formatThousands(regionN)})
                                </div>
                                <div className="header">
                                    U.S.<br />
                                    (N={formatThousands(usN)})
                                </div>
                            </div>
                            <Select
                                id="select-demographic"
                                className="select-demographic mt-2"
                                classNamePrefix="select"
                                isClearable={false}
                                isSearchable={true}
                                name="select-demographic"
                                options={demographicOptions}
                                onChange={_onDemographicChange}
                                value={selectedDemographicOption}
                                aria-labelledby={`demographicCharacteristicsLabel`}
                            />
                        </div>
                        <TransplantRecipientCharacteristicsChart
                            data={selectedLivingDeceasedOption.value === "living" && isLivingDonor ? props.livingData : props.deceasedData}
                            type={selectedLivingDeceasedOption.value}
                            selectedOption={selectedDemographicOption}
                            centerCode={props.centerCode}
                            title="Transplant Recipient Demographic Characteristics"
                            subTitle={subTitle}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <div className="select-medical-wrapper">
                            <div className="sub-title" id="medicalCharacteristicsLabel">
                                Medical Characteristics
                        </div>
                            <div className="center-region-us-numbers">
                                <div className="header">
                                    Center<br />
                                    (N={formatThousands(centerN)})
                                </div>
                                <div className="header">
                                    Region<br />
                                    (N={formatThousands(regionN)})
                                </div>
                                <div className="header">
                                    U.S.<br />
                                    (N={formatThousands(usN)})
                                </div>
                            </div>
                            <Select
                                id="select-medical"
                                className="select-medical mt-2"
                                classNamePrefix="select"
                                isClearable={false}
                                isSearchable={true}
                                name="select-medical"
                                options={medicalOptions}
                                onChange={_onMedicalChange}
                                value={selectedMedicalOption}
                                aria-labelledby={`medicalCharacteristicsLabel`}
                            />
                        </div>
                        <TransplantRecipientCharacteristicsChart
                            data={selectedLivingDeceasedOption.value === "living" && isLivingDonor ? props.livingData : props.deceasedData}
                            type={selectedLivingDeceasedOption.value}
                            selectedOption={selectedMedicalOption}
                            centerCode={props.centerCode}
                            title="Transplant Recipient Medical Characteristics"
                            subTitle={subTitle}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
    else {

        return (
            <div className="transplant-recipients-wrapper">
                <Row>
                    <Col xs={12} className="mb-4">
                        <h1 className="section-title">
                            <Skeleton width={600} />
                        </h1>
                        <div className="sub-title">
                            <Skeleton width={500} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="background-teal psr-select-options">
                            <div className="select-living-deceased-wrapper">
                                <div className="sub-title">
                                    <Skeleton width={200} />
                                </div>
                                <Skeleton width={400} height={20} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <div className="select-demographic-wrapper">
                            <div className="sub-title">
                                <Skeleton width={400} />
                            </div>
                            <Skeleton width={400} height={20} />
                        </div>
                        <Skeleton height={500} />
                    </Col>
                    <Col lg={6}>
                        <div className="select-demographic-wrapper">
                            <div className="sub-title">
                                <Skeleton width={400} />
                            </div>
                            <Skeleton width={400} height={20} />
                        </div>
                        <Skeleton height={500} />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default TransplantRecipientCharacteristics;