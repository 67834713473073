import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
function OrganUtilizationTable(props) {
    let subTitle = props.data ? `${moment(new Date(props.data.begDate)).format("MM/DD/YYYY")} to ${moment(new Date(props.data.endDate)).format("MM/DD/YYYY")}` : <Skeleton width={600} />;
    return (
        <>
            <Row>
                <Col xs={12} >
                    <h1 className="section-title">
                        {props.data ? "Organ Utilization" : <Skeleton width={600} />}
                    </h1>
                    <div className="sub-title mb-4">
                        {subTitle}
                        <div className="d-md-none mt-4">
                            {"<"} Swipe side-to-side view more {">"}
                    </div>
                    </div>

                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className="table-responsive">
                        <Table striped bordered className="observed-expected-yield-table">
                            <thead>
                                <tr>
                                    <th>Organ</th>
                                    <th className="text-center">Organs Authorized</th>
                                    <th className="text-center">Not Recovered*</th>
                                    <th className="text-center">Recovered Not for Transplant*</th>
                                    <th className="text-center">Recovered for Transplant, Not Transplanted*</th>
                                    <th className="text-center">Recovered for Transplant, Transplanted* </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Heart</td>
                                    <td className="text-center">{props.data.ooUhr_oc}</td>
                                    <td className="text-center">{props.data.ooUhr_on}</td>
                                    <td className="text-center">{props.data.ooUhr_or}</td>
                                    <td className="text-center">{props.data.ooUhr_od}</td>
                                    <td className="text-center">{props.data.ooUhr_ot}</td>
                                </tr>
                                <tr>
                                    <td>Intestine</td>
                                    <td className="text-center">{props.data.ooUin_oc}</td>
                                    <td className="text-center">{props.data.ooUin_on}</td>
                                    <td className="text-center">{props.data.ooUin_or}</td>
                                    <td className="text-center">{props.data.ooUin_od}</td>
                                    <td className="text-center">{props.data.ooUin_ot}</td>
                                </tr>
                                <tr>
                                    <td>Kidney</td>
                                    <td className="text-center">{props.data.ooUki_oc}</td>
                                    <td className="text-center">{props.data.ooUki_on}</td>
                                    <td className="text-center">{props.data.ooUki_or}</td>
                                    <td className="text-center">{props.data.ooUki_od}</td>
                                    <td className="text-center">{props.data.ooUki_ot}</td>
                                </tr>
                                <tr>
                                    <td>Liver</td>
                                    <td className="text-center">{props.data.ooUli_oc}</td>
                                    <td className="text-center">{props.data.ooUli_on}</td>
                                    <td className="text-center">{props.data.ooUli_or}</td>
                                    <td className="text-center">{props.data.ooUli_od}</td>
                                    <td className="text-center">{props.data.ooUli_ot}</td>
                                </tr>
                                <tr>
                                    <td>Lung</td>
                                    <td className="text-center">{props.data.ooUlu_oc}</td>
                                    <td className="text-center">{props.data.ooUlu_on}</td>
                                    <td className="text-center">{props.data.ooUlu_or}</td>
                                    <td className="text-center">{props.data.ooUlu_od}</td>
                                    <td className="text-center">{props.data.ooUlu_ot}</td>
                                </tr>
                                <tr>
                                    <td>Pancreas</td>
                                    <td className="text-center">{props.data.ooUpa_oc}</td>
                                    <td className="text-center">{props.data.ooUpa_on}</td>
                                    <td className="text-center">{props.data.ooUpa_or}</td>
                                    <td className="text-center">{props.data.ooUpa_od}</td>
                                    <td className="text-center">{props.data.ooUpa_ot}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="foot-note">
                        *Each liver or pancreas segment is counted separately. Pancreas islet cells are not included
                    </div>
                </Col>
            </Row>

        </>
    );
}

export default OrganUtilizationTable;