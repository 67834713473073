import React, { Component } from "react";
import Helmet from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HoverOPOPopup from "./HoverOPOPopup";
import SelectOPO from "../../utilities/SelectOPO";
import CompareOPO from "../compare/CompareOPO";
import { ScaleLoader } from "react-spinners";
import mapboxgl from "mapbox-gl";
import CovidAlert from "../../utilities/CovidAlert";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

class OPOOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: -96,
      lat: 38,
      zoom: 3.0,
      hoveredOPO: null,
      map: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    try {
      let map = new mapboxgl.Map({
        container: this.mapContainer,
        style: "mapbox://styles/srtr/cliyqnwe402kn01qp2n2scpsl",
        center: [this.state.lng, this.state.lat],
        zoom: this.state.zoom,
        maxBounds: [
          [-127.53841348339105, 22.073911061149417], // Southwest coordinates
          [-62.865962219716735, 49.80119545580888],
        ],
      });

      map.on("move", () => {
        this.setState({
          lng: map.getCenter().lng.toFixed(4),
          lat: map.getCenter().lat.toFixed(4),
          zoom: map.getZoom().toFixed(2),
        });
      });

      map.on("load", () => {
        map.on("click", "srtrdsaopomapbox", (e) => {
          if (e != null && e.features != null && e.features.length > 0) {
            let feature = e.features[0];
            if (feature && feature.properties && feature.properties.cms_opo) {
              let opoCode = feature.properties.cms_opo.toLowerCase();
              this._redirectToOPO(opoCode);
            }
          }
        });

        map.on("mousemove", "srtrdsaopomapbox", (e) => {
          map.getCanvas().style.cursor = "pointer";

          const _hoveredOPO = e.features[0];
          this.setState({
            hoveredOPO: _hoveredOPO,
            offsetX: e.point.x,
            offsetY: e.point.y,
          });
        });

        map.on("mouseleave", "srtrdsaopomapbox", () => {
          map.getCanvas().style.cursor = "";
          this.setState({
            hoveredOPO: null,
          });
        });
      });

      map.addControl(new mapboxgl.NavigationControl());

      const waiting = () => {
        if (!map.isStyleLoaded()) {
          setTimeout(waiting, 200);
        } else {
          this.setState({
            map,
            isLoading: false,
          });
        }
      };
      waiting();
    } catch (err) {
      console.log(err.message);
    }
  }

  _onSelectOPO = (opoCode) => {
    if (opoCode) this._redirectToOPO(opoCode);
  };

  _redirectToOPO = (opoCode) => {
    if (opoCode) {
      if ("parentIFrame" in window) {
        var json = {
          type: "opo",
          opoCode: opoCode,
        };

        //window.parentIFrame.sendMessage(json, `${process.env.REACT_APP_TARGET_ORIGIN}`);
        window.parentIFrame.sendMessage(json, "*");
      }

      window.location.href = `/opo/${opoCode.toLowerCase()}`;
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>OPO Overview | SRTR Data Visualization</title>
          <link rel="canonical" href="http://data.srtr.org/opo" />
        </Helmet>
        <Row>
          <Col>
            <Row className="opo-overview-about">
              <Col sm={12} lg={6}>
                <h1>Organ Procurement Organization Statistics</h1>
                <div className="note">
                  * As of 1/1/2023, The Living Legacy Foundation of Maryland and
                  Washington Regional Transplant Community have merged.
                  OPO-specific reports (OSRs) will reflect this change beginning
                  with the Spring 2023 OSRs (July, 2023).
                </div>
              </Col>
              <Col sm={12} lg={6} className="opos-description">
                There are 56* organ procurement organizations (OPOs) in the
                United States. OPOs are responsible for working with donor
                hospitals to help place donor organs for transplant. Our
                OPO-specific reports (OSRs) contain information about the deaths
                in the OPO's donor service area and metrics detailing which organs 
                were successfully placed for transplant. 
                You can find detailed analytic methods&nbsp;
                <a
                  href={`${process.env.REACT_APP_SRTR_HOST}/about-the-data/technical-methods-for-the-opo-specific-reports/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                &nbsp;and the risk adjustment models used to evaluate donor yield&nbsp;
                <a
                  href={`${process.env.REACT_APP_SRTR_HOST}/tools/deceased-donor-yield/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                . You may also&nbsp;
                <a
                  href={`${process.env.REACT_APP_SRTR_HOST}/media/1461/OSR_final_tables2405.xlsx`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  download an Excel file
                </a>
                &nbsp;containing all OSR data for each of the 56* OPOs, and
                access the&nbsp;
                <a
                  href={`${process.env.REACT_APP_SRTR_HOST}/reports/osr-reporting-timeline/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  OSR Reporting Timeline
                </a>
                .
              </Col>
            </Row>
            <Row>
              <Col>
                <CovidAlert />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="background-teal opo-select-options">
                  <div className="opo-overview-select-opo">
                    <SelectOPO onSelectOPO={this._onSelectOPO} />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div id="opoOverviewMap" className="opo-map">
                  {this.state.isLoading && (
                    <div className="loading">
                      <ScaleLoader
                        height={50}
                        width={20}
                        radius={2}
                        margin={6}
                        color={"#d1ece8"}
                        loading={this.state.isLoading}
                      />
                      Getting OPO Information...
                    </div>
                  )}

                  <div
                    style={{
                      overflow: "visible",
                      height: "100%",
                      visibility: this.state.isLoading ? "hidden" : "visible",
                    }}
                    ref={(el) => (this.mapContainer = el)}
                  >
                    {this.state.hoveredOPO && (
                      <HoverOPOPopup
                        hoveredFeature={this.state.hoveredOPO}
                        x={this.state.offsetX}
                        y={this.state.offsetY}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="outer-wrapper">
                  <div className="inner-wrapper">
                    <Row>
                      <Col sm={12} lg={6} className="opo-compare-col">
                        <CompareOPO />
                      </Col>
                      <Col sm={12} lg={6} className="opo-compare-col">
                        <CompareOPO />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default OPOOverview;
