import React, { useState } from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CountiesTable from "./CountiesTable";

function ControlPanel(props) {
    const [showCounties, setShowCounties] = useState(false);

    const handleCloseCounties = () => setShowCounties(false);
    const handleShowCounties = () => setShowCounties(true);

    const county = props.hoveredCounty;

    return (
        <>
            <div className="control-panel">
                <div className="title">
                    {props.opoName}
                </div>
                <div className="description">
                    This map shows the counties within the OPO’s donation service area (DSA) and transplant programs both within and outside of the DSA. Hover over a county for more information, or choose to filter by transplant program type above.
                </div>
                <hr />
                <div>
                    <label className="label">County:</label> {county ? county.properties.county : "--"}
                </div>
                <div>
                    <label className="label">State: </label>{county ? county.properties.state : "--"}
                </div>
                <hr />
                <div className="mt-3">
                    <label className="label">Number of Counties: </label>
                    {props.counties.length}&nbsp;
                    <div><Button className="btn-dark-blue min-155 btn btn-primary" onClick={handleShowCounties}>
                        View Counties
                    </Button>
                    </div>
                </div>
            </div>

            <Modal
                show={showCounties}
                onHide={handleCloseCounties}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="section">
                    <Modal.Title>
                        Counties served by {props.opoName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CountiesTable data={props.counties} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCounties}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ControlPanel;