import React from 'react'
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

import OPOOverview from "../components/opo/opo-overview/OPOOverview";
import OPODetail from "../components/opo/opo-detail/OPODetail";

function OPO() {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route exact path="/opo" component={OPOOverview} />
      <Route path={`${match.path}/:opoCode`} component={OPODetail} />

    </Switch>
  );
}

export default OPO;