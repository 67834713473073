import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { formatTwoDecimals } from "../../utilities/formatter";

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

(function (H) {
  var each = H.each,
    pick = H.pick;
  H.Chart.prototype.getDataRows = function () {
    var csvOptions =
        (this.options.exporting && this.options.exporting.csv) || {},
      xAxes = this.xAxis,
      rows = {},
      rowArr = [],
      dataRows,
      names = [],
      i,
      x,
      xTitle,
      // Options
      columnHeaderFormatter = function (item, key, keyLength) {
        if (csvOptions.columnHeaderFormatter) {
          var s = csvOptions.columnHeaderFormatter(item, key, keyLength);
          if (s !== false) {
            return s;
          }
        }

        if (item instanceof Highcharts.Axis) {
          return (
            (item.options.title && item.options.title.text) ||
            (item.isDatetimeAxis ? "DateTime" : "Category")
          );
        }
        return item
          ? item.name + (keyLength > 1 ? " (" + key + ")" : "")
          : "Category";
      },
      xAxisIndices = [];

    // Loop the series and index values
    i = 0;

    this.setUpKeyToAxis();

    each(this.series, function (series) {
      var keys = series.options.keys,
        pointArrayMap = keys || series.pointArrayMap || ["y"],
        valueCount = pointArrayMap.length,
        xTaken = !series.requireSorting && {},
        categoryMap = {},
        datetimeValueAxisMap = {},
        xAxisIndex = Highcharts.inArray(series.xAxis, xAxes),
        j;

      // Map the categories for value axes
      each(pointArrayMap, function (prop) {
        var axisName =
          ((series.keyToAxis && series.keyToAxis[prop]) || prop) + "Axis";

        categoryMap[prop] =
          (series[axisName] && series[axisName].categories) || [];
        datetimeValueAxisMap[prop] =
          series[axisName] && series[axisName].isDatetimeAxis;
      });

      if (
        series.options.includeInCSVExport !== false &&
        series.visible !== false // #55
      ) {
        // Build a lookup for X axis index and the position of the first
        // series that belongs to that X axis. Includes -1 for non-axis
        // series types like pies.
        if (
          !Highcharts.find(xAxisIndices, function (index) {
            return index[0] === xAxisIndex;
          })
        ) {
          xAxisIndices.push([xAxisIndex, i]);
        }

        // Add the column headers, usually the same as series names
        j = 0;
        while (j < valueCount) {
          names.push(
            columnHeaderFormatter(
              series,
              pointArrayMap[j],
              pointArrayMap.length
            )
          );
          j++;
        }

        each(series.points, function (point, pIdx) {
          var key = point.x,
            prop,
            val;

          if (xTaken) {
            if (xTaken[key]) {
              key += "|" + pIdx;
            }
            xTaken[key] = true;
          }

          j = 0;

          if (!rows[key]) {
            // Generate the row
            rows[key] = [];
            // Contain the X values from one or more X axes
            rows[key].xValues = [];
          }
          rows[key].x = point.x;
          rows[key].xValues[xAxisIndex] = point.x;

          // Pies, funnels, geo maps etc. use point name in X row
          if (!series.xAxis || series.exportKey === "name") {
            rows[key].name = point.name;
          }

          while (j < valueCount) {
            prop = pointArrayMap[j]; // y, z etc
            val = point[prop];
            rows[key][i + j] = pick(
              categoryMap[prop][val], // Y axis category if present
              datetimeValueAxisMap[prop]
                ? Highcharts.dateFormat(csvOptions.dateFormat, val)
                : null,
              val
            );
            j++;
          }

          // add original point reference
          rows[key].point = point;
        });
        i = i + j;
      }
    });

    // Make a sortable array
    for (x in rows) {
      if (rows.hasOwnProperty(x)) {
        rowArr.push(rows[x]);
      }
    }

    dataRows = [names];

    i = xAxisIndices.length;
    while (i--) {
      let xAxisIndex, column, xAxis;
      // Start from end to splice in
      xAxisIndex = xAxisIndices[i][0];
      column = xAxisIndices[i][1];
      xAxis = xAxes[xAxisIndex];

      // Sort it by X values
      rowArr.sort(function (a, b) {
        // eslint-disable-line no-loop-func
        return a.xValues[xAxisIndex] - b.xValues[xAxisIndex];
      });

      // Add header row
      xTitle = columnHeaderFormatter(xAxis);
      dataRows[0].splice(column, 0, xTitle);

      // Add the category column
      each(rowArr, function (row) {
        // eslint-disable-line no-loop-func
        var category = row.name;
        if (!category) {
          if (xAxis.isDatetimeAxis) {
            if (row.x instanceof Date) {
              row.x = row.x.getTime();
            }
            category = Highcharts.dateFormat(csvOptions.dateFormat, row.x);
          } else if (xAxis.categories) {
            category = pick(xAxis.names[row.x], xAxis.categories[row.x], row.x);
          } else {
            category = row.x;
          }
        }

        // Add the X/date/category
        row.splice(column, 0, category);
      });
    }
    dataRows = dataRows.concat(rowArr);

    return dataRows;
  };

  H.wrap(H.Chart.prototype, "getCSV", function (p, useLocalDecimalPoint) {
    let csv = "";
    const rows = this.getDataRows();
    const csvOptions = this.options.exporting.csv;
    const decimalPoint = pick(
      csvOptions.decimalPoint,
      csvOptions.itemDelimiter !== "," && useLocalDecimalPoint
        ? (1.1).toLocaleString()[1]
        : "."
    );
    // use ';' for direct to Excel
    const itemDelimiter = pick(
      csvOptions.itemDelimiter,
      decimalPoint === "," ? ";" : ","
    );
    // '\n' isn't working with the js csv data extraction
    const lineDelimiter = csvOptions.lineDelimiter;

    const yAxisTitle = this.yAxis[0].axisTitle.textStr;
    // Transform the rows to CSV
    rows.forEach(function (row, i) {
      if (i === 0) {
        if (row[1] === "Other OPOs") {
          row[1] = yAxisTitle;
          row[2] = "OPO";
        } else {
          row[1] = yAxisTitle;
          row[2] = "Program";
        }
      } else {
        let program = row["point"].centerName
          ? '"' + row["point"].centerName + '"'
          : '"' + row["point"].opoCode + '"';

        if (row.length === 3) {
          row[1] = row[2];
        }
        row[2] = program;
      }
      // Add the values
      csv += row.join(itemDelimiter);
      // Add the line delimiter
      if (i < rows.length - 1) {
        csv += lineDelimiter;
      }
    });
    return csv;
  });
})(Highcharts);
function RatioScatterPlotChart(props) {
  const [chartOptions, setOptions] = useState({
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "printChart",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "downloadCSV",
          ],
        },
      },
      title: {
        text: `<div style="font-family:Open Sans;font-size:14px;font-weight: 700;text-align:center;">${props.title}</div><br /><div style="font-family:Open Sans;font-size:12px;font-weight: 400;text-align:center;"></div>`,
      },
      chart: {
        events: {
          load: function () {
            let width = 0;
            let height = this.chartHeight - 50;
            this.renderer.image("/srtr_logo.jpg", width, height, 50, 50).add();
          },
        },
      },
    },
    chart: {
      type: "scatter",
      zoomType: "xy",
    },
    lang: {
      noData: getNoDataMessage(
        props.centerCode,
        props.organ,
        props.survivalResult
      ),
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      description: "",
    },
    xAxis: {
      min: 0,
      title: {
        text: "Program Volume",
        style: {
          fontFamily: "Open Sans",
          fontSize: "14px",
        },
      },
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      labels: {
        style: {
          fontFamily: "Open Sans",
          fontSize: "14px",
        },
      },
    },
    yAxis: {
      type: "logarithmic",
      title: {
        text: props.yAxisLabel,
        style: {
          fontFamily: "Open Sans",
          fontSize: "14px",
        },
      },
      plotLines: [
        {
          dashStyle: "solid",
          value: 1,
          width: 4,
        },
      ],
      labels: {
        style: {
          fontFamily: "Open Sans",
          fontSize: "14px",
        },
      },
    },
    legend: {
      itemStyle: {
        fontFamily: "Open Sans",
        fontSize: "14px",
      },
    },
    tooltip: {
      useHTML: true,
      headerFormat: "",
      pointFormat: `
                <div style="margin-bottom: 5px;">
                    <span style="font-family:Open Sans;font-size:16px;font-weight: 700;text-align:left;padding-bottom:30px;">{point.centerName}</span>
                </div>
                <div style="padding-bottom: 5px;">
                    <span style="font-family:Open Sans;font-size:16px;font-weight: 700;text-align:left">${props.ratioLabel}: </span>
                    <span style="font-family:Open Sans;font-size:16px;font-weight: 400;text-align:left">{point.y}</span>
                </div>
                <div style="padding-bottom: 5px;">
                    <span style="font-family:Open Sans;font-size:16px;font-weight: 700;text-align:left">Program Volume: </span>
                    <span style="font-family:Open Sans;font-size:16px;font-weight: 400;text-align:left">{point.x}</span>
                </div>`,
    },
    plotOptions: {
      scatter: {
        marker: {
          radius: 10,
        },
      },
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
  });

  Highcharts.Axis.prototype.log2lin = function (num) {
    return Math.log(num) / Math.LN2;
  };

  Highcharts.Axis.prototype.lin2log = function (num) {
    return Math.pow(2, num);
  };

  useEffect(() => {
    let otherCentersData = props.otherCentersData
      .filter((x) => x[props.ratio] !== null)
      .map(function (obj) {
        return {
          x: obj[props.programVolume],
          y: formatTwoDecimals(obj[props.ratio]),
          centerName: obj.centerName,
        };
      });

    let series = [];

    series.push({
      name: "Other Centers",
      marker: {
        symbol: "circle",
        lineWidth: 1,
      },
      data: otherCentersData,
      color: "#e45d2c",
    });

    if (props.data[props.ratio]) {
      series.push({
        name: props.centerCode,
        data: [
          {
            x: props.data[props.programVolume],
            y: formatTwoDecimals(props.data[props.ratio]),
            centerName: props.centerCode,
          },
        ],
        color: "#182474",
        marker: {
          symbol: "circle",
          lineWidth: 1,
        },
      });
    }

    setOptions({
      ...chartOptions,
      title: {
        text: `<div style="font-family:Open Sans;font-size:20px;font-weight: 400;text-align:center;">${props.title}</div>`,
      },
      series,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <div className="transplant-grafts-chart-container mt-4">
      {!props.data[props.ratio] && (
        <div className="sub-title mt-2">
          {getNoDataMessage(
            props.centerCode,
            props.organ,
            props.survivalResult
          )}
        </div>
      )}
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        oneToOne={true}
        containerProps={{ className: "highcharts-light" }}
      />
    </div>
  );
}

export default RatioScatterPlotChart;

const getNoDataMessage = (centerCode, organ, survivalResult) => {
  if (organ === "pa" && survivalResult === "gsr") {
    return "Graft failure has not been defined for pancreas grafts";
  } else {
    return `${centerCode} did not perform any transplants meeting these criteria during the timeframe studied`;
  }
};
