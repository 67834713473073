import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Skeleton from "react-loading-skeleton";
import { formatThousands } from "../../utilities/formatter";
import start from "../../../assets/images/waitlist_start.png";
import start_mobile from "../../../assets/images/waitlist_start_mobile.png";
import end from "../../../assets/images/waitlist_end.png";
import upIcon from "../../../assets/images/waitlist_up.png";
import evenIcon from "../../../assets/images/waitlist_even.png";
import downIcon from "../../../assets/images/waitlist_down.png";
import moment from "moment";

function WaitlistOverview(props) {
    if (props.data) {
        let endDate = moment(new Date(props.data.endDate));
        let startDate = moment(new Date(endDate)).subtract(11, 'M');
        let subtitle = <>{startDate.startOf("month").format("MM/DD/YYYY")} to {endDate.format("MM/DD/YYYY")}</>;
        let joined = props.data.wla_addcen_nc2;
        let removed =
            props.data.wla_remdet_nc2 +
            props.data.wla_remdied_nc2 +
            props.data.wla_remoth_nc2 +
            props.data.wla_remrec_nc2 +
            props.data.wla_remtfer_nc2 +
            props.data.wla_remtxc_nc2 +
            props.data.wla_remtxl_nc2 +
            props.data.wla_remtxoc_nc2;

            return (
            <div className="waitlist-overview-wrapper">
                <Row>
                    <Col xs={12}>
                        <h1 className="section-title">
                            Waiting List Overview
                                </h1>
                        <div className="sub-title">
                            {subtitle}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="background-blue">
                            <div className="waitlist-overview-inner">
                                <Row>
                                    <Col className="column1 single">
                                        <div className="wrapper">
                                            <img src={start} className="desktop" alt="People on waitlist at the start" />
                                            <img src={start_mobile} className="mobile" alt="People on waitlist at the start"/>
                                            <div className="innertext">
                                                <div className="number">
                                                    {formatThousands(props.data.wla_st_nc2)}
                                                </div>
                                                <div className="text">
                                                    People
                                        </div>
                                                <div className="sub-text">
                                                    as of {startDate.format("MMMM YYYY")}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="column2">
                                        <div className="wrapper">
                                            <img src={getUpdownEvenIcon(joined, removed)} alt="Number of people joined or removed from waitlist"/>
                                            <div className="joined">
                                                <div className="number">
                                                    + {formatThousands(props.data.wla_addcen_nc2)}
                                                </div>
                                                <div className="text">
                                                    People Joined
                                        </div>
                                            </div>
                                            <div className="removed">
                                                <div className="number">
                                                    - {formatThousands(removed)}
                                                </div>
                                                <div className="text">
                                                    Were Removed
                                                        </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="column3 single">
                                        <div className="wrapper">
                                            <img src={end} alt="People on waitlist at the end"/>
                                            <div className="innertext">
                                                <div className="number">
                                                    {formatThousands(props.data.wla_end_nc2)}
                                                </div>
                                                <div className="text">
                                                    People
                                                        </div>
                                                <div className="sub-text">
                                                    as of {endDate.format("MMMM YYYY")}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="removals">
                            <Row>
                                <Col xs={12}>
                                    <div className="title">
                                        Reasons For Removal
                                    </div>
                                </Col>
                                <Col className="removal">
                                    <div className="number">
                                        {formatThousands(props.data.wla_remtfer_nc2)}
                                    </div>
                                    <div className="text">
                                        Transferred to another center
                                        </div>
                                </Col>
                                <Col className="removal">
                                    <div className="number">
                                        {formatThousands(props.data.wla_remtxl_nc2)}
                                    </div>
                                    <div className="text">
                                        Received living donor transplant*
                                        </div>
                                </Col>
                                <Col className="removal">
                                    <div className="number">
                                        {formatThousands(props.data.wla_remtxc_nc2)}
                                    </div>
                                    <div className="text">
                                        Received deceased donor transplant*
                                        </div>
                                </Col>
                                <Col className="removal">
                                    <div className="number">
                                        {formatThousands(props.data.wla_remdied_nc2)}
                                    </div>
                                    <div className="text">
                                        Died
                                        </div>
                                </Col>
                                <Col className="removal">
                                    <div className="number">
                                        {formatThousands(props.data.wla_remtxoc_nc2)}
                                    </div>
                                    <div className="text">
                                        Transplanted at another center
                                        </div>
                                </Col>
                                <Col className="removal">
                                    <div className="number">
                                        {formatThousands(props.data.wla_remdet_nc2)}
                                    </div>
                                    <div className="text">
                                        Deteriorated
                                        </div>
                                </Col>
                                <Col className="removal">
                                    <div className="number">
                                        {formatThousands(props.data.wla_remrec_nc2)}
                                    </div>
                                    <div className="text">
                                        Recovered
                                        </div>
                                </Col>
                                <Col className="removal">
                                    <div className="number">
                                        {formatThousands(props.data.wla_remoth_nc2)}
                                    </div>
                                    <div className="text">
                                        Other reasons
                                        </div>
                                </Col>
                                <Col xs={12} className="removal-description">
                                    <hr />
                                        *These patients were removed from waiting list with removal code indicating transplant; this may not equal the
                                        number of transplants performed at this center during the specified period
                                    </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>

        );
    }
    else {
        return (
            <Row>
                <Col xs={12}>
                    <h1 className="section-title">
                        <Skeleton width={600} />
                    </h1>
                    <div className="sub-title">
                        <Skeleton width={600} />
                    </div>
                </Col>
            </Row>
        );
    }


}

function getUpdownEvenIcon(joined, removed){
    if(joined > removed){
        return upIcon;
    }

    if(joined < removed){
        return downIcon;
    }

    return evenIcon;
}

export default WaitlistOverview;