import React from "react";
import Table from "react-bootstrap/Table";

function CountiesTable(props) {
    return (
        <div className="collapse-inner table-wrapper-scroll-y table-scroll ">
            <Table striped bordered className="mb-0 counties">
                <thead>
                    <tr>
                        <th>State Name</th>
                        <th>County Name</th>
                        <th className="text-center">County FIPS</th>
                        <th className="text-center">Shared with</th>
                    </tr>

                </thead>
                <tbody>
                    {props.data.map((item, i) => {
                        //Items are expected to come in in GeoJSON format
                        //That is why there is a .properties after each item
                        return [
                            <tr key={item.id}>
                                <td>{item.state}</td>
                                <td>
                                    {item.name}
                                </td>
                                <td className="text-center">
                                    {item.fips}
                                </td>
                                <td className="text-center">
                                    {item.sharedWith && 
                                        item.sharedWith
                                    }
                                </td>
                            </tr>
                        ];
                    })}
                </tbody>
            </Table>
        </div>
    );
}

export default CountiesTable;