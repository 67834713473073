import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Table from "react-bootstrap/Table";
import { formatThousands, formatOneDecimal } from "../../../../utilities/formatter";

function TransplantRatesTable(props) {
    const [open, setOpen] = useState(false);
    return (
        <>
            <div className="collapse-container show-all-row mt-4">
                View all waitling list transplant rate data &nbsp;&nbsp;
                <Button
                    onClick={() => setOpen(!open)}
                    aria-controls="collapse-text"
                    aria-expanded={open}
                    className="btn-dark-blue min-98"
                >
                    {!open && "Show All"}
                    {open && "Hide All"}
                </Button>
            </div>
            <Collapse in={open}>
                <div id="collapse-text" className="collapse-inner">
                    <div className="table-responsive">
                        <div className="sub-title">
                            Swipe to view more data {">>>"}
                        </div>
                        <Table striped className="overflow-auto description-of-donors-recovered-table">
                            <thead>
                                <tr>
                                    <th className="border-bottom">Waiting List Registrations</th>
                                    <th className="text-center border-bottom">
                                        {props.centerCode}
                                    </th>
                                    <th className="text-center border-bottom">
                                        OPO/DSA
                                </th>
                                    <th className="text-center border-bottom">
                                        Region
                                </th>
                                    <th className="text-center border-bottom">
                                        U.S.
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="5" className="font-weight-bold">All Candidates</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30">Count on waiting list at start</td>
                                    <td className="text-center">{props.data.all ? formatThousands(props.data.all["tmr_txn_c"]) : "-"}</td>
                                    <td className="text-center">{props.data.all ? formatThousands(props.data.all["tmr_txn_o"]) : "-"}</td>
                                    <td className="text-center">{props.data.all ? formatThousands(props.data.all["tmr_txn_r"]) : "-"}</td>
                                    <td className="text-center">{props.data.all ? formatThousands(props.data.all["tmr_txn_u"]) : "-"}</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30">Person Years</td>
                                    <td className="text-center">{props.data.all ? formatThousands(formatOneDecimal(props.data.all["tmr_txpy_c"])) : "-"}</td>
                                    <td className="text-center">{props.data.all ? formatThousands(formatOneDecimal(props.data.all["tmr_txpy_o"])) : "-"}</td>
                                    <td className="text-center">{props.data.all ? formatThousands(formatOneDecimal(props.data.all["tmr_txpy_r"])) : "-"}</td>
                                    <td className="text-center">{props.data.all ? formatThousands(formatOneDecimal(props.data.all["tmr_txpy_u"])) : "-"}</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30">Removals for Transplant</td>
                                    <td className="text-center">{props.data.all ? formatThousands(formatOneDecimal(props.data.all["tmr_txed_c"])) : "-"}</td>
                                    <td className="text-center">{props.data.all ? formatThousands(formatOneDecimal(props.data.all["tmr_txed_o"])) : "-"}</td>
                                    <td className="text-center">{props.data.all ? formatThousands(formatOneDecimal(props.data.all["tmr_txed_r"])) : "-"}</td>
                                    <td className="text-center">{props.data.all ? formatThousands(formatOneDecimal(props.data.all["tmr_txed_u"])) : "-"}</td>
                                </tr>
                                <tr>
                                    <td colSpan="5" className="font-weight-bold">Adult (18+) Candidates</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30">Count on waiting list at start</td>
                                    <td className="text-center">{props.data.adult ? formatThousands(props.data.adult["tmr_ad_txn_c"]) : "-"}</td>
                                    <td className="text-center">{props.data.adult ? formatThousands(props.data.adult["tmr_ad_txn_o"]) : "-"}</td>
                                    <td className="text-center">{props.data.adult ? formatThousands(props.data.adult["tmr_ad_txn_r"]) : "-"}</td>
                                    <td className="text-center">{props.data.adult ? formatThousands(props.data.adult["tmr_ad_txn_u"]) : "-"}</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30">Person Years</td>
                                    <td className="text-center">{props.data.adult ? formatThousands(formatOneDecimal(props.data.adult["tmr_ad_txpy_c"])) : "-"}</td>
                                    <td className="text-center">{props.data.adult ? formatThousands(formatOneDecimal(props.data.adult["tmr_ad_txpy_o"])) : "-"}</td>
                                    <td className="text-center">{props.data.adult ? formatThousands(formatOneDecimal(props.data.adult["tmr_ad_txpy_r"])) : "-"}</td>
                                    <td className="text-center">{props.data.adult ? formatThousands(formatOneDecimal(props.data.adult["tmr_ad_txpy_u"])) : "-"}</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30">Removals for Transplant</td>
                                    <td className="text-center">{props.data.adult ? formatThousands(formatOneDecimal(props.data.adult["tmr_ad_txed_c"])) : "-"}</td>
                                    <td className="text-center">{props.data.adult ? formatThousands(formatOneDecimal(props.data.adult["tmr_ad_txed_o"])) : "-"}</td>
                                    <td className="text-center">{props.data.adult ? formatThousands(formatOneDecimal(props.data.adult["tmr_ad_txed_r"])) : "-"}</td>
                                    <td className="text-center">{props.data.adult ? formatThousands(formatOneDecimal(props.data.adult["tmr_ad_txed_u"])) : "-"}</td>
                                </tr>
                                <tr>
                                    <td colSpan="5" className="font-weight-bold">Pediatric ({"<18"}) Candidates</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30">Count on waiting list at start</td>
                                    <td className="text-center">{props.data.pediatric ? formatThousands(props.data.pediatric["tmr_p0_txn_c"]) : "-"}</td>
                                    <td className="text-center">{props.data.pediatric ? formatThousands(props.data.pediatric["tmr_p0_txn_o"]) : "-"}</td>
                                    <td className="text-center">{props.data.pediatric ? formatThousands(props.data.pediatric["tmr_p0_txn_r"]) : "-"}</td>
                                    <td className="text-center">{props.data.pediatric ? formatThousands(props.data.pediatric["tmr_p0_txn_u"]) : "-"}</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30">Person Years</td>
                                    <td className="text-center">{props.data.pediatric ? formatThousands(formatOneDecimal(props.data.pediatric["tmr_p0_txpy_c"])) : "-"}</td>
                                    <td className="text-center">{props.data.pediatric ? formatThousands(formatOneDecimal(props.data.pediatric["tmr_p0_txpy_o"])) : "-"}</td>
                                    <td className="text-center">{props.data.pediatric ? formatThousands(formatOneDecimal(props.data.pediatric["tmr_p0_txpy_r"])) : "-"}</td>
                                    <td className="text-center">{props.data.pediatric ? formatThousands(formatOneDecimal(props.data.pediatric["tmr_p0_txpy_u"])) : "-"}</td>
                                </tr>
                                <tr>
                                    <td className="padding-left-30">Removals for Transplant</td>
                                    <td className="text-center">{props.data.pediatric ? formatThousands(formatOneDecimal(props.data.pediatric["tmr_p0_txed_c"])) : "-"}</td>
                                    <td className="text-center">{props.data.pediatric ? formatThousands(formatOneDecimal(props.data.pediatric["tmr_p0_txed_o"])) : "-"}</td>
                                    <td className="text-center">{props.data.pediatric ? formatThousands(formatOneDecimal(props.data.pediatric["tmr_p0_txed_r"])) : "-"}</td>
                                    <td className="text-center">{props.data.pediatric ? formatThousands(formatOneDecimal(props.data.pediatric["tmr_p0_txed_u"])) : "-"}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Collapse>
        </>
    );
}

export default TransplantRatesTable;