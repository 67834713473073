import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Table from "react-bootstrap/Table";
import { formatTwoDecimals, formatThousands } from "../../../utilities/formatter";

function TransplantsFacilitatedTable(props) {
    const [open, setOpen] = useState(false);

    return (
        <>
        <hr />
            <div className="collapse-container show-all-row mb-4">
                View all characteristics of deceased donor {props.organ} recipients &nbsp;&nbsp;
                <Button
                    onClick={() => setOpen(!open)}
                    aria-controls="collapse-text"
                    aria-expanded={open}
                    className="btn-dark-blue min-98"
                >
                    {!open && "Show All"}
                    {open && "Hide All"}
                </Button>
            </div>
            <Collapse in={open}>
                <div id="collapse-text" className="collapse-inner">
                    <Table striped className="overflow-auto description-of-donors-recovered-table">
                        <thead>
                            <tr>
                                <td></td>
                                <th className="text-center" colSpan="2">
                                    {props.opoCode}
                                </th>
                                <th className="text-center" colSpan="2">
                                    United States
                                </th>
                            </tr>
                            <tr>
                                <td className="border-bottom"></td>
                                <th className="text-center border-bottom">
                                    {props.yearOne}
                                </th>
                                <th className="text-center border-bottom">
                                    {props.yearTwo}
                                </th>
                                <th className="text-center border-bottom">
                                    {props.yearOne}
                                </th>
                                <th className="text-center border-bottom">
                                    {props.yearTwo}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="font-weight-bold">Total Number of Transplants</td>
                                <td className="text-center">{formatThousands(formatTwoDecimals(props.data[props.prefix + "_n_o1"]))}</td>
                                <td className="text-center">{formatThousands(formatTwoDecimals(props.data[props.prefix + "_n_o2"]))}</td>
                                <td className="text-center">{formatThousands(formatTwoDecimals(props.data[props.prefix + "_n_u1"]))}</td>
                                <td className="text-center">{formatThousands(formatTwoDecimals(props.data[props.prefix + "_n_u2"]))}</td>
                            </tr>
                            {
                                props.options.map((item, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <tr key={i}>
                                                <td colSpan="5" className="font-weight-bold">{item.label} (%)</td>
                                            </tr>
                                            {item.value.map((subItem, i) => {
                                                return(
                                                    <tr key={i}>
                                                        <td className="padding-left-30">{subItem.label}</td>
                                                        <td className="text-center">{formatTwoDecimals(props.data[subItem.value.opoYearOne])}</td>
                                                        <td className="text-center">{formatTwoDecimals(props.data[subItem.value.opoYearTwo])}</td>
                                                        <td className="text-center">{formatTwoDecimals(props.data[subItem.value.usYearOne])}</td>
                                                        <td className="text-center">{formatTwoDecimals(props.data[subItem.value.usYearTwo])}</td>
                                                    </tr>
                                                )
                                            })}
                                        </React.Fragment>
                                    )
                                })}
                        </tbody>
                    </Table>
                </div>
            </Collapse>
        </>
    );
}

export default TransplantsFacilitatedTable;