import React from "react";
import moment from "moment";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DemographicsChart from "./DemographicsChart";

function Demographics(props) {
    let beginDate = moment(new Date(props.data.begDate));
    let endDate = moment(new Date(props.data.endDate));
    let title = props.title;
    let subTitle = `Candidates registered on the waiting list between ${beginDate.format("MM/DD/YYYY")} and ${endDate.format("MM/DD/YYYY")}`
    return (
        <div className="waitlist-demographics-wrapper section">
            <Row>
                <Col xs={12}>
                    <h1 className="section-title">
                        Demographic Characteristics
                    </h1>
                    <div className="sub-title">
                        {subTitle}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <DemographicsChart
                        organ={props.organ}
                        data={props.data}
                        dataType="new"
                        centerCode={props.centerCode}
                        title={title}
                        subTitle={subTitle}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default Demographics;