import React from 'react'
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

import PSROverview from "../components/psr/psr-overview/PSROverview";
import CenterDetail from "../components/psr/center/CenterDetail";
import Waitlist from "../components/psr/waitlist/Waitlist";

function PSR() {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route exact path="/psr" component={PSROverview} />
      <Route path={`${match.path}/:centerCode/:organ/waitlist`} component={Waitlist} />
      <Route path={`${match.path}/:centerCode/:organ?`} component={CenterDetail} />
      


    </Switch>
  );
}

export default PSR;