
export function getOptions(organ) {
    let options = [];
    if (organ === "hr") {
        options = [
            { name: "Overall", displayName: "Overall" },
            { name: "PHS", displayName: "PHS increased infectious risk" },
            { name: "LowEjection", displayName: "Ejection fraction < 60" },
            { name: "OldAge40", displayName: "Donor age >= 40" },
            { name: "HTP50", displayName: "Offer number > 50" },
            { name: "Miles500", displayName: "Donor more than 500 miles away" }
        ];
    }
    if (organ === "ki") {
        options = [
            { name: "Overall", displayName: "Overall" },
            { name: "LowKDRI", displayName: "Low-KDRI" },
            { name: "MediumKDRI", displayName: "Medium-KDRI" },
            { name: "HighKDRI", displayName: "High-KDRI" },
            { name: "HTP100", displayName: "Offer number > 100" }
        ];
    }

    if (organ === "kp") {
        options = [
            { name: "Overall", displayName: "Overall" },
            { name: "HighRiskDonor", displayName: "High risk donor" },
            { name: "HighBMI", displayName: "Donor BMI > 28" },
            { name: "PHS", displayName: "PHS increased infectious risk" },
            { name: "Miles250", displayName: "Donor more than 250 miles away" },
            { name: "HTP10", displayName: "Offer number > 10" }
        ];
    }

    if (organ === "li") {
        options = [
            { name: "Overall", displayName: "Overall" },
            { name: "PHS", displayName: "PHS increased infectious risk" },
            { name: "DCD", displayName: "DCD Donor" },
            { name: "HCV", displayName: "HCV+ Donor" },
            { name: "HTP50", displayName: "Offer number > 50" },
            { name: "Miles500", displayName: "Donor more than 500 miles away" }
        ];
    }

    if (organ === "lu") {
        options = [
            { name: "Overall", displayName: "Overall" },
            { name: "PHS", displayName: "PHS increased infectious risk" },
            { name: "CurrentSmoker", displayName: "Donor was current smoker" },
            { name: "OldAge55", displayName: "Donor age >= 55" },
            { name: "HTP50", displayName: "Offer number > 50" },
            { name: "Miles500", displayName: "Donor more than 500 miles away" }
        ];
    }

    if (organ === "pa") {
        options = [
            { name: "Overall", displayName: "Overall" },
            { name: "PHS", displayName: "PHS increased infectious risk" },
            { name: "HighRiskDonor", displayName: "High risk donor" },
            { name: "HighBMI", displayName: "Donor BMI > 28" },
            { name: "HTP10", displayName: "Offer number > 10" },
            { name: "Miles250", displayName: "Donor more than 250 miles away" }
        ];
    }
    return options;
}

export function getTableOptions(optionName) {
    switch (optionName) {
        case "Overall":
            return {
                offers: "oa_overall_offers_",
                accepts: "oa_overall_accepts_",
                expected: "oa_overall_exp_accepts_",
                ratio: "oa_overall_hr_mn_",
                ratiolow: "oa_overall_hr_lb_",
                ratiohigh: "oa_overall_hr_ub_"
            }

        case "CurrentSmoker":
            return {
                offers: "oa_currentsmoker_offers_",
                accepts: "oa_currentsmoker_accepts_",
                expected: "oa_currentsmoker_exp_accepts_",
                ratio: "oa_currentsmoker_hr_mn_",
                ratiolow: "oa_currentsmoker_hr_lb_",
                ratiohigh: "oa_currentsmoker_hr_ub_"
            }

        case "DCD":
            return {
                offers: "oa_dcd_offers_",
                accepts: "oa_dcd_accepts_",
                expected: "oa_dcd_exp_accepts_",
                ratio: "oa_dcd_hr_mn_",
                ratiolow: "oa_dcd_hr_lb_",
                ratiohigh: "oa_dcd_hr_ub_"
            }

        case "HCV":
            return {
                offers: "oa_hcv_offers_",
                accepts: "oa_hcv_accepts_",
                expected: "oa_hcv_exp_accepts_",
                ratio: "oa_hcv_hr_mn_",
                ratiolow: "oa_hcv_hr_lb_",
                ratiohigh: "oa_hcv_hr_ub_"
            }

        case "PHS":
            return {
                offers: "oa_phshighrisk_offers_",
                accepts: "oa_phshighrisk_accepts_",
                expected: "oa_phshighrisk_exp_accepts_",
                ratio: "oa_phshighrisk_hr_mn_",
                ratiolow: "oa_phshighrisk_hr_lb_",
                ratiohigh: "oa_phshighrisk_hr_ub_"
            }

        case "LowEjection":
            return {
                offers: "oa_lowejection_offers_",
                accepts: "oa_lowejection_accepts_",
                expected: "oa_lowejection_exp_accepts_",
                ratio: "oa_lowejection_hr_mn_",
                ratiolow: "oa_lowejection_hr_lb_",
                ratiohigh: "oa_lowejection_hr_ub_"
            }

        case "OldAge40":
            return {
                offers: "oa_oldage40_offers_",
                accepts: "oa_oldage40_accepts_",
                expected: "oa_oldage40_exp_accepts_",
                ratio: "oa_oldage40_hr_mn_",
                ratiolow: "oa_oldage40_hr_lb_",
                ratiohigh: "oa_oldage40_hr_ub_"
            }

        case "OldAge55":
            return {
                offers: "oa_oldage55_offers_",
                accepts: "oa_oldage55_accepts_",
                expected: "oa_oldage55_exp_accepts_",
                ratio: "oa_oldage55_hr_mn_",
                ratiolow: "oa_oldage55_hr_lb_",
                ratiohigh: "oa_oldage55_hr_ub_"
            }

        case "HTP10":
            return {
                offers: "oa_hardtoplace10_offers_",
                accepts: "oa_hardtoplace10_accepts_",
                expected: "oa_hardtoplace10_exp_accepts_",
                ratio: "oa_hardtoplace10_hr_mn_",
                ratiolow: "oa_hardtoplace10_hr_lb_",
                ratiohigh: "oa_hardtoplace10_hr_ub_"
            }

        case "HTP50":
            return {
                offers: "oa_hardtoplace50_offers_",
                accepts: "oa_hardtoplace50_accepts_",
                expected: "oa_hardtoplace50_exp_accepts_",
                ratio: "oa_hardtoplace50_hr_mn_",
                ratiolow: "oa_hardtoplace50_hr_lb_",
                ratiohigh: "oa_hardtoplace50_hr_ub_"
            }

        case "Miles250":
            return {
                offers: "oa_miles250_offers_",
                accepts: "oa_miles250_accepts_",
                expected: "oa_miles250_exp_accepts_",
                ratio: "oa_miles250_hr_mn_",
                ratiolow: "oa_miles250_hr_lb_",
                ratiohigh: "oa_miles250_hr_ub_"
            }

        case "Miles500":
            return {
                offers: "oa_miles500_offers_",
                accepts: "oa_miles500_accepts_",
                expected: "oa_miles500_exp_accepts_",
                ratio: "oa_miles500_hr_mn_",
                ratiolow: "oa_miles500_hr_lb_",
                ratiohigh: "oa_miles500_hr_ub_"
            }

        case "LowKDRI":
            return {
                offers: "oa_lowrisk_offers_",
                accepts: "oa_lowrisk_accepts_",
                expected: "oa_lowrisk_exp_accepts_",
                ratio: "oa_lowrisk_hr_mn_",
                ratiolow: "oa_lowrisk_hr_lb_",
                ratiohigh: "oa_lowrisk_hr_ub_"
            }

        case "MediumKDRI":
            return {
                offers: "oa_mediumrisk_offers_",
                accepts: "oa_mediumrisk_accepts_",
                expected: "oa_mediumrisk_exp_accepts_",
                ratio: "oa_mediumrisk_hr_mn_",
                ratiolow: "oa_mediumrisk_hr_lb_",
                ratiohigh: "oa_mediumrisk_hr_ub_"
            }

        case "HighKDRI":
            return {
                offers: "oa_highrisk_offers_",
                accepts: "oa_highrisk_accepts_",
                expected: "oa_highrisk_exp_accepts_",
                ratio: "oa_highrisk_hr_mn_",
                ratiolow: "oa_highrisk_hr_lb_",
                ratiohigh: "oa_highrisk_hr_ub_"
            }

        case "HTP100":
            return {
                offers: "oa_hardtoplace100_offers_",
                accepts: "oa_hardtoplace100_accepts_",
                expected: "oa_hardtoplace100_exp_accepts_",
                ratio: "oa_hardtoplace100_hr_mn_",
                ratiolow: "oa_hardtoplace100_hr_lb_",
                ratiohigh: "oa_hardtoplace100_hr_ub_"
            }

        case "HighRiskDonor":
            return {
                offers: "oa_highriskdonor_offers_",
                accepts: "oa_highriskdonor_accepts_",
                expected: "oa_highriskdonor_exp_accepts_",
                ratio: "oa_highriskdonor_hr_mn_",
                ratiolow: "oa_highriskdonor_hr_lb_",
                ratiohigh: "oa_highriskdonor_hr_ub_"
            }

        case "HighBMI":
            return {
                offers: "oa_highbmi28_offers_",
                accepts: "oa_highbmi28_accepts_",
                expected: "oa_highbmi28_exp_accepts_",
                ratio: "oa_highbmi28_hr_mn_",
                ratiolow: "oa_highbmi28_hr_lb_",
                ratiohigh: "oa_highbmi28_hr_ub_"
            }
        default:
    }
}