export function formatOneDecimal(num) {
    if (num !== null && !isNaN(num)) {
        return parseFloat(num.toFixed(1));
    }
    return "--";
}

export function formatTwoDecimals(num) {
    if (num !== null && !isNaN(num)) {
        return parseFloat(num.toFixed(2));
    }
    return "--";
}

export function formatThousands(num) {
    if (num !== null && !isNaN(num)) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    return "--";
}

export function formatThousandsOneDecimal(num) {
    if (num !== null && !isNaN(num)) {
        return parseFloat(num.toFixed(2)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    return "--";
}

export function formatPValueTwoDecimals(num){
    if (num !== null && !isNaN(num)) {
        if(num < .01)
        {
            return "<0.01";
        }
        else
        {
            return formatTwoDecimals(num);
        } 
    }
    return "--";
}


export const brandColorArray = [
    "#182474",
    "#2d62a1",
    "#43a7cb",
    "#9fd6ce",
    "#8c412b",
    "#e45d2c",
    "#ee9c48",
    "#fbdb68"
]
