export const heartLungOptions = [
    {
        label: "Race",
        value: [
            { label: "White", value: { opoYearOne: "rchl_rw_o1", opoYearTwo: "rchl_rw_o2", usYearOne: "rchl_rw_u1", usYearTwo: "rchl_rw_u2", category: "Race"}},
            { label: "African-American", value: { opoYearOne: "rchl_rb_o1", opoYearTwo: "rchl_rb_o2", usYearOne: "rchl_rb_u1", usYearTwo: "rchl_rb_u2", category: "Race"}},
            { label: "Hispanic/Latino", value: { opoYearOne: "rchl_rh_o1", opoYearTwo: "rchl_rh_o2", usYearOne: "rchl_rh_u1", usYearTwo: "rchl_rh_u2", category: "Race"}},
            { label: "Asian", value: { opoYearOne: "rchl_ra_o1", opoYearTwo: "rchl_ra_o2", usYearOne: "rchl_ra_u1", usYearTwo: "rchl_ra_u2", category: "Race"}},
            { label: "Other", value: { opoYearOne: "rchl_ro_o1", opoYearTwo: "rchl_ro_o2", usYearOne: "rchl_ro_u1", usYearTwo: "rchl_ro_u2", category: "Race"}},
        ]
    },
    {
        label: "Age",
        value: [
            { label: "0-1 years", value: { opoYearOne: "rchl_a2_o1", opoYearTwo: "rchl_a2_o2", usYearOne: "rchl_a2_u1", usYearTwo: "rchl_a2_u2", category: "Age"}},
            { label: "2-11 years", value: { opoYearOne: "rchl_a10_o1", opoYearTwo: "rchl_a10_o2", usYearOne: "rchl_a10_u1", usYearTwo: "rchl_a10_u2", category: "Age"}},
            { label: "12-17 years", value: { opoYearOne: "rchl_a17_o1", opoYearTwo: "rchl_a17_o2", usYearOne: "rchl_a17_u1", usYearTwo: "rchl_a17_u2", category: "Age"}},
            { label: "18-34 years", value: { opoYearOne: "rchl_a34_o1", opoYearTwo: "rchl_a34_o2", usYearOne: "rchl_a34_u1", usYearTwo: "rchl_a34_u2", category: "Age"}},
            { label: "35-49 years", value: { opoYearOne: "rchl_a49_o1", opoYearTwo: "rchl_a49_o2", usYearOne: "rchl_a49_u1", usYearTwo: "rchl_a49_u2", category: "Age"}},
            { label: "50-64 years", value: { opoYearOne: "rchl_a64_o1", opoYearTwo: "rchl_a64_o2", usYearOne: "rchl_a64_u1", usYearTwo: "rchl_a64_u2", category: "Age"}},
            { label: "65+ years", value: { opoYearOne: "rchl_a65p_o1", opoYearTwo: "rchl_a65p_o2", usYearOne: "rchl_a65p_u1", usYearTwo: "rchl_a65p_u2", category: "Age"}}
        ]
    },
    {
        label: "Gender",
        value: [
            { label: "Male", value: { opoYearOne: "rchl_gm_o1", opoYearTwo: "rchl_gm_o2", usYearOne: "rchl_gm_u1", usYearTwo: "rchl_gm_u2", category: "Gender"}},
            { label: "Female", value: { opoYearOne: "rchl_gf_o1", opoYearTwo: "rchl_gf_o2", usYearOne: "rchl_gf_u1", usYearTwo: "rchl_gf_u2", category: "Gender"}}
        ]
    },
    {
        label: "Blood Type",
        value: [
            { label: "O", value: { opoYearOne: "rchl_bo_o1", opoYearTwo: "rchl_bo_o2", usYearOne: "rchl_bo_u1", usYearTwo: "rchl_bo_u2", category: "Blood Type"}},
            { label: "A", value: { opoYearOne: "rchl_ba_o1", opoYearTwo: "rchl_ba_o2", usYearOne: "rchl_ba_u1", usYearTwo: "rchl_ba_u2", category: "Blood Type"}},
            { label: "B", value: { opoYearOne: "rchl_bb_o1", opoYearTwo: "rchl_bb_o2", usYearOne: "rchl_bb_u1", usYearTwo: "rchl_bb_u2", category: "Blood Type"}},
            { label: "AB", value: { opoYearOne: "rchl_bab_o1", opoYearTwo: "rchl_bab_o2", usYearOne: "rchl_bab_u1", usYearTwo: "rchl_bab_u2", category: "Blood Type"}}
        ]
    },
    {
        label: "Primary Cause of Disease",
        value: [
            { label: "Congenital Disease", value: { opoYearOne: "rchl_cod_o1", opoYearTwo: "rchl_cod_o2", usYearOne: "rchl_cod_u1", usYearTwo: "rchl_cod_u2", category: "Primary Cause of Disease"}},
            { label: "Primary Pulmonary Hypertension", value: { opoYearOne: "rchl_pph_o1", opoYearTwo: "rchl_pph_o2", usYearOne: "rchl_pph_u1", usYearTwo: "rchl_pph_u2", category: "Primary Cause of Disease"}},
            { label: "Cystic Fibrosis", value: { opoYearOne: "rchl_cyf_o1", opoYearTwo: "rchl_cyf_o2", usYearOne: "rchl_cyf_u1", usYearTwo: "rchl_cyf_u2", category: "Primary Cause of Disease"}},
            { label: "Idiopathic Pulmonary Fibrosis", value: { opoYearOne: "rchl_idp_o1", opoYearTwo: "rchl_idp_o2", usYearOne: "rchl_idp_u1", usYearTwo: "rchl_idp_u2", category: "Primary Cause of Disease"}},
            { label: "Alpha-1-antitrypsin Deficiency", value: { opoYearOne: "rchl_aad_o1", opoYearTwo: "rchl_aad_o2", usYearOne: "rchl_aad_u1", usYearTwo: "rchl_aad_u2", category: "Primary Cause of Disease"}},
            { label: "Emphysema/COPD", value: { opoYearOne: "rchl_eco_o1", opoYearTwo: "rchl_eco_o2", usYearOne: "rchl_eco_u1", usYearTwo: "rchl_eco_u2", category: "Primary Cause of Disease"}},
            { label: "Retransplant/Graft Failure", value: { opoYearOne: "rchl_ret_o1", opoYearTwo: "rchl_ret_o2", usYearOne: "rchl_ret_u1", usYearTwo: "rchl_ret_u2", category: "Primary Cause of Disease"}},
            { label: "Other", value: { opoYearOne: "rchl_oth_o1", opoYearTwo: "rchl_oth_o2", usYearOne: "rchl_oth_u1", usYearTwo: "rchl_oth_u2", category: "Primary Cause of Disease"}},
            { label: "Missing", value: { opoYearOne: "rchl_mis_o1", opoYearTwo: "rchl_mis_o2", usYearOne: "rchl_mis_u1", usYearTwo: "rchl_mis_u2", category: "Primary Cause of Disease"}}
        ]
    }
];

export const heartOptions = [
    {
        label: "Race",
        value: [
            { label: "White", value: { opoYearOne: "rchr_rw_o1", opoYearTwo: "rchr_rw_o2", usYearOne: "rchr_rw_u1", usYearTwo: "rchr_rw_u2", category: "Race"}},
            { label: "African-American", value: { opoYearOne: "rchr_rb_o1", opoYearTwo: "rchr_rb_o2", usYearOne: "rchr_rb_u1", usYearTwo: "rchr_rb_u2", category: "Race"}},
            { label: "Hispanic/Latino", value: { opoYearOne: "rchr_rh_o1", opoYearTwo: "rchr_rh_o2", usYearOne: "rchr_rh_u1", usYearTwo: "rchr_rh_u2", category: "Race"}},
            { label: "Asian", value: { opoYearOne: "rchr_ra_o1", opoYearTwo: "rchr_ra_o2", usYearOne: "rchr_ra_u1", usYearTwo: "rchr_ra_u2", category: "Race"}},
            { label: "Other", value: { opoYearOne: "rchr_ro_o1", opoYearTwo: "rchr_ro_o2", usYearOne: "rchr_ro_u1", usYearTwo: "rchr_ro_u2", category: "Race"}},
        ]
    },
    {
        label: "Age",
        value: [
            { label: "0-1 years", value: { opoYearOne: "rchr_a2_o1", opoYearTwo: "rchr_a2_o2", usYearOne: "rchr_a2_u1", usYearTwo: "rchr_a2_u2", category: "Age"}},
            { label: "2-11 years", value: { opoYearOne: "rchr_a10_o1", opoYearTwo: "rchr_a10_o2", usYearOne: "rchr_a10_u1", usYearTwo: "rchr_a10_u2", category: "Age"}},
            { label: "12-17 years", value: { opoYearOne: "rchr_a17_o1", opoYearTwo: "rchr_a17_o2", usYearOne: "rchr_a17_u1", usYearTwo: "rchr_a17_u2", category: "Age"}},
            { label: "18-34 years", value: { opoYearOne: "rchr_a34_o1", opoYearTwo: "rchr_a34_o2", usYearOne: "rchr_a34_u1", usYearTwo: "rchr_a34_u2", category: "Age"}},
            { label: "35-49 years", value: { opoYearOne: "rchr_a49_o1", opoYearTwo: "rchr_a49_o2", usYearOne: "rchr_a49_u1", usYearTwo: "rchr_a49_u2", category: "Age"}},
            { label: "50-64 years", value: { opoYearOne: "rchr_a64_o1", opoYearTwo: "rchr_a64_o2", usYearOne: "rchr_a64_u1", usYearTwo: "rchr_a64_u2", category: "Age"}},
            { label: "65+ years", value: { opoYearOne: "rchr_a65p_o1", opoYearTwo: "rchr_a65p_o2", usYearOne: "rchr_a65p_u1", usYearTwo: "rchr_a65p_u2", category: "Age"}}
        ]
    },
    {
        label: "Gender",
        value: [
            { label: "Male", value: { opoYearOne: "rchr_gm_o1", opoYearTwo: "rchr_gm_o2", usYearOne: "rchr_gm_u1", usYearTwo: "rchr_gm_u2", category: "Gender"}},
            { label: "Female", value: { opoYearOne: "rchr_gf_o1", opoYearTwo: "rchr_gf_o2", usYearOne: "rchr_gf_u1", usYearTwo: "rchr_gf_u2", category: "Gender"}}
        ]
    },
    {
        label: "Blood Type",
        value: [
            { label: "O", value: { opoYearOne: "rchr_bo_o1", opoYearTwo: "rchr_bo_o2", usYearOne: "rchr_bo_u1", usYearTwo: "rchr_bo_u2", category: "Blood Type"}},
            { label: "A", value: { opoYearOne: "rchr_ba_o1", opoYearTwo: "rchr_ba_o2", usYearOne: "rchr_ba_u1", usYearTwo: "rchr_ba_u2", category: "Blood Type"}},
            { label: "B", value: { opoYearOne: "rchr_bb_o1", opoYearTwo: "rchr_bb_o2", usYearOne: "rchr_bb_u1", usYearTwo: "rchr_bb_u2", category: "Blood Type"}},
            { label: "AB", value: { opoYearOne: "rchr_bab_o1", opoYearTwo: "rchr_bab_o2", usYearOne: "rchr_bab_u1", usYearTwo: "rchr_bab_u2", category: "Blood Type"}}
        ]
    },
    {
        label: "Medical Urgency Status",
        value: [
            { label: "1A", value: { opoYearOne: "rchr_lst1a_o1", opoYearTwo: "rchr_lst1a_o2", usYearOne: "rchr_lst1a_u1", usYearTwo: "rchr_lst1a_u2", category: "Medical Urgency Status"}},
            { label: "1B", value: { opoYearOne: "rchr_lst1b_o1", opoYearTwo: "rchr_lst1b_o2", usYearOne: "rchr_lst1b_u1", usYearTwo: "rchr_lst1b_u2", category: "Medical Urgency Status"}},
            { label: "2", value: { opoYearOne: "rchr_lst2_o1", opoYearTwo: "rchr_lst2_o2", usYearOne: "rchr_lst2_u1", usYearTwo: "rchr_lst2_u2", category: "Medical Urgency Status"}},
            { label: "Other", value: { opoYearOne: "rchr_loth_o1", opoYearTwo: "rchr_loth_o2", usYearOne: "rchr_loth_u1", usYearTwo: "rchr_loth_u2", category: "Medical Urgency Status"}}
        ]
    },
    {
        label: "Primary Cause of Disease",
        value: [
            { label: "Cardiomyopathy", value: { opoYearOne: "rchr_car_o1", opoYearTwo: "rchr_car_o2", usYearOne: "rchr_car_u1", usYearTwo: "rchr_car_u2", category: "Primary Cause of Disease"}},
            { label: "Coronary Artery Disease", value: { opoYearOne: "rchr_cad_o1", opoYearTwo: "rchr_cad_o2", usYearOne: "rchr_cad_u1", usYearTwo: "rchr_cad_u2", category: "Primary Cause of Disease"}},
            { label: "Valvular Heart Disease", value: { opoYearOne: "rchr_vhd_o1", opoYearTwo: "rchr_vhd_o2", usYearOne: "rchr_vhd_u1", usYearTwo: "rchr_vhd_u2", category: "Primary Cause of Disease"}},
            { label: "Congenital Heart Disease", value: { opoYearOne: "rchr_chd_o1", opoYearTwo: "rchr_chd_o2", usYearOne: "rchr_chd_u1", usYearTwo: "rchr_chd_u2", category: "Primary Cause of Disease"}},
            { label: "Retransplant/Graft Failure", value: { opoYearOne: "rchr_ret_o1", opoYearTwo: "rchr_ret_o2", usYearOne: "rchr_ret_u1", usYearTwo: "rchr_ret_u2", category: "Primary Cause of Disease"}},
            { label: "Other", value: { opoYearOne: "rchr_oth_o1", opoYearTwo: "rchr_oth_o2", usYearOne: "rchr_oth_u1", usYearTwo: "rchr_oth_u2", category: "Primary Cause of Disease"}},
            { label: "Missing", value: { opoYearOne: "rchr_mis_o1", opoYearTwo: "rchr_mis_o2", usYearOne: "rchr_mis_u1", usYearTwo: "rchr_mis_u2", category: "Primary Cause of Disease"}}
        ]
    }
];

export const intestineOptions = [
    {
        label: "Race",
        value: [
            { label: "White", value: { opoYearOne: "rcin_rw_o1", opoYearTwo: "rcin_rw_o2", usYearOne: "rcin_rw_u1", usYearTwo: "rcin_rw_u2", category: "Race"}},
            { label: "African-American", value: { opoYearOne: "rcin_rb_o1", opoYearTwo: "rcin_rb_o2", usYearOne: "rcin_rb_u1", usYearTwo: "rcin_rb_u2", category: "Race"}},
            { label: "Hispanic/Latino", value: { opoYearOne: "rcin_rh_o1", opoYearTwo: "rcin_rh_o2", usYearOne: "rcin_rh_u1", usYearTwo: "rcin_rh_u2", category: "Race"}},
            { label: "Asian", value: { opoYearOne: "rcin_ra_o1", opoYearTwo: "rcin_ra_o2", usYearOne: "rcin_ra_u1", usYearTwo: "rcin_ra_u2", category: "Race"}},
            { label: "Other", value: { opoYearOne: "rcin_ro_o1", opoYearTwo: "rcin_ro_o2", usYearOne: "rcin_ro_u1", usYearTwo: "rcin_ro_u2", category: "Race"}},
        ]
    },
    {
        label: "Age",
        value: [
            { label: "0-1 years", value: { opoYearOne: "rcin_a2_o1", opoYearTwo: "rcin_a2_o2", usYearOne: "rcin_a2_u1", usYearTwo: "rcin_a2_u2", category: "Age"}},
            { label: "2-11 years", value: { opoYearOne: "rcin_a10_o1", opoYearTwo: "rcin_a10_o2", usYearOne: "rcin_a10_u1", usYearTwo: "rcin_a10_u2", category: "Age"}},
            { label: "12-17 years", value: { opoYearOne: "rcin_a17_o1", opoYearTwo: "rcin_a17_o2", usYearOne: "rcin_a17_u1", usYearTwo: "rcin_a17_u2", category: "Age"}},
            { label: "18-34 years", value: { opoYearOne: "rcin_a34_o1", opoYearTwo: "rcin_a34_o2", usYearOne: "rcin_a34_u1", usYearTwo: "rcin_a34_u2", category: "Age"}},
            { label: "35-49 years", value: { opoYearOne: "rcin_a49_o1", opoYearTwo: "rcin_a49_o2", usYearOne: "rcin_a49_u1", usYearTwo: "rcin_a49_u2", category: "Age"}},
            { label: "50-64 years", value: { opoYearOne: "rcin_a64_o1", opoYearTwo: "rcin_a64_o2", usYearOne: "rcin_a64_u1", usYearTwo: "rcin_a64_u2", category: "Age"}},
            { label: "65+ years", value: { opoYearOne: "rcin_a65p_o1", opoYearTwo: "rcin_a65p_o2", usYearOne: "rcin_a65p_u1", usYearTwo: "rcin_a65p_u2", category: "Age"}}
        ]
    },
    {
        label: "Gender",
        value: [
            { label: "Male", value: { opoYearOne: "rcin_gm_o1", opoYearTwo: "rcin_gm_o2", usYearOne: "rcin_gm_u1", usYearTwo: "rcin_gm_u2", category: "Gender"}},
            { label: "Female", value: { opoYearOne: "rcin_gf_o1", opoYearTwo: "rcin_gf_o2", usYearOne: "rcin_gf_u1", usYearTwo: "rcin_gf_u2", category: "Gender"}}
        ]
    },
    {
        label: "Blood Type",
        value: [
            { label: "O", value: { opoYearOne: "rcin_bo_o1", opoYearTwo: "rcin_bo_o2", usYearOne: "rcin_bo_u1", usYearTwo: "rcin_bo_u2", category: "Blood Type"}},
            { label: "A", value: { opoYearOne: "rcin_ba_o1", opoYearTwo: "rcin_ba_o2", usYearOne: "rcin_ba_u1", usYearTwo: "rcin_ba_u2", category: "Blood Type"}},
            { label: "B", value: { opoYearOne: "rcin_bb_o1", opoYearTwo: "rcin_bb_o2", usYearOne: "rcin_bb_u1", usYearTwo: "rcin_bb_u2", category: "Blood Type"}},
            { label: "AB", value: { opoYearOne: "rcin_bab_o1", opoYearTwo: "rcin_bab_o2", usYearOne: "rcin_bab_u1", usYearTwo: "rcin_bab_u2", category: "Blood Type"}}
        ]
    },
    {
        label: "Primary Cause of Disease",
        value: [
            { label: "Short Gut Syndrome", value: { opoYearOne: "rcin_sgs_o1", opoYearTwo: "rcin_sgs_o2", usYearOne: "rcin_sgs_u1", usYearTwo: "rcin_sgs_u2", category: "Primary Cause of Disease"}},
            { label: "Functional Bowel Problem", value: { opoYearOne: "rcin_fbp_o1", opoYearTwo: "rcin_fbp_o2", usYearOne: "rcin_fbp_u1", usYearTwo: "rcin_fbp_u2", category: "Primary Cause of Disease"}},
            { label: "Other", value: { opoYearOne: "rcin_oth_o1", opoYearTwo: "rcin_oth_o2", usYearOne: "rcin_oth_u1", usYearTwo: "rcin_oth_u2", category: "Primary Cause of Disease"}},
            { label: "Missing", value: { opoYearOne: "rcin_mis_o1", opoYearTwo: "rcin_mis_o2", usYearOne: "rcin_mis_u1", usYearTwo: "rcin_mis_u2", category: "Primary Cause of Disease"}}
        ]
    }
];

export const kidneyOptions = [
    {
        label: "Race",
        value: [
            { label: "White", value: { opoYearOne: "rcki_rw_o1", opoYearTwo: "rcki_rw_o2", usYearOne: "rcki_rw_u1", usYearTwo: "rcki_rw_u2", category: "Race"}},
            { label: "African-American", value: { opoYearOne: "rcki_rb_o1", opoYearTwo: "rcki_rb_o2", usYearOne: "rcki_rb_u1", usYearTwo: "rcki_rb_u2", category: "Race"}},
            { label: "Hispanic/Latino", value: { opoYearOne: "rcki_rh_o1", opoYearTwo: "rcki_rh_o2", usYearOne: "rcki_rh_u1", usYearTwo: "rcki_rh_u2", category: "Race"}},
            { label: "Asian", value: { opoYearOne: "rcki_ra_o1", opoYearTwo: "rcki_ra_o2", usYearOne: "rcki_ra_u1", usYearTwo: "rcki_ra_u2", category: "Race"}},
            { label: "Other", value: { opoYearOne: "rcki_ro_o1", opoYearTwo: "rcki_ro_o2", usYearOne: "rcki_ro_u1", usYearTwo: "rcki_ro_u2", category: "Race"}},
        ]
    },
    {
        label: "Age",
        value: [
            { label: "0-1 years", value: { opoYearOne: "rcki_a2_o1", opoYearTwo: "rcki_a2_o2", usYearOne: "rcki_a2_u1", usYearTwo: "rcki_a2_u2", category: "Age"}},
            { label: "2-11 years", value: { opoYearOne: "rcki_a10_o1", opoYearTwo: "rcki_a10_o2", usYearOne: "rcki_a10_u1", usYearTwo: "rcki_a10_u2", category: "Age"}},
            { label: "12-17 years", value: { opoYearOne: "rcki_a17_o1", opoYearTwo: "rcki_a17_o2", usYearOne: "rcki_a17_u1", usYearTwo: "rcki_a17_u2", category: "Age"}},
            { label: "18-34 years", value: { opoYearOne: "rcki_a34_o1", opoYearTwo: "rcki_a34_o2", usYearOne: "rcki_a34_u1", usYearTwo: "rcki_a34_u2", category: "Age"}},
            { label: "35-49 years", value: { opoYearOne: "rcki_a49_o1", opoYearTwo: "rcki_a49_o2", usYearOne: "rcki_a49_u1", usYearTwo: "rcki_a49_u2", category: "Age"}},
            { label: "50-64 years", value: { opoYearOne: "rcki_a64_o1", opoYearTwo: "rcki_a64_o2", usYearOne: "rcki_a64_u1", usYearTwo: "rcki_a64_u2", category: "Age"}},
            { label: "65+ years", value: { opoYearOne: "rcki_a65p_o1", opoYearTwo: "rcki_a65p_o2", usYearOne: "rcki_a65p_u1", usYearTwo: "rcki_a65p_u2", category: "Age"}}
        ]
    },
    {
        label: "Gender",
        value: [
            { label: "Male", value: { opoYearOne: "rcki_gm_o1", opoYearTwo: "rcki_gm_o2", usYearOne: "rcki_gm_u1", usYearTwo: "rcki_gm_u2", category: "Gender"}},
            { label: "Female", value: { opoYearOne: "rcki_gf_o1", opoYearTwo: "rcki_gf_o2", usYearOne: "rcki_gf_u1", usYearTwo: "rcki_gf_u2", category: "Gender"}}
        ]
    },
    {
        label: "Blood Type",
        value: [
            { label: "O", value: { opoYearOne: "rcki_bo_o1", opoYearTwo: "rcki_bo_o2", usYearOne: "rcki_bo_u1", usYearTwo: "rcki_bo_u2", category: "Blood Type"}},
            { label: "A", value: { opoYearOne: "rcki_ba_o1", opoYearTwo: "rcki_ba_o2", usYearOne: "rcki_ba_u1", usYearTwo: "rcki_ba_u2", category: "Blood Type"}},
            { label: "B", value: { opoYearOne: "rcki_bb_o1", opoYearTwo: "rcki_bb_o2", usYearOne: "rcki_bb_u1", usYearTwo: "rcki_bb_u2", category: "Blood Type"}},
            { label: "AB", value: { opoYearOne: "rcki_bab_o1", opoYearTwo: "rcki_bab_o2", usYearOne: "rcki_bab_u1", usYearTwo: "rcki_bab_u2", category: "Blood Type"}}
        ]
    },
    {
        label: "Calculated Panel Reactive Antibody (CPRA %)",
        value: [
            { label: "0-9", value: { opoYearOne: "rcki_pra9_o1", opoYearTwo: "rcki_pra9_o2", usYearOne: "rcki_pra9_u1", usYearTwo: "rcki_pra9_u2", category: "calculated panel reactive antibody (cpra)"}},
            { label: "10-79", value: { opoYearOne: "rcki_pra79_o1", opoYearTwo: "rcki_pra79_o2", usYearOne: "rcki_pra79_u1", usYearTwo: "rcki_pra79_u2", category: "calculated panel reactive antibody (cpra)"}},
            { label: "80+", value: { opoYearOne: "rcki_pra80_o1", opoYearTwo: "rcki_pra80_o2", usYearOne: "rcki_pra80_u1", usYearTwo: "rcki_pra80_u2", category: "calculated panel reactive antibody (cpra)"}},
        ]
    },
    {
        label: "Primary Cause of Disease",
        value: [
            { label: "Glomerular Diseases", value: { opoYearOne: "rcki_glo_o1", opoYearTwo: "rcki_glo_o2", usYearOne: "rcki_glo_u1", usYearTwo: "rcki_glo_u2", category: "Primary Cause of Disease"}},
            { label: "Tubular and Interstitial Disease", value: { opoYearOne: "rcki_tub_o1", opoYearTwo: "rcki_tub_o2", usYearOne: "rcki_tub_u1", usYearTwo: "rcki_tub_u2", category: "Primary Cause of Disease"}},
            { label: "Polycystic Kidneys", value: { opoYearOne: "rcki_pol_o1", opoYearTwo: "rcki_pol_o2", usYearOne: "rcki_pol_u1", usYearTwo: "rcki_pol_u2", category: "Primary Cause of Disease"}},
            { label: "Congenital, Familial, Metabolic", value: { opoYearOne: "rcki_con_o1", opoYearTwo: "rcki_con_o2", usYearOne: "rcki_con_u1", usYearTwo: "rcki_con_u2", category: "Primary Cause of Disease"}},
            { label: "Diabetes", value: { opoYearOne: "rcki_dia_o1", opoYearTwo: "rcki_dia_o2", usYearOne: "rcki_dia_u1", usYearTwo: "rcki_dia_u2", category: "Primary Cause of Disease"}},
            { label: "Renovascular & Vascular Diseases", value: { opoYearOne: "rcki_vas_o1", opoYearTwo: "rcki_vas_o2", usYearOne: "rcki_vas_u1", usYearTwo: "rcki_vas_u2", category: "Primary Cause of Disease"}},
            { label: "Neoplasms", value: { opoYearOne: "rcki_neo_o1", opoYearTwo: "rcki_neo_o2", usYearOne: "rcki_neo_u1", usYearTwo: "rcki_neo_u2", category: "Primary Cause of Disease"}},
            { label: "Hypertensive Nephrosclerosis", value: { opoYearOne: "rcki_hyp_o1", opoYearTwo: "rcki_hyp_o2", usYearOne: "rcki_hyp_u1", usYearTwo: "rcki_hyp_u2", category: "Primary Cause of Disease"}},
            { label: "Other Kidney", value: { opoYearOne: "rcki_otk_o1", opoYearTwo: "rcki_otk_o2", usYearOne: "rcki_otk_u1", usYearTwo: "rcki_otk_u2", category: "Primary Cause of Disease"}},
            { label: "Missing", value: { opoYearOne: "rcki_mis_o1", opoYearTwo: "rcki_mis_o2", usYearOne: "rcki_mis_u1", usYearTwo: "rcki_mis_u2", category: "Primary Cause of Disease"}}
        ]
    }
];

export const kidneyPancreasOptions = [
    {
        label: "Race",
        value: [
            { label: "White", value: { opoYearOne: "rckp_rw_o1", opoYearTwo: "rckp_rw_o2", usYearOne: "rckp_rw_u1", usYearTwo: "rckp_rw_u2", category: "Race"}},
            { label: "African-American", value: { opoYearOne: "rckp_rb_o1", opoYearTwo: "rckp_rb_o2", usYearOne: "rckp_rb_u1", usYearTwo: "rckp_rb_u2", category: "Race"}},
            { label: "Hispanic/Latino", value: { opoYearOne: "rckp_rh_o1", opoYearTwo: "rckp_rh_o2", usYearOne: "rckp_rh_u1", usYearTwo: "rckp_rh_u2", category: "Race"}},
            { label: "Asian", value: { opoYearOne: "rckp_ra_o1", opoYearTwo: "rckp_ra_o2", usYearOne: "rckp_ra_u1", usYearTwo: "rckp_ra_u2", category: "Race"}},
            { label: "Other", value: { opoYearOne: "rckp_ro_o1", opoYearTwo: "rckp_ro_o2", usYearOne: "rckp_ro_u1", usYearTwo: "rckp_ro_u2", category: "Race"}},
        ]
    },
    {
        label: "Age",
        value: [
            { label: "0-1 years", value: { opoYearOne: "rckp_a2_o1", opoYearTwo: "rckp_a2_o2", usYearOne: "rckp_a2_u1", usYearTwo: "rckp_a2_u2", category: "Age"}},
            { label: "2-11 years", value: { opoYearOne: "rckp_a10_o1", opoYearTwo: "rckp_a10_o2", usYearOne: "rckp_a10_u1", usYearTwo: "rckp_a10_u2", category: "Age"}},
            { label: "12-17 years", value: { opoYearOne: "rckp_a17_o1", opoYearTwo: "rckp_a17_o2", usYearOne: "rckp_a17_u1", usYearTwo: "rckp_a17_u2", category: "Age"}},
            { label: "18-34 years", value: { opoYearOne: "rckp_a34_o1", opoYearTwo: "rckp_a34_o2", usYearOne: "rckp_a34_u1", usYearTwo: "rckp_a34_u2", category: "Age"}},
            { label: "35-49 years", value: { opoYearOne: "rckp_a49_o1", opoYearTwo: "rckp_a49_o2", usYearOne: "rckp_a49_u1", usYearTwo: "rckp_a49_u2", category: "Age"}},
            { label: "50-64 years", value: { opoYearOne: "rckp_a64_o1", opoYearTwo: "rckp_a64_o2", usYearOne: "rckp_a64_u1", usYearTwo: "rckp_a64_u2", category: "Age"}},
            { label: "65+ years", value: { opoYearOne: "rckp_a65p_o1", opoYearTwo: "rckp_a65p_o2", usYearOne: "rckp_a65p_u1", usYearTwo: "rckp_a65p_u2", category: "Age"}}
        ]
    },
    {
        label: "Gender",
        value: [
            { label: "Male", value: { opoYearOne: "rckp_gm_o1", opoYearTwo: "rckp_gm_o2", usYearOne: "rckp_gm_u1", usYearTwo: "rckp_gm_u2", category: "Gender"}},
            { label: "Female", value: { opoYearOne: "rckp_gf_o1", opoYearTwo: "rckp_gf_o2", usYearOne: "rckp_gf_u1", usYearTwo: "rckp_gf_u2", category: "Gender"}}
        ]
    },
    {
        label: "Blood Type",
        value: [
            { label: "O", value: { opoYearOne: "rckp_bo_o1", opoYearTwo: "rckp_bo_o2", usYearOne: "rckp_bo_u1", usYearTwo: "rckp_bo_u2", category: "Blood Type"}},
            { label: "A", value: { opoYearOne: "rckp_ba_o1", opoYearTwo: "rckp_ba_o2", usYearOne: "rckp_ba_u1", usYearTwo: "rckp_ba_u2", category: "Blood Type"}},
            { label: "B", value: { opoYearOne: "rckp_bb_o1", opoYearTwo: "rckp_bb_o2", usYearOne: "rckp_bb_u1", usYearTwo: "rckp_bb_u2", category: "Blood Type"}},
            { label: "AB", value: { opoYearOne: "rckp_bab_o1", opoYearTwo: "rckp_bab_o2", usYearOne: "rckp_bab_u1", usYearTwo: "rckp_bab_u2", category: "Blood Type"}}
        ]
    },
    {
        label: "Calculated Panel Reactive Antibody (CPRA %)",
        value: [
            { label: "0-9", value: { opoYearOne: "rckp_pra9_o1", opoYearTwo: "rckp_pra9_o2", usYearOne: "rckp_pra9_u1", usYearTwo: "rckp_pra9_u2", category: "calculated panel reactive antibody (cpra)"}},
            { label: "10-79", value: { opoYearOne: "rckp_pra79_o1", opoYearTwo: "rckp_pra79_o2", usYearOne: "rckp_pra79_u1", usYearTwo: "rckp_pra79_u2", category: "calculated panel reactive antibody (cpra)"}},
            { label: "80+", value: { opoYearOne: "rckp_pra80_o1", opoYearTwo: "rckp_pra80_o2", usYearOne: "rckp_pra80_u1", usYearTwo: "rckp_pra80_u2", category: "calculated panel reactive antibody (cpra)"}},
        ]
    }
];

export const liverOptions = [
    {
        label: "Race",
       value: [
            { label: "White", value: { opoYearOne: "rcli_rw_o1", opoYearTwo: "rcli_rw_o2", usYearOne: "rcli_rw_u1", usYearTwo: "rcli_rw_u2", category: "Race"}},
            { label: "African-American", value: { opoYearOne: "rcli_rb_o1", opoYearTwo: "rcli_rb_o2", usYearOne: "rcli_rb_u1", usYearTwo: "rcli_rb_u2", category: "Race"}},
            { label: "Hispanic/Latino", value: { opoYearOne: "rcli_rh_o1", opoYearTwo: "rcli_rh_o2", usYearOne: "rcli_rh_u1", usYearTwo: "rcli_rh_u2", category: "Race"}},
            { label: "Asian", value: { opoYearOne: "rcli_ra_o1", opoYearTwo: "rcli_ra_o2", usYearOne: "rcli_ra_u1", usYearTwo: "rcli_ra_u2", category: "Race"}},
            { label: "Other", value: { opoYearOne: "rcli_ro_o1", opoYearTwo: "rcli_ro_o2", usYearOne: "rcli_ro_u1", usYearTwo: "rcli_ro_u2", category: "Race"}},
        ]
    },
    {
        label: "Age",
       value: [
            { label: "0-1 years", value: { opoYearOne: "rcli_a2_o1", opoYearTwo: "rcli_a2_o2", usYearOne: "rcli_a2_u1", usYearTwo: "rcli_a2_u2", category: "Age"}},
            { label: "2-11 years", value: { opoYearOne: "rcli_a10_o1", opoYearTwo: "rcli_a10_o2", usYearOne: "rcli_a10_u1", usYearTwo: "rcli_a10_u2", category: "Age"}},
            { label: "12-17 years", value: { opoYearOne: "rcli_a17_o1", opoYearTwo: "rcli_a17_o2", usYearOne: "rcli_a17_u1", usYearTwo: "rcli_a17_u2", category: "Age"}},
            { label: "18-34 years", value: { opoYearOne: "rcli_a34_o1", opoYearTwo: "rcli_a34_o2", usYearOne: "rcli_a34_u1", usYearTwo: "rcli_a34_u2", category: "Age"}},
            { label: "35-49 years", value: { opoYearOne: "rcli_a49_o1", opoYearTwo: "rcli_a49_o2", usYearOne: "rcli_a49_u1", usYearTwo: "rcli_a49_u2", category: "Age"}},
            { label: "50-64 years", value: { opoYearOne: "rcli_a64_o1", opoYearTwo: "rcli_a64_o2", usYearOne: "rcli_a64_u1", usYearTwo: "rcli_a64_u2", category: "Age"}},
            { label: "65+ years", value: { opoYearOne: "rcli_a65p_o1", opoYearTwo: "rcli_a65p_o2", usYearOne: "rcli_a65p_u1", usYearTwo: "rcli_a65p_u2", category: "Age"}}
        ]
    },
    {
        label: "Gender",
       value: [
            { label: "Male", value: { opoYearOne: "rcli_gm_o1", opoYearTwo: "rcli_gm_o2", usYearOne: "rcli_gm_u1", usYearTwo: "rcli_gm_u2", category: "Gender"}},
            { label: "Female", value: { opoYearOne: "rcli_gf_o1", opoYearTwo: "rcli_gf_o2", usYearOne: "rcli_gf_u1", usYearTwo: "rcli_gf_u2", category: "Gender"}}
        ]
    },
    {
        label: "Blood Type",
       value: [
            { label: "O", value: { opoYearOne: "rcli_bo_o1", opoYearTwo: "rcli_bo_o2", usYearOne: "rcli_bo_u1", usYearTwo: "rcli_bo_u2", category: "Blood Type"}},
            { label: "A", value: { opoYearOne: "rcli_ba_o1", opoYearTwo: "rcli_ba_o2", usYearOne: "rcli_ba_u1", usYearTwo: "rcli_ba_u2", category: "Blood Type"}},
            { label: "B", value: { opoYearOne: "rcli_bb_o1", opoYearTwo: "rcli_bb_o2", usYearOne: "rcli_bb_u1", usYearTwo: "rcli_bb_u2", category: "Blood Type"}},
            { label: "AB", value: { opoYearOne: "rcli_bab_o1", opoYearTwo: "rcli_bab_o2", usYearOne: "rcli_bab_u1", usYearTwo: "rcli_bab_u2", category: "Blood Type"}}
        ]
    },
    {
        label: "Medical Urgency Status",
       value: [
            { label: "Status 1A", value: { opoYearOne: "rcli_lst1a_o1", opoYearTwo: "rcli_lst1a_o2", usYearOne: "rcli_lst1a_u1", usYearTwo: "rcli_lst1a_u2", category: "Medical Urgency Status"}},
            { label: "Status 1B", value: { opoYearOne: "rcli_lst1b_o1", opoYearTwo: "rcli_lst1b_o2", usYearOne: "rcli_lst1b_u1", usYearTwo: "rcli_lst1b_u2", category: "Medical Urgency Status"}},
            { label: "MELD 6-10", value: { opoYearOne: "rcli_me6_o1", opoYearTwo: "rcli_me6_o2", usYearOne: "rcli_me6_u1", usYearTwo: "rcli_me6_u2", category: "Medical Urgency Status"}},
            { label: "MELD 11-14", value: { opoYearOne: "rcli_me11_o1", opoYearTwo: "rcli_me11_o2", usYearOne: "rcli_me11_u1", usYearTwo: "rcli_me11_u2", category: "Medical Urgency Status"}},
            { label: "MELD 15-20", value: { opoYearOne: "rcli_me15_o1", opoYearTwo: "rcli_me15_o2", usYearOne: "rcli_me15_u1", usYearTwo: "rcli_me15_u2", category: "Medical Urgency Status"}},
            { label: "MELD 21-30", value: { opoYearOne: "rcli_me21_o1", opoYearTwo: "rcli_me21_o2", usYearOne: "rcli_me21_u1", usYearTwo: "rcli_me21_u2", category: "Medical Urgency Status"}},
            { label: "MELD 31-40", value: { opoYearOne: "rcli_me31_o1", opoYearTwo: "rcli_me31_o2", usYearOne: "rcli_me31_u1", usYearTwo: "rcli_me31_u2", category: "Medical Urgency Status"}},
            { label: "PELD <= 10", value: { opoYearOne: "rcli_pe6_o1", opoYearTwo: "rcli_pe6_o2", usYearOne: "rcli_pe6_u1", usYearTwo: "rcli_pe6_u2", category: "Medical Urgency Status"}},
            { label: "PELD 11-14", value: { opoYearOne: "rcli_pe11_o1", opoYearTwo: "rcli_pe11_o2", usYearOne: "rcli_pe11_u1", usYearTwo: "rcli_pe11_u2", category: "Medical Urgency Status"}},
            { label: "PELD 15-20", value: { opoYearOne: "rcli_pe15_o1", opoYearTwo: "rcli_pe15_o2", usYearOne: "rcli_pe15_u1", usYearTwo: "rcli_pe15_u2", category: "Medical Urgency Status"}},
            { label: "PELD 21-30", value: { opoYearOne: "rcli_pe21_o1", opoYearTwo: "rcli_pe21_o2", usYearOne: "rcli_pe21_u1", usYearTwo: "rcli_pe21_u2", category: "Medical Urgency Status"}},
            { label: "PELD >=31", value: { opoYearOne: "rcli_pe31_o1", opoYearTwo: "rcli_pe31_o2", usYearOne: "rcli_pe31_u1", usYearTwo: "rcli_pe31_u2", category: "Medical Urgency Status"}}
        ]
    },
    {
        label: "Primary Cause of Disease",
       value: [
            { label: "Acute Hepatic Necrosis", value: { opoYearOne: "rcli_nec_o1", opoYearTwo: "rcli_nec_o2", usYearOne: "rcli_nec_u1", usYearTwo: "rcli_nec_u2", category: "Primary Cause of Disease"}},
            { label: "Non-cholestatic Cirrhosis", value: { opoYearOne: "rcli_ncc_o1", opoYearTwo: "rcli_ncc_o2", usYearOne: "rcli_ncc_u1", usYearTwo: "rcli_ncc_u2", category: "Primary Cause of Disease"}},
            { label: "Cholestatic Liver Disease/Cirrhosis", value: { opoYearOne: "rcli_cld_o1", opoYearTwo: "rcli_cld_o2", usYearOne: "rcli_cld_u1", usYearTwo: "rcli_cld_u2", category: "Primary Cause of Disease"}},
            { label: "Biliary Atresia", value: { opoYearOne: "rcli_bat_o1", opoYearTwo: "rcli_bat_o2", usYearOne: "rcli_bat_u1", usYearTwo: "rcli_bat_u2", category: "Primary Cause of Disease"}},
            { label: "Metabolic Diseases", value: { opoYearOne: "rcli_met_o1", opoYearTwo: "rcli_met_o2", usYearOne: "rcli_met_u1", usYearTwo: "rcli_met_u2", category: "Primary Cause of Disease"}},
            { label: "Malignant Neoplasms", value: { opoYearOne: "rcli_neo_o1", opoYearTwo: "rcli_neo_o2", usYearOne: "rcli_neo_u1", usYearTwo: "rcli_neo_u2", category: "Primary Cause of Disease"}},
            { label: "Other", value: { opoYearOne: "rcli_oth_o1", opoYearTwo: "rcli_oth_o2", usYearOne: "rcli_oth_u1", usYearTwo: "rcli_oth_u2", category: "Primary Cause of Disease"}},
            { label: "Missing", value: { opoYearOne: "rcli_mis_o1", opoYearTwo: "rcli_mis_o2", usYearOne: "rcli_mis_u1", usYearTwo: "rcli_mis_u2", category: "Primary Cause of Disease"}}
        ]
    }
];

export const lungOptions = [
    {
        label: "Race",
       value: [
            { label: "White", value: { opoYearOne: "rclu_rw_o1", opoYearTwo: "rclu_rw_o2", usYearOne: "rclu_rw_u1", usYearTwo: "rclu_rw_u2", category: "Race"}},
            { label: "African-American", value: { opoYearOne: "rclu_rb_o1", opoYearTwo: "rclu_rb_o2", usYearOne: "rclu_rb_u1", usYearTwo: "rclu_rb_u2", category: "Race"}},
            { label: "Hispanic/Latino", value: { opoYearOne: "rclu_rh_o1", opoYearTwo: "rclu_rh_o2", usYearOne: "rclu_rh_u1", usYearTwo: "rclu_rh_u2", category: "Race"}},
            { label: "Asian", value: { opoYearOne: "rclu_ra_o1", opoYearTwo: "rclu_ra_o2", usYearOne: "rclu_ra_u1", usYearTwo: "rclu_ra_u2", category: "Race"}},
            { label: "Other", value: { opoYearOne: "rclu_ro_o1", opoYearTwo: "rclu_ro_o2", usYearOne: "rclu_ro_u1", usYearTwo: "rclu_ro_u2", category: "Race"}},
        ]
    },
    {
        label: "Age",
       value: [
            { label: "0-1 years", value: { opoYearOne: "rclu_a2_o1", opoYearTwo: "rclu_a2_o2", usYearOne: "rclu_a2_u1", usYearTwo: "rclu_a2_u2", category: "Age"}},
            { label: "2-11 years", value: { opoYearOne: "rclu_a10_o1", opoYearTwo: "rclu_a10_o2", usYearOne: "rclu_a10_u1", usYearTwo: "rclu_a10_u2", category: "Age"}},
            { label: "12-17 years", value: { opoYearOne: "rclu_a17_o1", opoYearTwo: "rclu_a17_o2", usYearOne: "rclu_a17_u1", usYearTwo: "rclu_a17_u2", category: "Age"}},
            { label: "18-34 years", value: { opoYearOne: "rclu_a34_o1", opoYearTwo: "rclu_a34_o2", usYearOne: "rclu_a34_u1", usYearTwo: "rclu_a34_u2", category: "Age"}},
            { label: "35-49 years", value: { opoYearOne: "rclu_a49_o1", opoYearTwo: "rclu_a49_o2", usYearOne: "rclu_a49_u1", usYearTwo: "rclu_a49_u2", category: "Age"}},
            { label: "50-64 years", value: { opoYearOne: "rclu_a64_o1", opoYearTwo: "rclu_a64_o2", usYearOne: "rclu_a64_u1", usYearTwo: "rclu_a64_u2", category: "Age"}},
            { label: "65+ years", value: { opoYearOne: "rclu_a65p_o1", opoYearTwo: "rclu_a65p_o2", usYearOne: "rclu_a65p_u1", usYearTwo: "rclu_a65p_u2", category: "Age"}}
        ]
    },
    {
        label: "Gender",
       value: [
            { label: "Male", value: { opoYearOne: "rclu_gm_o1", opoYearTwo: "rclu_gm_o2", usYearOne: "rclu_gm_u1", usYearTwo: "rclu_gm_u2", category: "Gender"}},
            { label: "Female", value: { opoYearOne: "rclu_gf_o1", opoYearTwo: "rclu_gf_o2", usYearOne: "rclu_gf_u1", usYearTwo: "rclu_gf_u2", category: "Gender"}}
        ]
    },
    {
        label: "Blood Type",
       value: [
            { label: "O", value: { opoYearOne: "rclu_bo_o1", opoYearTwo: "rclu_bo_o2", usYearOne: "rclu_bo_u1", usYearTwo: "rclu_bo_u2", category: "Blood Type"}},
            { label: "A", value: { opoYearOne: "rclu_ba_o1", opoYearTwo: "rclu_ba_o2", usYearOne: "rclu_ba_u1", usYearTwo: "rclu_ba_u2", category: "Blood Type"}},
            { label: "B", value: { opoYearOne: "rclu_bb_o1", opoYearTwo: "rclu_bb_o2", usYearOne: "rclu_bb_u1", usYearTwo: "rclu_bb_u2", category: "Blood Type"}},
            { label: "AB", value: { opoYearOne: "rclu_bab_o1", opoYearTwo: "rclu_bab_o2", usYearOne: "rclu_bab_u1", usYearTwo: "rclu_bab_u2", category: "Blood Type"}}
        ]
    },
    {
        label: "Primary Cause of Disease",
       value: [
            { label: "Congenital Disease", value: { opoYearOne: "rclu_cod_o1", opoYearTwo: "rclu_cod_o2", usYearOne: "rclu_cod_u1", usYearTwo: "rclu_cod_u2", category: "Primary Cause of Disease"}},
            { label: "Primary Pulmonary Hypertension", value: { opoYearOne: "rclu_pph_o1", opoYearTwo: "rclu_pph_o2", usYearOne: "rclu_pph_u1", usYearTwo: "rclu_pph_u2", category: "Primary Cause of Disease"}},
            { label: "Cystic Fibrosis", value: { opoYearOne: "rclu_cyf_o1", opoYearTwo: "rclu_cyf_o2", usYearOne: "rclu_cyf_u1", usYearTwo: "rclu_cyf_u2", category: "Primary Cause of Disease"}},
            { label: "Idiopathic Pulmonary Fibrosis", value: { opoYearOne: "rclu_idp_o1", opoYearTwo: "rclu_idp_o2", usYearOne: "rclu_idp_u1", usYearTwo: "rclu_idp_u2", category: "Primary Cause of Disease"}},
            { label: "Emphysema/COPD", value: { opoYearOne: "rclu_eco_o1", opoYearTwo: "rclu_eco_o2", usYearOne: "rclu_eco_u1", usYearTwo: "rclu_eco_u2", category: "Primary Cause of Disease"}},
            { label: "Retransplant/Graft Failure", value: { opoYearOne: "rclu_ret_o1", opoYearTwo: "rclu_ret_o2", usYearOne: "rclu_ret_u1", usYearTwo: "rclu_ret_u2", category: "Primary Cause of Disease"}},
            { label: "Other", value: { opoYearOne: "rclu_oth_o1", opoYearTwo: "rclu_oth_o2", usYearOne: "rclu_oth_u1", usYearTwo: "rclu_oth_u2", category: "Primary Cause of Disease"}},
            { label: "Missing", value: { opoYearOne: "rclu_mis_o1", opoYearTwo: "rclu_mis_o2", usYearOne: "rclu_mis_u1", usYearTwo: "rclu_mis_u2", category: "Primary Cause of Disease"}}
        ]
    }
];

export const pancreasOptions = [
    {
        label: "Race",
       value: [
            { label: "White", value: { opoYearOne: "rcpa_rw_o1", opoYearTwo: "rcpa_rw_o2", usYearOne: "rcpa_rw_u1", usYearTwo: "rcpa_rw_u2", category: "Race"}},
            { label: "African-American", value: { opoYearOne: "rcpa_rb_o1", opoYearTwo: "rcpa_rb_o2", usYearOne: "rcpa_rb_u1", usYearTwo: "rcpa_rb_u2", category: "Race"}},
            { label: "Hispanic/Latino", value: { opoYearOne: "rcpa_rh_o1", opoYearTwo: "rcpa_rh_o2", usYearOne: "rcpa_rh_u1", usYearTwo: "rcpa_rh_u2", category: "Race"}},
            { label: "Asian", value: { opoYearOne: "rcpa_ra_o1", opoYearTwo: "rcpa_ra_o2", usYearOne: "rcpa_ra_u1", usYearTwo: "rcpa_ra_u2", category: "Race"}},
            { label: "Other", value: { opoYearOne: "rcpa_ro_o1", opoYearTwo: "rcpa_ro_o2", usYearOne: "rcpa_ro_u1", usYearTwo: "rcpa_ro_u2", category: "Race"}},
        ]
    },
    {
        label: "Age",
       value: [
            { label: "0-1 years", value: { opoYearOne: "rcpa_a2_o1", opoYearTwo: "rcpa_a2_o2", usYearOne: "rcpa_a2_u1", usYearTwo: "rcpa_a2_u2", category: "Age"}},
            { label: "2-11 years", value: { opoYearOne: "rcpa_a10_o1", opoYearTwo: "rcpa_a10_o2", usYearOne: "rcpa_a10_u1", usYearTwo: "rcpa_a10_u2", category: "Age"}},
            { label: "12-17 years", value: { opoYearOne: "rcpa_a17_o1", opoYearTwo: "rcpa_a17_o2", usYearOne: "rcpa_a17_u1", usYearTwo: "rcpa_a17_u2", category: "Age"}},
            { label: "18-34 years", value: { opoYearOne: "rcpa_a34_o1", opoYearTwo: "rcpa_a34_o2", usYearOne: "rcpa_a34_u1", usYearTwo: "rcpa_a34_u2", category: "Age"}},
            { label: "35-49 years", value: { opoYearOne: "rcpa_a49_o1", opoYearTwo: "rcpa_a49_o2", usYearOne: "rcpa_a49_u1", usYearTwo: "rcpa_a49_u2", category: "Age"}},
            { label: "50-64 years", value: { opoYearOne: "rcpa_a64_o1", opoYearTwo: "rcpa_a64_o2", usYearOne: "rcpa_a64_u1", usYearTwo: "rcpa_a64_u2", category: "Age"}},
            { label: "65+ years", value: { opoYearOne: "rcpa_a65p_o1", opoYearTwo: "rcpa_a65p_o2", usYearOne: "rcpa_a65p_u1", usYearTwo: "rcpa_a65p_u2", category: "Age"}}
        ]
    },
    {
        label: "Gender",
       value: [
            { label: "Male", value: { opoYearOne: "rcpa_gm_o1", opoYearTwo: "rcpa_gm_o2", usYearOne: "rcpa_gm_u1", usYearTwo: "rcpa_gm_u2", category: "Gender"}},
            { label: "Female", value: { opoYearOne: "rcpa_gf_o1", opoYearTwo: "rcpa_gf_o2", usYearOne: "rcpa_gf_u1", usYearTwo: "rcpa_gf_u2", category: "Gender"}}
        ]
    },
    {
        label: "Blood Type",
       value: [
            { label: "O", value: { opoYearOne: "rcpa_bo_o1", opoYearTwo: "rcpa_bo_o2", usYearOne: "rcpa_bo_u1", usYearTwo: "rcpa_bo_u2", category: "Blood Type"}},
            { label: "A", value: { opoYearOne: "rcpa_ba_o1", opoYearTwo: "rcpa_ba_o2", usYearOne: "rcpa_ba_u1", usYearTwo: "rcpa_ba_u2", category: "Blood Type"}},
            { label: "B", value: { opoYearOne: "rcpa_bb_o1", opoYearTwo: "rcpa_bb_o2", usYearOne: "rcpa_bb_u1", usYearTwo: "rcpa_bb_u2", category: "Blood Type"}},
            { label: "AB", value: { opoYearOne: "rcpa_bab_o1", opoYearTwo: "rcpa_bab_o2", usYearOne: "rcpa_bab_u1", usYearTwo: "rcpa_bab_u2", category: "Blood Type"}}
        ]
    },
    {
        label: "Calculated Panel Reactive Antibody (CPRA %)",
        value: [
            { label: "0-9", value: { opoYearOne: "rcpa_pra9_o1", opoYearTwo: "rcpa_pra9_o2", usYearOne: "rcpa_pra9_u1", usYearTwo: "rcpa_pra9_u2", category: "calculated panel reactive antibody (cpra)"}},
            { label: "10-79", value: { opoYearOne: "rcpa_pra79_o1", opoYearTwo: "rcpa_pra79_o2", usYearOne: "rcpa_pra79_u1", usYearTwo: "rcpa_pra79_u2", category: "calculated panel reactive antibody (cpra)"}},
            { label: "80+", value: { opoYearOne: "rcpa_pra80_o1", opoYearTwo: "rcpa_pra80_o2", usYearOne: "rcpa_pra80_u1", usYearTwo: "rcpa_pra80_u2", category: "calculated panel reactive antibody (cpra)"}},
        ]
    }
];
