import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Table from "react-bootstrap/Table";
import IconList from "../../../utilities/organs/IconList";
import moment from "moment";

function ReceivingProgramsTable(props) {
    const [open, setOpen] = useState(false);
    return (
        <>
        <hr />
            <div className="collapse-container show-all-row">
                Programs Transplanting Organs Procured by {props.opoCode}  &nbsp;&nbsp;
            <Button
                    onClick={() => setOpen(!open)}
                    aria-controls="collapse-text"
                    aria-expanded={open}
                    className="btn-dark-blue min-98"
                >
                    {!open && "Show All"}
                    {open && "Hide All"}
                </Button>
            </div>
            <Collapse in={open}>
                <div id="collapse-text" className="collapse-inner">
                    <div className="transplant-program-list-scroll-wrapper">
                    <Table striped bordered className="overflow-auto transplant-program-list">
                        <thead>
                            <tr>
                                <th>Hospital</th>
                                <th>Organs Received From {props.opoCode.toUpperCase()}</th>
                            </tr>

                        </thead>
                        <tbody>
                            {props.data && props.data.map((item, i) => {
                                return [
                                    <tr key={item.properties.id}>
                                        <td>
                                            <div>{item.properties.name}</div>
                                            <div>{item.properties.city}, {item.properties.state}</div>
                                        </td>
                                        <td className="organ-cell">
                                            <div className="organs">
                                                <IconList item={item.properties} showcount={true} />
                                            </div>
                                        </td>
                                    </tr>
                                ];
                            })}
                        </tbody>
                    </Table>
                    <div className="foot-note text-right">
                            Programs transplanting organs procured by {props.opoCode}, {moment(new Date(props.data[0].properties.begDate)).format("MM/DD/YYYY")} to {moment(new Date(props.data[0].properties.endDate)).format("MM/DD/YYYY")}
                    </div>
                    </div>
                </div>
            </Collapse>
        </>
    );
}

export default ReceivingProgramsTable;