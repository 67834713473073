import React, {useState} from "react";
import Intestine from "../../../assets/images/organs/72x72/Intestine_Up_72x72.png";
import IntestineHover from "../../../assets/images/organs/72x72/Intestine_Over_72x72.png";

function IntestineIcon(props) {
    const [isHover, setIsHover] = useState(false);

    if (props.showHover) {
        return (
            <div 
                className={props.count ? "icon-container organ-count-wrapper" : "icon-container"}
                onMouseEnter={()=> setIsHover(true)}
                onMouseLeave={()=> setIsHover(false)}
            >
                <img src={isHover ? IntestineHover : Intestine} alt="Intestine Icon" className="organ-icon" />
                {props.count &&
                    <div className="organ-count intestine">
                        {props.count}
                    </div>
                }
            </div>
        );
    }
    else {
        return (<img src={Intestine} alt="Intestine Icon" className="organ-icon" />);
    }
}

export default IntestineIcon;