import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TransplantDonorCharacteristicsChart from "./TransplantDonorCharacteristicsChart";
import { demographicOptions } from "./Options";
import moment from "moment";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import {formatThousands} from "../../../utilities/formatter";

function TransplantDonorCharacteristics(props) {
    const [selectedDeceasedDemographicOption, setSelectedDeceasedDemographicOption] = useState(demographicOptions[0]);
    const [selectedLivingDemographicOption, setSelectedLivingDemographicOption] = useState(demographicOptions[1]);
    let livingDemographicOptions = demographicOptions.filter(obj => obj.label !== "Cause of Death");
    let isLivingDonor = props.organ === "ki" || props.organ === "li";

    let subTitle = "Transplants performed";
    if (!isLivingDonor && props.deceasedData) {
        subTitle = `Transplants performed between ${moment(new Date(props.deceasedData.begDate)).format("MM/DD/YYYY")} and ${moment(new Date(props.deceasedData.endDate)).format("MM/DD/YYYY")}`;
    }

    if (isLivingDonor && props.livingData) {
        subTitle = `Transplants performed between ${moment(new Date(props.livingData.begDate)).format("MM/DD/YYYY")} and ${moment(new Date(props.livingData.endDate)).format("MM/DD/YYYY")}`;
    }

    if (isLivingDonor && props.deceasedData) {
        subTitle = `Transplants performed between ${moment(new Date(props.deceasedData.begDate)).format("MM/DD/YYYY")} and ${moment(new Date(props.deceasedData.endDate)).format("MM/DD/YYYY")}`;
    }

    
    const _onDeceasedDemographicChange = (selectedDeceasedDemographicOption) => {
        setSelectedDeceasedDemographicOption(selectedDeceasedDemographicOption);
    }

    const _onLivingDemographicChange = (selectedLivingDemographicOption) => {
        setSelectedLivingDemographicOption(selectedLivingDemographicOption);
    }

    if (!props.isLoading) {
        return (
            <div className="transplant-recipients-wrapper">
                <Row>
                    <Col xs={12} className="mb-4">
                        <h1 className="section-title">
                            Transplant Donor Characteristics
                    </h1>
                        <div className="sub-title">
                            {subTitle}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={isLivingDonor ? 6 : 12}>
                        <div className="select-demographic-wrapper">
                            <div className="sub-title" id="deceasedDonorCharacteristicsLabel">
                                Deceased Donor Characteristic
                        </div>
                        <div className="center-region-us-numbers">
                                <div className="header">
                                    Center<br />
                                    (N={props.deceasedData ? formatThousands(props.deceasedData["dcc_all_c"]) : "NA"})
                                </div>
                                <div className="header">
                                    Region<br />
                                    (N={props.deceasedData ? formatThousands(props.deceasedData["dcc_all_r"]): "NA"})
                                </div>
                                <div className="header">
                                    U.S.<br />
                                    (N={props.deceasedData ? formatThousands(props.deceasedData["dcc_all_u"]): "NA"})
                                </div>
                            </div>
                            <Select
                                id="select-demographic"
                                className="select-demographic mt-2"
                                classNamePrefix="select"
                                isClearable={false}
                                isSearchable={true}
                                name="select-demographic"
                                options={demographicOptions}
                                onChange={_onDeceasedDemographicChange}
                                defaultValue={demographicOptions[0]}
                                aria-labelledby={`deceasedDonorCharacteristicsLabel`}
                            />
                        </div>
                        <TransplantDonorCharacteristicsChart
                            data={props.deceasedData}
                            type="deceased"
                            selectedOption={selectedDeceasedDemographicOption}
                            centerCode={props.centerCode}
                            title="Deceased Transplant Donor Characteristics"
                            subTitle={subTitle}

                        />
                    </Col>
                    {isLivingDonor &&
                        <Col lg={6}>
                            <div className="select-demographic-wrapper">
                                <div className="sub-title" id="livingDonorCharacteristicsLabel">
                                    Living Donor Characteristic
                        </div>
                        <div className="center-region-us-numbers">
                                <div className="header">
                                    Center<br />
                                    (N={props.livingData ? formatThousands(props.livingData["dcl_all_c"]): "NA"})
                                </div>
                                <div className="header">
                                    Region<br />
                                    (N={props.livingData ? formatThousands(props.livingData["dcl_all_r"]): "NA"})
                                </div>
                                <div className="header">
                                    U.S.<br />
                                    (N={props.livingData ? formatThousands(props.livingData["dcl_all_u"]): "NA"})
                                </div>
                            </div>
                                <Select
                                    id="select-demographic"
                                    className="select-demographic mt-2"
                                    classNamePrefix="select"
                                    isClearable={false}
                                    isSearchable={true}
                                    name="select-demographic"
                                    options={livingDemographicOptions}
                                    onChange={_onLivingDemographicChange}
                                    defaultValue={livingDemographicOptions[0]}
                                    aria-labelledby={`livingDonorCharacteristicsLabel`}
                                />
                            </div>
                            <TransplantDonorCharacteristicsChart
                                data={props.livingData}
                                type="living"
                                selectedOption={selectedLivingDemographicOption}
                                centerCode={props.centerCode}
                                title="Living Transplant Donor Characteristics"
                                subTitle={subTitle}
                            />
                        </Col>
                    }
                </Row>
            </div>
        );
    }
    else {
        return (
            <div className="transplant-recipients-wrapper">
                <Row>
                    <Col xs={12} className="mb-4">
                        <h1 className="section-title">
                            <Skeleton width={600} />
                        </h1>
                        <div className="sub-title">
                            <Skeleton width={500} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="background-teal psr-select-options">
                            <div className="select-living-deceased-wrapper">
                                <div className="sub-title">
                                    <Skeleton width={200} />
                                </div>
                                <Skeleton width={400} height={20} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <div className="select-demographic-wrapper">
                            <div className="sub-title">
                                <Skeleton width={400} />
                            </div>
                            <Skeleton width={400} height={20} />
                        </div>
                        <Skeleton height={500} />
                    </Col>
                    <Col lg={6}>
                        <div className="select-demographic-wrapper">
                            <div className="sub-title">
                                <Skeleton width={400} />
                            </div>
                            <Skeleton width={400} height={20} />
                        </div>
                        <Skeleton height={500} />
                    </Col>
                </Row>
            </div>
        );
    }
}
export default TransplantDonorCharacteristics;