import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import imgAdults from "../../../../assets/images/adults.png";
import imgChildren from "../../../../assets/images/children.png";

import imgBars1 from "../../../../assets/images/bars1.png";
import imgBars2 from "../../../../assets/images/bars2.png";
import imgBars3 from "../../../../assets/images/bars3.png";
import imgBars4 from "../../../../assets/images/bars4.png";
import imgBars5 from "../../../../assets/images/bars5.png";
import imgNA from "../../../../assets/images/na.png";
function TransplantSummary(props) {

    const [transplantType, setTransplantType] = useState("Adult");

    let imgIcon = transplantType === "Adult" ? imgAdults : imgChildren;
    let organName = "";
    let showLiving = false;

    if (props.organ === "ki") {
        organName = "Kidney";
        showLiving = true;
    }
    if (props.organ === "kp") {
        organName = "Kidney-Pancreas";
    }
    if (props.organ === "hr") {
        organName = "Heart";
    }
    if (props.organ === "hl") {
        organName = "Heart-Lung";
    }
    if (props.organ === "li") {
        organName = "Liver";
        showLiving = true;
    }
    if (props.organ === "lu") {
        organName = "Lung";
    }
    if (props.organ === "in") {
        organName = "Intestine";
    }
    if (props.organ === "pa") {
        organName = "Pancreas";
    }

    let deceasedDonorsInYear = transplantType.toLowerCase() === "adult" ? props.data.cadtx_adn_c : props.data.cadtx_p0n_c;
    let livingDonorsInYear = transplantType.toLowerCase() === "adult" ? props.data.litx_adn_c : props.data.litx_p0n_c;
    let survivalOnWaitlist = transplantType.toLowerCase() === "adult" ? GetTier(props.data.tmr_ad_dthrtier_c) : GetTier(props.data.tmr_p0_dthrtier_c);
    let getttingDeceasedDonorTransplant = transplantType.toLowerCase() === "adult" ? GetTier(props.data.tmr_ad_cadtxrtier_c) : GetTier(props.data.tmr_p0_cadtxrtier_c);
    let survivalFollowingTransplant = transplantType.toLowerCase() === "adult" ? GetTier(props.data.gsr_ad_rating_c1y) : GetTier(props.data.gsr_p0_rating_c1y);

    let topRow = (
        <div className="top-row">
            <div className="title-wrapper">
                <img src={imgIcon} alt={`${transplantType} ${organName} Transplants`} />
                <div className="title">
                    {`${transplantType} ${organName} Transplant Summary`}
                </div>
            </div>
            <div className="button">
                <Button
                    onClick={() => setTransplantType(transplantType === "Adult" ? "Pediatric" : "Adult")}
                    className="btn-dark-blue min-98"
                >
                    {transplantType === "Adult" && "Show Pediatric"}
                    {transplantType !== "Adult" && "Show Adult"}
                </Button>
            </div>
        </div>
    );

    if (!deceasedDonorsInYear && !livingDonorsInYear) {
        return (
            <Row>
                <Col>
                    <div className="organ-summary">
                        {topRow}
                        <div className="tier-wrapper no-results">
                            {`No ${transplantType} ${organName} Transplants Performed`}  
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }

    return (
        <Row>
            <Col>
                <div className="organ-summary">
                    {topRow}
                    <div className={!showLiving ? "tier-wrapper four" : "tier-wrapper"}>
                        <div className="item">
                            <div className="text">
                                Transplants from Deceased Donors in a year
                         </div>
                            <div className="stat">
                                {deceasedDonorsInYear}
                            </div>
                        </div>
                        {showLiving &&
                            <div className="item">
                                <div className="text">
                                    Transplants from Living Donors in a year
                        </div>

                                <div className="stat">
                                    <div>{livingDonorsInYear}</div>
                                </div>
                            </div>
                        }
                        <div className="item">
                            <div className="text">
                                Survival on the waitlist
                        </div>
                            <div className="stat">
                                {survivalOnWaitlist}
                            </div>
                        </div>
                        <div className="item">
                            <div className="text">
                                Getting a Deceased Donor Transplant Faster
                        </div>
                            <div className="stat">
                                {getttingDeceasedDonorTransplant}
                            </div>
                        </div>
                        <div className="item">
                            <div className="text">
                                1-Year {organName} Survival
                            </div>
                            <div className="stat">
                                {survivalFollowingTransplant}
                            </div>
                        </div>
                    </div>

                </div>
            </Col>
        </Row>
    );
}

function GetTier(tier) {
    if (tier === 1) {
        return <div><img src={imgBars1} alt="Tier 1" /></div>;
    }

    if (tier === 2) {
        return <div><img src={imgBars2} alt="Tier 2" /></div>;
    }

    if (tier === 3) {
        return <div><img src={imgBars3} alt="Tier 3" /></div>;
    }

    if (tier === 4) {
        return <div><img src={imgBars4} alt="Tier 4" /></div>;
    }

    if (tier === 5) {
        return <div><img src={imgBars5} alt="Tier 5" /></div>;
    }
    return <div><img src={imgNA} alt="Not Assessed" /></div>;
}

export default TransplantSummary;