import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ObservedExpectedOrganRateChart from "./ObservedExpectedOrganRateChart";
import Skeleton from "react-loading-skeleton";

function ObservedExpectedOrganYieldRates(props) {
  let subTitle = props.data ? (
    `Observed and Expected Organ Yield per 100 Donors - ${props.data.begDate} to ${props.data.endDate}`
  ) : (
    <Skeleton width={600} />
  );

  return (
    <div>
      <Row>
        <Col xs={12}>
          <h1 className="section-title">
            {props.data ? (
              "Organ Specific Yield Rates"
            ) : (
              <Skeleton width={600} />
            )}
          </h1>
          <div className="sub-title">{subTitle}</div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="organ-specific-donation-rates">
          <Row>
            <Col xs={12} md={4}>
              <div className="organ-block">
                <ObservedExpectedOrganRateChart
                  organ="heart"
                  observedData={props.data.observedHeartData}
                  expectedData={props.data.expectedHeartData}
                />
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="organ-block">
                <ObservedExpectedOrganRateChart
                  organ="kidney"
                  observedData={props.data.observedKidneyData}
                  expectedData={props.data.expectedKidneyData}
                />
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="organ-block">
                <ObservedExpectedOrganRateChart
                  organ="liver"
                  observedData={props.data.observedLiverData}
                  expectedData={props.data.expectedLiverData}
                />
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="organ-block">
                <ObservedExpectedOrganRateChart
                  organ="lung"
                  observedData={props.data.observedLungData}
                  expectedData={props.data.expectedLungData}
                />
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="organ-block">
                <ObservedExpectedOrganRateChart
                  organ="pancreas"
                  observedData={props.data.observedPancreasData}
                  expectedData={props.data.expectedPancreasData}
                />
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="organ-block">
                <ObservedExpectedOrganRateChart
                  organ="intestine"
                  observedData={props.data.observedIntestineData}
                  expectedData={props.data.expectedIntestineData}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default ObservedExpectedOrganYieldRates;
