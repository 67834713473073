import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Skeleton from "react-loading-skeleton";
import { formatThousands } from "../../utilities/formatter";
import { withRouter } from "react-router-dom";

function OPOInformation(props) {
  const _viewOPO = () => {
    if ("parentIFrame" in window) {
      var json = {
        type: "opo",
        opoCode: props.opoData.code,
      };

      //window.parentIFrame.sendMessage(json, `${process.env.REACT_APP_TARGET_ORIGIN}`);
      window.parentIFrame.sendMessage(json, "*");
    }

    window.location.href = "/opo/" + props.opoData.code;
  };

  if (props.opoData) {
    return (
      <Row>
        <Col className="opo-information">
          <Row>
            <Col className="opo-name">
              <h4>
                {props.opoData.name} ({props.opoData.code})
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped bordered className="compare-opo-table">
                <tbody>
                  <tr>
                    <td>
                      <label className="label">Population Served: </label>
                      {formatThousands(props.opoData.population)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="label">Population Density: </label>
                      {props.opoData.populationDensity !== 0 ? (
                        <span>
                          {formatThousands(
                            props.opoData.populationDensity.toFixed(0)
                          )}{" "}
                          persons/mi<sup>2</sup>
                        </span>
                      ) : (
                        <Skeleton />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="label">Population Rank: </label>
                      {props.opoData.populationRank} among the 56 DSAs
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="label">Land Area Served: </label>
                      {formatThousands(props.opoData.landArea)} square miles
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="label">Land Area Rank: </label>
                      {props.opoData.landAreaRank} among the 56 DSAs
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="label">Deaths: </label>
                      {formatThousands(props.opoData.death)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="label">Deaths per 1,000: </label>
                      {formatThousands(props.opoData.deathRate.toFixed(2))}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="label">Deaths Rank: </label>
                      {props.opoData.deathRank} among the 56 DSAs
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>

            {props.showCompare && (
              <Col xs={12} className="text-center compare-buttons">
                <Button onClick={() => _viewOPO()} className="btn-dark-blue">
                  View OPO Detail
                </Button>
                <Button
                  onClick={props.onCompareClick}
                  className="btn-dark-blue"
                >
                  Compare New OPO
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col className="opo-information">
          <Row>
            <Col className="opo-name">
              <h4>
                <Skeleton count={2} />
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped bordered className="compare-opo-table">
                <tbody>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Skeleton />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default withRouter(OPOInformation);
