import React, {useState} from "react";
import HeartLung from "../../../assets/images/organs/72x72/Heart-Lungs.png";
import HeartLungHover from "../../../assets/images/organs/72x72/Heart-Lungs-Over.png";

function HeartLungIcon(props) {
    const [isHover, setIsHover] = useState(false);

    if (props.showHover) {
        return (
            <div 
                className={props.count ? "icon-container organ-count-wrapper" : "icon-container"}
                onMouseEnter={()=> setIsHover(true)}
                onMouseLeave={()=> setIsHover(false)}
            >
                <img src={isHover ? HeartLungHover : HeartLung} alt="Heart Lung Icon" className="organ-icon large" />
                {props.count &&
                    <div className="organ-count heart-lung">
                        {props.count}
                    </div>
                }
                
            </div>
        );
    }
    else {
        return (<img src={HeartLung} alt="Heart Lung Icon" className="organ-icon large" />);
    }
}

export default HeartLungIcon;