
import "core-js/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/stable/array/includes';
import 'core-js/features/number/is-nan';

import 'core-js/es/symbol';
import 'core-js/features/array/from';
import 'core-js/features/number/is-finite';
import 'core-js/features/reflect';
import 'core-js/features/symbol/iterator.js';
import 'core-js/es/math/log2';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "./assets/fonts/Montserrat/Montserrat-Regular.otf";

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
