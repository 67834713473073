import React, { useState, useEffect, useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Select from "react-select";
import { brandColorArray, formatOneDecimal } from "../../../utilities/formatter";
import DescriptionOfDonorsRecoveredTable from "./DescriptionOfDonorsRecoveredTable";
import moment from "moment";
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

function DescriptionOfDonorsRecovered(props) {
    let title = "Description Of Donors Recovered";
    let subTitle = props.data ? `Characteristics of all deceased donors recovered, ${moment(new Date(props.data.begDate)).format("MM/DD/YYYY")} to ${moment(new Date(props.data.endDate)).format("MM/DD/YYYY")}` : "";

    const chartRef = useRef(null);
    const yearOneLabel = props.data ? `${props.data.yearOne}` : "";
    const yearTwoLabel = props.data ? `${props.data.yearTwo}` : "";

    const [chartOptions, setChartOptions] = useState({
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ['printChart', 'downloadPNG', 'downloadSVG', 'downloadPDF', 'downloadCSV']
                },
            },
            title: {
                text: `<div style="font-family:Open Sans;font-size:14px;font-weight: 700;text-align:center;">${title}</div><br /><div style="font-family:Open Sans;font-size:12px;font-weight: 400;text-align:center;">${subTitle}</div>`
            },
            chart: {
                events: {
                    load: function () {
                        let width = 0;
                        let height = this.chartHeight - 50;
                        this.renderer.image('/srtr_logo.jpg', width, height, 50, 50)
                            .add();
                    }
                }
            }
        },
        chart: {
            type: "column"
        },
        credits: {
            enabled: false
        },
        title: {
            text: ""
        },
        xAxis: {
            categories: [
                `${props.opoCode.toUpperCase()} <br />${yearOneLabel}`,
                `US <br />${yearOneLabel}`,
                `${props.opoCode.toUpperCase()} <br />${yearTwoLabel}`,
                `US <br />${yearTwoLabel}`,
            ],
            labels: {
                style: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"
                }
            }
        },
        yAxis: {
            title: {
                text: "Percent (%)",
                style: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"
                }
            },
            labels: {
                style: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"
                }
            },
            max: 100,
            reversedStacks: false
        },
        tooltip: {
            borderWidth: 0,
            backgroundColor: '#FCFFC5',
            borderRadius: 10,
            shadow: true,
            useHTML: true,
            headerFormat: '<div style="font-family:Open Sans;font-size:14px;font-weight: 700;text-align:center;margin-bottom:10px">{point.key}</div>',
            pointFormat: '<div style="padding:0px 5px 5px 5px;font-family:Open Sans;font-size:14px;font-weight: 700;text-align:left"><span>{series.name}</span>: <span style="font-family:Open Sans;font-size:14px;font-weight: 600;text-align:left">{point.y:.1f}</span></div>',
            shared: false
        },

        legend: {
            itemStyle: {
                fontFamily: "Open Sans",
                fontSize: "14px"

            }
        },
        labels: {
            useHTML: true,
            allowOverlap: true,
            style: {
                wordBreak: 'break-all',
                textOverflow: 'allow'
            }
        },
        plotOptions: {
            column: {
                stacking: 'column'
            }
        }
    });

    const selectOptions = [
        {
            label: "Race",
            value: [
                { label: "White", value: { opoYearOne: "dcA_Rw_o1", opoYearTwo: "dcA_Rw_o2", usYearOne: "dcA_Rw_u1", usYearTwo: "dcA_Rw_u2", category: "Race" } },
                { label: "African-American", value: { opoYearOne: "dcA_Rb_o1", opoYearTwo: "dcA_Rb_o2", usYearOne: "dcA_Rb_u1", usYearTwo: "dcA_Rb_u2", category: "Race" } },
                { label: "Hispanic/Latino", value: { opoYearOne: "dcA_Rh_o1", opoYearTwo: "dcA_Rh_o2", usYearOne: "dcA_Rh_u1", usYearTwo: "dcA_Rh_u2", category: "Race" } },
                { label: "Asian", value: { opoYearOne: "dcA_Ra_o1", opoYearTwo: "dcA_Ra_o2", usYearOne: "dcA_Ra_u1", usYearTwo: "dcA_Ra_u2", category: "Race" } },
                { label: "Other", value: { opoYearOne: "dcA_Ro_o1", opoYearTwo: "dcA_Ro_o2", usYearOne: "dcA_Ro_u1", usYearTwo: "dcA_Ro_u2", category: "Race" } },
            ]
        },
        {
            label: "Age",
            value: [
                { label: "0-1 Years", value: { opoYearOne: "dcA_A2_o1", opoYearTwo: "dcA_A2_o2", usYearOne: "dcA_A2_u1", usYearTwo: "dcA_A2_u2", category: "Age" } },
                { label: "2-11 Years", value: { opoYearOne: "dcA_A10_o1", opoYearTwo: "dcA_A10_o2", usYearOne: "dcA_A10_u1", usYearTwo: "dcA_A10_u2", category: "Age" } },
                { label: "12-17 Years", value: { opoYearOne: "dcA_A17_o1", opoYearTwo: "dcA_A17_o2", usYearOne: "dcA_A17_u1", usYearTwo: "dcA_A17_u2", category: "Age" } },
                { label: "18-34 Years", value: { opoYearOne: "dcA_A34_o1", opoYearTwo: "dcA_A34_o2", usYearOne: "dcA_A34_u1", usYearTwo: "dcA_A34_u2", category: "Age" } },
                { label: "35-49 Years", value: { opoYearOne: "dcA_A49_o1", opoYearTwo: "dcA_A49_o2", usYearOne: "dcA_A49_u1", usYearTwo: "dcA_A49_u2", category: "Age" } },
                { label: "50-64 Years", value: { opoYearOne: "dcA_A64_o1", opoYearTwo: "dcA_A64_o2", usYearOne: "dcA_A64_u1", usYearTwo: "dcA_A64_u2", category: "Age" } },
                { label: "65+ Years", value: { opoYearOne: "dcA_A65p_o1", opoYearTwo: "dcA_A65p_o2", usYearOne: "dcA_A65p_u1", usYearTwo: "dcA_A65p_u2", category: "Age" } }
            ]
        },
        {
            label: "Gender",
            value: [
                { label: "Male", value: { opoYearOne: "dcA_Gm_o1", opoYearTwo: "dcA_Gm_o2", usYearOne: "dcA_Gm_u1", usYearTwo: "dcA_Gm_u2", category: "Gender" } },
                { label: "Female", value: { opoYearOne: "dcA_Gf_o1", opoYearTwo: "dcA_Gf_o2", usYearOne: "dcA_Gf_u1", usYearTwo: "dcA_Gf_u2", category: "Gender" } }
            ]
        },
        {
            label: "Blood Type",
            value: [
                { label: "O", value: { opoYearOne: "dcA_Bo_o1", opoYearTwo: "dcA_Bo_o2", usYearOne: "dcA_Bo_u1", usYearTwo: "dcA_Bo_u2", category: "Blood Type" } },
                { label: "A", value: { opoYearOne: "dcA_Ba_o1", opoYearTwo: "dcA_Ba_o2", usYearOne: "dcA_Ba_u1", usYearTwo: "dcA_Ba_u1", category: "Blood Type" } },
                { label: "B", value: { opoYearOne: "dcA_Bb_o1", opoYearTwo: "dcA_Bb_o2", usYearOne: "dcA_Bb_u1", usYearTwo: "dcA_Bb_u1", category: "Blood Type" } },
                { label: "AB", value: { opoYearOne: "dcA_Bab_o1", opoYearTwo: "dcA_Bab_o2", usYearOne: "dcA_Bab_u1", usYearTwo: "dcA_Bab_u1", category: "Blood Type" } }
            ]
        },
        {
            label: "Cause of Death",
            value: [
                { label: "Anoxia", value: { opoYearOne: "dcA_Anox_o1", opoYearTwo: "dcA_Anox_o2", usYearOne: "dcA_Anox_u1", usYearTwo: "dcA_Anox_u2", category: "Cause of Death" } },
                { label: "Stroke", value: { opoYearOne: "dcA_Str_o1", opoYearTwo: "dcA_Str_o2", usYearOne: "dcA_Str_u1", usYearTwo: "dcA_Str_u2", category: "Cause of Death" } },
                { label: "CNS Tumor", value: { opoYearOne: "dcA_CNS_o1", opoYearTwo: "dcA_CNS_o2", usYearOne: "dcA_CNS_u1", usYearTwo: "dcA_CNS_u1", category: "Cause of Death" } },
                { label: "Head Trauma", value: { opoYearOne: "dcA_Traum_o1", opoYearTwo: "dcA_Traum_o2", usYearOne: "dcA_Traum_u1", usYearTwo: "dcA_Traum_u1", category: "Cause of Death" } },
                { label: "Other", value: { opoYearOne: "dcA_CDOth_o1", opoYearTwo: "dcA_CDOth_o2", usYearOne: "dcA_CDOth_u1", usYearTwo: "dcA_CDOth_u1", category: "Cause of Death" } },
                { label: "Unknowns", value: { opoYearOne: "dcA_CDUnk_o1", opoYearTwo: "dcA_CDUnk_o2", usYearOne: "dcA_CDUnk_u1", usYearTwo: "dcA_CDUnk_u1", category: "Cause of Death" } }
            ]
        },
        {
            label: "Donors After Circulatory Death",
            value: [
                { label: "Donors After Circulatory Death", value: { opoYearOne: "dcA_nhbd_o1", opoYearTwo: "dcA_nhbd_o2", usYearOne: "dcA_nhbd_u1", usYearTwo: "dcA_nhbd_u2", category: "Donors After Circulatory Death" } }
            ]
        }];

    const onChange = (selectedOption) => {
        const chart = chartRef.current.chart;
        if (chart && selectedOption) {
            let selectedValue = selectedOption.value;
            let series = [];

            if (selectedOption.label === "Donors After Circulatory Death") {
                let newSeries = {
                    name: "Donors After Circulatory Death",
                    color: brandColorArray[0],
                    data: [
                        formatOneDecimal(props.data[selectedValue[0].value.opoYearOne]),
                        formatOneDecimal(props.data[selectedValue[0].value.usYearOne]),
                        formatOneDecimal(props.data[selectedValue[0].value.opoYearTwo]),
                        formatOneDecimal(props.data[selectedValue[0].value.usYearTwo])
                    ]
                };

                series.push(newSeries);

                let newSeriesTwo = {
                    name: "Donors After Brain Death",
                    color: brandColorArray[1],
                    data: [
                        formatOneDecimal(100 - props.data[selectedValue[0].value.opoYearOne]),
                        formatOneDecimal(100 - props.data[selectedValue[0].value.usYearOne]),
                        formatOneDecimal(100 - props.data[selectedValue[0].value.opoYearTwo]),
                        formatOneDecimal(100 - props.data[selectedValue[0].value.usYearTwo])
                    ]
                };

                series.push(newSeriesTwo);

            }
            else {

                series = selectedValue.map((x, index) => {
                    let newSeries = {
                        name: x.label,
                        color: brandColorArray[index],
                        data: [
                            formatOneDecimal(props.data[x.value.opoYearOne]),
                            formatOneDecimal(props.data[x.value.usYearOne]),
                            formatOneDecimal(props.data[x.value.opoYearTwo]),
                            formatOneDecimal(props.data[x.value.usYearTwo])
                        ]
                    };
                    return newSeries;
                });
            }

            let newOptions = {
                ...chartOptions,
                series: series
            }

            setChartOptions(newOptions)
        }
    }

    useEffect(() => {
        onChange(selectOptions[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className="description-of-donors-recovered">
            <Row>
                <Col xs={12} className="text-center">
                    <h1 className="section-title">
                        {title}
                    </h1>
                    <div className="sub-title mb-4">
                        {subTitle}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={8}>
                    <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOptions} containerProps={{ className: "highcharts-light" }}/>
                </Col>
                <Col xs={12} lg={4}>
                    <div className="green-control-box">
                        <div className="description" id="label-description-donors-recovered">
                            Select characteristic
                        </div>
                        <Select
                            className="select-characteristics"
                            classNamePrefix="select"
                            isClearable={false}
                            isSearchable={true}
                            name="characteristics"
                            options={selectOptions}
                            onChange={onChange}
                            defaultValue={selectOptions[0]}
                            aria-labelledby="label-description-donors-recovered"
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} >
                    {props.data &&
                        <DescriptionOfDonorsRecoveredTable opoCode={props.opoCode} data={props.data} />
                    }
                </Col>
            </Row>
        </div>
    );
}

export default DescriptionOfDonorsRecovered;