import React from "react";
import HeartIcon from "./HeartIcon";
import IntestineIcon from "./IntestineIcon";
import KidneyIcon from "./KidneyIcon";
import LiverIcon from "./LiverIcon";
import LungIcon from "./LungIcon";
import PancreasIcon from "./PancreasIcon";

function IconList(props){
    let icons = [];
    
    if(props.item.hasHeart)
    {
        icons.push(<HeartIcon key="hasHeart" showHover={true} count={props.item.heartTransplants} />);
    }
    if(props.item.hasKidney)
    {
        icons.push(<KidneyIcon key="hasKidney" showHover={true} count={props.item.kidneyTransplants} />);
    }
    if(props.item.hasLiver)
    {
        icons.push(<LiverIcon key="hasLiver" showHover={true} count={props.item.liverTransplants} />);
    }
    if(props.item.hasLung)
    {
        icons.push(<LungIcon key="hasLung" showHover={true} count={props.item.lungTransplants} />);
    }
    if(props.item.hasPancreas)
    {
        icons.push(<PancreasIcon  key="hasPancreas"  showHover={true} count={props.item.pancreasTransplants} />);
    }
    if(props.item.hasIntestine)
    {
        icons.push(<IntestineIcon key="hasIntestine" showHover={true} count={props.item.intestineTransplants} />);
    }

    return icons;
}

export default IconList;