import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
import Select from "react-select";
import RatioScatterPlotChart from "../common-charts/RatioScatterPlotChart";
import RatioBoxPlotChart from "../common-charts/RatioBoxPlotChart";
import TransplantGraftsTable from "./TransplantGraftsTable";

function TransplantGrafts(props) {
  let livingDonor = props.organ === "ki" || props.organ === "li";
  let recipientOptions = [
    { label: "Adult (18+)", value: "ad", title: "Adult (18+)" },
    { label: "Pediatric (<18)", value: "p0", title: "Pediatric (<18)" },
  ];

  let timeFrameOptions = [
    { label: "1 Month", value: "c1m", title: "1-month" },
    { label: "90 Day", value: "c90d", title: "90 day" },
    {
      label: "1 year (conditional on 90 day)",
      value: "c1yc",
      title: "1 year (conditional on 90 day)",
    },
    { label: "1 Year", value: "c1y", title: "1-year" },
    { label: "3 Year", value: "c3y", title: "3-year" },
  ];

  let donorTypeOptions = [];
  if (livingDonor) {
    donorTypeOptions = [
      { label: "All", value: "", title: "" },
      { label: "Deceased", value: "ca", title: "deceased donor" },
      { label: "Living", value: "li", title: "living donor" },
    ];
  } else {
    donorTypeOptions = [
      { label: "Deceased", value: "", title: "deceased donor" },
    ];
  }

  let survivalResultOptions = [
    { label: "Graft Survival Results", value: "gsr", title: "graft failure" },
    { label: "Patient Survival Results", value: "psr", title: "patient death" },
  ];

  const [selectedRecipientOption, setSelectedRecipientOption] = useState(
    recipientOptions[0]
  );
  const [selectedTimeFrameOption, setSelectedTimeFrameOption] = useState(
    timeFrameOptions[0]
  );
  const [selectedDonorTypeOption, setSelectedDonorTypeOption] = useState(
    donorTypeOptions[0]
  );
  const [selectedSurvivalResultOption, setSelectedSurvivalResultOption] =
    useState(survivalResultOptions[0]);

  useEffect(() => {
    setSelectedRecipientOption(recipientOptions[0]);
    setSelectedTimeFrameOption(timeFrameOptions[0]);
    setSelectedDonorTypeOption(donorTypeOptions[0]);
    setSelectedSurvivalResultOption(survivalResultOptions[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const _onRecipientChange = (selectedRecipientOption) => {
    setSelectedRecipientOption(selectedRecipientOption);
  };

  const _onTimeFrameChange = (selectedTimeFrameOption) => {
    setSelectedTimeFrameOption(selectedTimeFrameOption);
  };

  const _onDonorTypeChange = (selectedDonorTypeOption) => {
    setSelectedDonorTypeOption(selectedDonorTypeOption);
  };

  const _onSurvivalResultChange = (selectedSurvivalResultOption) => {
    setSelectedSurvivalResultOption(selectedSurvivalResultOption);
  };

  let ratio =
    selectedSurvivalResultOption.value +
    "_" +
    selectedDonorTypeOption.value +
    selectedRecipientOption.value +
    "_hr_" +
    selectedTimeFrameOption.value;

  let ratioLow =
    selectedSurvivalResultOption.value +
    "_" +
    selectedDonorTypeOption.value +
    selectedRecipientOption.value +
    "_credlo_" +
    selectedTimeFrameOption.value;
  let ratioHigh =
    selectedSurvivalResultOption.value +
    "_" +
    selectedDonorTypeOption.value +
    selectedRecipientOption.value +
    "_credhi_" +
    selectedTimeFrameOption.value;
  let programVolume =
    selectedSurvivalResultOption.value +
    "_" +
    selectedDonorTypeOption.value +
    selectedRecipientOption.value +
    "_n_" +
    selectedTimeFrameOption.value;
  let currentData =
    selectedSurvivalResultOption.value === "gsr"
      ? props.gsrData
      : props.psrData;
  let otherCentersData =
    selectedSurvivalResultOption.value === "gsr"
      ? props.otherCentersGSRData
      : props.otherCentersPSRData;
  let title = "";
  let tableTitle = "";
  let subTitle = getSubTitle(
    currentData,
    selectedSurvivalResultOption.value,
    selectedTimeFrameOption.value
  );
  if (selectedSurvivalResultOption.value === "gsr") {
    title =
      selectedRecipientOption.title +
      " " +
      selectedTimeFrameOption.title +
      " " +
      selectedDonorTypeOption.title +
      " graft failure HR";
    tableTitle =
      selectedRecipientOption.title +
      " " +
      selectedTimeFrameOption.title +
      " survival with a functioning " +
      (selectedDonorTypeOption.title
        ? `${selectedDonorTypeOption.title}`
        : "") +
      " graft";
  }

  if (selectedSurvivalResultOption.value === "psr") {
    title =
      selectedRecipientOption.title +
      " " +
      selectedTimeFrameOption.title +
      " " +
      selectedDonorTypeOption.title +
      " patient death HR";
    tableTitle =
      selectedRecipientOption.title +
      " " +
      selectedTimeFrameOption.title +
      " patient survival " +
      (selectedDonorTypeOption.title
        ? `(${selectedDonorTypeOption.title} graft recipients)`
        : "");
  }

  return (
    <div className="transplant-grafts-wrapper section">
      <Row>
        <Col xs={12} className="mb-4">
          <h1 className="section-title">Posttransplant Outcomes</h1>
          <div className="sub-title">
            {currentData && `Transplants performed between ${subTitle}`}
          </div>
          <div className="description">
            The Estimated Hazard Ratio shows whether the program has higher than
            expected (greater than 1.0) or lower than expected (less than 1.0)
            failure rates (graft failures or patient deaths).
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          <div className="background-teal grafts-select-options">
            <div className="select-area-wrapper">
              <div className="sub-title">
                <label id="recipientLabel" name="recipientLabel">
                  Recipient
                </label>
              </div>
              <Select
                id="recipient"
                className="select-area mt-2"
                classNamePrefix="select"
                isClearable={false}
                isSearchable={true}
                name="select-area"
                options={recipientOptions}
                onChange={_onRecipientChange}
                value={selectedRecipientOption}
                aria-labelledby={`recipientLabel`}
              />
              <div className="sub-title">
                <label id="timeFrameLabel" name="timeFrameLabel">
                  Time Frame
                </label>
              </div>
              <Select
                id="select-area"
                className="select-area mt-2"
                classNamePrefix="select"
                isClearable={false}
                isSearchable={true}
                name="select-area"
                options={timeFrameOptions}
                onChange={_onTimeFrameChange}
                value={selectedTimeFrameOption}
                aria-labelledby={`timeFrameLabel`}
              />
              {livingDonor && (
                <>
                  <div className="sub-title">
                    <label id="donorTypeLabel" name="donorTypeLabel">
                      Donor Type
                    </label>
                  </div>
                  <Select
                    id="select-area"
                    className="select-area mt-2"
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={true}
                    name="select-area"
                    options={donorTypeOptions}
                    onChange={_onDonorTypeChange}
                    value={selectedDonorTypeOption}
                    aria-labelledby={`donorTypeLabel`}
                  />
                </>
              )}
              <div className="sub-title">
                <label id="survivalResultsLabel" name="survivalResultsLabel">
                  Survival Results
                </label>
              </div>
              <Select
                id="select-area"
                className="select-area mt-2"
                classNamePrefix="select"
                isClearable={false}
                isSearchable={true}
                name="select-area"
                options={survivalResultOptions}
                onChange={_onSurvivalResultChange}
                value={selectedSurvivalResultOption}
                aria-labelledby={`survivalResultsLabel`}
              />
            </div>
          </div>
        </Col>
        <Col xs={12} md={8}>
          {currentData && (
            <RatioBoxPlotChart
              organ={props.organ}
              centerCode={props.centerCode}
              ratio={currentData[ratio]}
              ratioLow={currentData[ratioLow]}
              ratioHigh={currentData[ratioHigh]}
              title={title}
              subTitle={subTitle}
              yAxisLabel="Estimated Hazard Ratio (HR)"
              ratioLabel="Hazard Ratio"
              survivalResult={
                selectedSurvivalResultOption
                  ? selectedSurvivalResultOption.value
                  : ""
              }
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {currentData && (
            <RatioScatterPlotChart
              organ={props.organ}
              centerCode={props.centerCode}
              data={currentData}
              otherCentersData={otherCentersData}
              ratio={ratio}
              programVolume={programVolume}
              title={`${title} program comparison`}
              subTitle={subTitle}
              yAxisLabel="Estimate Hazard Ratio (HR)"
              ratioLabel="Hazard Ratio"
              survivalResult={
                selectedSurvivalResultOption
                  ? selectedSurvivalResultOption.value
                  : ""
              }
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {currentData && (
            <TransplantGraftsTable
              centerCode={props.centerCode}
              data={currentData}
              selectedSurvivalResultOption={selectedSurvivalResultOption.value}
              selectedDonorTypeOption={selectedDonorTypeOption.value}
              selectedRecipientOption={selectedRecipientOption.value}
              selectedTimeFrameOption={selectedTimeFrameOption.value}
              title={tableTitle}
              subTitle={subTitle}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}

function getSubTitle(selectedDataSet, selectedDataSetName, selectedTimeFrame) {
  if (selectedDataSet && selectedDataSetName && selectedTimeFrame) {
    if (selectedDataSetName === "gsr") {
      if (selectedTimeFrame === "c3y") {
        /*
        return `${moment(
          new Date(selectedDataSet["gsr_cohort_startdt_3y"])
        ).format("MM/DD/YYYY")} - ${moment(
          new Date(selectedDataSet["gsr_cohort_enddt_3y"])
        ).format("MM/DD/YYYY")}`;
        */
        //COVID-CARVEOUT
        return "07/01/2018 - 03/12/2020, 06/13/2020 - 12/31/2020";
      }

      return `${moment(
        new Date(selectedDataSet["gsr_cohort_startdt_1y"])
      ).format("MM/DD/YYYY")} - ${moment(
        new Date(selectedDataSet["gsr_cohort_enddt_1y"])
      ).format("MM/DD/YYYY")}`;
    }

    if (selectedDataSetName === "psr") {
      if (selectedTimeFrame === "c3y") {
        /*
        return `${moment(
          new Date(selectedDataSet["psr_cohort_startdt_3y"])
        ).format("MM/DD/YYYY")} - ${moment(
          new Date(selectedDataSet["psr_cohort_enddt_3y"])
        ).format("MM/DD/YYYY")}`;
        */
        //COVID-CARVEOUT
        return "07/01/2018 - 03/12/2020, 06/13/2020 - 12/31/2020";
      }

      return `${moment(
        new Date(selectedDataSet["psr_cohort_startdt_1y"])
      ).format("MM/DD/YYYY")} - ${moment(
        new Date(selectedDataSet["psr_cohort_enddt_1y"])
      ).format("MM/DD/YYYY")}`;
    }

    //COVID-CARVEOUT
    //return "01/01/2020 - 03/12/2020, 06/12/2020 - 06/30/2022";
  }

  return null;
}

export default TransplantGrafts;
