import React from "react";
import {formatOneDecimal, formatThousands} from "../../../utilities/formatter";
function Demographics(props){
            return (
                <>
                    <tr>
                        <td colSpan="11" className="text-left font-weight-bold">Ethnicity/Race</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">White</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_rw_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rw_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rw_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rw_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rw_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_rw_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rw_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rw_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rw_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rw_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">African-American</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_rb_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rb_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rb_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rb_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rb_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_rb_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rb_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rb_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rb_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rb_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">Hispanic/Latino</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_rh_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rh_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rh_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rh_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rh_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_rh_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rh_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rh_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rh_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_rh_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">Asian</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ra_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ra_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ra_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ra_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ra_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ra_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ra_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ra_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ra_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ra_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">Other</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ro_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ro_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ro_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ro_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ro_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ro_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ro_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ro_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ro_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ro_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">Unknown</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ru_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ru_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ru_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ru_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ru_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ru_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ru_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ru_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ru_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ru_put4))}</td>
                    </tr>
                    <tr>
                        <td colSpan="11" className="text-left font-weight-bold">Age</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">&lt;2 years</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_a2_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a2_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a2_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a2_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a2_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_a2_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a2_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a2_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a2_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a2_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">2-11 years</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_a10_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a10_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a10_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a10_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a10_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_a10_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a10_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a10_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a10_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a10_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">12-17 years</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_a17_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a17_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a17_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a17_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a17_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_a17_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a17_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a17_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a17_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a17_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">18-34 years</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_a34_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a34_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a34_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a34_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a34_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_a34_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a34_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a34_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a34_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a34_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">35-49 years</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_a49_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a49_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a49_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a49_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a49_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_a49_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a49_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a49_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a49_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a49_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">50-64 years</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_a64_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a64_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a64_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a64_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a64_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_a64_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a64_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a64_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a64_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a64_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">65-69 years</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_a69_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a69_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a69_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a69_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a69_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_a69_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a69_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a69_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a69_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a69_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">70+ years</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_a70p_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a70p_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a70p_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a70p_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a70p_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_a70p_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a70p_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a70p_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a70p_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_a70p_put4))}</td>
                    </tr>
                    <tr>
                        <td colSpan="11" className="text-left font-weight-bold">Gender</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">Male</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_gm_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_gm_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_gm_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_gm_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_gm_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_gm_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_gm_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_gm_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_gm_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_gm_put4))}</td>
                    </tr>
                    <tr>
                        <td className="padding-left-30">Female</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_gf_nc))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_gf_pct1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_gf_pct2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_gf_pct3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_gf_pct4))}</td>
                        <td className="text-center">{formatThousands(parseFloat(props.data.tpc_gf_nu))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_gf_put1))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_gf_put2))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_gf_put3))}</td>
                        <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_gf_put4))}</td>
                    </tr>
                </>
            );
       
}

export default Demographics;