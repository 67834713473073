import React, { useEffect, useState } from "react";
import moment from "moment";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OfferAcceptancesChart from "./OfferAcceptancesChart";
import OfferAcceptancesTable from "./OfferAcceptancesTable";
import NoData from "../../utilities/NoData";

import { getOptions } from "./Options";
function OfferAcceptances(props) {

    const [offerAcceptancesTableData, setOfferAcceptancesTableData] = useState(null);
    useEffect(() => {
        const Host = process.env.REACT_APP_API_HOST;
        fetch(`${Host}/psr/${props.centerCode}${props.centerType}/offeracceptancetables`)
            .then(response => response.json())
            .then(response => {
                setOfferAcceptancesTableData(response);
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let options = getOptions(props.organ);
    let subTitle = "";
    let offerAcceptancesBody = (
        <Row>
            <Col xs={12}>
                <NoData centerCode={props.centerCode} />
            </Col>
        </Row>
    );

    if (props.organ !== "in" && props.data) {
        let begDate = props.data ? moment(new Date(props.data.begDate)) : "";
        let endDate = moment(new Date(props.data.endDate));
        subTitle = `${begDate.format("MM/DD/YYYY")} - ${endDate.format("MM/DD/YYYY")}`;

        offerAcceptancesBody = (
            options.map(function (option) {
                let nationalData = props.nationalData ? props.nationalData.filter((obj) => { return obj.characteristic === "Ratio" + option.name })[0] : null;
                return (
                    <Row key={`${option.name}`}>
                        <Col xs={12}  >
                            <div className="offer-acceptance-wrapper">
                                <OfferAcceptancesChart
                                    organ={props.organ}
                                    centerCode={props.centerCode}
                                    nationalData={nationalData}
                                    offerType={"Ratio" + option.Name}
                                    displayName={option.displayName}
                                    ratio={props.data["ratio" + option.name]}
                                    low={props.data["lowCredibleInterval" + option.name]}
                                    high={props.data["highCredibleInterval" + option.name]}
                                    title={option.displayName}
                                    subTitle={subTitle}
                                />
                            </div>
                        </Col>
                        <Col xs={12} >
                            {offerAcceptancesTableData &&
                                <OfferAcceptancesTable
                                    data={offerAcceptancesTableData[props.organ + "Data"]}
                                    option={option}
                                    centerCode={props.centerCode}
                                />
                            }
                        </Col>
                        <Col xs={12}>
                            <hr />
                        </Col>
                    </Row>
                );
            })
        );
    }

    return (
        <div className="transplant-recipients-wrapper">
            <Row>
                <Col xs={12} className="mb-4">
                    <h1 className="section-title">
                        Offer Acceptance Practices
                    </h1>
                    <div className="sub-title">
                        {subTitle}
                    </div>
                    <div className="description">
                    The Offer Acceptance Ratio describes whether the program is accepting organ offers at a rate higher than (greater than 1.0) or lower than (less than 1.0) other programs receiving similar offers for similar candidates. Offer acceptance ratios are provided for all offers as well as subgroups of offers for certain donor characteristics.
                    </div>
                </Col>
            </Row>
            {offerAcceptancesBody}
        </div >
    );
}

export default OfferAcceptances;