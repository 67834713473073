import React from "react";
import {formatOneDecimal, formatThousands} from "../../../utilities/formatter";
function YearsSinceDiabetes(props){
    if (props.organ === "pa" || props.organ === "kp") {
        return (
            <>
                <tr>
                    <td colSpan="11" className="text-left font-weight-bold">Years Since Diabetes Onset</td>
                </tr>
                <tr>
                    <td className="padding-left-30">0-20</td>
                    <td className="text-center">{formatThousands(parseFloat(props.data.tpc_diab20_nc))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab20_pct1))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab20_pct2))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab20_pct3))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab20_pct4))}</td>
                    <td className="text-center">{formatThousands(parseFloat(props.data.tpc_diab20_nu))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab20_put1))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab20_put2))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab20_put3))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab20_put4))}</td>
                </tr>
                <tr>
                    <td className="padding-left-30">20-30</td>
                    <td className="text-center">{formatThousands(parseFloat(props.data.tpc_diab30_nc))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab30_pct1))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab30_pct2))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab30_pct3))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab30_pct4))}</td>
                    <td className="text-center">{formatThousands(parseFloat(props.data.tpc_diab30_nu))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab30_put1))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab30_put2))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab30_put3))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab30_put4))}</td>
                </tr>
                <tr>
                    <td className="padding-left-30">30+</td>
                    <td className="text-center">{formatThousands(parseFloat(props.data.tpc_diab30p_nc))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab30p_pct1))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab30p_pct2))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab30p_pct3))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab30p_pct4))}</td>
                    <td className="text-center">{formatThousands(parseFloat(props.data.tpc_diab30p_nu))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab30p_put1))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab30p_put2))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab30p_put3))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diab30p_put4))}</td>
                </tr>
                <tr>
                    <td className="padding-left-30">Unknown</td>
                    <td className="text-center">{formatThousands(parseFloat(props.data.tpc_diabu_nc))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diabu_pct1))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diabu_pct2))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diabu_pct3))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diabu_pct4))}</td>
                    <td className="text-center">{formatThousands(parseFloat(props.data.tpc_diabu_nu))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diabu_put1))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diabu_put2))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diabu_put3))}</td>
                    <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_diabu_put4))}</td>
                </tr>
            </>
        );
    }

    return null;
}

export default YearsSinceDiabetes;