import React from "react";
import centerIcon from "../../../assets/images/icons/Hospital_Bl.png";
import HeartIcon from "../../utilities/organs/HeartIcon";
import IntestineIcon from "../../utilities/organs/IntestineIcon";
import KidneyIcon from "../../utilities/organs/KidneyIcon";
import LiverIcon from "../../utilities/organs/LiverIcon";
import LungIcon from "../../utilities/organs/LungIcon";
import PancreasIcon from "../../utilities/organs/PancreasIcon";
import HeartLungIcon from "../../utilities/organs/HeartLungIcon";
import KidneyPancreasIcon from "../../utilities/organs/KidneyPancreasIcon";
import Skeleton from "react-loading-skeleton";

function TopNav(props) {

    return (
        <div className="sticky-container">
            <div className="sticky-inner">
                <div className="top-nav-center">
                    {props.centerName &&
                        <>
                            <img src={centerIcon} alt="Hospital icon" />
                            {props.centerName}
                        </>
                    }
                    {!props.centerName &&
                        <>
                            <Skeleton width={41} height={41} circle={true} />
                        &nbsp;&nbsp;<Skeleton width={250} height={20} />
                        </>
                    }
                </div>
                <div className="top-nav-current">
                    {props.selectedOrgan &&
                        getSelected(props.selectedOrgan)
                    }
                    {!props.selectedOrgan &&
                        <>
                            <Skeleton width={41} height={41} circle={true} />
                         &nbsp;&nbsp;<Skeleton width={250} height={20} />
                        </>
                    }
                </div>
                <div className="top-nav-organs">
                    {props.organs &&
                        getOrgans(props.organs, props.onSelectClick)
                    }
                    {!props.organs &&
                    <>
                        <Skeleton width={41} height={41} circle={true} />&nbsp;&nbsp;
                        <Skeleton width={41} height={41} circle={true} />&nbsp;&nbsp;
                        <Skeleton width={41} height={41} circle={true} />&nbsp;&nbsp;
                        <Skeleton width={41} height={41} circle={true} />&nbsp;&nbsp;
                        <Skeleton width={41} height={41} circle={true} />&nbsp;&nbsp;
                        <Skeleton width={41} height={41} circle={true} />&nbsp;&nbsp;
                    </>
                    }
                </div>
            </div>
        </div>
    );
}

function getSelected(selectedOrgan) {
    if (selectedOrgan === "hr") {
        return <><HeartIcon showHover={true} /> Heart Transplants</>
    }

    if (selectedOrgan === "ki") {
        return <><KidneyIcon showHover={true} /> Kidney Transplants</>
    }

    if (selectedOrgan === "li") {
        return <><LiverIcon showHover={true} /> Liver Transplants</>
    }

    if (selectedOrgan === "lu") {
        return <><LungIcon showHover={true} /> Lung Transplants</>
    }

    if (selectedOrgan === "in") {
        return <><IntestineIcon showHover={true} /> Intestine Transplants</>
    }

    if (selectedOrgan === "pa") {
        return <><PancreasIcon showHover={true} /> Pancreas Transplants</>
    }

    if (selectedOrgan === "hl") {
        return <><HeartLungIcon showHover={true} /> Heart-Lung Transplants</>
    }

    if (selectedOrgan === "kp") {
        return <><KidneyPancreasIcon showHover={true} /> Kidney-Pancreas Transplants</>
    }
}

function getOrgans(organs, onSelectClick) {
    let icons = [];

    if (organs.includes("hl")) {
        icons.push(<div onClick={() => { onSelectClick("hl") }} key="hasHeartLung"><HeartLungIcon showHover={true} /></div>);
    }
    if (organs.includes("hr")) {
        icons.push(<div onClick={() => { onSelectClick("hr") }} key="hasHeart"><HeartIcon showHover={true} /></div>);
    }
    
    if (organs.includes("ki")) {
        icons.push(<div onClick={() => { onSelectClick("ki") }} key="hasKidney"><KidneyIcon showHover={true} /></div>);
    }
    if (organs.includes("kp")) {
        icons.push(<div onClick={() => { onSelectClick("kp") }} key="hasKidneyPancreas"><KidneyPancreasIcon showHover={true} /></div>);
    }
    if (organs.includes("li")) {
        icons.push(<div onClick={() => { onSelectClick("li") }} key="hasLiver"><LiverIcon showHover={true} /></div>);
    }
    if (organs.includes("lu")) {
        icons.push(<div onClick={() => { onSelectClick("lu") }} key="hasLung"><LungIcon showHover={true} /></div>);
    }
    if (organs.includes("pa")) {
        icons.push(<div onClick={() => { onSelectClick("pa") }} key="hasPancreas"><PancreasIcon showHover={true} /></div>);
    }
    if (organs.includes("in")) {
        icons.push(<div onClick={() => { onSelectClick("in") }} key="hasIntestine"><IntestineIcon showHover={true} /></div>);
    }

    return icons;
}

export default TopNav;