import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Demographics from "./demographics/Demographics";
import Medical from "./medical/Medical";
import NoData from "../../../utilities/NoData";

function Characteristics(props) {
    return (
        <div className="waitlist-characteristics-wrapper">
            <Row>
                <Col xs={12} className="mb-4">
                    <h1 className="section-title">
                        Characteristics Of Waiting List Candidates
                    </h1>
                </Col>
            </Row>
            <Tabs defaultActiveKey="demographics" unmountOnExit={false}>
                <Tab eventKey="demographics" title="Demographic Characteristics" className="margin-right">
                            {props.data &&
                                <Demographics
                                    data={props.data}
                                    organ={props.organ}
                                    centerCode={props.centerCode}
                                    title={"Demographic Characteristics Of Waiting List Candidates"}
                                />
                            }
                            {props.data === null &&
                                <NoData centerCode={props.centerCode} />
                            }
                </Tab>
                <Tab eventKey="medical" title="Medical Characteristics" className="margin-right">
                    <Row>
                        <Col xs={12}>
                            {props.data &&
                                <Medical
                                    data={props.data}
                                    organ={props.organ}
                                    centerCode={props.centerCode}
                                    title={"Medical Characteristics Of Waiting List Candidates"}
                                />
                            }
                             {props.data === null &&
                               <NoData centerCode={props.centerCode} />
                            }
                        </Col>
                    </Row>
                </Tab>
            </Tabs>
        </div>
    );
}

export default Characteristics;