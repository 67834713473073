import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeartIcon from "../../../utilities/organs/HeartIcon";
import KidneyIcon from "../../../utilities/organs/KidneyIcon";
import LiverIcon from "../../../utilities/organs/LiverIcon";
import LungIcon from "../../../utilities/organs/LungIcon";
import PancreasIcon from "../../../utilities/organs/PancreasIcon";
import IntestineIcon from "../../../utilities/organs/IntestineIcon";
function HoverTransplantCenterPopup(props) {
    let hoveredTransplantCenter = props.hoveredTransplantCenter;
    let x = props.x;
    let y = props.y;

    if (hoveredTransplantCenter) {
        let properties = hoveredTransplantCenter.properties;
        let name = properties.name;
        let hasHeart = properties.hasHeart;
        let hasIntestine = properties.hasIntestine;
        let hasKidney = properties.hasKidney;
        let hasLiver = properties.hasLiver;
        let hasLung = properties.hasLung;
        let hasPancreas = properties.hasPancreas;

        return (
            <div className="opo-detail-hover-center" style={{ left: x, top: y }}>
                <Row className="opo-organs">
                    <Col xs={12}>
                        <div className="opo-name">{name}</div>
                    </Col>
                    <Col xs={12}>
                        {hasHeart && <HeartIcon />}
                        {hasKidney && <KidneyIcon />}
                        {hasLiver && <LiverIcon />}
                        {hasLung && <LungIcon />}
                        {hasPancreas && <PancreasIcon />}
                        {hasIntestine && <IntestineIcon />}
                    </Col>
                </Row>
            </div>
        )

    }
    else {
        return null;
    }
}

export default HoverTransplantCenterPopup;