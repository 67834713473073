import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TransplantsFacilitatedChart from "./TransplantsFacilitatedChart";
import HeartIcon from "../../../utilities/organs/HeartIcon";
import KidneyIcon from "../../../utilities/organs/KidneyIcon";
import LiverIcon from "../../../utilities/organs/LiverIcon";
import LungIcon from "../../../utilities/organs/LungIcon";
import PancreasIcon from "../../../utilities/organs/PancreasIcon";
import IntestineIcon from "../../../utilities/organs/IntestineIcon";
import HeartLungIcon from "../../../utilities/organs/HeartLungIcon";
import KidneyPancreasIcon from "../../../utilities/organs/KidneyPancreasIcon";

import Skeleton from "react-spinners";
import { heartLungOptions, heartOptions, intestineOptions, kidneyOptions, kidneyPancreasOptions, liverOptions, lungOptions, pancreasOptions } from "./Options";

function TransplantsFacilitated(props) {
let title = props.opoCode ? `Transplants Facilitated by ${props.opoCode.toUpperCase()}` : "";
    return (
        <div className="transplants-facilitated-wrapper">
            <Row>
                <Col xs={12} className="text-center">
                    <h1 className="section-title">
                        {title}
                    </h1>
                    <div className="sub-title">

                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="icon-tabs">
                    <Tabs defaultActiveKey="heartData">
                        <Tab eventKey="heartData" title={props.data ? <HeartIcon  showHover={true} /> : <Skeleton circle={true} height={71} width={71} />} className="margin-right">
                            {props.data && 
                                <TransplantsFacilitatedChart
                                    opoCode={props.opoCode}
                                    data={props.data.heartData}
                                    selectOptions={heartOptions}
                                    organ="heart"
                                    prefix="rchr"
                                    title={title}
                                />
                            }
                        </Tab>
                        <Tab eventKey="heartLungData" title={props.data ? <HeartLungIcon  showHover={true} /> : <Skeleton circle={true} height={71} width={71} />} className="margin-right">
                            {props.data &&  
                                <TransplantsFacilitatedChart
                                    opoCode={props.opoCode}
                                    data={props.data.heartLungData ? props.data.heartLungData : null}
                                    selectOptions={heartLungOptions}
                                    organ="heart-lung"
                                    prefix="rchl"
                                    title={title}
                                />
                            }
                        </Tab>
                        <Tab eventKey="kidneyData" title={props.data ? <KidneyIcon  showHover={true} /> : <Skeleton circle={true} height={71} width={71} />} className="margin-right">
                            {props.data && 
                                <TransplantsFacilitatedChart
                                    opoCode={props.opoCode}
                                    data={props.data.kidneyData}
                                    selectOptions={kidneyOptions}
                                    organ="kidney"
                                    prefix="rcki"
                                    title={title}
                                />
                            }
                        </Tab>
                        <Tab eventKey="kidneyPancreasData" title={props.data ? <KidneyPancreasIcon  showHover={true} /> : <Skeleton circle={true} height={71} width={71} />} className="margin-right">
                            {props.data && 
                                <TransplantsFacilitatedChart
                                    opoCode={props.opoCode}
                                    data={props.data.kidneyPancreasData}
                                    selectOptions={kidneyPancreasOptions}
                                    organ="kidney-pancreas"
                                    prefix="rckp"
                                    title={title}
                                />
                            }
                           
                        </Tab>
                        <Tab eventKey="liverData" title={props.data ? <LiverIcon  showHover={true} /> : <Skeleton circle={true} height={71} width={71} />} className="margin-right">
                            {props.data && 
                                <TransplantsFacilitatedChart
                                    opoCode={props.opoCode}
                                    data={props.data.liverData}
                                    selectOptions={liverOptions}
                                    organ="liver"
                                    prefix="rcli"
                                    title={title}
                                />
                            }
                            
                        </Tab>
                        <Tab eventKey="lungData" title={props.data ? <LungIcon  showHover={true} /> : <Skeleton circle={true} height={71} width={71} />} className="margin-right">
                            {props.data && 
                                <TransplantsFacilitatedChart
                                    opoCode={props.opoCode}
                                    data={props.data.lungData}
                                    selectOptions={lungOptions}
                                    organ="lung"
                                    prefix="rclu"
                                    title={title}
                                />
                            }
                          
                        </Tab>
                        <Tab eventKey="pancreasData" title={props.data ? <PancreasIcon  showHover={true} /> : <Skeleton circle={true} height={71} width={71} />} className="margin-right">
                            {props.data &&
                                <TransplantsFacilitatedChart
                                    opoCode={props.opoCode}
                                    data={props.data.pancreasData}
                                    selectOptions={pancreasOptions}
                                    organ="pancreas"
                                    prefix="rcpa"
                                    title={title}
                                />
                            }
                           
                        </Tab>
                        <Tab eventKey="intestineData" title={props.data ? <IntestineIcon  showHover={true} /> : <Skeleton circle={true} height={71} width={71} />} className="margin-right">
                            {props.data &&
                                <TransplantsFacilitatedChart
                                    opoCode={props.opoCode}
                                    data={props.data.intestineData}
                                    selectOptions={intestineOptions}
                                    organ="intestine"
                                    prefix="rcin"
                                    title={title}
                                />
                            }
                            
                        </Tab>
                    </Tabs>
                </Col>
            </Row>


        </div>
    )
}

export default TransplantsFacilitated;