import React from "react";

function InvalidCenter() {
    return (
      <div role="alert" className="error-message">
        <p>Could not find a valid center with this code.</p>
        <p>Please check the center code and try again.</p>
      </div>
    )
  }

export default InvalidCenter;