import React from "react";
import Select from "react-select";

function SelectOrgan(props) {
    const uniqueId = `select-organ${Math.random()}`;
    const selectedOrgan = props.selectedOrgan == null ? "" : props.selectedOrgan;
    const options = [
        { value: "", label: "All Organs" },
        { value: "heart", label: "Heart" },
        { value: "kidney", label: "Kidney" },
        { value: "liver", label: "Liver" },
        { value: "lung", label: "Lung" },
        { value: "intestine", label: "Intestine" },
        { value: "pancreas", label: "Pancreas" }
    ]

    const _onChange = (event) => {
        let organ = event.value;
        props.onSelectOrgan(organ);
    }

    return (
        <>
            <div id={`label-${uniqueId}`} htmlFor={uniqueId} className="select-opo-text">
                Filter by organ
            </div>
            <Select
                id={uniqueId}
                className="select-organ"
                classNamePrefix="select"
                isClearable={false}
                isSearchable={true}
                name="organs"
                options={options}
                onChange={_onChange}
                aria-labelledby={`label-${uniqueId}`}
                value={options.filter(option => {
                    if (selectedOrgan) {
                        if (selectedOrgan.indexOf("has") > -1) {
                            let organ = selectedOrgan.replace('has', '');
                            return option.value.toLowerCase() === organ.toLowerCase();
                        }
                        else {
                            return option.value.toLowerCase() === selectedOrgan.toLowerCase();
                        }
                    }
                    else {
                        return option.value === "";
                    }
                })}
            />
        </>
    );
}

export default SelectOrgan;