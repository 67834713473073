import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Table from "react-bootstrap/Table";
import { formatTwoDecimals, formatThousands} from "../../utilities/formatter";
function TransplantGraftsTable(props) {
    const [open, setOpen] = useState(false);

    let selectedSurvivalResultOption = props.selectedSurvivalResultOption;
    let selectedDonorTypeOption = props.selectedDonorTypeOption;
    let selectedRecipientOption = props.selectedRecipientOption;
    let selectedTimeFrameOptionCenter = props.selectedTimeFrameOption;
    let selectedTimeFrameOptionUS = props.selectedTimeFrameOption.replace("c", "u");
    let timeFrame = getTimeFrameStrings(selectedTimeFrameOptionCenter);
    let dataPrefix = selectedSurvivalResultOption + "_" + selectedDonorTypeOption + selectedRecipientOption;
    let subTitle = "Single organ transplants performed between " + props.subTitle;

    return (
        <>
            <div className="collapse-container show-all-row mt-4">
                View {props.title} data &nbsp;&nbsp;
                <Button
                    onClick={() => setOpen(!open)}
                    aria-controls="collapse-text"
                    aria-expanded={open}
                    className="btn-dark-blue min-98" 
                >
                    {!open && "Show All"}
                    {open && "Hide All"}
                </Button>
            </div>
            <Collapse in={open}>
                <div id="collapse-text" className="collapse-inner">
                    <div className="table-responsive">
                        <Table striped className="overflow-auto transplant-grafts-table">
                            <thead>
                                <tr>
                                    <td className="border-bottom description">
                                        {props.title}<br />
                                        {subTitle}<br />
                                        {selectedSurvivalResultOption === "gsr" ? "Deaths and retransplants are considered graft failures" : "Retransplants excluded"}
                                    </td>
                                    <th className="text-center border-bottom">
                                        {props.centerCode}
                                    </th>
                                    <th className="text-center border-bottom">
                                        U.S.
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="">Number of transplants evaluated</td>
                                    <td className="text-center">{formatThousands(props.data[dataPrefix + "_n_" + selectedTimeFrameOptionCenter])}</td>
                                    <td className="text-center">{formatThousands(props.data[dataPrefix + "_n_" + selectedTimeFrameOptionUS])}</td>
                                </tr>
                                <tr>
                                    <td className="">Estimated probability of surviving {selectedSurvivalResultOption === "gsr" ? "with a functioning graft" : ""} at {timeFrame.digit} (unadjusted for patient and donor characteristics)</td>
                                    <td className="text-center">{formatTwoDecimals(props.data[dataPrefix + "_act_" + selectedTimeFrameOptionCenter])}%</td>
                                    <td className="text-center">{formatTwoDecimals(props.data[dataPrefix + "_act_" + selectedTimeFrameOptionUS])}%</td>
                                </tr>
                                <tr>
                                    <td className="">95% confidence interval for the unadjusted survival probability</td>
                                    <td className="text-center">[{formatTwoDecimals(props.data[dataPrefix + "_actlower_" + selectedTimeFrameOptionCenter])}%, {formatTwoDecimals(props.data[dataPrefix + "_actupper_" + selectedTimeFrameOptionCenter])}%]</td>
                                    <td className="text-center">[{formatTwoDecimals(props.data[dataPrefix + "_actlower_" + selectedTimeFrameOptionUS])}%, {formatTwoDecimals(props.data[dataPrefix + "_actupper_" + selectedTimeFrameOptionUS])}%]</td>
                                </tr>
                                <tr>
                                    <td className="">Expected probability of surviving {selectedSurvivalResultOption === "gsr" ? "with a functioning graft" : ""} at {timeFrame.digit} (adjusted for patient and donor characteristics)</td>
                                    <td className="text-center">{formatTwoDecimals(props.data[dataPrefix + "_adj_" + selectedTimeFrameOptionCenter])}%</td>
                                    <td className="text-center">--</td>
                                </tr>
                                <tr>
                                    <td className="">Number of observed {selectedSurvivalResultOption === "gsr" ? "graft failures (including deaths)" : "deaths"} during the {timeFrame.string} after transplant</td>
                                    <td className="text-center">{formatThousands(props.data[dataPrefix + "_obs_" + selectedTimeFrameOptionCenter])}</td>
                                    <td className="text-center">{formatThousands(props.data[dataPrefix + "_obs_" + selectedTimeFrameOptionUS])}</td>
                                </tr>
                                <tr>
                                    <td className="">Number of expected {selectedSurvivalResultOption === "gsr" ? "graft failures (including deaths)" : "deaths"} during the {timeFrame.string} after transplant</td>
                                    <td className="text-center">{formatTwoDecimals(props.data[dataPrefix + "_exp_" + selectedTimeFrameOptionCenter])}</td>
                                    <td className="text-center">--</td>
                                </tr>
                                <tr>
                                    <td className="">Estimated hazard ratio</td>
                                    <td className="text-center">{formatTwoDecimals(props.data[dataPrefix + "_hr_" + selectedTimeFrameOptionCenter])}</td>
                                    <td className="text-center">--</td>
                                </tr>
                                <tr>
                                    <td className="">95% credible interval for the hazard ratio</td>
                                    <td className="text-center">[{formatTwoDecimals(props.data[dataPrefix + "_credlo_" + selectedTimeFrameOptionCenter])}, {formatTwoDecimals(props.data[dataPrefix + "_credhi_" + selectedTimeFrameOptionCenter])}]</td>
                                    <td className="text-center">--</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Collapse>
        </>
    );
}

export default TransplantGraftsTable;

function getTimeFrameStrings(timeFrame){
    switch(timeFrame){
        case "c1m":
            return {digit: "1 month", string: "first month"};
        case "c1y":
            return {digit: "1 year", string: "first year"};
        case "c3y":
            return {digit: "3 years", string: "first 3 years"};
        case "c90d":
            return {digit: "90 days", string: "90 days"};
        case "c1yc":
            return {digit: "1 year (conditional on 90 day) ", string: "91-365 days"};
        default:
            return {digit: "", string: ""};;
    }
}