import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);

function TotalDonorsAndOrgansTransplantedLineChart(props) {
    let title = "Total Donors Transplanted";
    let series = [];
    let dbdData = [];
    let dcdData = [];
    let totalData = [];

    for (let i = 0; i < props.chartData.length; i++) {
        if (props.chartData[i].donorType.toLowerCase() === "total") {
            totalData.push(props.chartData[i].value);
        }
        else if (props.chartData[i].donorType.toLowerCase() === "dbd") {
            dbdData.push(props.chartData[i].value);
        }
        else {
            dcdData.push(props.chartData[i].value);
        }
    }

    series.push({
        name: "Total",
        data: totalData,
        color: "#2d62a1"
    });

    series.push({
        name: "DBD",
        data: dbdData,
        color: "#43a7cb"
    });

    series.push({
        name: "DCD",
        data: dcdData,
        color: "#9fd6ce"
    });

    let options = {
        exporting: {
                buttons: {
                    contextButton: {
                        menuItems: ['printChart', 'downloadPNG', 'downloadSVG', 'downloadPDF', 'downloadCSV']
                    },
                },
                title: {
                    text: `<div style="font-family:Open Sans;font-size:14px;font-weight: 700;text-align:center;">${title}</div><br /><div style="font-family:Open Sans;font-size:12px;font-weight: 400;text-align:center;"></div>`
                },
                chart: {
                    events: {
                        load: function () {
                            let width = 0;
                            let height = this.chartHeight - 50;
                            this.renderer.image('/srtr_logo.jpg', width, height, 50, 50)
                                .add();
                        }
                    }
                }
        },
        chart: {
            type: 'line',
        },

        credits: {
            enabled: false
        },
        title: "",
        xAxis: {
            labels: {
                style: {
                    fontSize: "14px"
                }
            },
            crosshair: {
                width: 3
            },
            categories: props.categories
        },
        yAxis: {
            title: {
                style: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"
                },
                text: "Number of Donors"
            },
            labels: {
                style: {
                    fontFamily: "Open Sans",
                    fontSize: "14px"
                }
            }
        },
        tooltip: {
            useHTML: true,
            formatter: function () {
                // The first returned item is the header, subsequent items are the
                // points
                return [''].concat(
                    this.points ?
                        this.points.map(function (point) {
                            return '<span style="font-family:Open Sans;font-size:16px;font-weight: 700;text-align:left">' + point.y + ' </span><span style="font-family:Open Sans;font-size:14px;font-weight: 400;text-align:left">' + point.series.name + '</span>';
                        }) : []
                );
            },
            split: true
        },
        legend: {
            labelFormatter: function () {
                return '<span class="area-label">' + this.name + '</span>';
            },
            itemStyle: {
                fontFamily: "Open Sans",
                fontSize: "14px"

            }
        },
        series: series
    };

    return (
        <div className="donors-organs-transplanted">
            <Row>
                <Col>
                    {options.xAxis.categories &&
                        <>
                            <div className="title">
                                <div className="organ-title">{title}</div>;
                            </div>
                            <div>
                                <HighchartsReact highcharts={Highcharts} options={options} oneToOne={true} containerProps={{ className: "highcharts-light" }}/>
                            </div>
                        </>
                    }

                </Col>
            </Row>
        </div>
    )
}

export default TotalDonorsAndOrgansTransplantedLineChart;