import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Table from "react-bootstrap/Table";
import {
  formatTwoDecimals,
  formatPValueTwoDecimals,
} from "../../../../utilities/formatter";
function ObservedExpectedYieldTable(props) {
  const [open, setOpen] = useState(false);
  const data = props.data;

  let title = `Observed and expected organ yield data ${props.begDate} to ${props.endDate}`;

  return (
    <>
      <hr />
      <div className="collapse-container show-all-row">
        {title} &nbsp;&nbsp;
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="collapse-text"
          aria-expanded={open}
          className="btn-dark-blue min-98"
        >
          {!open && "Show All"}
          {open && "Hide All"}
        </Button>
      </div>
      <Collapse in={open}>
        <div id="collapse-text" className="collapse-inner">
          <div className="table-responsive">
            <div className="sub-title">Swipe to view more data {">>>"}</div>
            <Table striped bordered className="observed-expected-yield-table">
              <thead>
                <tr>
                  <th>Number of Donors: {data.numberOfDonors}</th>
                  <th className="text-center">Aggregate Organ</th>
                  <th className="text-center">Heart</th>
                  <th className="text-center">Intestine</th>
                  <th className="text-center">Kidney</th>
                  <th className="text-center">Liver</th>
                  <th className="text-center">Lung*</th>
                  <th className="text-center">Pancreas</th>
                </tr>
              </thead>
              <tbody>
                {data.observedExpectedOrganYieldData.map((item, i) => {
                  if (item.description.startsWith("P Value")) {
                    return [
                      <tr key={i}>
                        <td>{item.description}</td>
                        <td className="text-center">
                          {formatPValueTwoDecimals(item.aggregateOrgan)}
                        </td>
                        <td className="text-center">
                          {item.heart
                            ? formatPValueTwoDecimals(item.heart)
                            : "--"}
                        </td>
                        <td className="text-center">
                          {item.intestine
                            ? formatPValueTwoDecimals(item.intestine)
                            : "--"}
                        </td>
                        <td className="text-center">
                          {item.kidney
                            ? formatPValueTwoDecimals(item.kidney)
                            : "--"}
                        </td>
                        <td className="text-center">
                          {item.liver
                            ? formatPValueTwoDecimals(item.liver)
                            : "--"}
                        </td>
                        <td className="text-center">
                          {item.lung
                            ? formatPValueTwoDecimals(item.lung)
                            : "--"}
                        </td>
                        <td className="text-center">
                          {item.pancreas
                            ? formatPValueTwoDecimals(item.pancreas)
                            : "--"}
                        </td>
                      </tr>,
                    ];
                  } else {
                    return [
                      <tr key={i}>
                        <td>{item.description}</td>
                        <td className="text-center">
                          {formatTwoDecimals(item.aggregateOrgan)}
                        </td>
                        <td className="text-center">
                          {item.heart ? formatTwoDecimals(item.heart) : "--"}
                        </td>
                        <td className="text-center">
                          {item.intestine
                            ? formatTwoDecimals(item.intestine)
                            : "--"}
                        </td>
                        <td className="text-center">
                          {item.kidney ? formatTwoDecimals(item.kidney) : "--"}
                        </td>
                        <td className="text-center">
                          {item.liver ? formatTwoDecimals(item.liver) : "--"}
                        </td>
                        <td className="text-center">
                          {item.lung ? formatTwoDecimals(item.lung) : "--"}
                        </td>
                        <td className="text-center">
                          {item.pancreas
                            ? formatTwoDecimals(item.pancreas)
                            : "--"}
                        </td>
                      </tr>,
                    ];
                  }
                })}
              </tbody>
            </Table>
          </div>
          <div className="foot-note">
            *Lung yield is calculated as the number of donors that had at least
            one lung transplanted.
          </div>
        </div>
      </Collapse>
    </>
  );
}

export default ObservedExpectedYieldTable;
