import React from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TrendsInOrgansTransplantedPerDonor from "./trends-in-organs-transplanted/TrendsInOrgansTransplantedPerDonor";
import ObservedExpectedOrganYieldRates from './observed-expected-organ-yield-rates/ObservedExpectedOrganYieldRates';
import ObservedExpectedOrganYield from './observed-expected-organ-yield/ObservedExpectedOrganYield';
import OrganUtilizationTable from "./OrganUtilizationTable";

function OrganYield(props) {
    return (
        <Row>
            <Col xs={12}>
                <Row>
                    <Col>
                        <Row>
                            <Col xs={12} className="section mt-3">
                                {props.utilizationData &&
                                    <ObservedExpectedOrganYieldRates
                                        data={props.utilizationData.observedExpectedOrganYield.observedExpectedOrganYieldRate}
                                    />
                                }
                                {!props.utilizationData &&
                                    <ObservedExpectedOrganYieldRates
                                        data={null}
                                    />
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="section">
                                <div className="outer-wrapper mt-0">
                                    <div className="inner-wrapper">
                                        {props.utilizationData &&
                                            <ObservedExpectedOrganYield
                                                data={props.utilizationData.observedExpectedOrganYield}
                                            />
                                        }
                                        {!props.utilizationData &&
                                            <ObservedExpectedOrganYield
                                                data={null}
                                            />
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="section">
                                {props.utilizationData &&
                                    <OrganUtilizationTable
                                        data={props.utilizationData.organUtilizationTable}
                                    />
                                }
                                {!props.utilizationData &&
                                    <OrganUtilizationTable
                                        data={null}
                                    />
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="section">
                                <div className="outer-wrapper mt-0">
                                    <div className="inner-wrapper">
                                        {props.utilizationData &&
                                            <TrendsInOrgansTransplantedPerDonor
                                                opoCode={props.utilizationData.opoCode}
                                                data={props.utilizationData.trendsInOrgansTransplantedPerDonor}
                                            />
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default OrganYield;