import React from "react";
import {formatOneDecimal, formatThousands} from "../../../utilities/formatter";
function PrimaryDisease(props){
    if (props.organ !== "pa" && props.organ !== "kp") {
        return (
            <>
                <tr>
                    <td colSpan="11" className="text-left font-weight-bold">Primary Disease</td>
                </tr>
                {props.organ === "ki" &&
                    <>
                        <tr>
                            <td className="padding-left-30">Glomerular Diseases</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ki_glo_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_glo_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_glo_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_glo_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_glo_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ki_glo_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_glo_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_glo_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_glo_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_glo_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Tubular &amp; Interstitial Diseases</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ki_tub_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_tub_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_tub_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_tub_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_tub_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ki_tub_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_tub_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_tub_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_tub_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_tub_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Polycystic Kidneys</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ki_pol_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_pol_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_pol_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_pol_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_pol_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ki_pol_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_pol_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_pol_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_pol_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_pol_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Congenital, Familial, Metabolic</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ki_con_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_con_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_con_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_con_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_con_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ki_con_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_con_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_con_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_con_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_con_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Diabetes</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ki_dia_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_dia_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_dia_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_dia_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_dia_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ki_dia_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_dia_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_dia_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_dia_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_dia_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Renovascular &amp; Vascular Diseases</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ki_ren_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_ren_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_ren_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_ren_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_ren_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ki_ren_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_ren_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_ren_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_ren_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_ren_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Neoplasms</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ki_neo_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_neo_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_neo_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_neo_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_neo_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ki_neo_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_neo_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_neo_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_neo_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_neo_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Hypertensive Nephrosclerosis</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ki_hyp_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_hyp_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_hyp_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_hyp_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_hyp_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ki_hyp_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_hyp_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_hyp_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_hyp_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_hyp_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Other</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ki_oth_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_oth_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_oth_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_oth_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_oth_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ki_oth_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_oth_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_oth_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_oth_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_oth_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Missing</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ki_mis_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_mis_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_mis_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_mis_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_mis_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_ki_mis_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_mis_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_mis_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_mis_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_ki_mis_put4))}</td>
                        </tr>
                    </>
                }
                {props.organ === "in" &&
                    <>
                        <tr>
                            <td className="padding-left-30">Short Gut Syndrome</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_in_sgs_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_sgs_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_sgs_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_sgs_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_sgs_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_in_sgs_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_sgs_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_sgs_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_sgs_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_sgs_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Functional Bowel Problem</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_in_fbp_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_fbp_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_fbp_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_fbp_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_fbp_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_in_fbp_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_fbp_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_fbp_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_fbp_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_fbp_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Other</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_in_oth_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_oth_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_oth_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_oth_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_oth_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_in_oth_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_oth_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_oth_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_oth_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_oth_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Missing</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_in_mis_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_mis_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_mis_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_mis_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_mis_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_in_mis_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_mis_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_mis_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_mis_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_in_mis_put4))}</td>
                        </tr>
                    </>
                }
                {props.organ === "lu" &&
                    <>
                        <tr>
                            <td className="padding-left-30">Congenital Disease</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lu_con_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_con_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_con_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_con_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_con_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lu_con_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_con_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_con_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_con_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_con_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Retransplant/Graft Failure</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lu_ret_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_ret_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_ret_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_ret_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_ret_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lu_ret_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_ret_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_ret_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_ret_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_ret_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Idiopathic Pulmonary Arterial Hypertension</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lu_pri_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_pri_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_pri_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_pri_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_pri_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lu_pri_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_pri_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_pri_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_pri_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_pri_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Cystic Fibrosis</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lu_cys_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_cys_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_cys_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_cys_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_cys_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lu_cys_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_cys_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_cys_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_cys_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_cys_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Idiopathic Pulmonary Fibrosis</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lu_idi_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_idi_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_idi_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_idi_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_idi_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lu_idi_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_idi_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_idi_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_idi_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_idi_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Alpha-1-Antitrypsin Deficiency</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lu_alp_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_alp_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_alp_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_alp_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_alp_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lu_alp_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_alp_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_alp_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_alp_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_alp_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Emphysema/COPD</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lu_emp_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_emp_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_emp_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_emp_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_emp_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lu_emp_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_emp_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_emp_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_emp_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_emp_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Other</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lu_oth_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_oth_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_oth_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_oth_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_oth_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lu_oth_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_oth_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_oth_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_oth_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_oth_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Missing</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lu_mis_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_mis_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_mis_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_mis_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_mis_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_lu_mis_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_mis_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_mis_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_mis_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_lu_mis_put4))}</td>
                        </tr>
                    </>
                }
                {props.organ === "li" &&
                    <>
                        <tr>
                            <td className="padding-left-30">Acute Hepatic Necrosis</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_li_acu_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_acu_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_acu_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_acu_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_acu_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_li_acu_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_acu_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_acu_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_acu_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_acu_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Non-Cholestatic Cirrhosis</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_li_nch_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_nch_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_nch_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_nch_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_nch_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_li_nch_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_nch_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_nch_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_nch_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_nch_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Cholestatic Liver Disease/Cirrhosis</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_li_cho_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_cho_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_cho_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_cho_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_cho_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_li_cho_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_cho_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_cho_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_cho_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_cho_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Biliary Atresia</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_li_bil_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_bil_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_bil_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_bil_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_bil_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_li_bil_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_bil_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_bil_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_bil_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_bil_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Metabolic Diseases</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_li_met_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_met_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_met_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_met_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_met_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_li_met_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_met_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_met_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_met_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_met_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Malignant Neoplasms</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_li_mal_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_mal_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_mal_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_mal_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_mal_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_li_mal_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_mal_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_mal_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_mal_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_mal_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Other</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_li_oth_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_oth_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_oth_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_oth_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_oth_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_li_oth_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_oth_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_oth_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_oth_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_oth_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Missing</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_li_mis_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_mis_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_mis_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_mis_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_mis_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_li_mis_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_mis_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_mis_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_mis_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_li_mis_put4))}</td>
                        </tr>
                    </>
                }
                {props.organ === "hr" &&
                    <>
                        <tr>
                            <td className="padding-left-30">Cardiomyopathy</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_hr_car_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_car_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_car_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_car_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_car_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_hr_car_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_car_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_car_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_car_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_car_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Coronary Artery Disease</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_hr_cor_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_cor_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_cor_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_cor_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_cor_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_hr_cor_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_cor_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_cor_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_cor_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_cor_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Retransplant/Graft Failure</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_hr_rtr_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_rtr_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_rtr_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_rtr_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_rtr_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_hr_rtr_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_rtr_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_rtr_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_rtr_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_rtr_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Valvular Heart Disease</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_hr_vlv_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_vlv_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_vlv_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_vlv_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_vlv_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_hr_vlv_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_vlv_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_vlv_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_vlv_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_vlv_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Congenital Heart Disease</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_hr_con_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_con_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_con_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_con_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_con_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_hr_con_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_con_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_con_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_con_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_con_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Other</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_hr_oth_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_oth_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_oth_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_oth_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_oth_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_hr_oth_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_oth_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_oth_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_oth_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_oth_put4))}</td>
                        </tr>
                        <tr>
                            <td className="padding-left-30">Missing</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_hr_mis_nc))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_mis_pct1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_mis_pct2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_mis_pct3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_mis_pct4))}</td>
                            <td className="text-center">{formatThousands(parseFloat(props.data.tpc_hr_mis_nu))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_mis_put1))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_mis_put2))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_mis_put3))}</td>
                            <td className="text-center">{formatOneDecimal(parseFloat(props.data.tpc_hr_mis_put4))}</td>
                        </tr>
                    </>
                }
            </>
        );
    }

    return null;
}

export default PrimaryDisease;