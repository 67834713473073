import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Table from "react-bootstrap/Table";
import IconList from "../../../utilities/organs/IconList";
import moment from "moment";

function TransplantProgramsTable(props) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <div className="collapse-container show-all-row">
                Transplant Programs within the OPO’s Donation Service Area &nbsp;&nbsp;
                <Button
                    onClick={() => setOpen(!open)}
                    aria-controls="collapse-text"
                    aria-expanded={open}
                    className="btn-dark-blue min-155"
                >
                    {!open && "Show Programs"}
                    {open && "Hide Programs"}
                </Button>
            </div>
            <Collapse in={open}>
                <div id="collapse-text" className="collapse-inner">
                    <Table striped bordered className="transplant-program-list">
                        <thead>
                            <tr>
                                <th>Hospital</th>
                                <th>Organs Transplanted</th>
                            </tr>

                        </thead>
                        <tbody>
                            {props.data && props.data.map((item, i) => {
                                return [
                                    <tr key={item.properties.id}>
                                        <td>
                                            <div>{item.properties.name}</div>
                                            <div>{item.properties.city}, {item.properties.state}</div>
                                        </td>
                                        <td>
                                            <div className="organs">
                                                <IconList item={item.properties} />
                                            </div>
                                        </td>
                                    </tr>
                                ];
                            })}
                        </tbody>
                    </Table>
                    <div className="foot-note">
                        {props.data && props.data[0] &&
                            <span>Performed at least one transplant of that type from {moment(new Date(props.data[0].properties.begDate)).format("MM/DD/YYYY")} to {moment(new Date(props.data[0].properties.endDate)).format("MM/DD/YYYY")}</span>
                        }
                    </div>
                </div>
            </Collapse>
        </>
    );
}

export default TransplantProgramsTable;